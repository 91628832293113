/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";

import React, { useEffect, useState } from "react";
import { MusicUpload } from "../../icons/MusicUpload";
import { PlayIcon2 } from "../../icons/PlayIcon2";
import { Expand } from "../Expand";
import { FrameWrapper3 } from "../FrameWrapper3";
import "./style.css";
import { toastError } from '../../constants/Constants';

import { FiRrCloudUpload } from "./../../icons/FiRrCloudUpload";
import { connect } from "react-redux";
import { Box, CircularProgress } from "@mui/material";
import { setEventSuccess, uploadStudentInformation } from "../../store/Actions/PreEventAction";
const PropertyDefaultWrapper = ({
  property1,
  className,
  divClassName,
  text = "Event ID",
  text1 = "",
  visible = true,
  visibleupload = true,
  frameWrapperVisible,
  frameWrapperVisibleinfo,
  frameWrapperText = "Please upload MP3 format only.",
  divClassNameOverride,
  expandPolygon = "/img/polygon-1-3.svg",
  expandPolygonClassName,
  frameWrapperCheckboxPropertyFiRrClassName,
  eventDetails,
  postStudentInformation,
  handleStudentInfo,
  eventSuccess,
  setEventSuccess
}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if(eventSuccess){
      setEventSuccess(false);
      handleStudentInfo();
    }
  }, [eventSuccess])

  // Opens file explorer to select the desired file
  const handleDivClick = () => {
    // if (event.eventlist.result.eventData.studentDataUploaded) { 
    //   return;
    // }
    document.getElementById('fileInput').click();
  };

// Adds the user selected image file to the UI
  const handleFileInputChange = (eventFile) => {
    const file = eventFile.target.files[0];
    if (file) {
      // Check if the selected file's type is allowed
      const allowedTypes = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
      if (allowedTypes.includes(file.type)) {
        setSelectedFile(file);
      } else {
        // If the selected file type is not allowed, display an error message or handle it accordingly
        toastError('Invalid file type. Please select a .xlsx file.');
      }
    }
  };
  

  // Submits the student info and calls function to send data to API
  const handleUploadButtonClick = async () => {
    if(!selectedFile){
      return;
    }
    // Trigger postStudentInformation function with the selected file
    if (selectedFile) {
      setLoading(true);
      await postStudentInformation(selectedFile, {
        EventId: eventDetails.eventId,
        EventName: eventDetails.eventName,
        ClientId: eventDetails.clientId,
        ClientName: eventDetails.clientName,
      });
      setLoading(false);
    }
  };


  return (
    <div className={`property-default-wrapper property-1-0-${property1} ${className}`}>
      <div className="frame-15">
        <div className="event-ID-wrapper">
          <div className={`event-ID ${divClassName}`}>{text}</div>
        </div>
        <div className="frame-16">
          <div className="frame-17" onClick={handleDivClick}>

            {["default", "variant-3"].includes(property1) && (
              <>
                <div className="frame-18">
                  <div className="element-wrapper">
                    <div className="element" title={selectedFile ? selectedFile.name : ""}>
                      {selectedFile ? selectedFile.name : text1}
                      </div>
                    <input
                      id="fileInput"
                      type="file"
                      accept=".xlsx"
                      className="element"
                      name="FileUpload"
                      onChange={handleFileInputChange}
                      style={{ display: 'none' }}
                    />
                  </div>
                </div>{visibleupload && <FiRrCloudUpload />}
                <>{visible && <Expand polygon={expandPolygon} polygonClassName={expandPolygonClassName} />}</>
                {/* <button onClick={deleteUploadedFile}>Delete</button> */}
              </>

            )}


            {property1 === "variant-2" && (
              <>
                <div className="frame-19">
                  <div className="frame-18">
                    <div className="text-wrapper-4">{text1}</div>
                  </div>
                  <Expand polygon="/img/polygon-1-3.svg" />
                </div>
                <div className="frame-20">
                  <div className="frame-21">
                    <div className="frame-18">
                      <div className="text-wrapper-4">background-music.mp3</div>
                    </div>
                    <PlayIcon2 className="instance-node" />
                  </div>
                  <div className="frame-21">
                    <div className="frame-18">
                      <div className="text-wrapper-4">background-music.mp3</div>
                    </div>
                    <PlayIcon2 className="instance-node" />
                  </div>
                  <div className="frame-21">
                    <div className="frame-18">
                      <div className="text-wrapper-4">background-music.mp3</div>
                    </div>
                    <PlayIcon2 className="instance-node" />
                  </div>
                  <div className="frame-22">
                    <div className="frame-18">
                      <div className="text-wrapper-5">Upload background music clip</div>
                    </div>
                    <MusicUpload className="instance-node" />
                  </div>
                </div>
              </>
            )}
          </div>
          <br />
          <div className="delete-button button-text-wrapper" style = {{ backgroundColor: `${selectedFile ? "" : "#7A7979"}`, cursor: `${selectedFile ? "" : "not-allowed"}` }}>
            <button className="button" type="button" style = {{ backgroundColor: `${selectedFile ? "" : "#7A7979"}`, cursor: `${selectedFile ? "" : "not-allowed"}` }} onClick={handleUploadButtonClick}>UPLOAD</button>
          </div>

          <FrameWrapper3
            checkboxPropertyFiRrClassName={frameWrapperCheckboxPropertyFiRrClassName}
            className="frame-1027"
            text={frameWrapperText}
            visible={frameWrapperVisible}
            visibleinfo={frameWrapperVisibleinfo}
          />
          {/* {loading && (
            <div className="loader-container">
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px' }}>
                <CircularProgress />
              </Box>
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
};

PropertyDefaultWrapper.propTypes = {
  property1: PropTypes.oneOf(["variant-2", "variant-3", "default"]),
  text: PropTypes.string,
  text1: PropTypes.string,
  visible: PropTypes.bool,
  frameWrapperVisible: PropTypes.bool,
  frameWrapperText: PropTypes.string,
  expandPolygon: PropTypes.string,
};


const mapStateToProps = (state) => {
  return {
    eventDetails: state.preEvent.eventDetails,
    eventSuccess: state.preEvent.eventSuccess,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    postStudentInformation: (file, infoData) => dispatch(uploadStudentInformation(file, infoData)),  
    setEventSuccess: (flag) => dispatch(setEventSuccess(flag)),
  };
};

const ConnectedPropertyDefaultWrapper = connect(mapStateToProps, mapDispatchToProps)(PropertyDefaultWrapper);

export { ConnectedPropertyDefaultWrapper as PropertyDefaultWrapper };