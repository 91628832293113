import React, { useState, useEffect } from "react";
import "./style_pedd.css";
import { StyledEngineProvider } from '@mui/material/styles';
import { CustomVideoReview } from "../CustomVideoReview";
import  DropDown  from "../../../components/DropDown/DropDown";
import Datagrid from "../Datagrid";
import homeImage from '../../../icons/ImageIcons/material-symbols_home-rounded.svg';

import rightarrow from '../../../icons/ImageIcons/keyboard-arrow-right.svg';
import searchicon from '../../../icons/ImageIcons/searchicon.png';
import { getClientData } from "../../../constants/Constants";
import { FetchCustomerReviewEvents } from "../../../store/Actions/PreEventAction";
import { connect } from "react-redux";
const CustomVideoReviewDashboard = ({loadevent, eventlist, seasons}) => {
  
  const [selectedRowCount, setSelectedRowCount] = useState([]);
  const [rows, setRows] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [seasonId, setSeasonId] = useState(0);
  const clientData = getClientData();

  useEffect(() => {
    if (clientData) {
      loadevent(
        clientData.clientID
      );
    }
  }, [])

  useEffect(() => {
    setRows((Array.isArray(eventlist?.result?.result) ? eventlist?.result?.result : []).map(row => ({
        id: row.eventId,
        eventName: row.eventName,
        eventDateTime: row.EventDateTime,
        clientName: row.clientName,
        template: row.template,
        clientId: row.clientId,
        eventId: row.eventId,
        eventstatuspercent: row.videoReviewStatus, // Assuming percentComplete is a value between 0 and 1
        seasonName: row.seasonName,
        seasonId: row.seasonId
    })));
}, [eventlist])

useEffect(() => {
  filterDataFun(searchQuery, seasonId);
}, [seasonId, rows])

  // Filter rows based on the search query
  const filterRows = (query) => {
    return rows.filter(row =>
      (row.eventName.toLowerCase().includes(query.toLowerCase()) ||
      row.clientName.toLowerCase().includes(query.toLowerCase()) ||
      row.eventstatus.toLowerCase().includes(query.toLowerCase())) &&
      row.template.toLowerCase().includes('custom')
    );
  };

  // Event handler for search input change
  const handleSearchChange = (event) => {
    const query = event.target.value;
    filterDataFun(query, seasonId);
    setSearchQuery(query);
  };

  // Filters the rows based on seasons and input value
  const filterDataFun = (query, seasonId) => {
    console.log("rows", query, seasonId, rows);
    setSearchQuery(query);

    if (seasonId === '0') {
      const filteredRows = rows.filter(row => {
        // Customize the search logic based on your requirements
        return (
          ((row.eventName.toLowerCase().includes(query.toLowerCase()) ||
      row.clientName.toLowerCase().includes(query.toLowerCase()) ||
      row.eventstatus.toLowerCase().includes(query.toLowerCase())) &&
      row.template.toLowerCase().includes('custom'))
        );
      });
      setFilteredData(filteredRows);
    } else {
      const filteredRows = rows.filter(row => {
        // Customize the search logic based on your requirements
        return (
          ((row.eventName.toLowerCase().includes(query.toLowerCase()) ||
      row.clientName.toLowerCase().includes(query.toLowerCase()) ||
      row.eventstatus.toLowerCase().includes(query.toLowerCase())) &&
      row.template.toLowerCase().includes('custom'))
          && (
            (seasonId > 0 && row.seasonId == seasonId) || !seasonId
          )
        );
      });
      setFilteredData(filteredRows);
    }
  }

  // Changes the choice of season option
  const onChangeSeason = (id) => {
    setSeasonId(id);
    setSearchQuery('');
  }

  // Filtering events based on current season
  const filterEventsBySeason = (events) => {
    let selectedSeason;
    if (seasonId) {
      // Find the season object based on the seasonId
      selectedSeason = seasons?.find(season => season.seasonId === seasonId);
    } else {
      const currentDate = new Date();
      selectedSeason = seasons?.find(season => {
        const startDate = new Date(season.startDate);
        const endDate = new Date(season.endDate);
        return currentDate >= startDate && currentDate <= endDate;
      });
    }

    if (selectedSeason) {
      return events.filter(event => {
        const eventDate = new Date(event.eventDateTime);
        return eventDate >= new Date(selectedSeason.startDate) && eventDate <= new Date(selectedSeason.endDate);
      });
    }

    // If no season is selected or found, return all events
    return events.filter(row =>
      (row.eventName.toLowerCase().includes(''.toLowerCase()) ||
      row.clientName.toLowerCase().includes(''.toLowerCase()) ||
      row.eventstatus.toLowerCase().includes(''.toLowerCase())) &&
      row.template.toLowerCase().includes('custom')
    );;
  };

  const filteredEvents = filterEventsBySeason(searchQuery.trim() !== "" || seasonId > 0 ? filteredData : rows);

  return (
    
    <div className="custom-video-review-dashboard">
      <div className="div-2">
       <CustomVideoReview />
        <div className="frame-19">
          <div className="frame-20">
          <div className="section-title">
              <div className="frame-21">
                <div className="page-title">
                  <div className="pagetitle">Custom Video Review</div>
                </div>
                <div className="breadcrum">
                  <img
                    className="material-symbols"
                    alt="Material symbols"
                    src={homeImage}
                  />
                  <img
                    className="keyboard-arrow-right"
                    alt="Keyboard arrow right"
                    src={rightarrow}
                  />
                  <div className="text-wrapper-4">Custom Video Review</div>
                  <img
                    className="keyboard-arrow-right"
                    alt="Keyboard arrow right"
                    src={rightarrow}
                  />
                  <div className="text-wrapper-5">Custom Video Review Dashboard</div>
                </div>
              </div>
            </div>
            <div className="frame-data">

            <div className="frame-datasearch">
              
              <div className="frame-datasearch2">
                <div className="search">
                  <p className="label-and">
                    <input className="input" placeholder="Search" type="text" value={searchQuery} onChange={handleSearchChange}/>
                  </p>
                  <img
                    className="generic"
                    alt="Generic"
                    src={searchicon}
                  />
                </div>
                
              </div>
              
              <div className="frame-datafilter">
              <StyledEngineProvider injectFirst>
      <DropDown onChangeSeason={onChangeSeason} />
    </StyledEngineProvider>
              </div>
              {/* <div className="frame-datafilter2">
    <button type="button" class="apply-season-settings"  disabled={selectedRowCount.length == 0}>Apply Season Settings</button>
              </div> */}
            </div>
            
            <div className="frame-datagrid">
              <StyledEngineProvider injectFirst>
      <Datagrid rows={filteredEvents} setSelectedRowCount={setSelectedRowCount} selectedRowCount={selectedRowCount} />
    </StyledEngineProvider>
    </div>

           </div>
            
          </div>
          
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
      eventlist: state.preEvent.customReviewEventList,
      seasons: state.shared.seasonList?.result,    
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
      loadevent: (clientId) => dispatch(FetchCustomerReviewEvents(clientId)),
  };
};

const ConnectedCustomVideoReviewDashboard = connect(mapStateToProps, mapDispatchToProps)(CustomVideoReviewDashboard);

export { ConnectedCustomVideoReviewDashboard as CustomVideoReviewDashboard };