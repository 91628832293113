import PropTypes from "prop-types";
import React from "react";
import { Home } from "../Home";
import "./style.css";

export const LeftNav = ({
  className,
  overlapGroupClassName,
  frameClassName,
  homeText = "45%",
  homeText1 = "45%",
  homeHasGroup,
  tab,
}) => {
  return (
    <div className={`left-nav ${className}`}>
      <div className={`overlap-group-2 ${overlapGroupClassName}`}>
        <div className={`frame-11 ${frameClassName}`}>
          <Home
            className={tab === 'preEvent' ? "home-instance" : "home-3"}
            fiRrGraduationCap6Color={tab === 'preEvent' ? "white" : "#4A3AFF"}
            divClassName={tab === 'preEvent' ? "" : "home-4"}
            text="Pre Event Details"
            hasGroup={homeHasGroup}
            to="/PreEventDetailsDashboard"
          />
          <Home
            className={tab === 'customVideoReview' ? "home-instance" : "home-3"}
            divClassName={tab === 'customVideoReview' ? "" : "home-4"}
            fiRrGraduationCap6Color={tab === 'customVideoReview' ? "white" : "#4A3AFF"}
            hasGroup={homeHasGroup}
            text="Custom Video Review"
            to="/CustomVideoReviewDashboard"
          />
          <Home
            className={tab === 'postEvent' ? "home-instance" : "home-3"}
            divClassName={tab === 'postEvent' ? "" : "home-4"}
            fiRrGraduationCap6Color={tab === 'postEvent' ? "white" : "#4A3AFF"}
            hasGroup={homeHasGroup}
            text="Post Event Details"
            to="/PostEventDetailsDashboard"
          />
          <Home
            className={tab === 'seasonSettings' ? "home-instance" : "home-3"}
            divClassName={tab === 'seasonSettings' ? "" : "home-4"}
            fiRrGraduationCap6Color={tab === 'seasonSettings' ? "white" : "#4A3AFF"}
            text="Season Settings"
            hasGroup={homeHasGroup}
            to="/"
          />
        </div>
      </div>
    </div>
  );
};

LeftNav.propTypes = {
  homeText: PropTypes.string,
  homeText1: PropTypes.string,
  homeHasGroup: PropTypes.bool,
  tab: PropTypes.string.isRequired,
};
