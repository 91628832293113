import PropTypes from "prop-types";
import React, { useState } from "react";
import "./style.css";

export const StandardBtn = ({ property1, text, onGradReelTypeChange, selected }) => {
  const handleClick = () => {
    onGradReelTypeChange();
  };

  return (
    <div
      className={`standard-btn ${property1} ${selected ? 'selected' : ''}`}
      onClick={handleClick}
    >
      <div className="standard-gradreel">{text}</div>
    </div>
  );
};

StandardBtn.propTypes = {
  property1: PropTypes.oneOf(['variant-4', 'variant-3', 'default']),
  text: PropTypes.string,
  onGradReelTypeChange: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
};
