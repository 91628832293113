/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const RadioButtons5 = ({ className }) => {
  return (
    <svg
      className={`radio-buttons-5 ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_251_10408)">
        <path
          className="path"
          d="M22.8516 6.86632C21.8719 4.78976 20.3203 3.04601 18.3656 1.82257C15.6469 0.125693 12.4266 -0.413369 9.30468 0.308506C6.18281 1.02569 3.52499 2.91944 1.82812 5.63819C0.126556 8.35694 -0.412506 11.5726 0.309369 14.6991C1.03124 17.821 2.92499 20.4788 5.63906 22.1757C7.55156 23.371 9.74531 24.0038 11.9906 24.0038H12.1359C14.3719 23.9757 16.5469 23.3288 18.4312 22.1382C18.9797 21.7913 19.1391 21.0694 18.7922 20.521C18.4453 19.9726 17.7234 19.8132 17.175 20.1601C15.6609 21.121 13.9078 21.6413 12.1078 21.6648C10.2609 21.6882 8.45624 21.1773 6.88124 20.1976C4.69218 18.8288 3.16874 16.6913 2.59218 14.1788C2.01562 11.6663 2.44687 9.07882 3.81562 6.88976C6.63749 2.37569 12.6094 0.997569 17.1234 3.81944C18.6984 4.80382 19.9453 6.20538 20.7328 7.87413C21.5016 9.50069 21.8016 11.3054 21.6 13.0866C21.5297 13.7288 21.9891 14.3101 22.6359 14.3804C23.2781 14.4507 23.8594 13.9913 23.9297 13.3444C24.1781 11.1273 23.8031 8.88663 22.8516 6.86632Z"
          fill="#D7D7D7"
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_251_10408">
          <rect className="rect" fill="white" height="24" width="24" />
        </clipPath>
      </defs>
    </svg>
  );
};
