/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const FiRrPicture1 = ({ className }) => {
  return (
    <svg
      className={`fi-rr-picture-1 ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 25 24"
      width="25"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_424_3300)">
        <path
          className="path"
          d="M19.5 -0.000488281H5.5C4.17441 0.00109958 2.90356 0.528394 1.96622 1.46573C1.02888 2.40307 0.501588 3.67392 0.5 4.99951L0.5 18.9995C0.501588 20.3251 1.02888 21.596 1.96622 22.5333C2.90356 23.4706 4.17441 23.9979 5.5 23.9995H19.5C20.8256 23.9979 22.0964 23.4706 23.0338 22.5333C23.9711 21.596 24.4984 20.3251 24.5 18.9995V4.99951C24.4984 3.67392 23.9711 2.40307 23.0338 1.46573C22.0964 0.528394 20.8256 0.00109958 19.5 -0.000488281V-0.000488281ZM5.5 1.99951H19.5C20.2956 1.99951 21.0587 2.31558 21.6213 2.87819C22.1839 3.4408 22.5 4.20386 22.5 4.99951V18.9995C22.4983 19.445 22.3957 19.8843 22.2 20.2845L13.037 11.1215C12.5727 10.6571 12.0214 10.2887 11.4147 10.0373C10.808 9.78595 10.1577 9.65658 9.501 9.65658C8.84428 9.65658 8.19399 9.78595 7.58728 10.0373C6.98056 10.2887 6.42931 10.6571 5.965 11.1215L2.5 14.5855V4.99951C2.5 4.20386 2.81607 3.4408 3.37868 2.87819C3.94129 2.31558 4.70435 1.99951 5.5 1.99951ZM5.5 21.9995C4.70435 21.9995 3.94129 21.6834 3.37868 21.1208C2.81607 20.5582 2.5 19.7952 2.5 18.9995V17.4135L7.378 12.5355C7.6566 12.2567 7.9874 12.0356 8.35151 11.8847C8.71561 11.7338 9.10587 11.6562 9.5 11.6562C9.89413 11.6562 10.2844 11.7338 10.6485 11.8847C11.0126 12.0356 11.3434 12.2567 11.622 12.5355L20.785 21.6995C20.3848 21.8952 19.9455 21.9978 19.5 21.9995H5.5Z"
          fill="#717171"
        />
        <path
          className="path"
          d="M16.5002 10.499C17.1925 10.499 17.8692 10.2938 18.4447 9.90917C19.0203 9.52458 19.4689 8.97796 19.7338 8.33842C19.9987 7.69888 20.068 6.99514 19.933 6.31621C19.7979 5.63728 19.4646 5.01364 18.9751 4.52415C18.4856 4.03467 17.862 3.70132 17.1831 3.56628C16.5041 3.43123 15.8004 3.50054 15.1609 3.76545C14.5213 4.03035 13.9747 4.47896 13.5901 5.05453C13.2055 5.6301 13.0002 6.30679 13.0002 6.99903C13.0002 7.92728 13.369 8.81752 14.0254 9.4739C14.6817 10.1303 15.572 10.499 16.5002 10.499ZM16.5002 5.49903C16.7969 5.49903 17.0869 5.587 17.3336 5.75182C17.5803 5.91664 17.7725 6.15091 17.8861 6.425C17.9996 6.69909 18.0293 7.00069 17.9714 7.29166C17.9135 7.58263 17.7707 7.84991 17.5609 8.05969C17.3511 8.26946 17.0839 8.41233 16.7929 8.4702C16.5019 8.52808 16.2003 8.49838 15.9262 8.38484C15.6521 8.27131 15.4179 8.07905 15.253 7.83238C15.0882 7.58571 15.0002 7.2957 15.0002 6.99903C15.0002 6.6012 15.1583 6.21967 15.4396 5.93836C15.7209 5.65706 16.1024 5.49903 16.5002 5.49903Z"
          fill="#717171"
        />
     </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_424_3300">
          <rect className="rect" fill="white" height="24" transform="translate(0.5 -0.000488281)" width="24" />
        </clipPath>
      </defs>
      
    </svg>
  );
};
