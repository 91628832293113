/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const MusicUpload = ({ className }) => {
  return (
    <svg
      className={`music-upload ${className}`}
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M5.99984 11.333V7.33301L4.6665 8.66634"
        stroke="#4A3AFF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        className="path"
        d="M6 7.33301L7.33333 8.66634"
        stroke="#4A3AFF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        className="path"
        d="M14.6668 6.66634V9.99967C14.6668 13.333 13.3335 14.6663 10.0002 14.6663H6.00016C2.66683 14.6663 1.3335 13.333 1.3335 9.99967V5.99967C1.3335 2.66634 2.66683 1.33301 6.00016 1.33301H9.3335"
        stroke="#4A3AFF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        className="path"
        d="M14.6668 6.66634H12.0002C10.0002 6.66634 9.3335 5.99967 9.3335 3.99967V1.33301L14.6668 6.66634Z"
        stroke="#4A3AFF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
};
