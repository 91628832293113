import React from "react";
import Modal from "react-modal";
import CropperComponent from "./CropperComponent";


const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)", 
  },
};

const ImageModal = ({ modalIsOpen, onClose, image, ratio, onCropCompleted, onUpload, onFileCropping }) => {
  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={onClose}
        style={customStyles}
        contentLabel="Example Modal"
      >
        {image && (
          <CropperComponent
            onCropCompleted={onCropCompleted}
            onUpload={onUpload}
            onFileCropping={onFileCropping}
            imgName={image.name}
            src={image}
            onClose={onClose}
            ratio={ratio}
          />
        )}
      </Modal>
    </div>
  );
};

export default ImageModal;
