import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Player } from "video-react";

import "./style.css";

import { RadioButtons4 } from "../../icons/RadioButtons4";
import { RadioButtons5 } from "../../icons/RadioButtons5";

import { PropertyDefaultWrapperMusic } from "../PropertyDefaultWrapperMusic";
import { RadioBtnMusicClip } from "../RadioBtnMusicClip";

const UploadMediaClipSection = ({
  property1,
  className,
  radioBtnMusicClipRadioOptionIcon = <RadioButtons4 className="radio-buttons-2" />,
  override = <RadioButtons5 className="radio-buttons-2" />,
  frameWrapperCheckboxPropertyFiRrClassName,
  isChecked,
  onCheckboxChange,
  onFileChange,
  postEventData,
  ceremonyVideoUri,
  isStreamingEvent,
  selectedRadioButton,
  onRadioButtonChange,
  streamingURLChange,
  eventState,
  submitButtonEnabled
}) => {
  const [variant, setVariant] = useState(property1 || "default");
  const [uploadedMediaUrl, setUploadedMediaUrl] = useState(null);
  const [uploadedStreamingUrl, setUploadedStreamingUrl] = useState(null);
  const isDisabled = ['ProcessingInProgress', 'ProcessingFailed', 'PartialSuccess', 'AssetPublished', 'RequestForProcessing'].includes(eventState);

  useEffect(() => {
    onRadioButtonChange(selectedRadioButton);
  }, [selectedRadioButton, onRadioButtonChange]);

  useEffect(() => {
    setUploadedMediaUrl(postEventData?.result?.postEventData?.uploadedFilePathSasUri);
    setUploadedStreamingUrl(postEventData?.result?.postEventData?.streamingUrl);
    if (postEventData?.result?.postEventData?.uploadedFilePathSasUri || postEventData?.result?.postEventData?.streamingUrl) {
      submitButtonEnabled(true);
    }
  }, [postEventData]);

  useEffect(() => {
    setVariant(isStreamingEvent ? "variant-2" : "default");
  }, [isStreamingEvent]);

  const handleStreamingUrlChange = (event) => {
    streamingURLChange(event.target.value);
    setUploadedStreamingUrl(event.target.value);
  };

  const handleFileChange = (file) => {
    onFileChange(file);
  };

  return (
    <div className={`upload-media-clip-section property-1-3-${variant} ${className}`}>
      <div className="frame-23">
        {variant === "default" && (
          <>
            <div className="frame-radio-button">
              <RadioBtnMusicClip
                className="radio-btn-music-clip-instance"
                property1="default"
                radioOptionIcon={radioBtnMusicClipRadioOptionIcon}
                radioOptionItemClassName="radio-btn-music-clip-2"
                radioOptionText="Upload Media"
                disable={isStreamingEvent}
              />
              <RadioBtnMusicClip
                className="radio-btn-music-clip-instance"
                onClick={() => {
                  setVariant("variant-2");
                  onRadioButtonChange("variant-2");
                }}
                property1="default"
                radioOptionIcon={override}
                radioOptionItemClassName="radio-btn-music-clip-3"
                radioOptionText="Streaming URL"
                disable={!isStreamingEvent}
              />
            </div>
            {uploadedMediaUrl ? (
              <>
                <div className="frame-24">
                </div>
                <Player>
                  <source src={uploadedMediaUrl} type="video/mp4" />
                </Player>
                {!isDisabled ? (
                  <div className="delete-button button-text-wrapper">
                    <button className="button" type="button" onClick={() => { setUploadedMediaUrl(null); submitButtonEnabled(false) }}>Delete</button>
                  </div>
                ) : null}
              </>
            ) : (
              <div className="frame-26">
                <div className="group-wrapper">
                  <PropertyDefaultWrapperMusic
                    className="group-3"
                    divClassName="group-4"
                    frameWrapperText="Please upload .mp4 format only"
                    frameWrapperVisible={false}
                    property1="default"
                    text="Upload Ceremony Video"
                    text1="Upload Video"
                    visible={false}
                    onFileChange={handleFileChange}
                  />
                </div>
              </div>
            )}
          </>
        )}
      </div>
      {variant === "variant-2" && (
        <>
          <div className="frame-radio-button">
            <RadioBtnMusicClip
              className="radio-btn-music-clip-instance"
              onClick={() => {
                setVariant("default");
                onRadioButtonChange("default");
              }}
              property1="default"
              radioOptionIcon={<RadioButtons5 className="radio-buttons-2" />}
              radioOptionItemClassName="radio-btn-music-clip-2"
              radioOptionText="Upload Media"
              disable={isStreamingEvent}
            />
            <RadioBtnMusicClip
              className="radio-btn-music-clip-instance"
              property1="default"
              radioOptionIcon={<RadioButtons4 className="radio-buttons-2" />}
              radioOptionItemClassName="radio-btn-music-clip-3"
              radioOptionText="Streaming URL"
              disable={!isStreamingEvent}
            />
          </div>
          <div className="frame-streaming-provider">
            <div className="event-ID-wrapper">
              <div className={`event-ID group-4`}>Select Streaming Providers</div>
              <select id="streamingproviderpostevent" className="element" disabled={true} value={postEventData?.result?.postEventData?.streamingProvider}>
                <option value="Mediasite">Mediasite</option>
                <option value="Kaltura">Kaltura</option>
                <option value="Vimeo">Vimeo</option>
                <option value="IBM">IBM</option>
              </select>
            </div>
            <div className="event-ID-wrapper">
              <div className={`event-ID group-4`}>Enter Ceremony Streaming Url</div>
              <input id="streamingurlpostevent" type="text" className="element" placeholder="https://" value={uploadedStreamingUrl || ''} disabled={isDisabled} onChange={handleStreamingUrlChange} />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

UploadMediaClipSection.propTypes = {
  property1: PropTypes.oneOf(["variant-2", "default"]),
  selectedRadioButton: PropTypes.oneOf(["variant-2", "default"]),
  onRadioButtonChange: PropTypes.func,
  mediaUrl: PropTypes.string,
  isStreamingEvent: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    postEventData: state.postEvent.postEventData,
  };
};

const ConnectedUploadMediaClipSection = connect(mapStateToProps)(UploadMediaClipSection);

export { ConnectedUploadMediaClipSection as UploadMediaClipSection };
