/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const Property1Variant4 = ({ color = "#EA0C0C", className }) => {
  return (
    <svg
      className={`property-1-variant4 ${className}`}
      fill="none"
      height="80"
      viewBox="0 0 80 80"
      width="80"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M40 73.3337C58.4095 73.3337 73.3333 58.4098 73.3333 40.0003C73.3333 21.5908 58.4095 6.66699 40 6.66699C21.5905 6.66699 6.66663 21.5908 6.66663 40.0003C6.66663 58.4098 21.5905 73.3337 40 73.3337Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="6"
      />
      <path
        className="path"
        d="M40 26.667V40.0003"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="8"
      />
      <path
        className="path"
        d="M40 53.333H40.0333"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="8"
      />
    </svg>
  );
};

Property1Variant4.propTypes = {
  color: PropTypes.string,
};
