/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { FiRrGraduationCap6 } from "../../icons/FiRrGraduationCap6";
import { Subitem } from "../Subitem";
import "./style.css";
import infoImage from '../../icons/ImageIcons/fi-sr-info.svg';

export const Home = ({
  className,
  text = "Event Overview",
  fiRrGraduationCap6Color = "white",
  divClassName,
  hasGroup = true,
  to,
}) => {
  return (
    <Link className={`home ${className}`} to={to}>
      <div className="frame-7">
        <div className="frame-8">
          <div className="frame-9">
            <div className="frame-10">
              <FiRrGraduationCap6 className="fi-rr-graduation-cap" color={fiRrGraduationCap6Color} />
            </div>
            <div className="event-overview-wrapper">
              <div className={`event-overview-2 ${divClassName}`}>{text}</div>
            </div>
          </div>
          {hasGroup && (
            <img className="element-bell" alt="Element bell" src={infoImage}  title="Provide your common settings which needs to be applied for all the events in a season."/>
          )}
        </div>
        <div className="dd-items">
          <Subitem
            className="subitem-instance"
            divClassName="design-component-instance-node"
            property1="frame-966"
            text="Item 1"
          />
          <Subitem
            className="subitem-instance"
            divClassName="design-component-instance-node"
            property1="frame-966"
            text="Item 1"
          />
          <Subitem
            className="subitem-instance"
            divClassName="design-component-instance-node"
            property1="frame-966"
            text="Item 1"
          />
          <Subitem
            className="subitem-instance"
            divClassName="design-component-instance-node"
            property1="frame-966"
            text="Item 1"
          />
        </div>
      </div>
    </Link>
  );
};

Home.propTypes = {
  text: PropTypes.string,
  fiRrGraduationCap6Color: PropTypes.string,
  text1: PropTypes.string,
  hasGroup: PropTypes.bool,
  to: PropTypes.string,
};
