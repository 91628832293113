/*
We're constantly improving the code you see.
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/
 
import PropTypes from "prop-types";
import React from "react";

export const FiRrCommentInfo = ({ color = "#374957", className }) => {
  return (
    <svg
      className={`fi-rr-comment-info ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_424_3289)">
        <path
          className="path"
          d="M13.4998 6.4998C13.4998 6.89762 13.3418 7.27915 13.0605 7.56046C12.7792 7.84176 12.3976 7.9998 11.9998 7.9998C11.602 7.9998 11.2205 7.84176 10.9391 7.56046C10.6578 7.27915 10.4998 6.89762 10.4998 6.4998C10.4998 6.10197 10.6578 5.72044 10.9391 5.43914C11.2205 5.15783 11.602 4.9998 11.9998 4.9998C12.3976 4.9998 12.7792 5.15783 13.0605 5.43914C13.3418 5.72044 13.4998 6.10197 13.4998 6.4998ZM23.9998 18.9998V12.3398C24.0371 9.25725 22.9069 6.27469 20.8361 3.99097C18.7653 1.70725 15.9073 0.291436 12.8358 0.0277985C11.1215 -0.0921438 9.40134 0.157538 7.79185 0.759951C6.18235 1.36236 4.72102 2.30345 3.50672 3.51955C2.29243 4.73564 1.35351 6.19836 0.753484 7.80875C0.153457 9.41914 -0.0936746 11.1396 0.0288091 12.8538C0.470809 19.2078 6.08181 23.9998 13.0828 23.9998H18.9998C20.3254 23.9982 21.5963 23.4709 22.5336 22.5336C23.4709 21.5962 23.9982 20.3254 23.9998 18.9998ZM12.6998 2.0238C15.2663 2.25084 17.6517 3.44071 19.3769 5.35435C21.1021 7.268 22.0391 9.76362 21.9998 12.3398V18.9998C21.9998 19.7955 21.6837 20.5585 21.1211 21.1211C20.5585 21.6837 19.7955 21.9998 18.9998 21.9998H13.0828C7.04881 21.9998 2.39981 18.0998 2.02481 12.7158C1.92636 11.3444 2.11178 9.96739 2.56949 8.67087C3.0272 7.37434 3.74736 6.18613 4.68498 5.18047C5.6226 4.17481 6.75754 3.37331 8.01888 2.82605C9.28022 2.27878 10.6409 1.99751 12.0158 1.9998C12.2428 1.9998 12.4718 2.0088 12.6998 2.0238ZM13.9998 17.9998V11.9998C13.9998 11.4694 13.7891 10.9607 13.414 10.5856C13.039 10.2105 12.5302 9.9998 11.9998 9.9998H10.9998C10.7346 9.9998 10.4802 10.1052 10.2927 10.2927C10.1052 10.4802 9.99981 10.7346 9.99981 10.9998C9.99981 11.265 10.1052 11.5194 10.2927 11.7069C10.4802 11.8944 10.7346 11.9998 10.9998 11.9998H11.9998V17.9998C11.9998 18.265 12.1052 18.5194 12.2927 18.7069C12.4802 18.8944 12.7346 18.9998 12.9998 18.9998C13.265 18.9998 13.5194 18.8944 13.7069 18.7069C13.8945 18.5194 13.9998 18.265 13.9998 17.9998Z"
          fill={color}
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_424_3289">
          <rect className="rect" fill="white" height="24" width="24" />
        </clipPath>
      </defs>
    </svg>
  );
};
 
FiRrCommentInfo.propTypes = {
  color: PropTypes.string,
};