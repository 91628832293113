import axios from "axios"
import { toast } from "react-toastify"
import { CREATE_EVENT_FAILURE, CREATE_EVENT_START, CREATE_EVENT_SUCCESS, FAIL_REQUEST, GET_LOGIN_USER, GET_SEASON_LIST, GET_USER_ACCEPTANCE, MAKE_REQUEST } from "../ActionTypes/SharedActionTypes";

export const makeRequest = () => {
    return {
      type: MAKE_REQUEST,
    };
  };
  export const failRequest = (err) => {
    return {
      type: FAIL_REQUEST,
      payload: err,
    };
  };

  export const createEventStart = () => ({
    type: CREATE_EVENT_START,
  });

  export const createEventSuccess = () => ({
    type: CREATE_EVENT_SUCCESS,
  });

  export const createEventFailure = (error) => ({
    type: CREATE_EVENT_FAILURE,
    payload: error,
  });
 
  export const getSeasonList = (data) => {
    return {
      type: GET_SEASON_LIST,
      payload: data
    }
  }
  

  export const getUserLoginDetails = (data) => {
    return {
      type: GET_LOGIN_USER,
      payload: data
    }
  }

  export const getUserAcceptanceAggrement = (data) => {
    return {
      type: GET_USER_ACCEPTANCE,
      payload: data
    }
  }

// Gets login user details based on query
export const getLoginUser = (query) => {
    return (dispatch) => {
      dispatch(makeRequest());
      // toast.info('Loading...');
      axios.get(`${window.APP.apiUrl}api/initialize/GetLoginUser`, {
        headers: {
          "X-API-Key": window.APP.apiKey,
        },
        params: {
          query: query
        }
      }).then(res => {
        const details = res.data;
        dispatch(getUserLoginDetails(details));
        // toast.dismiss();
      }).catch(err => {
        dispatch(failRequest(err.message))
        // toast.dismiss();
      })
  
    }
  }

  // Gets the data for user acceptance
  export const getUserAcceptance = (clientId, userEmail) => {
    return async (dispatch) => {
      dispatch(makeRequest());
      toast.info('Loading...');
      try {
        const res = await axios.get(`${window.APP.apiUrl}api/initialize/GetUserAcceptanceAgreement`, {
          headers: {
            "X-API-Key": window.APP.apiKey,
          },
          params: {
            ClientId: clientId,
            UserEmail: userEmail
          }
        });
        const userDetails = res.data;
        dispatch(getUserAcceptanceAggrement(userDetails));
        toast.dismiss();
        return userDetails; // Return data if needed
      } catch (err) {
        dispatch(failRequest(err.message))
        toast.dismiss();
        throw err; // Re-throw the error to propagate it to the caller
      }
    };
  };
  
  // Uploads the data for user acceptance
  export const postUserAcceptance = (userDetails) => {
    return async (dispatch) => {
      dispatch(makeRequest());
      try {
        const result = await axios.post(`${window.APP.apiUrl}api/initialize/UserAcceptanceAgreement`, JSON.stringify(userDetails), {
          headers: {
            'Content-Type': 'application/json',
            "X-API-Key": window.APP.apiKey,
          },
        });
  
        if (result.data.isSuccess) {
          // dispatch(createEventSuccess());
        } else {
          dispatch(failRequest(result.data.error));
        }
      } catch (error) {
        dispatch(failRequest(error.message));
      }
    };
  };

  // Gets the list of seasons
  export const getSeasons = () => {
    return (dispatch) => {
        dispatch(makeRequest()); // Dispatch loading action

        // Return the axios promise directly
        return axios.get(`${window.APP.apiUrl}api/initialize/GetSeasons`, {
            headers: {
                "X-API-Key": window.APP.apiKey,
            },
        }).then(res => {
            const seasonList = res.data;
            dispatch(getSeasonList(seasonList)); // Dispatch success action
            return seasonList; // You can choose to return data if needed
        }).catch(err => {
            dispatch(failRequest(err.message)); // Dispatch failure action
            throw err; // Re-throw the error to propagate it to the caller
        });
    };
};

  