// postEventReducer.js

import {
  ADD_TO_QUEUE,
  CANCEL_FILE,
  CANCEL_UPLOAD,
  CREATE_EVENT_FAILURE_FOR_POST,
  CREATE_EVENT_START_FOR_POST,
  CREATE_EVENT_SUCCESS_FOR_POST,
  FAIL_REQUEST_FOR_POST,
  GET_CEREMONY_VIDEO_UPLOAD_URI,
  GET_FIRST_CANDIDATE_ANNOUNCEMENT_TIME,
  GET_HANDSHAKE_DETAILS,
  GET_POST_EVENT_DATA,
  GET_POST_EVENT_LIST,
  GET_URL_FOR_CLIP,
  GET_VIDEO_PROCESSING_STATUS,
  INITIALIZE_VALUES_OF_POSTEVENT,
  MAKE_REQUEST_FOR_POST,
  PROCESS_QUEUE,
  SET_EVENT_SUCCESS_FOR_POST,
  SET_POST_EVENT_DETAILS,
  SET_SEARCH_QUERY_FOR_POST,
  START_UPLOAD,
  START_UPLOAD_FROM_QUEUE,
  UPLOAD_FAILED,
  UPLOAD_PROGRESS,
  UPLOAD_SUCCESS,
} from "../ActionTypes/PostEventActionTypes";

// Initial values for post event
const initialState = {
  loading: true,
  eventSuccess: false,
  errmessage: '',
  postEventList: null,
  postEventDetails: null,
  postEventData: null,
  ceremonyVideoUri: null,
  handshakeDetails: null,
  announcementTime: null,
  selectedSeasonForPost: null,
  searchQueryForPost: '',
  videoProcessingStatus: null,
  sasUrlForClip: '',
  uploadProgress: 0,
  uploadInProgress: false,
  fileNameForCeremony: '',
  uploads: [],
  uploadQueue: []
}

// Stores the fetched or modified values in store
const postEventReducer = (state = initialState, action) => {
  switch (action.type) {
    case MAKE_REQUEST_FOR_POST:
      return {
        ...state,
        loading: true,
      };
    case FAIL_REQUEST_FOR_POST:
      return {
        ...state,
        loading: false,
        errmessage: action.payload,
      };
    case SET_EVENT_SUCCESS_FOR_POST:
      return {
        ...state,
        eventSuccess: false,
      };
    case CREATE_EVENT_START_FOR_POST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_EVENT_SUCCESS_FOR_POST:
      return {
        ...state,
        loading: false,
        eventSuccess: true,
      };

    case CREATE_EVENT_FAILURE_FOR_POST:
      return {
        ...state,
        loading: false,
        errmessage: action.payload,
        eventSuccess: false,
      };
    case INITIALIZE_VALUES_OF_POSTEVENT:
      return {
        ...state,
        postEventData: null
      };
    case GET_POST_EVENT_LIST:
      return {
        ...state,
        errmessage: "",
        postEventList: action.payload,
      };

    case SET_POST_EVENT_DETAILS:
      return {
        ...state,
        postEventDetails: action.payload,
      };

    case GET_POST_EVENT_DATA:
      return {
        ...state,
        loading: false,
        errmessage: "",
        postEventData: action.payload,
      };
    case GET_CEREMONY_VIDEO_UPLOAD_URI:
      return {
        ...state,
        loading: false,
        errmessage: "",
        ceremonyVideoUri: action.payload,
      };

    case GET_HANDSHAKE_DETAILS:
      return {
        ...state,
        loading: false,
        errmessage: "",
        handshakeDetails: action.payload,
      };

    case GET_FIRST_CANDIDATE_ANNOUNCEMENT_TIME:
      return {
        ...state,
        loading: false,
        errmessage: "",
        announcementTime: action.payload,
      };

    case SET_SEARCH_QUERY_FOR_POST:
      return {
        ...state,
        loading: false,
        errmessage: "",
        searchQueryForPost: action.payload,
      };
    case GET_VIDEO_PROCESSING_STATUS:
      return {
        ...state,
        loading: false,
        errmessage: "",
        videoProcessingStatus: action.payload,
      };
    case GET_URL_FOR_CLIP:
      return {
        ...state,
        loading: false,
        errmessage: "",
        sasUrlForClip: action.payload,
      };
    case CANCEL_FILE:
      return {
        ...state,
        loading: false,
        errmessage: "",
        fileNameForCeremony: action.payload,
      };
    case START_UPLOAD:
      return {
        ...state,
        uploads: [
          ...state.uploads,
          {
            fileName: action.payload.fileName,
            eventId: action.payload.eventId,
            progress: 0
          },
        ],
      };
    case UPLOAD_PROGRESS:
      return {
        ...state,
        uploads: state.uploads.map(upload =>
          upload.fileName === action.payload.fileName
            ? { ...upload, progress: action.payload.progress }
            : upload
        ),
      };
    case UPLOAD_SUCCESS:
      return {
        ...state,
        uploads: state.uploads.filter(upload => upload.fileName !== action.payload.fileName),
      };
    case UPLOAD_FAILED:
      return {
        ...state,
        uploads: state.uploads.map(upload =>
          upload.fileName === state.fileNameForCeremony
            ? { ...upload, error: true }
            : upload
        ),
      };
    case CANCEL_UPLOAD:
      return {
        ...state,
        uploads: state.uploads.filter(upload => upload.fileName !== action.payload.fileName),
      };

    case ADD_TO_QUEUE:
      return {
        ...state,
        uploadQueue: [
          ...state.uploadQueue,
          { fileName: action.payload.fileName, eventId: action.payload.eventId }
        ]
      };

    case START_UPLOAD_FROM_QUEUE:
      return {
        ...state,
        uploadQueue: state.uploadQueue.filter(upload =>
          upload.fileName !== action.payload.fileName
        )
      };
    case PROCESS_QUEUE:
      return {
        ...state
      };
    default:
      return state;
  }
};

export default postEventReducer;
