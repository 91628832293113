import React from "react";
import "./style.css";
import gradimagesLogo from '../../icons/ImageIcons/gradimagesLogo.png';

export const Frame = ({ className }) => {
  return (
    <div className={`frame ${className}`}>
      <img className="image" alt="Image" src={gradimagesLogo} />
    </div>
  );
};
