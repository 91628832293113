import React,{useState} from "react";
import "./style.css";
import alert from "./brightness-alert.png"
 
export const PopupStartupAuth = ({ onCancel, onConfirm }) => {
  const [isChecked, setIsChecked] = useState(false);
 
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  return (
    <div className="popup-startup-auth">
      <div className="title">
        <div className="frame">
          <img className="brightness-alert" alt="Brightness alert" src={alert} />
        </div>
        <div className="text-wrapper">Authorisation required</div>
      </div>
      <div className="content">
        <div className="div">
          <div className="checkbox-wrapper">
            <input type="Checkbox" className="checkbox-instance"  checked={isChecked}
              onChange={handleCheckboxChange} />
          </div>
          <div className="text">
            <p className="p">
              By opting in to using GradReels, you are giving GradImages full rights to use the information provided for
              the purpose of supplying personalized video clips to students for the upcoming event(s), including but not
              limited to, school colors, logos, student information, music, and any other assets provided.
            </p>
          </div>
        </div>
      </div>
      <div className="actions">
        <div className="secondary-action" onClick={onCancel}/>
        <div className="dialog-actions">
        <button className="button-primary" onClick={onCancel}>
            <div className="label">Cancel</div>
          </button>
          <button
            className={`button ${isChecked ? "" : "disabled"}`}
            onClick={isChecked ? onConfirm : null}
          >
            <div className="label-2">Confirm</div>
          </button>
        </div>
      </div>
    </div>
  );
};
 