import React, { useCallback } from "react";
import "./style.css";

export const TimestampFieldComponent = ({
    getTimestamp = null,
    timestamps = null,
}) => {
    const handleTimestampChange = useCallback(
        (event) => {
            const newTimestamp = event.target.value;
            getTimestamp(newTimestamp);
        },
        [getTimestamp]
    );

    return (
        <div className="timestamp-field-wrapper">
            <div>
                <label
                    htmlFor="timestampInput"
                    className="timestamp-label"
                    style={{ fontFamily: "Poppins" }}
                >
                    First Handshake Video Timestamp:{" "}
                </label>
                <br />
                <br />
                <input
                    type="text"
                    placeholder="HH:MM:SS"
                    id="timestampInput"
                    value={timestamps}
                    onChange={handleTimestampChange}
                    className="timestamp-input"
                />
            </div>
            <div className="timestamp-format-label">
                <div className="checkbox fi-sr-info class-2"></div>
                <p className="i-authorise">Please enter in HH:MM:SS format</p>
            </div>
        </div>
    );
};
