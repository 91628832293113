/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { RadioButtons5 } from "../../icons/RadioButtons5";
import "./style.css";

export const RadioOption = ({
  className,
  icon = <RadioButtons5 className="radio-buttons" />,
  itemClassName,
  text = "Upload Media",
  disabled = false,
}) => {
  return (
    <div className={`radio-option ${className}`} style={{ opacity: disabled ? 0.5 : 1 }}>
      {icon}
      <div className={`item ${itemClassName}`}>
        <div className="upload-media">{text}</div>
      </div>
    </div>
  );
};

RadioOption.propTypes = {
  text: PropTypes.string,
};
