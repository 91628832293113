import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { connect, useDispatch } from "react-redux";

import { Box, CircularProgress } from "@mui/material";
import { StyledEngineProvider } from "@mui/material/styles";
import "./style.css";

import { PreEventDetails } from "../PreEventDetails";
import Stepper from "../Stepper";

import homeImage from '../../../icons/ImageIcons/material-symbols_home-rounded.svg';
import rightarrow from '../../../icons/ImageIcons/keyboard-arrow-right.svg';

import { Frame1026 } from "../../../components/Frame1026";
import { UploadVideoGroup } from "../../../components/UploadVideoGroup/UploadVideoGroup";

import { completeStep, FetchGradreelType, getExitVideoData, postExitVideo, setEventSuccess } from "../../../store/Actions/PreEventAction";


const CreateExitClip = ({ loading, eventMasterData, exitVideoData, getExitVideoData, postExitVideo, eventState, setEventSuccess, eventSuccess, eventDetails, loadevent }) => {
  const navigate = useNavigate();
  const [videoSrc, setVideoSrc] = useState("");
  const [template, setTemplate] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [videoUrl, setVideoUrl] = useState(null);
  // const [loading, setLoading] = useState(false);
  const [createChecked, setCreateChecked] = useState(false);
  const [isButtonSelected, setIsButtonSelected] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!eventMasterData) {
      navigate('/PreEventDetailsDashboard')
    }
  }, [eventMasterData]);

  useEffect(() => {
    if(!eventMasterData?.result?.isDataAvailable){
      navigate('/SelectTemplate')
    }
  }, [eventMasterData])


  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!eventMasterData) {
          navigate('/PreEventDetailsDashboard');
          return;
        }
        // Dispatch all two API calls simultaneously
        await Promise.all([
          loadevent({ eventId: eventDetails?.eventId, clientId: eventDetails?.clientId }),
          getExitVideoData({ ClientId: eventMasterData?.result?.eventData?.clientId, EventId: eventMasterData?.result?.eventData?.eventId })
        ]);
      } catch (error) {
        console.error('Error fetching data:', error);
        // Handle error if necessary
      }
    };
    fetchData();
  }, [])

  useEffect(() => {
    if (exitVideoData?.result?.data?.videoAssetFileSasUri) {
      setVideoUrl(exitVideoData?.result?.data?.videoAssetFileSasUri)
      setVideoSrc(exitVideoData?.result?.data?.videoAssetFileSasUri)
      setIsButtonSelected(true);
    } else {
      setIsButtonSelected(false);
    }
  }, [exitVideoData?.result?.data?.videoAssetFileSasUri])


  useEffect(() => {
    setTemplate(eventMasterData?.result?.eventData?.template)
  }, [eventMasterData?.result?.eventData?.template])

  useEffect(() => {
    if (exitVideoData?.result?.data?.createVideo === true) {
      navigate('/CreateExitClipFrame')
    }
  }, [exitVideoData?.result?.data?.createVideo])

  useEffect(() => {
    if (eventSuccess) {
      console.log("Event created successfully!");
      setEventSuccess(false);
      navigate("/ConfirmPreEventDetails");   
      if(eventMasterData?.result?.eventData?.template === 'Standard'){
        dispatch(completeStep(4, true));
      } else if(eventMasterData?.result?.eventData?.template === 'Custom'){
        dispatch(completeStep(5, true));
      }
    }
  }, [eventSuccess]);

  // Sets the uplaoded video file by the user
  const handleFileChange = (file) => {
    try {
      setSelectedFile(file)
      // Transform file into blob URL
      setVideoSrc(URL.createObjectURL(file));
      setIsButtonSelected(true);
    } catch (error) {
      console.error(error);
    }
  };

  // Checks if the user want to create a video instead on uploading one
  const onCheckedHandler = (checked) => {
    setCreateChecked(checked);
  }

  // Upload a video selected by user
  const handleSave = async (e) => {
    e.preventDefault();

    if (!isButtonSelected) {
      return;
    }

    if(eventState) {
      navigate("/ConfirmPreEventDetails");
    } else {
    if (selectedFile) {
      // setLoading(true);
      await postExitVideo(selectedFile, {
        itemType: 'ExitClip',
        clipType: 'Exit',
        eventId: eventMasterData?.result?.eventData?.eventId,
        eventName: eventMasterData?.result?.eventData?.eventName,
        clientId: eventMasterData?.result?.eventData?.clientId,
        clientName: eventMasterData?.result?.eventData?.clientName,
        createVideo: false,
        exitVideo: {
          frameBGColor: '',
          textAsset: [
            { textId: "", textData: '', textFontStyle: "", textColor: '' },
          ],
          logoName: '',
          generatedVideoPath: "",
          generatedVideoData: "",
        },
        updatedOn: new Date(),
        updatedBy: eventDetails?.adminEmail
      });
      // setLoading(false);
    }  else if (videoUrl){
      navigate("/ConfirmPreEventDetails");
    }
  }
  }

  return (
    <div className="upload-video-exit">
      <div className="div-2">
        <PreEventDetails />

        <div className="frame-19">
          <div className="frame-20">
            <div className="section-title">
              <div className="frame-21">
                <div className="page-title">
                  <div className="text-wrapper-3">Create Exit Clip</div>
                </div>
                <div className="div-3">
                  <img
                    className="material-symbols"
                    alt="Material symbols"
                    src={homeImage}
                  />
                  <img
                    className="keyboard-arrow-right"
                    alt="Keyboard arrow right"
                    src={rightarrow}
                  />
                  <div className="text-wrapper-4">Events</div>
                  <img
                    className="keyboard-arrow-right"
                    alt="Keyboard arrow right"
                    src={rightarrow}
                  />
                  <div className="text-wrapper-5">Create Exit Clip</div>
                </div>
              </div>
            </div>
            <div className="frame-22">
              <div className="frame-23">
                <StyledEngineProvider injectFirst>
                  <Stepper gradReelType={eventMasterData?.result?.eventData?.template}/>
                </StyledEngineProvider>
              </div>
              <div className="frame-26">
                <div className="frame-26">
                  <div className="frame-26">
                    <div className="frame-27">
                      <div className="frame-28">
                        <div className="text-wrapper-6">Selected Template</div>
                      </div>
                      <div className="div-3">
                        <div className="frame-29">
                          <div className="text-wrapper-7">{template}</div>
                        </div>
                      </div>
                    </div>
                    <div className={createChecked ? "frame-30 disabled" : "frame-30"}>
                      {/* {!videoUrl && */}
                        <div className={videoUrl ? "frame-31 disabled" : "frame-31"}>
                          <div className="frame-32">
                            <div className="text-wrapper-8">Exit Clip</div>
                          </div>
                          <div className="frame-33">
                            <div className="frame-34">
                              <UploadVideoGroup
                                className="group-37146"
                                divClassName="group-instance"
                                frame1027Text="Please upload .mp4 format only."
                                text="Upload Video"
                                text1="Upload"
                                visible={true}
                                handleFileChange={handleFileChange}
                              />
                            </div>
                          </div>
                        </div>
                      {/* } */}

                      <div className="frame-35">
                        <div className="text-wrapper-9">Preview</div>
                        <div className="group-wrapper">
                          <video src={videoSrc} controls width="100%" height="100%">
                            Sorry, your browser doesn't support embedded videos.
                          </video>
                        </div>
                      </div>
                    </div>
                    {videoUrl && <div class="delete-button button-text-wrapper" style={{ pointerEvents: (eventMasterData?.result?.eventData?.exitClipPublished || eventState) ? 'none' : 'auto', opacity: (eventMasterData?.result?.eventData?.exitClipPublished || eventState) ? 0.5 : 1 }}><button className="button" type="button" onClick={() => { setVideoUrl(''); setVideoSrc(''); setIsButtonSelected(false); }}>Delete</button></div>}
                  </div>
                  <Frame1026
                    checkboxPropertyFiRrClassName="frame-1026-instance"
                    className="frame-24" videoUrl={videoUrl} onChecked={onCheckedHandler}
                    frametext="If you dont have a video, no worries! We can assist you in crafting a dynamic video by simply uploading
                    your photos."
                    linktoURL="/CreateExitClipFrame"/>
                </div>
              </div>
            </div>
          </div>
          <div className="frame-36">
          {eventMasterData?.result?.eventData?.template === 'Custom' ? (
              <Link to="/StudentClip" className="button-text-2">
                <div className="primary-BTN-2">
                  Back
                </div>
              </Link>
            ) : (
              <Link to="/CreateIntroClip" className="button-text-2">
                <div className="primary-BTN-2">
                  Back
                </div>
              </Link>
            )}
            <Link onClick={handleSave} className="button-text-3">
              <div className={`primary-BTN-3 ${isButtonSelected ? "" : "disabled"}`}>
                Continue
              </div>
            </Link>
          </div>
          {loading && (
            <div className="loader-container">
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px' }}>
                <CircularProgress />
              </Box>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};


const mapStateToProps = (state) => {
  return {
    loading: state.preEvent.loading,
    eventMasterData: state.preEvent.gradreelType,
    exitVideoData: state.preEvent.exitVideoData,
    eventState: state.preEvent.eventState,
    eventSuccess: state.preEvent.eventSuccess,
    eventDetails: state.preEvent.eventDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getExitVideoData: (eventData) => dispatch(getExitVideoData(eventData)),
    postExitVideo: (files, introData) => dispatch(postExitVideo(files, introData)),
    setEventSuccess: (flag) => dispatch(setEventSuccess(flag)),
    loadevent: (eventData) => dispatch(FetchGradreelType(eventData)),
  };
};

const ConnectedCreateExitClip = connect(mapStateToProps, mapDispatchToProps)(CreateExitClip);

export { ConnectedCreateExitClip as CreateExitClip };