export const MAKE_REQUEST='MAKE_REQUEST'
export const FAIL_REQUEST='FAIL_REQUEST'

export const CREATE_EVENT_START = 'CREATE_EVENT_START';
export const CREATE_EVENT_SUCCESS = 'CREATE_EVENT_SUCCESS';
export const CREATE_EVENT_FAILURE = 'CREATE_EVENT_FAILURE';

export const SET_EVENT_SUCCESS = 'SET_EVENT_SUCCESS'

export const GET_EVENT_LIST='GET_EVENT_LIST'

export const GET_GRADREEL_TYPE='GET_GRADREEL_TYPE'


export const GET_EVENT_STATE = 'GET_EVENT_STATE'
export const COMPLETE_STEP = 'COMPLETE_STEP';
export const SET_EVENT_DETAILS = 'SET_EVENT_DETAILS';

export const GET_MEDIA_DETAILS = 'GET_MEDIA_DETAILS';
export const GET_STUDENT_DETAILS = 'GET_STUDENT_DETAILS';

export const GET_INTRO_VIDEO_DATA = 'GET_INTRO_VIDEO_DATA'
export const GET_STUDENT_CLIP_DATA = 'GET_STUDENT_CLIP_DATA';
export const GET_EXIT_VIDEO_DATA = 'GET_EXIT_VIDEO_DATA'

export const GENERATE_VIDEO_FOR_INTRO = 'GENERATE_VIDEO_FOR_INTRO';
export const GENERATE_VIDEO_FOR_EXIT = 'GENERATE_VIDEO_FOR_EXIT';

export const SET_SEARCH_QUERY_FOR_PRE = 'SET_SEARCH_QUERY_FOR_PRE'

export const INITIALIZE_VALUES_OF_PREEVENT = 'INITIALIZE_VALUES_OF_PREEVENT';


//CUSTOM VIDEO REVIEW TYPES
export const GET_CUSTOM_REVIEW_EVENT_LIST = 'GET_CUSTOM_REVIEW_EVENT_LIST';
export const GET_STUDENT_VIDEO_SUMMARY = 'GET_STUDENT_VIDEO_SUMMARY';
export const GET_STUDENT_VIDEO_FOR_APPROVAL = 'GET_STUDENT_VIDEO_FOR_APPROVAL';