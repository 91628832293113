// preEventReducer.js

import {
  COMPLETE_STEP,
  CREATE_EVENT_FAILURE,
  CREATE_EVENT_START,
  CREATE_EVENT_SUCCESS,
  FAIL_REQUEST,
  GENERATE_VIDEO_FOR_EXIT,
  GENERATE_VIDEO_FOR_INTRO,
  GET_CUSTOM_REVIEW_EVENT_LIST,
  GET_EVENT_LIST,
  GET_EVENT_STATE,
  GET_EXIT_VIDEO_DATA,
  GET_GRADREEL_TYPE,
  GET_INTRO_VIDEO_DATA,
  GET_MEDIA_DETAILS,
  GET_STUDENT_CLIP_DATA,
  GET_STUDENT_DETAILS,
  GET_STUDENT_VIDEO_FOR_APPROVAL,
  GET_STUDENT_VIDEO_SUMMARY,
  INITIALIZE_VALUES_OF_PREEVENT,
  MAKE_REQUEST,
  SET_EVENT_DETAILS,
  SET_EVENT_SUCCESS,
  SET_SEARCH_QUERY_FOR_PRE,
} from "../ActionTypes/PreEventActionTypes";

// Initial values for pre event
const initialState = {
  loading: true,
  eventSuccess: false,
  errmessage: '',
  eventlist: [],
  eventDetails: null,
  gradreelType: null,
  mediaDetails: null,
  studentDetails: null,
  eventState: false,
  completed: { 0: true },
  introVideoData: null,
  customerClipDetails: null,
  exitVideoData: null,
  genVideoForIntro: null,
  genVideoForExit: null,
  selectedSeasonForPre: null,
  searchQueryForPre: '',
  searchQueryForPre: '',
  customReviewEventList: null,
  studentVideoForApproval: null,
  studentVideoSummary: null
}

// Stores the fetched or modified values in store
const preEventReducer = (state = initialState, action) => {
  switch (action.type) {
    case MAKE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FAIL_REQUEST:
      return {
        ...state,
        loading: false,
        errmessage: action.payload,
      };
    case SET_EVENT_SUCCESS:
      return {
        ...state,
        eventSuccess: false,
      };
    case CREATE_EVENT_START:
      return {
        ...state,
        loading: true,
      };
    case CREATE_EVENT_SUCCESS:
      return {
        ...state,
        loading: false,
        eventSuccess: true,
      };

    case CREATE_EVENT_FAILURE:
      return {
        ...state,
        loading: false,
        errmessage: action.payload,
        eventSuccess: false,
      };
    case GET_EVENT_LIST:
      return {
        ...state,
        errmessage: "",
        eventlist: action.payload,
      };
    case GET_GRADREEL_TYPE:
      return {
        ...state,
        loading: false,
        gradreelType: action.payload,
      };

    case GET_EVENT_STATE:
      return {
        ...state,
        errmessage: "",
        eventState: action.payload,
      };

    case COMPLETE_STEP:
      const { step, status } = action.payload;
      return {
        ...state,
        completed: {
          ...state.completed,
          [step]: status,
        },
      };
    case SET_EVENT_DETAILS:
      return {
        ...state,
        eventDetails: action.payload,
      };
    case GET_MEDIA_DETAILS:
      return {
        ...state,
        loading: false,
        errmessage: "",
        mediaDetails: action.payload,
      };

    case GET_STUDENT_DETAILS:
      return {
        ...state,
        loading: false,
        errmessage: "",
        studentDetails: action.payload,
      };
    case GET_INTRO_VIDEO_DATA:
      return {
        ...state,
        loading: false,
        errmessage: "",
        introVideoData: action.payload,
      };
    case GET_STUDENT_CLIP_DATA:
      return {
        ...state,
        loading: false,
        errmessage: "",
        customerClipDetails: action.payload,
      };
    case GET_EXIT_VIDEO_DATA:
      return {
        ...state,
        loading: false,
        errmessage: "",
        exitVideoData: action.payload,
      };
    case GENERATE_VIDEO_FOR_INTRO:
      return {
        ...state,
        loading: false,
        errmessage: "",
        genVideoForIntro: action.payload,
      };
    case GENERATE_VIDEO_FOR_EXIT:
      return {
        ...state,
        loading: false,
        errmessage: "",
        genVideoForExit: action.payload,
      };
    case SET_SEARCH_QUERY_FOR_PRE:
      return {
        ...state,
        loading: false,
        errmessage: "",
        searchQueryForPre: action.payload,
      };
    case INITIALIZE_VALUES_OF_PREEVENT:
      return {
        ...state,
        mediaDetails: null,
        studentDetails: null,
        gradreelType: null,
        introVideoData: null,
        customerClipDetails: null,
        exitVideoData: null,
      };
    case GET_CUSTOM_REVIEW_EVENT_LIST:
      return {
        ...state,
        errmessage: "",
        customReviewEventList: action.payload,
      };
    case GET_STUDENT_VIDEO_FOR_APPROVAL:
      return {
        ...state,
        loading: false,
        errmessage: "",
        studentVideoForApproval: action.payload,
      };
    case GET_STUDENT_VIDEO_SUMMARY:
      return {
        ...state,
        loading: false,
        errmessage: "",
        studentVideoSummary: action.payload,
      };
    default:
      return state;
  }
};

export default preEventReducer;