import React from "react";
import { useNavigate } from "react-router-dom";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { Button } from '@mui/material';
import Check from '@mui/icons-material/Check';
import { DataGrid } from '@mui/x-data-grid';
import Tooltip from '@mui/material/Tooltip';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { setPostEventDetails } from "../../store/Actions/PostEventAction";

const DataGridPostEvent = (props) => {
  const navigate = useNavigate();

  // Selection of rows and redirect to /PostEventOverview or /PostEventVideoProcessing page for the selected event
  const handleOnCellClick = async (params, event) => {
    // Check if the target is a button
    if (event.target.tagName.toLowerCase() === 'button') {
      return;
    }

    const currentRow = params.row;
    await props.setPostEventDetails(currentRow);
    const steps = ["Submitted", "Ops Processing", "Not Started"]
    if (steps.includes(currentRow.eventstatus)) {
      navigate("/PostEventOverview", { replace: true });
    } else {
      navigate("/PostEventVideoProcessing", { replace: true });
    }
  };

  const handleButtonClick = (params) => {
    navigate("/PostEventVideoProcessing", { replace: true });
  };

  const theme = createTheme();

  // Override MUI theme styles for DataGrid
  const customTheme = createTheme({
    components: {
      MuiDataGrid: {
        styleOverrides: {
          root: {
            border: 'none',
          },
          row: {
            '&:nth-of-type(even)': {
              backgroundColor: '#F8F8F8', // Alternate row color
            },
            '&:nth-of-type(odd)': {
              backgroundColor: '#FFFFFF', // Starting row color
            },
            fontWeight: 'normal', // Change font weight to normal
          },
          header: {
            backgroundColor: '#E4E4E4', // Header background color
            borderBottom: `1px solid ${theme.palette.divider}`, // Bottom border for header
          },
          cell: {
            borderBottom: 'none', // No bottom border for cells
          },
        },
      },
    },
  });

  // Connector css classes for status
  const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      left: 'calc(-50% + 16px)',
      right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#008744',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#008744',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
      borderTopWidth: 3,
      borderRadius: 1,
    },
  }));

  // Icons css for status
  const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
      color: '#784af4',
    }),
    '& .QontoStepIcon-completedIcon': {
      color: '#784af4',
      fill: '#784af4',
      zIndex: 1,
      fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
      width: 8,
      height: 8,
      borderRadius: '50%',
      backgroundColor: 'currentColor',
    },
  }));


  // Sets Icon for status
  function QontoStepIcon(props) {
    const { active, completed, className } = props;

    return (
      <QontoStepIconRoot ownerState={{ active }} className={className}>
        {completed ? (
          <Check className="QontoStepIcon-completedIcon" />
        ) : (
          <div className="QontoStepIcon-circle" />
        )}
      </QontoStepIconRoot>
    );
  }

  QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
  };

  const getSteps = () => ['Submitted', 'Ops Processing', 'Video Processing', 'Video Published'];

  const videoProcessingStatus = ['Processing Failed', 'Partial Success', 'Success']

  // Renders status cell based on current step
  const renderStatusCell = (params) => {
    const currentStatus = params.value; // 'eventstatus' column value
    const steps = getSteps();
    let activeStep = steps.indexOf(currentStatus);
    if (activeStep === -1 && videoProcessingStatus.includes(currentStatus)) {
      activeStep = 2
    }

    return (
      <Stepper activeStep={activeStep} alternativeLabel connector={<QontoConnector />}>
        {steps.map((label) => (
          <Step key={label}
            sx={{
              '& .MuiStepLabel-root .Mui-completed': {
                color: '#008744',
                border: '0px', // circle color (COMPLETED)
              },
              '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
              {
                color: 'grey.500', // Just text label (COMPLETED)
              },

              '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
              {
                color: '', // Just text label (ACTIVE)
              },
              '& .MuiStepLabel-root .Mui-active': {
                border: '0px',
                color: () => {
                  switch (currentStatus) {
                    case 'Processing Failed':
                      return '#ed0e0e';
                    case 'Partial Success':
                      return '#fa9150';
                    case 'Success':
                      return '#008744';
                    case 'Video Processing':
                      return '#1976d2';
                    default:
                      return '#008744';
                  }
                },
              },
              '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
                fill: '#FFF', // circle's number (ACTIVE)
              },
              '& .MuiStepIcon-root': {
                color: '#FFF',
                'border-radius': '50%',
                border: '1px solid rgba(0, 0, 0, 0.29)' // circle's number (ACTIVE)
              },
              '& .MuiStepIcon-text': {
                color: '#000',
                fill: 'unset' // circle's number (ACTIVE)
              },
              '& .MuiStepLabel-label': {
                fontSize: '10px !important',
                marginTop: '5px !important',
              }

            }}>
            <Tooltip title={(label === 'Video Processing' && videoProcessingStatus.includes(currentStatus)) ? currentStatus : label}>
              <StepLabel>{(label === 'Video Processing' && videoProcessingStatus.includes(currentStatus)) ? currentStatus.split(' ').map((word, wordIndex) => (
                <div key={wordIndex}>{word}</div>
              )) : label.split(' ').map((word, wordIndex) => (
                <div key={wordIndex}>{word}</div>
              ))}</StepLabel>
            </Tooltip>
          </Step>
        ))}
      </Stepper>
    );
  };

  const columns = [
    {
      field: 'eventName',
      headerName: 'Event Name',
      flex: 1,
    },
    {
      field: 'eventId',
      headerName: 'Event ID',
      flex: 1,
    },
    {
      field: 'eventDateTime',
      headerName: 'Event Date & Time',
      type: 'dateTime',
      valueGetter: ({ value }) => value && new Date(value),
      flex: 1,
    },
    {
      field: 'clientName',
      headerName: 'Client Name',
      flex: 1,
    },
    {
      field: 'template',
      headerName: 'Template',
      flex: 1,
    },
    {
      field: 'eventstatus',
      headerName: 'Status',
      flex: 2,
      renderCell: renderStatusCell,
    },
    // {
    //   field: 'action',
    //   headerName: 'Action',
    //   flex: 1,
    //   renderCell: (params) => {
    //     return (
    //       <Button
    //         variant="outlined"
    //         color="primary"
    //         sx={{ fontSize: '10px' }}
    //         onClick={() => handleButtonClick(params.row.id)} // assuming you have an ID for each row
    //       >
    //         Video Processing
    //       </Button>
    //     );
    //   }
    // }
  ];

  return (
    <ThemeProvider theme={customTheme}>
      <Box sx={{ height: '100%', width: '100%' }}>
        <DataGrid
          onCellClick={(params, event) => handleOnCellClick(params, event)}
          rows={props?.rows}
          columns={columns}
          getRowHeight={() => 70}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10, 20]}
          checkboxSelection
          disableRowSelectionOnClick
          sx={{
            '& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell': {
              fontWeight: 400, fontSize: '14px', fontFamily: 'Poppins', color: '#717171'
            }, '& .MuiDataGrid-columnHeader': {
              backgroundColor: '#F8F8F8', fontSize: '16px', fontFamily: 'Poppins', color: '#171B1E'
            }, '& .MuiDataGrid-columnHeaderTitle': {
              fontWeight: '600'
            }, '& .MuiDataGrid-columnHeadersInner': {
              width: '100%', backgroundColor: 'rgb(248, 248, 248)'
            }, '& .MuiDataGrid-virtualScrollerRenderZone': {
              cursor: 'pointer'
            },
          }}
        />
      </Box>
    </ThemeProvider>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPostEventDetails: (details) => dispatch(setPostEventDetails(details)),
  };
};

export default connect(null, mapDispatchToProps)(DataGridPostEvent);
