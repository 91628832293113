import React, { useEffect, useState } from "react";
import { Player } from 'video-react';

import PropTypes from "prop-types";

import "./style.css";

import { FrameWrapperTemplate } from "../FrameWrapperTemplate";

import StandardGradreelVideo from './StandardGradreel.MOV'
import CustomGradreelVideo from './CustomGradreel.MOV'
import StandardGradreelThumb from './StandardGradreel_Thumbnail.png'
import CustomGradreelThumb from './CustomGradreel_Thumbnail.png'

export const StandardCustom = ({ property1: initialProperty1, className, frameVisible, selectedSeason }) => {
  const [property1, setProperty1] = useState(initialProperty1 || "default");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const stopPropagation = (event) => {
    event.stopPropagation();
  };

  const toggleProperty = () => {
    setProperty1(prevProperty => prevProperty === "default" ? "variant-2" : "default");
  };

  useEffect(() => {
    // Hide content when the season changes
    setProperty1("default");
  }, [selectedSeason]);

  return (
    <div className={`standard-custom property-1-${property1} ${className}`}>
      <FrameWrapperTemplate
        checkboxPropertyFiRrClassName={`${property1 === "variant-2" ? "class" : "class-2"}`}
        className="frame-instance"
        onClick={toggleProperty}
        frameClassName="frame-1027"
        text="What are Standard and Custom GradReels?"
        visible={frameVisible}
      />
      <div className="frame-9">
        <div className="frame-10">
          <div className="frame-11">
            <div className="a-standard-gradreel">
              <p className="p">
                A Standard GradReel has three seamlessly woven sections: an Intro Clip, The Handshake Clip, and an
                Exit Clip, creating an amazing keepsake.
              </p>
            </div>
          </div>
          {property1 === "variant-2" && (
            <div className="table-cells">
              <div className="thumb-wrapper" onClick={() => openModal(StandardGradreelVideo)}>
                <div className="thumb">
                  <div className="overlap-group-wrapper1">
                    <div className="overlap-group">
                      <img src={StandardGradreelThumb} alt="Video Thumbnail" style={{width: '100%'}}/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="frame-10">
          <div className="frame-11">
            <div className="a-custom-gradreel">
              <p className="p">
                A Custom GradReel comes with an added bonus: a personalized Student Clip uploaded by the graduate,
                seamlessly integrated with the Intro clip, Handshake Clip, and Exit Clip.
              </p>
            </div>
          </div>
          {property1 === "variant-2" && (
            <div className="table-cells">
              <div className="thumb-wrapper" onClick={() => openModal(CustomGradreelVideo)}>
                <div className="thumb">
                  <div className="overlap-group-wrapper1">
                    <div className="overlap-group">
                      <img src={CustomGradreelThumb} alt="Video Thumbnail" style={{width: '100%'}}/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {property1 === "variant-2" && (
          <div className="frame-10">
            <div className="frame-11">
              <p className="don-t-worry-if-you">
                Dont worry if you dont have an Intro and Exit Clip, we can help you generate one.
              </p>
            </div>
          </div>
        )}
      </div>
      {isModalOpen && (
        <div className="video-modal" onClick={closeModal}>
          <div className="video-player-container" onClick={stopPropagation}>
            <button className="close-button" onClick={closeModal}>
              &times;
            </button>
            <div className="video-player">
              <Player src={property1 === "variant-2" ? StandardGradreelVideo : CustomGradreelVideo} poster={property1 === "variant-2" ? StandardGradreelThumb : CustomGradreelThumb}/>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

StandardCustom.propTypes = {
  property1: PropTypes.oneOf(["variant-2", "default"]),
  frameWrapperVisible: PropTypes.bool,
};
