import PropTypes from "prop-types";
import React, { useState } from "react";
import { RadioButtons4 } from "../../icons/RadioButtons4";
import { RadioOption } from "../RadioOption";
import "./style.css";

export const RadioBtnMusicClip = ({
  property1,
  className,
  radioOptionIcon = <RadioButtons4 className="radio-buttons-4" />,
  radioOptionItemClassName,
  radioOptionText = "Upload Background Music Clip",
  onClick,
  disable
}) => {
  const [variant, setVariant] = useState(property1 || "default");

  const handleClick = () => {
    if (!disable && onClick) {
      onClick();
      setVariant(prevVariant => prevVariant === "default" ? "variant-2" : "default");
    }
  };

  return (
    <div className={`radio-btn-music-clip ${className}`} onClick={handleClick}>
      <RadioOption
        className="radio-option-instance"
        icon={radioOptionIcon}
        itemClassName={radioOptionItemClassName}
        text={radioOptionText}
        disabled={disable}
      />
    </div>
  );
};

RadioBtnMusicClip.propTypes = {
  property1: PropTypes.oneOf(["variant-2", "default"]),
  radioOptionText: PropTypes.string,
};
