import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { Box, CircularProgress } from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';
import "./style.css";

import { PostEventDetails } from "../PostEventDetails";
import DatagridPostEvent from "../DatagridPostEvent";

import homeImage from '../../../icons/ImageIcons/material-symbols_home-rounded.svg';
import rightarrow from '../../../icons/ImageIcons/keyboard-arrow-right.svg';
import searchicon from '../../../icons/ImageIcons/searchicon.png';

import DropDown from "../../../components/DropDown/DropDown";

import { getClientData } from "../../../constants/Constants";
import { getUserAcceptance } from "../../../store/Actions/SharedAction";
import { FetchPostEventList, setSearchQueryForPostevent } from "../../../store/Actions/PostEventAction";

const PostEventDetailsDashboard = ({ getUserAcceptance, postEventList, loadevent, seasons, setSearchQueryForPostevent, searchQueryForPost }) => {

  const [searchQuery, setSearchQuery] = useState(searchQueryForPost || "");
  const [filteredData, setFilteredData] = useState([]);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);  
  const [loadingOperation, setLoadingOperation] = useState(false);
  const [seasonId, setSeasonId] = useState(0);

  useEffect(() => {
    // getUserAcceptance();
    const fetchData = async () => {
      const clientData = getClientData();
      if (clientData) {
        setLoading(false);
        setLoadingOperation(true);
        await loadevent(clientData.clientID).then(() => {
          setLoadingOperation(false);
        })
          .catch(error => {
            // Handle error if necessary
            console.error('Error loading event:', error);
          });
      } else {
        setLoading(true);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    filterDataFun(searchQuery, seasonId);
  }, [seasonId, rows])

  useEffect(() => {
    setRows((Array.isArray(postEventList?.result) ? postEventList?.result : []).map(row => ({
      id: row.eventId,
      eventName: row.eventName,
      eventDateTime: row.eventDateTimeInUTC,
      clientName: row.clientName,
      template: row.template,
      clientId: row.clientId,
      eventId: row.eventId,
      seasonId: row.seasonId,
      eventstatus: getSelectedStatus(row) //"row.status", // Assuming 'status' is the field for event status
      //  eventstatuspercent: `${(row.percentComplete * 100).toFixed(0)}%`, // Assuming percentComplete is a value between 0 and 1
    })));
  }, [postEventList])

  // Set the input value added by user into search query
  const handleSearch = (event) => {
    const query = event.target.value;
    filterDataFun(query, seasonId);
    setSearchQueryForPostevent(query);
  };

  // Filters the rows based on seasons and input value
  const filterDataFun = (query, seasonId) => {
    console.log("rows", query, seasonId, rows);
    setSearchQuery(query);

    if (seasonId === '0') {
      const filteredRows = rows.filter(row => {
        // Customize the search logic based on your requirements
        return (
          (row.eventName.toLowerCase().includes(query.toLowerCase()) ||
            row.eventId.toString().includes(query) ||
            row.template.toLowerCase().includes(query.toLowerCase()))
        );
      });
      setFilteredData(filteredRows);
    } else {
      const filteredRows = rows.filter(row => {
        // Customize the search logic based on your requirements
        return (
          (row.eventName.toLowerCase().includes(query.toLowerCase()) ||
            row.eventId.toString().includes(query) ||
            row.template.toLowerCase().includes(query.toLowerCase()))
          && (
            (seasonId > 0 && row.seasonId == seasonId) || !seasonId
          )
        );
      });
      setFilteredData(filteredRows);
    }
  }

  // gets selected status based on eventStage and eventState
  const getSelectedStatus = (rowDetails) => {
    if (rowDetails.eventStage == "PreEvent" && rowDetails.eventState == "Ready") {
      return 'Submitted';
    }
    else if (rowDetails.eventStage == "PostEvent" && rowDetails.eventState == "RequestForProcessing") {
      return "Ops Processing";
    }
    else if (rowDetails.eventStage == "PostEvent" && rowDetails.eventState == "Ready") {
      return "Ops Processing";
  }
    else if (rowDetails.eventStage == "PostEvent" && rowDetails.eventState == "AssetPublished") {
      return "Video Published";
    }
    else if (rowDetails.eventStage == "PostEvent" && rowDetails.eventState == "ProcessingInProgress") {
      return "Video Processing";
    }
    else if (rowDetails.eventStage == "PostEvent" && rowDetails.eventState == "ProcessingFailed") {
      return "Processing Failed";
    }
    else if (rowDetails.eventStage == "PostEvent" && rowDetails.eventState == "PartialSuccess") {
      return "Partial Success";
    }
    else if (rowDetails.eventStage == "PostEvent" && rowDetails.eventState == "Success") {
      return "Success";
    }
    else {
      return "Not Started";
    }
  }

  // Changes the choice of season option
  const onChangeSeason = (id) => {
    setSeasonId(id);
    setSearchQuery('');
  }

  // Filtering events based on current season
  const filterEventsBySeason = (events) => {
    let selectedSeason;
    if (seasonId) {
      // Find the season object based on the seasonId
      selectedSeason = seasons?.find(season => season.seasonId === seasonId);
    } else {
      const currentDate = new Date();
      selectedSeason = seasons?.find(season => {
        const startDate = new Date(season.startDate);
        const endDate = new Date(season.endDate);
        return currentDate >= startDate && currentDate <= endDate;
      });
    }

    if (selectedSeason) {
      return events.filter(event => {
        const eventDate = new Date(event.eventDateTime);
        return eventDate >= new Date(selectedSeason.startDate) && eventDate <= new Date(selectedSeason.endDate);
      });
    }

    // If no season is selected or found, return all events
    return events;
  };

  const filteredEvents = filterEventsBySeason(searchQuery.trim() !== "" || seasonId > 0 ? filteredData : rows);

  return (

    <div className="post-event-details-dashboard">
      <div className="div-2">
        <PostEventDetails />
        <div className="frame-19">
          <div className="frame-20">
            <div className="section-title">
              <div className="frame-21">
                <div className="page-title">
                  <div className="pagetitle">Post Event Details</div>
                </div>
                <div className="breadcrum">
                  <img
                    className="material-symbols"
                    alt="Material symbols"
                    src={homeImage}
                  />
                  <img
                    className="keyboard-arrow-right"
                    alt="Keyboard arrow right"
                    src={rightarrow}
                  />
                  <div className="text-wrapper-4">Post Event Details</div>
                  <img
                    className="keyboard-arrow-right"
                    alt="Keyboard arrow right"
                    src={rightarrow}
                  />
                  <div className="text-wrapper-5">Event Dashboard</div>
                </div>
              </div>
            </div>
            <div className="frame-data">

              <div className="frame-datasearch">

                <div className="frame-datasearch2">
                  <div className="search">
                    <p className="label-and">
                      <input
                        className="input"
                        placeholder="Search"
                        type="text"
                        value={searchQuery}
                        onChange={handleSearch} />
                    </p>
                    <img
                      className="generic"
                      alt="Generic"
                      src={searchicon}
                    />
                  </div>

                </div>

                <div className="frame-datafilter">
                  <StyledEngineProvider injectFirst>
                    <DropDown onChangeSeason={onChangeSeason}/>
                  </StyledEngineProvider>
                </div>
              </div>

              <div className="frame-datagrid">
                <StyledEngineProvider injectFirst>
                  <DatagridPostEvent rows={filteredEvents} />
                </StyledEngineProvider>
              </div>


            </div>

          </div>

        </div>
      </div>
      {loadingOperation && (
            <div className="loader-container">
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px' }}>
              <CircularProgress />
            </Box>
        </div>
          )}

      {loading && (
        <div className="video-modal">
          <div className="video-player-container">

            <div className="frame-success-model">
              <Box sx={{ justifyContent: 'center', marginTop: '10px', width: '50%' }}></Box>
              <div style={{ textAlign: 'center', marginTop: '10px', fontFamily: 'Poppins', fontSize: '1.2em' }} className="text-wrapper">You are not logged in.<br /><br /> To log in please <Link className="event-ID group-4" style={{ fontFamily: 'Poppins', color: 'blue' }} onClick={() => { window.location.href = `${ window.APP.loginUrl}` }}>Click Here</Link></div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    postEventList: state.postEvent.postEventList,
    postEventDetails: state.postEvent.postEventDetails,
    postEventData: state.postEvent.postEventData,
    seasons: state.shared.seasonList?.result,    
    selectedSeasonForPost: state.postEvent.selectedSeasonForPost,
    searchQueryForPost: state.postEvent.searchQueryForPost,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserAcceptance: () => dispatch(getUserAcceptance()),
    loadevent: (clientId) => dispatch(FetchPostEventList(clientId)),
    setSearchQueryForPostevent: (seasonId) => dispatch(setSearchQueryForPostevent(seasonId)),
  };
};

const ConnectedPostEventDetailsDashboard = connect(mapStateToProps, mapDispatchToProps)(PostEventDetailsDashboard);

export { ConnectedPostEventDetailsDashboard as PostEventDetailsDashboard };
