import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { Callout, ColorPicker, FontWeights, Modal, getTheme, mergeStyleSets } from '@fluentui/react';
import { Box, CircularProgress } from "@mui/material";
import { StyledEngineProvider } from '@mui/material/styles';
import { toastError } from '../../../constants/Constants';
import { PreEventDetails } from "../PreEventDetails";
import Stepper from '../Stepper';
import "./style.css";

import { FiRrCloudUpload13 } from "../../../icons/FiRrCloudUpload13/FiRrCloudUpload13";
import { FiRrCommentInfo } from "../../../icons/FiRrCommentInfo/FiRrCommentInfo";
import { FiRrEdit12 } from "../../../icons/FiRrEdit12/FiRrEdit12";
import { FiRrEdit14 } from "../../../icons/FiRrEdit14/FiRrEdit14";
import { FiRrEdit8 } from "../../../icons/FiRrEdit8/FiRrEdit8";
import { FiRrPicture1 } from "../../../icons/FiRrPicture1/FiRrPicture1";
import { TemplateEmptyVuesaxLinearProperty332 } from "../../../icons/TemplateEmptyVuesaxLinearProperty332/TemplateEmptyVuesaxLinearProperty332";

import rightarrow from '../../../icons/ImageIcons/keyboard-arrow-right.svg';
import homeImage from '../../../icons/ImageIcons/material-symbols_home-rounded.svg';
import uploadIcon from '../../../icons/ImageIcons/upload-icon.png';
import uploadMoreIcon from '../../../icons/ImageIcons/upload-more-icon.svg';

import { PropertyDefault } from "../../../components/PropertyDefault";

import { useBoolean } from '@fluentui/react-hooks';
import { completeStep, postIntroVideo, setEventSuccess } from "../../../store/Actions/PreEventAction";
import "./style.css";

import { Checkbox } from "../../../components/Checkbox";
import ImageModal from "../../../components/ImageCropper/ImageModal";

const fixedWidthForClass =20
const fixedWidthForYear = 30

const CreateIntroClipFrame = ({loading, eventMasterData, postIntroVideo, introVideoData, eventState, setEventSuccess, eventSuccess, eventDetails }) => {
  const navigate = useNavigate();
  const [sheetBackcolor, setsheetBackcolor] = React.useState('#f5f5f5');
  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
  const [isCalloutVisible_Images, { toggle: toggleIsCalloutVisible_Images }] = useBoolean(false);
  const [isCalloutVisible_Heading, { toggle: toggleIsCalloutVisible_Heading }] = useBoolean(false);
  const [isCalloutVisible_Subheading, { toggle: toggleIsCalloutVisible_Subheading }] = useBoolean(false);
  const [isCalloutVisible_Year, { toggle: toggleIsCalloutVisible_Year }] = useBoolean(false);
  const [textchangeClass, setTextChangeClass] = React.useState('CLASS OF');
  const [textchangeHeading, setTextChangeHeading] = React.useState('CONGRATULATIONS');
  const [textchangeYear, setTextChangeYear] = React.useState('2024');
  const [isbuttontoggle, setToggle] = useBoolean(false);
  const [imageBordercolor, setimageBordercolor] = React.useState('#6e6e6e');
  const [headingColor, setheadingColor] = React.useState('#6e6e6e');
  const [subheadingColor, setsubheadingColor] = React.useState('#6e6e6e');
  const [yearColor, setyearColor] = React.useState('#6e6e6e');
  const [isModalOpen_Images, { setTrue: showModal_Images, setFalse: hideModal_Images }] = useBoolean(false);
  const [isModalOpen_Images_logo, { setTrue: showModal_Images_logo, setFalse: hideModal_Images_logo }] = useBoolean(false);
  const [preview_Images, setPreview_Images] = useState([]);
  const [preview_Images_logo, setPreview_Images_logo] = useState();
  const [isLogoUploaded, setIsLogoUploaded] = useState(false);
  const [latestFile, setLatestFile] = useState('');
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isFileSelectorDisabled, setIsFileSelectorDisabled] = useState(false);
  const [uploadedImageFiles, setUploadedImageFiles] = useState([]);
  // const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const [inputWidthForYear, setInputWidthForYear] = useState((textchangeYear.length + 1 )*fixedWidthForYear);
  const [inputWidthForClass, setInputWidthForClass] = useState((textchangeClass.length + 1 )*fixedWidthForClass);
  
  const [selectedImageForCropping, setSelectedImageForCropping] = useState(null);
  const [isCroppingForLogo, setIsCroppingForLogo] = useState(false);
  const [isCroppingForImages, setIsCroppingForImages] = useState(false);
  const [completedCrop, setCompletedCrop] = useState(null);

  useEffect(() => {
    if (!eventMasterData) {
      navigate('/PreEventDetailsDashboard')
    }
  }, [eventMasterData]);

  useEffect(() => {
    if (eventSuccess) {
      console.log("Event created successfully!");
      setEventSuccess(false);
      if(eventMasterData?.result?.eventData?.template === 'Custom'){
        navigate("/StudentClip");
      } else {
        navigate("/CreateExitClip");
      }
      dispatch(completeStep(3, true));
    }
  }, [eventSuccess]);

  useEffect(() => {
    // if (introVideoData?.result?.isDataAvailable) {
      setsheetBackcolor(introVideoData?.result?.data?.createIntroVideo?.frameBGColor || '#f5f5f5');
      setimageBordercolor(introVideoData?.result?.data?.createIntroVideo?.thinFrameColor || '#6e6e6e');
      setheadingColor(introVideoData?.result?.data?.createIntroVideo?.textAsset[0]?.textColor || '#6e6e6e')
      setsubheadingColor(introVideoData?.result?.data?.createIntroVideo?.textAsset[1]?.textColor || '#6e6e6e')
      setyearColor(introVideoData?.result?.data?.createIntroVideo?.textAsset[2]?.textColor || '#6e6e6e')
      setTextChangeHeading(introVideoData?.result?.data?.createIntroVideo?.textAsset?.[0]?.textData || 'CONGRATULATIONS');
      setTextChangeClass(introVideoData?.result?.data?.createIntroVideo?.textAsset?.[1]?.textData || 'CLASS OF');
      setTextChangeYear(introVideoData?.result?.data?.createIntroVideo?.textAsset?.[2]?.textData || '2024');
      setInputWidthForYear(((introVideoData?.result?.data?.createIntroVideo?.textAsset?.[2]?.textData || '2024').length + 1) * fixedWidthForYear);
      setInputWidthForClass(((introVideoData?.result?.data?.createIntroVideo?.textAsset?.[1]?.textData || 'CLASS OF').length + 1) * fixedWidthForClass);
      setPreview_Images_logo({ url: introVideoData?.result?.data?.createIntroVideo?.logoSasUri, uploadedData: true } || {});
      if (introVideoData?.result?.data?.createIntroVideo?.thinFramePersonImageAssets) {
        const filteredImages = introVideoData?.result?.data?.createIntroVideo?.thinFramePersonImageAssets.filter(imageData => imageData.orderId !== 0);
        const allEmpty = filteredImages.every(imageData => (
          !imageData.imageName &&
          !imageData.imageSasUri
      ));
  
      if (allEmpty) {
          setPreview_Images(null);
          setLatestFile(null)
          setUploadedImageFiles(null)
      } else {
          const previewImages = filteredImages.map(imageData => ({
              name: imageData.imageName,
              result: imageData.imageSasUri,
              uploadedData: true
          }));
          setPreview_Images(previewImages);
          setLatestFile(previewImages[0]?.result || null);
          const previewImagesWithAllData = filteredImages.map(imageData => ({
            orderId: imageData.orderId,
            imageName: imageData.imageName,
            imagePath: imageData.imagePath,
            imageType: imageData.imageType,
        }));
          setUploadedImageFiles(previewImagesWithAllData)
      }
      } else {
        setPreview_Images(null);
        setLatestFile(null)
        setUploadedImageFiles(null);
    }
      console.log(textchangeHeading);
    // }
  }, [introVideoData])

  // Changes selected colors based on user inputs
  const onChangeBackgroundColor = React.useCallback((ev, colorObj) =>
    setsheetBackcolor(colorObj.str), []);

  // Changes selected colors based on user inputs
  const onChangeBackgroundColor_Images = React.useCallback((ev, colorObj) =>
    setimageBordercolor(colorObj.str), []);

  // Changes selected colors based on user inputs
  const onChangeBackgroundColor_Heading = React.useCallback((ev, colorObj) =>
    setheadingColor(colorObj.str), []);

  // Changes selected colors based on user inputs
  const onChangeBackgroundColor_Subheading = React.useCallback((ev, colorObj) =>
    setsubheadingColor(colorObj.str), []);

  // Changes selected colors based on user inputs
  const onChangeBackgroundColor_Year = React.useCallback((ev, colorObj) =>
    setyearColor(colorObj.str), []);

  // Addds the texts based on user inputs
  const onChangeTexts = (inputValue, text) => {
    if (text === 'Class') {
      setTextChangeClass(inputValue.target.value);
      setInputWidthForClass((inputValue.target.value.length + 1) * fixedWidthForClass);
    }
    if (text === 'Year') {
      setTextChangeYear(inputValue.target.value);
      setInputWidthForYear((inputValue.target.value.length + 1) * fixedWidthForYear);
    }
  }

  // Adds the user selected image file to the UI
  const handleOnChange = (e) => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      const reader = new FileReader();
      const allowedTypes = ['image/png', 'image/jpeg'];
      if (!allowedTypes.includes(file.type)) {
        toastError('Invalid file type. Please select an image file with one of the following extensions: .png, .jpeg');
        return;
      } else {
        reader.onload = function () {
          const image = new Image();
          image.onload = () => {
            const width = image.width;
            const height = image.height;
            if (width >= 1000 && height >= 1000) {
              setSelectedImageForCropping({ name: file.name, url: reader.result });
              setIsCroppingForImages(true);
              hideModal_Images();
            } else {
              toastError('File is not in given dimensions');
            }
          };
          image.src = reader.result;
        };

        reader.readAsDataURL(file);
        e.target.value = null;
      }
    }
  };


  // Adds the user selected logo/image file to the UI
  const handleOnChange_logo = (e) => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      const reader = new FileReader();

      const allowedTypes = ['image/png'];
      if (!allowedTypes.includes(file.type)) {
        toastError('Invalid file type. Please select a .png file.');
      } else {
        reader.onload = function () {
          const image = new Image();
          image.onload = () => {
            const width = image.width;
            const height = image.height;
            if (width >= 500 && height >= 100) {
              setSelectedImageForCropping({name: file.name, url: reader.result});
              setIsLogoUploaded(true);
              showModal_Images_logo();
            } else {
              toastError('File is not in given dimensions');
            }
          };
          // Set the src of the Image object
          image.src = reader.result;
        };

        reader.readAsDataURL(file);
      }
    }
  };
  
 // Opens modal to upload logo
  const preview_logo_click=()=>{
    setIsCroppingForLogo(true);
    setIsLogoUploaded(false);
    hideModal_Images_logo();
  }

  // Closes the upload logo modal
  const handleCloseModal = () => {
    hideModal_Images_logo();
    setIsLogoUploaded(false); // Reset logoUploaded state when modal is closed
  };

  // Opens file explorer to select the desired file
  const openUpload = () => {
    if (document.getElementById('image_upload') != undefined) {
      document.getElementById('image_upload').click();
    }
  }

  // Opens file explorer to select the desired file
  const openUpload_outer = () => {
    if (document.getElementById('image_upload_outer') != undefined) {
      document.getElementById('image_upload_outer').click();
    }
  }

  // Opens file explorer to select the desired file
  const openUpload_logo = () => {
    if (document.getElementById('image_upload_logo') != undefined) {
      document.getElementById('image_upload_logo').click();
    }
  }

  const handleDrop = (e) => {
    const allowedTypes = new Set([e.target.accept]);
    if (!allowedTypes.has(e.dataTransfer.files[0].type)) {
      // stop event prepagation
      e.preventDefault();
    }
  };

  // Uploads the data for the creation of intro video
  const handleSave = async () => {
    if(eventMasterData?.result?.eventData?.introClipPublished || eventState){
      if(eventMasterData?.result?.eventData?.template === 'Custom'){
        navigate("/StudentClip");
      } else {
        navigate("/CreateExitClip");
      }
    } else {
    if ((!preview_Images_logo?.url && !selectedImageForCropping) || (!uploadedImageFiles?.length && !preview_Images?.length)) {
      toastError('Please select at least one file for images and logo');
      return;
    }

    if(textchangeHeading === '' || textchangeClass === '' || textchangeYear === ''){
      toastError('One of the text field is empty.');
      return;
  }

    // setLoading(true);
    const thinFramePersonImageAssets = [];
    let logoDetails;

    if (preview_Images_logo?.uploadedData) {
      logoDetails = {
        logoName: introVideoData?.result?.data?.createIntroVideo?.logoName,
        logoPath: introVideoData?.result?.data?.createIntroVideo?.logoPath,
      };
    } else {
      logoDetails = {
        logoName: preview_Images_logo.name,
      };
    }
    const previewImagesToAdd = preview_Images.filter(image => !image.uploadedData);
    let lengthOfUploadedImages = 0
    let remainingSlots = 3
    
    if (uploadedImageFiles) {
      lengthOfUploadedImages = uploadedImageFiles.length;
      remainingSlots = 3 - lengthOfUploadedImages;
    if (uploadedImageFiles.length > 0) {
      uploadedImageFiles.forEach(image => {
        const jsonObject = {
          orderId: image.orderId,
          imageName: image.imageName,
          imagePath: image.imagePath,
          imageType: image.imageType,
        };
        thinFramePersonImageAssets.push(jsonObject);
      });
    }
  }
    previewImagesToAdd.slice(0, remainingSlots).forEach((image, index) => {
      const imageType = image.result.substring(image.result.indexOf('/') + 1, image.result.indexOf(';'));
      const jsonObject = {
        orderId: lengthOfUploadedImages + index + 1,
        imageName: image.name,
        imagePath: "",
        imageType: imageType,
      };
      thinFramePersonImageAssets.push(jsonObject);
    });

    await postIntroVideo(selectedFiles, {
      itemType: 'IntroClip',
      clipType: 'Intro',
      eventId: eventMasterData?.result?.eventData?.eventId,
      eventName: eventMasterData?.result?.eventData?.eventName,
      clientId: eventMasterData?.result?.eventData?.clientId,
      clientName: eventMasterData?.result?.eventData?.clientName,
      createVideo: true,
      createIntroVideo: {
        frameBGColor: sheetBackcolor,
        thinFrameColor: imageBordercolor,
        textAsset: [
          { textId: "1", textData: textchangeHeading, textFontStyle: "Bold", textColor: headingColor },
          { textId: "2", textData: textchangeClass, textFontStyle: "BoldItalic", textColor: subheadingColor },
          { textId: "3", textData: textchangeYear, textFontStyle: "BoldLayers", textColor: yearColor },
        ],
        thinFramePersonImageAssets: thinFramePersonImageAssets,
        ...logoDetails,
        GeneratedVideoPath: ""
      },
      updatedOn: new Date(),
      updatedBy: eventDetails?.adminEmail
    });
    // setLoading(false);
  }
  };

  // Deletes the images when user clicks on cross icon
  const handleDelete = (index) => {
    // Create a copy of the preview_Images array
    const updatedPreviewImages = [...preview_Images];
    
    // Remove the image at the specified index
    updatedPreviewImages.splice(index, 1);

    // Update the state with the modified array
    setPreview_Images(updatedPreviewImages);

    // Check if the deleted image was the latestFile
    if (index === 0 && updatedPreviewImages.length === 0) {
      // If the deleted image was the latestFile and there are no more images, reset latestFile to null
        setLatestFile(null);
    }
    else if (index === 0 && updatedPreviewImages.length >= 1){
      setLatestFile(updatedPreviewImages[0].result);
    }
    else if (updatedPreviewImages.length > 0) {
      // If the deleted image was the latestFile, set the main preview to the next image
        setLatestFile(updatedPreviewImages[index - 1].result);
    }

    // Update uploadedImageFiles if necessary
    if (uploadedImageFiles) {
        const uploadedImages = [...uploadedImageFiles];
        uploadedImages.splice(index, 1);
        setUploadedImageFiles(uploadedImages);
    }

    // Reset isFileSelectorDisabled if necessary
    setIsFileSelectorDisabled(updatedPreviewImages.length === 3);
};

// Closes the cropper modal
const onCloseCropper = () => {
  setIsCroppingForLogo(false);
  setIsCroppingForImages(false);
}

  // const handleImageClick = (index) => {
  //   setLatestFile(preview_Images[index].result);
  // }

  return (
    <div className="create-intro-clip-frame">
      <div className="div-3">
        <PreEventDetails></PreEventDetails>
        <div className="frame-12">
          <div className="frame-13">
            <div className="section-title">
              <div className="frame-14">
                <div className="page-title">
                  <div className="text-wrapper-4">Create Intro Clip</div>
                </div>
                <div className="breadcrum">
                  <img
                    className="material-symbols"
                    alt="Material symbols"
                    src={homeImage}
                  />
                  <img
                    className="keyboard-arrow-right"
                    alt="Keyboard arrow right"
                    src={rightarrow}
                  />
                  <div className="text-wrapper-5">Events</div>
                  <img
                    className="keyboard-arrow-right"
                    alt="Keyboard arrow right"
                    src={rightarrow}
                  />
                  <div className="text-wrapper-6">Create Intro Clip Frame</div>
                </div>
              </div>
            </div>
            <div className="frame-15">
              <div className="frame-16">
                <StyledEngineProvider injectFirst>
                  <Stepper gradReelType={eventMasterData?.result?.eventData?.template}/>
                </StyledEngineProvider>
                <div className="frame-16-1">
                  <div className="frame-17">
                    <div className="frame-18">
                      <div className="frame-19">
                        <div className="frame-20">
                          <div className="frame-21">
                            <div className="text-wrapper-7">Create Intro Clip</div>
                          </div>
                          <div className="frame-22">
                            <div className="frame-23">
                              <div className="component-wrapper">
                                <TemplateEmptyVuesaxLinearProperty332 className="template-empty" />
                              </div>
                              <p className="video-tutorial-how">
                                <span className="span">Video Tutorial: </span>
                                <span className="text-wrapper-8">How to Generate an Intro Clip?</span>
                              </p>
                            </div>
                          </div>
                        </div>
                        <FiRrCommentInfo className="icon-instance-node" color="#7E7E7E" />
                      </div>
                      <div className="group-2" style={{backgroundColor: sheetBackcolor != '' ? sheetBackcolor : '#f5f5f5', pointerEvents: (eventMasterData?.result?.eventData?.introClipPublished || eventState) ? 'none' : 'auto', opacity: (eventMasterData?.result?.eventData?.introClipPublished || eventState) ? 0.5 : 1 }}>
                        <div className="group-3" style={{ borderColor: imageBordercolor != '' ? imageBordercolor : '#6e6e6e'}}>
                              {latestFile != null ?
                                <>
                                  <img style={{ width: '100%', height: '100%' }} src={latestFile} alt="Upload preview" />
                                </>
                                :
                                <div className="frame-24" onClick={showModal_Images}>
                                  <FiRrPicture1 className="icon-instance-node-upload" />
                                  <div className="text-wrapper-9">Upload Image(s)</div>
                                </div>
                              }
                            <div className="fi-rr-edit-12-wrapper"
                              id="callout-button-1" onClick={toggleIsCalloutVisible_Images}>
                              <FiRrEdit12 className="icon-instance-node-2" />
                            </div>

                            </div>
                            

                            <div className="frame-25">
                              <PropertyDefault
                                className="design-component-instance-node-2"
                                icon={<FiRrEdit8 className="icon-instance-node-2" />}
                                onclick={toggleIsCalloutVisible_Heading}
                                id="callout-button-2"
                                headvalue={headingColor}
                                onChange={(newValue) => {
                                  setTextChangeHeading(newValue);

                                }}
                                value={textchangeHeading}
                              />
                              <div className="frame-27-class">
                                 <input className="text-wrapper-10" style={{ fontFamily:'Lato', color: subheadingColor != '' ? subheadingColor : '#6e6e6e', width: inputWidthForClass + 'px', maxWidth: '100%' }} maxLength={18} onChange={(e)=> onChangeTexts(e,'Class')} value={textchangeClass}/>

                                <div className="fi-rr-edit-14-wrapper" id="callout-button-3" onClick={toggleIsCalloutVisible_Subheading}>
                                  <FiRrEdit14 className="icon-instance-node-2" />
                                </div>
                              </div>
                              <div className="frame-27-year">
                                <input className="text-wrapper-11" style={{ fontFamily:'SeasideResortNF', color: yearColor != '' ? yearColor : '#6e6e6e', width: inputWidthForYear + 'px', maxWidth: '100%' }} maxLength={18} onChange={(e)=>onChangeTexts(e,'Year')} value={textchangeYear}/>
                                 <div className="fi-rr-edit-15-wrapper" id="callout-button-4" onClick={toggleIsCalloutVisible_Year}>
                                  <FiRrEdit14 className="icon-instance-node-2" />
                                </div>
                              </div>
                              <div className="overlap-2">
                          <div className="rectangle-2" />

                          <div className="frame-28" style={{ width: '98%', left: preview_Images_logo?.url != null ? '2px' : '74px' }} onClick={showModal_Images_logo}>
                            {preview_Images_logo?.url != null ?
                              <img style={{ width: '100%', height: '56px' }} src={preview_Images_logo?.url} alt="Upload preview" />
                              :
                              <>
                                <div className="group-4">
                                  <div className="overlap-group-3">
                                    <div className="text-wrapper-12">Logo</div>
                                  </div>
                                </div>
                                <div className="text-wrapper-13">Your logo goes here</div>
                              </>
                            }

                          </div>
                          <div className="fi-rr-cloud-upload-wrapper"onClick={showModal_Images_logo}>
                            <FiRrCloudUpload13 className="icon-instance-node-2" color="#5C5C5C" />
                          </div>
                        </div>
                      </div>
                           
                          <div className="fi-rr-edit-11-wrapper" id="callout-button" onClick={toggleIsCalloutVisible}>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_4153_40510)">
                                <path d="M12.438 0.62135L4.31 8.74935C3.99956 9.05811 3.75345 9.42539 3.58592 9.82991C3.41839 10.2344 3.33276 10.6682 3.334 11.106V12.0014C3.334 12.1782 3.40424 12.3477 3.52926 12.4728C3.65428 12.5978 3.82385 12.668 4.00066 12.668H4.896C5.33384 12.6693 5.76758 12.5836 6.1721 12.4161C6.57663 12.2486 6.9439 12.0025 7.25266 11.692L15.3807 3.56402C15.7703 3.17346 15.9891 2.64433 15.9891 2.09268C15.9891 1.54103 15.7703 1.0119 15.3807 0.62135C14.9844 0.242613 14.4574 0.03125 13.9093 0.03125C13.3612 0.03125 12.8342 0.242613 12.438 0.62135ZM14.438 2.62135L6.31 10.7494C5.93408 11.123 5.426 11.3333 4.896 11.3347H4.66733V11.106C4.66872 10.576 4.87904 10.0679 5.25266 9.69202L13.3807 1.56402C13.5231 1.42799 13.7124 1.35208 13.9093 1.35208C14.1063 1.35208 14.2956 1.42799 14.438 1.56402C14.578 1.70436 14.6566 1.89448 14.6566 2.09268C14.6566 2.29089 14.578 2.48101 14.438 2.62135Z" fill="#5C5C5C"/>
                                <path d="M15.3333 5.986C15.1565 5.986 14.987 6.05624 14.8619 6.18126C14.7369 6.30629 14.6667 6.47586 14.6667 6.65267V10H12C11.4696 10 10.9609 10.2107 10.5858 10.5858C10.2107 10.9609 10 11.4696 10 12V14.6667H3.33333C2.8029 14.6667 2.29419 14.456 1.91912 14.0809C1.54405 13.7058 1.33333 13.1971 1.33333 12.6667V3.33333C1.33333 2.8029 1.54405 2.29419 1.91912 1.91912C2.29419 1.54405 2.8029 1.33333 3.33333 1.33333H9.36133C9.53815 1.33333 9.70771 1.2631 9.83274 1.13807C9.95776 1.01305 10.028 0.843478 10.028 0.666667C10.028 0.489856 9.95776 0.320286 9.83274 0.195262C9.70771 0.0702379 9.53815 0 9.36133 0L3.33333 0C2.4496 0.00105857 1.60237 0.352588 0.97748 0.97748C0.352588 1.60237 0.00105857 2.4496 0 3.33333L0 12.6667C0.00105857 13.5504 0.352588 14.3976 0.97748 15.0225C1.60237 15.6474 2.4496 15.9989 3.33333 16H10.8953C11.3333 16.0013 11.7671 15.9156 12.1718 15.7481C12.5764 15.5806 12.9438 15.3345 13.2527 15.024L15.0233 13.252C15.3338 12.9432 15.58 12.576 15.7477 12.1715C15.9153 11.767 16.0011 11.3332 16 10.8953V6.65267C16 6.47586 15.9298 6.30629 15.8047 6.18126C15.6797 6.05624 15.5101 5.986 15.3333 5.986ZM12.31 14.0813C12.042 14.3487 11.7031 14.5337 11.3333 14.6147V12C11.3333 11.8232 11.4036 11.6536 11.5286 11.5286C11.6536 11.4036 11.8232 11.3333 12 11.3333H14.6167C14.5342 11.7023 14.3493 12.0406 14.0833 12.3093L12.31 14.0813Z" fill="#5C5C5C"/>
                                </g>
                                <defs>
                                <clipPath id="clip0_4153_40510">
                                <rect width="16" height="16" fill="white"/>
                                </clipPath>
                                </defs>
                                </svg>
                            </div>
                      </div>

                        <input id="image_upload_outer" onChange={(e) => handleOnChange(e)} style={{ display: 'none' }} type="file" accept="image/png, image/jpeg" disabled={isFileSelectorDisabled} />

                          {preview_Images?.length > 0 ?
                           <div style={{ display: 'flex', flexDirection: 'row',width:'880px',marginLeft:'20px', pointerEvents: (eventMasterData?.result?.eventData?.introClipPublished || eventState) ? 'none' : 'auto', opacity: (eventMasterData?.result?.eventData?.introClipPublished || eventState) ? 0.5 : 1}}>
                              {preview_Images?.map((itm, index) => {
                                return (
                                  <p style={{ marginRight: '30px' }}>
                                    <img className="plusIcon_ImagePreview" src={itm.result} alt="Upload preview" />
                                    <button className="delete-button" onClick={() => handleDelete(index)}>X</button>
                                  </p>
                                )
                              })}
                              {preview_Images?.length == 3 ? '' :
                                <div onClick={showModal_Images} className="plusIcon_Image">
                                  <img src={uploadMoreIcon} alt="Upload preview" />
                                </div>
                              }
                            </div>


                            : ''}

                      </div>
                        
                        
                    </div>
                      

                  
                </div>
                

              </div>
            </div>
          </div>
          <div className="frame-29">
            <Link to="/MediaDetails" className="button-text">
              <button className="primary-BTN">
                Back
              </button>
            </Link>
            <button className="button-text-wrapper" onClick={handleSave}>
              <button className="button" >Continue</button>
            </button>
          </div>

          <ImageModal
            modalIsOpen={isCroppingForLogo}
            onClose={onCloseCropper}
            image={selectedImageForCropping}
            onCropCompleted={(area) => {
              setCompletedCrop(area);
            }}
            onUpload={(file) => {
              setSelectedFiles(prevSelectedFiles => [...prevSelectedFiles, file]);
            }}
            onFileCropping={(file) => {
              if (completedCrop.width >= 500 && completedCrop.height >= 100) {
                setIsCroppingForLogo(false);
                setPreview_Images_logo({ name: selectedImageForCropping.name, url: file.url, uploadedData: false })
              } else {
                toastError('Cropped image minimum dimensions must be 500x100');
              }
            }}
            ratio={5}
          />

           <ImageModal
            modalIsOpen={isCroppingForImages}
            onClose={onCloseCropper}
            image={selectedImageForCropping}
            onCropCompleted={(area) => {
              setCompletedCrop(area);
            }}
            onUpload={(file) => {
              setSelectedFiles(prevSelectedFiles => [...prevSelectedFiles, file]);
              }}
            onFileCropping={(file) => {
              if (completedCrop.width >= 1000 && completedCrop.height >= 1000) {
              const newArray = [{ name: selectedImageForCropping.name, result: file.url, uploadedData: false }];
              setPreview_Images(prev => prev ? [...prev, ...newArray] : newArray);
              setLatestFile(file.url); // Set latestFile only if it hasn't been set yet
              setIsCroppingForImages(false);  
            } else {
                toastError('Cropped image minimum dimensions must be 1000x1000');
              }
            }}
            ratio={1000/1000}
          />

          {isCalloutVisible && (
            <Callout
              className={styles1.callout}
              ariaLabelledBy={'callout-label'}
              ariaDescribedBy={'callout-description'}
              role="dialog"
              gapSpace={0}
              target={`#callout-button`}
              onDismiss={toggleIsCalloutVisible}
              setInitialFocus
            >
              <ColorPicker
                color={sheetBackcolor}
                onChange={onChangeBackgroundColor}
                alphaType='none'
                showPreview={false}
                styles={colorPickerStyles}
                strings={{
                  hueAriaLabel: 'Hue',
                }}
              />
            </Callout>
          )}

          {isCalloutVisible_Images && (
            <Callout
              className={styles1.callout}
              ariaLabelledBy={'callout-label'}
              ariaDescribedBy={'callout-description'}
              role="dialog"
              gapSpace={0}
              target={`#callout-button-1`}
              onDismiss={toggleIsCalloutVisible_Images}
              setInitialFocus
            >
              <ColorPicker
                color={imageBordercolor}
                onChange={onChangeBackgroundColor_Images}
                alphaType='none'
                showPreview={false}
                styles={colorPickerStyles}
                strings={{
                  hueAriaLabel: 'Hue',
                }}
              />
            </Callout>
          )}

          {isCalloutVisible_Heading && (
            <Callout
              className={styles1.callout}
              ariaLabelledBy={'callout-label'}
              ariaDescribedBy={'callout-description'}
              role="dialog"
              gapSpace={0}
              target={`#callout-button-2`}
              onDismiss={toggleIsCalloutVisible_Heading}
              setInitialFocus
            >
              <ColorPicker
                color={headingColor}
                onChange={onChangeBackgroundColor_Heading}
                alphaType='none'
                showPreview={false}
                styles={colorPickerStyles}
                strings={{
                  hueAriaLabel: 'Hue',
                }}
              />
            </Callout>
          )}

          {isCalloutVisible_Subheading && (
            <Callout
              className={styles1.callout}
              ariaLabelledBy={'callout-label'}
              ariaDescribedBy={'callout-description'}
              role="dialog"
              gapSpace={0}
              target={`#callout-button-3`}
              onDismiss={toggleIsCalloutVisible_Subheading}
              setInitialFocus
            >
              <ColorPicker
                color={subheadingColor}
                onChange={onChangeBackgroundColor_Subheading}
                alphaType='none'
                showPreview={false}
                styles={colorPickerStyles}
                strings={{
                  hueAriaLabel: 'Hue',
                }}
              />
            </Callout>
          )}

          {isCalloutVisible_Year && (
            <Callout
              className={styles1.callout}
              ariaLabelledBy={'callout-label'}
              ariaDescribedBy={'callout-description'}
              role="dialog"
              gapSpace={0}
              target={`#callout-button-4`}
              onDismiss={toggleIsCalloutVisible_Year}
              setInitialFocus
            >
              <ColorPicker
                color={yearColor}
                onChange={onChangeBackgroundColor_Year}
                alphaType='none'
                showPreview={false}
                styles={colorPickerStyles}
                strings={{
                  hueAriaLabel: 'Hue',
                }}
              />
            </Callout>
          )}

          <Modal
            titleAriaId={'title'}
            isOpen={isModalOpen_Images}
            onDismiss={hideModal_Images}
            isBlocking={false}
            containerClassName={contentStyles.container}
          // dragOptions={isDraggable ? dragOptions : undefined}
          >
            <div className="uploadModal">
              <div className="div-2">
                <button className="CTA">
                  <div className="label">UPLOAD PHOTO</div>
                </button>
                <div className="heading-upload">
                  <div className="text-wrapper">Upload Photos</div>

                </div>
                <div className="upload-file-area" onClick={openUpload}>
                  <div className="frame-2">
                    <img
                      className="upload-icon"
                      alt="Upload icon"
                      src={uploadIcon}
                    />
                    <div className="frame-3">
                      <div className="drag-drop-files">
                        <p className="drag-drop-files-or">
                          <span className="span">Drag &amp; drop files or </span>
                          <span className="text-wrapper-3">Browse</span>
                        </p>
                        <input id="image_upload" onChange={(e) => handleOnChange(e)} style={{ display: 'none' }} type="file" accept="image/png, image/jpeg" disabled={isFileSelectorDisabled} />
                      </div>
                      <div className={`frame frame-1027`}>
                        <div className="div" style={{ display: 'flex', gap: '2px' }}>
                          <Checkbox className="checkbox-instance" property1="fi-sr-info" />
                          <p className="i-authorise">Kindly submit images in either .jpeg or .png format. <br />Ensure that the images have a minimum <br />dimension of 1000x1000 pixels.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </Modal>


          <Modal
            titleAriaId={'title'}
            isOpen={isModalOpen_Images_logo}
            onDismiss={handleCloseModal}
            isBlocking={false}
            containerClassName={contentStyles.container}
          // dragOptions={isDraggable ? dragOptions : undefined}
          >
            <div className="uploadModal">
              <div className="div-2">
                <button className="CTA" onClick={preview_logo_click}
                disabled={!isLogoUploaded} // Disable the button if logoUploaded is false
                style={{ opacity: isLogoUploaded ? 1 : 0.5,
                cursor:isLogoUploaded ? 'pointer' : 'not-allowed' }}>
                  <div className="label">UPLOAD LOGO</div>
                </button>
                <div className="heading-upload">
                  <div className="text-wrapper">Upload Logo</div>

                </div>
               <div className="upload-file-area" onClick={openUpload_logo}>
                  <div className="frame-2">
                    <img
                      className="upload-icon"
                      alt="Upload icon"
                      src={uploadIcon}
                  />
                    <div className="frame-3">
                      <div className="drag-drop-files">
                        <p className="drag-drop-files-or">
                          <span className="span">Drag &amp; drop files or </span>
                          <span className="text-wrapper-3">Browse</span>
                        </p>
                        <input id="image_upload_logo" onChange={(e) => handleOnChange_logo(e)} style={{ display: 'none' }} type="file" accept="image/png" />
                      </div>
                      <div className={`frame frame-1027`}>
                        <div className="div" style={{ display: 'flex', gap: '2px' }}>
                          <Checkbox className="checkbox-instance" property1="fi-sr-info" />
                          <p className="i-authorise">Kindly upload logos in .png format ensuring they have a<br /> transparent background. Ensure there is minimal<br /> blank space surrounding the logo, and that its dimensions<br /> are at least 500x100 pixels</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
          {loading && (
            <div className="loader-container">
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px' }}>
                <CircularProgress />
              </Box>
            </div>
          )}
          
        </div>
        </div>
      
    </div>
  );
};

const colorPickerStyles = {
  panel: { padding: 12 },
  root: {
    maxWidth: 352,
    //minWidth: 352,
  },
  colorRectangle: { height: 268 },
};

const styles1 = mergeStyleSets({
  button: {
    width: 130,
  },
  callout: {
    width: 320,
    maxWidth: '90%',
    padding: '20px 24px',
  },
  title: {
    marginBottom: 12,
    fontWeight: FontWeights.semilight,
  },
  link: {
    display: 'block',
    marginTop: 20,
  },
});

// Images Popup
const cancelIcon = { iconName: 'Cancel' };

const theme = getTheme();
const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    borderRadius: '20px'
  },
  header: [
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      borderTop: `4px solid ${theme.palette.themePrimary}`,
      color: theme.palette.neutralPrimary,
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '12px 12px 14px 24px',
    },
  ],
  heading: {
    color: theme.palette.neutralPrimary,
    fontWeight: FontWeights.semibold,
    fontSize: 'inherit',
    margin: '0',
  },
  body: {
    flex: '4 4 auto',
    padding: '0 24px 24px 24px',
    overflowY: 'hidden',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
});
const stackProps = {
  horizontal: true,
  tokens: { childrenGap: 40 },
  styles: { root: { marginBottom: 20 } },
};
const iconButtonStyles = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const mapStateToProps = (state) => {
  return {
    loading: state.preEvent.loading,
    eventMasterData: state.preEvent.gradreelType,
    introVideoData: state.preEvent.introVideoData,
    eventState: state.preEvent.eventState,
    eventSuccess: state.preEvent.eventSuccess,
    eventDetails: state.preEvent.eventDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    postIntroVideo: (files, introData) => dispatch(postIntroVideo(files, introData)),
    setEventSuccess: (flag) => dispatch(setEventSuccess(flag)),
  };
};

const ConnectedCreateIntroClipFrame = connect(mapStateToProps, mapDispatchToProps)(CreateIntroClipFrame);

export { ConnectedCreateIntroClipFrame as CreateIntroClipFrame };
