/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const FiRrEdit1 = ({ className }) => {
  return (
    <svg
      className={`fi-rr-edit-1 ${className}`}
      fill="none"
      height="10"
      viewBox="0 0 16 10"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_422_10079)">
        <path
          className="path"
          d="M12.4374 -5.37984L4.30945 2.74816C3.99901 3.05692 3.7529 3.4242 3.58537 3.82872C3.41784 4.23325 3.33221 4.66699 3.33345 5.10483V6.00016C3.33345 6.17697 3.40369 6.34654 3.52871 6.47157C3.65373 6.59659 3.8233 6.66683 4.00011 6.66683H4.89545C5.33329 6.66806 5.76703 6.58244 6.17155 6.41491C6.57608 6.24737 6.94335 6.00127 7.25212 5.69083L15.3801 -2.43717C15.7697 -2.82773 15.9885 -3.35686 15.9885 -3.90851C15.9885 -4.46016 15.7697 -4.98929 15.3801 -5.37984C14.9839 -5.75858 14.4569 -5.96994 13.9088 -5.96994C13.3607 -5.96994 12.8337 -5.75858 12.4374 -5.37984ZM14.4374 -3.37984L6.30945 4.74816C5.93353 5.12178 5.42545 5.3321 4.89545 5.33349H4.66678V5.10483C4.66817 4.57482 4.87849 4.06674 5.25211 3.69083L13.3801 -4.43717C13.5225 -4.5732 13.7119 -4.64911 13.9088 -4.64911C14.1057 -4.64911 14.2951 -4.5732 14.4374 -4.43717C14.5774 -4.29683 14.656 -4.10671 14.656 -3.90851C14.656 -3.7103 14.5774 -3.52018 14.4374 -3.37984Z"
          fill="#5C5C5C"
        />
        <path
          className="path"
          d="M15.3333 -0.0139992C15.1565 -0.0139992 14.987 0.0562388 14.8619 0.181263C14.7369 0.306287 14.6667 0.475856 14.6667 0.652668V4H12C11.4696 4 10.9609 4.21071 10.5858 4.58579C10.2107 4.96086 10 5.46957 10 6V8.66667H3.33333C2.8029 8.66667 2.29419 8.45595 1.91912 8.08088C1.54405 7.70581 1.33333 7.1971 1.33333 6.66667V-2.66667C1.33333 -3.1971 1.54405 -3.70581 1.91912 -4.08088C2.29419 -4.45595 2.8029 -4.66667 3.33333 -4.66667H9.36133C9.53815 -4.66667 9.70771 -4.7369 9.83274 -4.86193C9.95776 -4.98695 10.028 -5.15652 10.028 -5.33333C10.028 -5.51014 9.95776 -5.67971 9.83274 -5.80474C9.70771 -5.92976 9.53815 -6 9.36133 -6H3.33333C2.4496 -5.99894 1.60237 -5.64741 0.97748 -5.02252C0.352588 -4.39763 0.00105857 -3.5504 0 -2.66667L0 6.66667C0.00105857 7.5504 0.352588 8.39763 0.97748 9.02252C1.60237 9.64741 2.4496 9.99894 3.33333 10H10.8953C11.3333 10.0013 11.7671 9.91564 12.1718 9.74811C12.5764 9.58058 12.9438 9.33446 13.2527 9.024L15.0233 7.252C15.3338 6.94325 15.58 6.57599 15.7477 6.17147C15.9153 5.76696 16.0011 5.33321 16 4.89533V0.652668C16 0.475856 15.9298 0.306287 15.8047 0.181263C15.6797 0.0562388 15.5101 -0.0139992 15.3333 -0.0139992ZM12.31 8.08133C12.042 8.34867 11.7031 8.53374 11.3333 8.61467V6C11.3333 5.82319 11.4036 5.65362 11.5286 5.5286C11.6536 5.40357 11.8232 5.33333 12 5.33333H14.6167C14.5342 5.70234 14.3493 6.04062 14.0833 6.30934L12.31 8.08133Z"
          fill="#5C5C5C"
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_422_10079">
          <rect className="rect" fill="white" height="16" transform="translate(0 -6)" width="16" />
        </clipPath>
      </defs>
    </svg>
  );
};
