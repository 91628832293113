import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from 'react-router-dom';

import { Callout, ColorPicker, FontWeights, getTheme, mergeStyleSets } from '@fluentui/react';
import { Box, CircularProgress } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { StyledEngineProvider } from '@mui/material/styles';
import "./style.css";

import { SeasonSettings } from "../SeasonSettings";
import Tabs from '../Tabs';

import { FiRrCloudUpload13 } from "../../../icons/FiRrCloudUpload13/FiRrCloudUpload13";
import { FiRrCommentInfo } from "../../../icons/FiRrCommentInfo/FiRrCommentInfo";
import { FiRrEdit12 } from "../../../icons/FiRrEdit12/FiRrEdit12";
import { FiRrEdit14 } from "../../../icons/FiRrEdit14/FiRrEdit14";
import { FiRrEdit8 } from "../../../icons/FiRrEdit8/FiRrEdit8";
import { FiRrPicture1 } from "../../../icons/FiRrPicture1/FiRrPicture1";
import { TemplateEmptyVuesaxLinearProperty332 } from "../../../icons/TemplateEmptyVuesaxLinearProperty332/TemplateEmptyVuesaxLinearProperty332";

import infoicon from "../../../icons/ImageIcons/fi-sr-info-1.svg";
import rightarrow from '../../../icons/ImageIcons/keyboard-arrow-right.svg';
import homeImage from '../../../icons/ImageIcons/material-symbols_home-rounded.svg';
import uploadIcon from '../../../icons/ImageIcons/upload-icon.png';

import { Checkbox } from "../../../components/Checkbox";
import DropDownSeasonSettings from "../../../components/DropDownSeasonSettings/DropDownSeasonSettings";
import { PropertyDefault } from "../../../components/PropertyDefault/PropertyDefault";

import { useBoolean } from '@fluentui/react-hooks';

import ImageModal from "../../../components/ImageCropper/ImageModal";
import { getClientData, toastError } from "../../../constants/Constants";
import { createSeasonSettingsClientMaster, fetchClientMaster, getCustomerVideoForSettings, getSelectedSeason, postCustomerVideoForSettings, setEventSuccess } from "../../../store/Actions/SeasonSettingsAction";

const fixedWidthForClass = 20
const fixedWidthForYear = 25

const CreateStudentClipSettings = ({ loading, customVideoDataForSettings, postCustomerVideoForSettings, getCustomerVideoForSettings, selectedSeason, getSelectedSeason, eventSuccess, setEventSuccess, clientMasterData, createSeasonSettingsClientMaster, fetchClientMaster }) => {
    const navigate = useNavigate();
    const clientData = getClientData();

    const [videoSrc, setVideoSrc] = useState("");
    const [studentFileUrl, setFile] = useState();
    const [openUploadModal, setOpenUploadModal] = useState(false);
    const [openLogoUploadModal, setOpenLogoUploadModal] = useState(false);
    const [isLogoUploaded, setIsLogoUploaded] = useState(false);
    const [isVideoUploaded, setIsVideoUploaded] = useState(false);

    const [sheetBackcolor, setsheetBackcolor] = React.useState('#f5f5f5');
    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
    const [isCalloutVisible_Images, { toggle: toggleIsCalloutVisible_Images }] = useBoolean(false);
    const [isCalloutVisible_Heading, { toggle: toggleIsCalloutVisible_Heading }] = useBoolean(false);
    const [isCalloutVisible_Subheading, { toggle: toggleIsCalloutVisible_Subheading }] = useBoolean(false);
    const [isCalloutVisible_Year, { toggle: toggleIsCalloutVisible_Year }] = useBoolean(false);
    const [textchangeClass, setTextChangeClass] = React.useState('Student Name');
    const [textchangeHeading, setTextChangeHeading] = React.useState('CONGRATULATIONS');
    const [textchangeYear, setTextChangeYear] = React.useState('Optional Message');
    const [isbuttontoggle, setToggle] = useBoolean(false);
    const [imageBordercolor, setimageBordercolor] = React.useState('#6e6e6e');
    const [headingColor, setheadingColor] = React.useState('#6e6e6e');
    const [subheadingColor, setsubheadingColor] = React.useState('#6e6e6e');
    const [yearColor, setyearColor] = React.useState('#6e6e6e');
    const [isModalOpen_Images, { setTrue: showModal_Images, setFalse: hideModal_Images }] = useBoolean(false);
    const [isModalOpen_Images_logo, { setTrue: showModal_Images_logo, setFalse: hideModal_Images_logo }] = useBoolean(false);
    const [preview_Images, setPreview_Images] = useState(null);
    const [preview_Images_temp, setPreview_Images_temp] = useState([]);
    const [preview_Images_logo, setPreview_Images_logo] = useState('');
    const [latestFile_temp, setLatestFile_temp] = useState(null);
    const [latestFile, setLatestFile] = useState(null);

    const [selectedFiles, setSelectedFiles] = useState([]);
    const [selectedFiles_temp, setSelectedFiles_temp] = useState([]);
    const [isFileSelectorDisabled, setIsFileSelectorDisabled] = useState(false);
    const [uploadedImageFiles, setUploadedImageFiles] = useState([]);
    const [seasonId, setSeasonId] = useState(0);
    const [seasonName, setSeasonName] = useState('');
    const [seasonStartPeriod, setSeasonStartPeriod] = useState('');
    const [seasonEndPeriod, setSeasonEndPeriod] = useState('');

    const [inputWidthForYear, setInputWidthForYear] = useState((textchangeYear.length + 1) * fixedWidthForYear);
    const [inputWidthForClass, setInputWidthForClass] = useState((textchangeClass.length + 1) * fixedWidthForClass);

    const [selectedImageForCropping, setSelectedImageForCropping] = useState(null);
    const [isCroppingForLogo, setIsCroppingForLogo] = useState(false);
    const [completedCrop, setCompletedCrop] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            if (seasonName) {
                await fetchClientMaster({ clientId: clientData.clientID, seasonName: seasonName });
                await getCustomerVideoForSettings({ ClientId: clientData.clientID, seasonName: seasonName });
            }
        };

        fetchData();
    }, [seasonName, selectedSeason]);

    useEffect(() => {
        if (eventSuccess && !loading) {
            console.log("Event created successfully!");
            setEventSuccess(false)
            navigate("/CreateExitClipSettings");
        }
    }, [eventSuccess, loading]);


    useEffect(() => {
        // if (customVideoDataForSettings?.result?.isDataAvailable) {
        setsheetBackcolor(customVideoDataForSettings?.result?.data?.customerVideoConfig?.frameBGColor || '#f5f5f5');
        setimageBordercolor(customVideoDataForSettings?.result?.data?.customerVideoConfig?.thinFrameColor || '#6e6e6e');
        if (customVideoDataForSettings?.result?.data?.customerVideoConfig?.textAsset) {
            setheadingColor(customVideoDataForSettings?.result?.data?.customerVideoConfig?.textAsset[0]?.textColor)
            setsubheadingColor(customVideoDataForSettings?.result?.data?.customerVideoConfig?.textAsset[1]?.textColor)
            setyearColor(customVideoDataForSettings?.result?.data?.customerVideoConfig?.textAsset[2]?.textColor)
            setTextChangeHeading(customVideoDataForSettings?.result?.data?.customerVideoConfig?.textAsset?.[0]?.textData || 'CONGRATULATIONS');
            setTextChangeClass(customVideoDataForSettings?.result?.data?.customerVideoConfig?.textAsset?.[1]?.textData || 'Student Name');
            setTextChangeYear(customVideoDataForSettings?.result?.data?.customerVideoConfig?.textAsset?.[2]?.textData || 'Optional Message');
            setInputWidthForYear(((customVideoDataForSettings?.result?.data?.customerVideoConfig?.textAsset?.[2]?.textData || 'Optional Message').length + 1) * fixedWidthForYear);
            setInputWidthForClass(((customVideoDataForSettings?.result?.data?.customerVideoConfig?.textAsset?.[1]?.textData || 'Student Name').length + 1) * fixedWidthForClass);

        } else {
            setheadingColor('#6e6e6e')
            setsubheadingColor('#6e6e6e')
            setyearColor('#6e6e6e')
            setTextChangeHeading('CONGRATULATIONS');
            setTextChangeClass('Student Name');
            setTextChangeYear('Optional Message');
        }

        setPreview_Images_logo({ url: customVideoDataForSettings?.result?.data?.customerVideoConfig?.logoUri, uploadedData: true } || {});
        setPreview_Images({ name: customVideoDataForSettings?.result?.data?.customerClipAssetFileData, result: customVideoDataForSettings?.result?.data?.customerClipAssetFilePathSaSUri, uploadedData: true } || {});
        // }
        setLatestFile(customVideoDataForSettings?.result?.data?.customerClipAssetFilePathSaSUri || null)
        //   setUploadedImageFiles(customVideoDataForSettings?.result?.data?.customerVideoConfig?.thinFramePersonImageAssets)

    }, [customVideoDataForSettings])

    // Changes selected colors based on user inputs
    const onChangeBackgroundColor = React.useCallback((ev, colorObj) =>
        setsheetBackcolor(colorObj.str), []);

    // Changes selected colors based on user inputs
    const onChangeBackgroundColor_Images = React.useCallback((ev, colorObj) =>
        setimageBordercolor(colorObj.str), []);

    // Changes selected colors based on user inputs
    const onChangeBackgroundColor_Heading = React.useCallback((ev, colorObj) =>
        setheadingColor(colorObj.str), []);

    // Changes selected colors based on user inputs
    const onChangeBackgroundColor_Subheading = React.useCallback((ev, colorObj) =>
        setsubheadingColor(colorObj.str), []);

    // Changes selected colors based on user inputs
    const onChangeBackgroundColor_Year = React.useCallback((ev, colorObj) =>
        setyearColor(colorObj.str), []);

    // Addds the texts based on user inputs
    const onChangeTexts = (inputValue, text) => {
        if (text === 'Class') {
            setTextChangeClass(inputValue.target.value);
            setInputWidthForClass((inputValue.target.value.length + 1) * fixedWidthForClass);
        }
        if (text === 'Year') {
            setTextChangeYear(inputValue.target.value);
            setInputWidthForYear((inputValue.target.value.length + 1) * fixedWidthForYear);
        }
    }

    // Adds the user selected video file to the UI
    const handleOnChange = (e) => {
        if (e.target.files.length > 0) {
            const file = e.target.files[0];
            const reader = new FileReader();


            const allowedTypes = ['video/mp4', 'video/quicktime'];
            if (!allowedTypes.includes(file.type)) {
                toastError('Invalid file type. Please select a .mp4 file.');
            } else {

                reader.onload = function () {
                    const newArray = { name: file.name, result: reader.result, uploadedData: false };
                    setPreview_Images(newArray);
                    setPreview_Images_temp(newArray);
                    setLatestFile_temp(reader.result);
                    setSelectedFiles(prevState => ({
                        ...prevState,
                        video: file
                    }));
                    if (newArray.length === 3) {
                        setIsFileSelectorDisabled(true);
                    }
                    setIsVideoUploaded(true);
                    setOpenUploadModal(true)
                }
                reader.readAsDataURL(file);
            }
        }
    }

    // Adds the user selected logo file to the UI
    const handleOnChange_logo = (e) => {
        if (e.target.files.length > 0) {
            const file = e.target.files[0];
            const reader = new FileReader();

            const allowedTypes = ['image/png'];
            if (!allowedTypes.includes(file.type)) {
                toastError('Invalid file type. Please select a .png file.');
            } else {

                reader.onload = function () {
                    const image = new Image();
                    image.onload = () => {
                        const width = image.width;
                        const height = image.height;
                        if (width >= 500 && height >= 100) {
                            setSelectedImageForCropping({ name: file.name, url: reader.result });
                            setIsLogoUploaded(true);
                            setOpenLogoUploadModal(true);
                        } else {
                            toastError('File is not in given dimensions');
                        }
                    };
                    image.src = reader.result;
                };
                reader.readAsDataURL(file);
            }
        }
    };

    // Opens modal to upload logo
    const render_preview_logo = () => {
        setIsCroppingForLogo(true);
        hideModal_Images_logo();
        setIsLogoUploaded(false);
        setOpenLogoUploadModal(false)
    }

    // Closes the upload logo modal
    const handleCloseModal = () => {
        setOpenLogoUploadModal(false);
        setIsLogoUploaded(false); // Reset logoUploaded state when modal is closed
    };

    // Opens modal to upload video
    const render_preview_video = () => {
        setLatestFile(preview_Images_temp.result);
        setIsVideoUploaded(false);
        setOpenUploadModal(false)
    }

    // Closes the upload video modal
    const handleVideoCloseModal = () => {
        setOpenUploadModal(false);
        setIsVideoUploaded(false); // Reset logoUploaded state when modal is closed
    };

    // Opens file explorer to select the desired file
    const openUpload = () => {
        if (document.getElementById('image_upload') != undefined) {
            document.getElementById('image_upload').click();
        }
    }

    const openUpload_outer = () => {
        if (document.getElementById('image_upload_outer') != undefined) {
            document.getElementById('image_upload_outer').click();
        }
    }

    // Opens file explorer to select the desired file
    const openUpload_logo = () => {
        if (document.getElementById('image_upload_logo') != undefined) {
            document.getElementById('image_upload_logo').click();
        }
    }

    const handleDrop = (e) => {
        const allowedTypes = new Set([e.target.accept]);
        if (!allowedTypes.has(e.dataTransfer.files[0].type)) {
            // stop event prepagation
            e.preventDefault();
        }
    };

    // Set the selected season name
    const handleSeasonName = (seasonName, seasonId) => {
        setSeasonName(seasonName);
        getSelectedSeason(seasonId);
        setSeasonId(seasonId);
    };

    // Set the selected season dates
    const handleSeasonDates = (startDate, endDate) => {
        setSeasonStartPeriod(startDate);
        setSeasonEndPeriod(endDate);
    }

    // Submits the student info data and call function to send data to the API
    const handleSave = async () => {
        if (!seasonName) {
            toastError('Please select Season from dropdown.');
            return;
        }
        if (textchangeHeading === '' || textchangeClass === '' || textchangeYear === '') {
            toastError('One of the text field is empty.');
            return;
        }

        let logoDetails;

        if (preview_Images_logo?.uploadedData) {
            logoDetails = {
                logoName: customVideoDataForSettings?.result?.data?.customerVideoConfig?.logoName,
                logoPath: customVideoDataForSettings?.result?.data?.customerVideoConfig?.logoPath,
            };
        } else {
            logoDetails = {
                logoName: preview_Images_logo.name,
            };
        }

        let videoDetails;

        if (preview_Images?.uploadedData) {
            videoDetails = {
                CustomerClipAssetFileData: customVideoDataForSettings?.result?.data?.customerClipAssetFileData,
                customerClipAssetFilePath: customVideoDataForSettings?.result?.data?.customerClipAssetFilePath,
                customerClipAssetFilePathSaSUri: customVideoDataForSettings?.result?.data?.customerClipAssetFilePathSaSUri,
            };
        } else {
            videoDetails = {
                CustomerClipAssetFileData: preview_Images.name
            };
        }
        if ((!logoDetails.logoName || !videoDetails.CustomerClipAssetFileData)) {
            toastError('Please select file for video and logo');
            return;
        }

        // setLoading(true);
        if (!clientMasterData?.result?.data?.template) {
            await createSeasonSettingsClientMaster({
                itemType: "SeasonSettingsMaster",
                itemTypeDesc: "SeasonSettingsMaster",
                clientId: clientData.clientID,
                clientName: clientData.clientName,
                seasonId: seasonId,
                seasonName: seasonName,
                seasonStartDate: seasonStartPeriod,
                seasonEndDate: seasonEndPeriod,
                template: 'Standard',
                templateDesc: 'Standard',
            });
        }
        await postCustomerVideoForSettings(selectedFiles, {
            clientId: clientData.clientID,
            clientName: clientData.clientName,
            seasonName: seasonName,
            seasonStartPeriod: seasonStartPeriod,
            seasonEndPeriod: seasonEndPeriod,
            CustomerVideoConfig: {
                frameBGColor: sheetBackcolor,
                thinFrameColor: imageBordercolor,
                textAsset: [
                    { textId: "1", textData: textchangeHeading, textFontStyle: "Bold", textColor: headingColor },
                    { textId: "2", textData: textchangeClass, textFontStyle: "BoldItalic", textColor: subheadingColor },
                    { textId: "3", textData: textchangeYear, textFontStyle: "BoldLayers", textColor: yearColor },
                ],
                // thinFramePersonImageAssets: thinFramePersonImageAssets,
                ...logoDetails,
            },
            ...videoDetails,
            updatedOn: new Date(),
            updatedBy: clientData.userEmail
        });
        // setLoading(false)
    };

    // Opens modal to upload video
    const handleFrameOnClick = () => {
        setOpenUploadModal(true);
    }

    // Opens modal to upload logo
    const handleLogoFrameOnClick = () => {
        setOpenLogoUploadModal(true);
    }
    const handleFileChange = (file) => {
        try {
            // Transform file into blob URL
            setVideoSrc(URL.createObjectURL(file));
        } catch (error) {
            console.error(error);
        }
    };

    // Closes the cropper modal
    const onCloseCropper = () => {
        setIsCroppingForLogo(false);
    }

    return (
        <div className="ind-studentclip-season">
            <div className="div-3">
                <SeasonSettings />
                <div className="frame-12">
                    <div className="frame-13">
                        <div className="section-title">
                            <div className="frame-14">
                                <div className="page-title">
                                    <div className="text-wrapper-4">Season Settings</div>
                                </div>
                                <div className="breadcrum">
                                    <img
                                        className="material-symbols"
                                        alt="Material symbols"
                                        src={homeImage}
                                    />
                                    <img
                                        className="keyboard-arrow-right"
                                        alt="Keyboard arrow right"
                                        src={rightarrow} />
                                    <div className="text-wrapper-5">Settings</div>
                                    <img
                                        className="keyboard-arrow-right"
                                        alt="Keyboard arrow right"
                                        src={rightarrow} />
                                    <div className="text-wrapper-6">Create Student Clip Settings</div>
                                </div>
                            </div>
                        </div>
                        <div className="label">
                            <div className="event-ID">Select Season Name</div>
                        </div>
                        <StyledEngineProvider injectFirst>
                            <DropDownSeasonSettings onSeasonsName={handleSeasonName} selectedSeason={selectedSeason} onSeasonDates={handleSeasonDates} />
                        </StyledEngineProvider>
                        <StyledEngineProvider injectFirst>
                            <Tabs />
                        </StyledEngineProvider>
                        <div className="frame-15">


                            <div className="frame-create-student-clip">
                                <div className="frame-18">
                                    <div className="frame-19">
                                        <div className="frame-studenttext">
                                            <div className="text-wrapper-7">Create Student Clip</div>
                                        </div>
                                        <div className="text-student">
                                            <img
                                                src={infoicon}
                                                alt="Information Icon"
                                                className="icon-image"
                                            />
                                            <span className="text-content">
                                                This configuration only applies to Custom GradReels.
                                            </span>
                                        </div>
                                        <div className="frame-23">
                                            <div className="component-wrapper">
                                                <TemplateEmptyVuesaxLinearProperty332 className="template-empty" />
                                                {/* <Component className="component-1" /> */}
                                            </div>
                                            <p className="video-tutorial-how">
                                                <span className="span">Video Tutorial: </span>
                                                <span className="text-wrapper-8">How to create an student video configuration?</span>
                                            </p>
                                        </div>
                                        <FiRrCommentInfo className="icon-instance-node" color="#7E7E7E" />
                                    </div>
                                    <div className="group-2" style={{ backgroundColor: sheetBackcolor != '' ? sheetBackcolor : '#f5f5f5' }}>


                                        <div className="group-3" style={{ borderColor: imageBordercolor != '' ? imageBordercolor : '#6e6e6e' }}>
                                            {latestFile != null ?
                                                <video style={{ width: '100%', height: '100%' }} controls>
                                                    <source src={latestFile} type="video/mp4" />
                                                    Your browser does not support the video tag.
                                                </video>
                                                :
                                                <div className="frame-24" onClick={handleFrameOnClick}>
                                                    <FiRrPicture1 className="icon-instance-node-upload" />
                                                    <div className="text-wrapper-9">Student recorded video will be played in this frame</div>
                                                </div>
                                            }
                                            <div className="fi-rr-edit-12-wrapper"
                                                id="callout-button-1" onClick={toggleIsCalloutVisible_Images}>
                                                <FiRrEdit12 className="icon-instance-node-2" />
                                            </div>


                                        </div>



                                        <div className="frame-25">
                                            <PropertyDefault
                                                className="design-component-instance-node-2"
                                                icon={<FiRrEdit8 className="icon-instance-node-2" />}
                                                onclick={toggleIsCalloutVisible_Heading}
                                                id="callout-button-2"
                                                headvalue={headingColor}
                                                onChange={(newValue) => {
                                                    setTextChangeHeading(newValue);

                                                }}
                                                value={textchangeHeading}
                                            />
                                            <div className="frame-27">
                                                <input className="text-wrapper-10" style={{ fontFamily: 'Lato', color: subheadingColor != '' ? subheadingColor : '#6e6e6e', width: inputWidthForClass + 'px', maxWidth: '100%' }} maxLength={18} onChange={(e) => onChangeTexts(e, 'Class')} value={textchangeClass} />

                                                <div className="fi-rr-edit-14-wrapper" id="callout-button-3" onClick={toggleIsCalloutVisible_Subheading}>
                                                    <FiRrEdit14 className="icon-instance-node-2" />
                                                </div>
                                            </div>
                                            <div className="frame-27">
                                                <input className="text-wrapper-11" style={{ fontFamily: 'SeasideResortNF', color: yearColor != '' ? yearColor : '#6e6e6e', width: inputWidthForYear + 'px', maxWidth: '100%' }} maxLength={18} onChange={(e) => onChangeTexts(e, 'Year')} value={textchangeYear} />

                                                <div className="fi-rr-edit-15-wrapper" id="callout-button-4" onClick={toggleIsCalloutVisible_Year}>
                                                    <FiRrEdit14 className="icon-instance-node-2" />
                                                </div>
                                            </div>
                                            <div className="overlap-2">
                                                <div className="rectangle-2" />

                                                <div className="frame-28" style={{ width: preview_Images_logo?.url != null ? '98%' : '', left: preview_Images_logo?.url != null ? '3px' : '75px' }} onClick={handleLogoFrameOnClick}>
                                                    {preview_Images_logo?.url != null ?
                                                        <img style={{ width: '100%', height: '56px' }} src={preview_Images_logo?.url} />
                                                        :
                                                        <>
                                                            <div className="group-4">
                                                                <div className="overlap-group-3">
                                                                    <div className="text-wrapper-12">Logo</div>
                                                                </div>
                                                            </div>
                                                            <div className="text-wrapper-13">Your logo goes here</div>
                                                        </>
                                                    }

                                                </div>
                                                <div className="fi-rr-cloud-upload-wrapper" onClick={handleLogoFrameOnClick}>
                                                    <FiRrCloudUpload13 className="icon-instance-node-2" color="#5C5C5C" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="fi-rr-edit-11-wrapper" id="callout-button" onClick={toggleIsCalloutVisible}>
                                            <FiRrEdit14 className="icon-instance-node-2" />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="frame-95">
                        <button className="primary-BTN">
                            <Link className="button-text" to="/CreateIntroClipSettings" >Back</Link>
                        </button>
                        <button className="primary-BTN-8">
                            <button className="button-text-7"><Link className="button-text-save" onClick={handleSave}>Save</Link></button>
                        </button>
                    </div>

                    <ImageModal
                        modalIsOpen={isCroppingForLogo}
                        ratio={5}
                        onClose={onCloseCropper}
                        image={selectedImageForCropping}
                        onCropCompleted={(area) => {
                            setCompletedCrop(area);
                        }}
                        onUpload={(file) => {
                            setSelectedFiles(prevState => ({
                                ...prevState,
                                logo: file
                            }));

                        }}
                        onFileCropping={(file) => {
                            if (completedCrop.width >= 500 && completedCrop.height >= 100) {
                                setIsCroppingForLogo(false);
                                setPreview_Images_logo({ name: selectedImageForCropping.name, url: file.url, uploadedData: false })
                            } else {
                                toastError('Cropped image minimum dimensions must be 500x100');
                            }
                        }}
                    />

                    {isCalloutVisible && (
                        <Callout
                            className={styles1.callout}
                            ariaLabelledBy={'callout-label'}
                            ariaDescribedBy={'callout-description'}
                            role="dialog"
                            gapSpace={0}
                            target={`#callout-button`}
                            onDismiss={toggleIsCalloutVisible}
                            setInitialFocus
                        >
                            <ColorPicker
                                color={sheetBackcolor}
                                onChange={onChangeBackgroundColor}
                                alphaType='none'
                                showPreview={false}
                                styles={colorPickerStyles}
                                strings={{
                                    hueAriaLabel: 'Hue',
                                }}
                            />
                        </Callout>
                    )}

                    {isCalloutVisible_Images && (
                        <Callout
                            className={styles1.callout}
                            ariaLabelledBy={'callout-label'}
                            ariaDescribedBy={'callout-description'}
                            role="dialog"
                            gapSpace={0}
                            target={`#callout-button-1`}
                            onDismiss={toggleIsCalloutVisible_Images}
                            setInitialFocus
                        >
                            <ColorPicker
                                color={imageBordercolor}
                                onChange={onChangeBackgroundColor_Images}
                                alphaType='none'
                                showPreview={false}
                                styles={colorPickerStyles}
                                strings={{
                                    hueAriaLabel: 'Hue',
                                }}
                            />
                        </Callout>
                    )}

                    {isCalloutVisible_Heading && (
                        <Callout
                            className={styles1.callout}
                            ariaLabelledBy={'callout-label'}
                            ariaDescribedBy={'callout-description'}
                            role="dialog"
                            gapSpace={0}
                            target={`#callout-button-2`}
                            onDismiss={toggleIsCalloutVisible_Heading}
                            setInitialFocus
                        >
                            <ColorPicker
                                color={headingColor}
                                onChange={onChangeBackgroundColor_Heading}
                                alphaType='none'
                                showPreview={false}
                                styles={colorPickerStyles}
                                strings={{
                                    hueAriaLabel: 'Hue',
                                }}
                            />
                        </Callout>
                    )}

                    {isCalloutVisible_Subheading && (
                        <Callout
                            className={styles1.callout}
                            ariaLabelledBy={'callout-label'}
                            ariaDescribedBy={'callout-description'}
                            role="dialog"
                            gapSpace={0}
                            target={`#callout-button-3`}
                            onDismiss={toggleIsCalloutVisible_Subheading}
                            setInitialFocus
                        >
                            <ColorPicker
                                color={subheadingColor}
                                onChange={onChangeBackgroundColor_Subheading}
                                alphaType='none'
                                showPreview={false}
                                styles={colorPickerStyles}
                                strings={{
                                    hueAriaLabel: 'Hue',
                                }}
                            />
                        </Callout>
                    )}

                    {isCalloutVisible_Year && (
                        <Callout
                            className={styles1.callout}
                            ariaLabelledBy={'callout-label'}
                            ariaDescribedBy={'callout-description'}
                            role="dialog"
                            gapSpace={0}
                            target={`#callout-button-4`}
                            onDismiss={toggleIsCalloutVisible_Year}
                            setInitialFocus
                        >
                            <ColorPicker
                                color={yearColor}
                                onChange={onChangeBackgroundColor_Year}
                                alphaType='none'
                                showPreview={false}
                                styles={colorPickerStyles}
                                strings={{
                                    hueAriaLabel: 'Hue',
                                }}
                            />
                        </Callout>
                    )}

                    <Dialog open={openUploadModal} onClose={handleVideoCloseModal}
                        className="dialogConfig1"

                    >

                        <DialogContent style={{
                            padding: '0px 35px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center'
                        }}>


                            <div className="uploadContainer">
                                <div className="div-2">
                                    <button className="CTA" onClick={render_preview_video}
                                        disabled={!isVideoUploaded} // Disable the button if logoUploaded is false
                                        style={{
                                            opacity: isVideoUploaded ? 1 : 0.5,
                                            cursor: isVideoUploaded ? 'pointer' : 'not-allowed'
                                        }}>
                                        <div className="label">UPLOAD FILES</div>
                                    </button>
                                    <div className="heading-upload">
                                        <div className="text-wrapper">Upload Video</div>
                                    </div>
                                    <div className="upload-file-area " onClick={openUpload}>
                                        <div className="frame-2">
                                            <img
                                                className="upload-icon"
                                                alt="Upload icon"
                                                src={uploadIcon} />
                                            <div className="frame-3">
                                                <div className="drag-drop-files">
                                                    <p className="drag-drop-files-or">
                                                        <span className="span">Drag & drop files or </span>
                                                        <span className="text-wrapper-2">Browse</span>
                                                    </p>
                                                    <input id="image_upload" onChange={(evt) => {
                                                        handleOnChange(evt)
                                                    }}
                                                        type="file" style={{ display: 'none' }} accept=".mp4" />
                                                </div>
                                                <div className={`frame frame-1027`}>
                                                    <div className="div" style={{ display: 'flex', gap: '2px' }}>
                                                        <Checkbox className="checkbox-instance" property1="fi-sr-info" />
                                                        <p className="i-authorise">Please upload .mp4 video.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </DialogContent>


                    </Dialog>


                    <Dialog open={openLogoUploadModal} onClose={handleCloseModal}
                        className="dialogConfig1"

                    >

                        <DialogContent style={{
                            padding: '0px 35px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center'
                        }}>


                            <div className="uploadContainer">
                                <div className="div-2">
                                    <button className="CTA" onClick={render_preview_logo}
                                        disabled={!isLogoUploaded} // Disable the button if logoUploaded is false
                                        style={{
                                            opacity: isLogoUploaded ? 1 : 0.5,
                                            cursor: isLogoUploaded ? 'pointer' : 'not-allowed'
                                        }}>
                                        <div className="label">UPLOAD LOGO</div>
                                    </button>
                                    <div className="heading-upload">
                                        <div className="text-wrapper">Upload Logo</div>
                                    </div>
                                    <div className="upload-file-area" onClick={openUpload_logo}>
                                        <div className="frame-2">
                                            <img
                                                className="upload-icon"
                                                alt="Upload icon"
                                                src={uploadIcon} />
                                            <div className="frame-3">
                                                <div className="drag-drop-files">
                                                    <p className="drag-drop-files-or">
                                                        <span className="span">Drag & drop files or </span>
                                                        <span className="text-wrapper-2">Browse</span>
                                                    </p>
                                                    <input id="image_upload_logo" onChange={(evt) => {

                                                        handleOnChange_logo(evt)
                                                    }}
                                                        type="file" style={{ display: 'none' }} accept="image/png" />
                                                </div>
                                                <div className={`frame frame-1027`}>
                                                    <div className="div" style={{ display: 'flex', gap: '2px' }}>
                                                        <Checkbox className="checkbox-instance" property1="fi-sr-info" />
                                                        <p className="i-authorise">Kindly upload logos in .png format ensuring they have a<br /> transparent background. Ensure there is minimal<br /> blank space surrounding the logo, and that its dimensions<br /> are at least 500x100 pixels</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </DialogContent>


                    </Dialog>


                    {loading && (
                        <div className="loader-container">
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px' }}>
                                <CircularProgress />
                            </Box>
                        </div>
                    )}
                </div>
            </div>
            
        </div>

    );
};
const colorPickerStyles = {
    panel: { padding: 12 },
    root: {
        maxWidth: 352,
        //minWidth: 352,
    },
    colorRectangle: { height: 268 },
};

const styles1 = mergeStyleSets({
    button: {
        width: 130,
    },
    callout: {
        width: 320,
        maxWidth: '90%',
        padding: '20px 24px',
    },
    title: {
        marginBottom: 12,
        fontWeight: FontWeights.semilight,
    },
    link: {
        display: 'block',
        marginTop: 20,
    },
});

// Images Popup
const cancelIcon = { iconName: 'Cancel' };

const theme = getTheme();
const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        borderRadius: '20px',
        width: '25%',

        padding: '26px'
    },
    header: [
        theme.fonts.xLargePlus,
        {
            flex: '1 1 auto',
            borderTop: `4px solid ${theme.palette.themePrimary}`,
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    heading: {
        color: theme.palette.neutralPrimary,
        fontWeight: FontWeights.semibold,
        fontSize: 'inherit',
        margin: '0',
    },
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
    },
});
const stackProps = {
    horizontal: true,
    tokens: { childrenGap: 40 },
    styles: { root: { marginBottom: 20 } },
};
const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};

const mapStateToProps = (state) => {
    return {
        loading: state.seasonSettings.loading,
        clientMasterData: state.seasonSettings.clientMasterData,
        selectedSeason: state.seasonSettings.selectedSeason,
        customVideoDataForSettings: state.seasonSettings.customVideoDataForSettings,
        eventSuccess: state.seasonSettings.eventSuccess,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchClientMaster: (eventData) => dispatch(fetchClientMaster(eventData)),
        getSelectedSeason: (eventData) => dispatch(getSelectedSeason(eventData)),
        getCustomerVideoForSettings: (eventData) => dispatch(getCustomerVideoForSettings(eventData)),
        createSeasonSettingsClientMaster: (clientData) =>
            dispatch(createSeasonSettingsClientMaster(clientData)),
        postCustomerVideoForSettings: (files, introData) => dispatch(postCustomerVideoForSettings(files, introData)),
        setEventSuccess: (flag) => dispatch(setEventSuccess(flag)),
    };
};

const ConnectedCreateStudentClipSettings = connect(mapStateToProps, mapDispatchToProps)(CreateStudentClipSettings);

export { ConnectedCreateStudentClipSettings as CreateStudentClipSettings };
