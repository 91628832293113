import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { connect } from 'react-redux';
import { getSeasons } from '../../store/Actions/SharedAction';
import { initializeValues } from '../../store/Actions/SeasonSettingsAction';
import { toastError } from '../../constants/Constants';

function SelectLabelsComponent({ getSeasons, seasonList, handleSeasonChange, onSeasonsName, onSeasonDates=null, selectedSeason = null, disabled=false, initializeValues }) {
  const [seasonId, setSeasonId] = React.useState(selectedSeason || '');

  React.useEffect(() => {
    const fetchData = async () => {
      await getSeasons()
        .then(() => {
          if (seasonList === null || seasonList.length === 0) {
            toastError("Seasons are not available.");
          }
        })
        .catch(error => {
          // Handle error if necessary
          console.error('Error loading event:', error);
        });
    };

    fetchData();
  }, []);

  React.useEffect(() => {
    // Get today's date
    const today = new Date();
    // Find the current season based on today's date
    const currentSeason = seasonList?.find(season => {
      const startDate = new Date(season.startDate);
      const endDate = new Date(season.endDate);
      return today >= startDate && today <= endDate;
    });
    // Set the seasonId of the current season as the default value
    if(!selectedSeason) {
    if (currentSeason) {
      setSeasonId(currentSeason.seasonId);
      onSeasonsName(currentSeason.seasonName, currentSeason.seasonId);
      if (onSeasonDates) {
        onSeasonDates(currentSeason.startDate, currentSeason.endDate);
      }
    }
  } else {
    const sesaonDetails = seasonList.find(season => season.seasonId === selectedSeason);
    onSeasonsName(sesaonDetails.seasonName, selectedSeason);
    if (onSeasonDates) {
      onSeasonDates(sesaonDetails.startDate, sesaonDetails.endDate);
    }
  }
  }, [seasonList]);

  const handleChange = (event) => {
    initializeValues();
    const selectedSeason = seasonList.find(season => season.seasonId === event.target.value);
    setSeasonId(event.target.value);
    onSeasonsName(selectedSeason.seasonName, event.target.value);
    if (onSeasonDates) {
      onSeasonDates(selectedSeason.startDate, selectedSeason.endDate);
    }
    if(handleSeasonChange)
        handleSeasonChange();
  };

  return (
    <div>
      <FormControl sx={{ m: 1, minWidth: 180, float: 'right', margin: '0px' }}>
        <InputLabel id="demo-simple-select-helper-label" sx={{ lineHeight: 'inherit' }}>Season</InputLabel>
        <Select
          disabled={disabled}
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={seasonId}
          label="Season"
          onChange={handleChange}
          sx={{ height: '44px', width: '300px' }}
        >
         
          {seasonList?.map(season => (
            <MenuItem key={season.seasonId} value={season.seasonId}>
              {season.seasonName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    seasonList: state.shared.seasonList?.result
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSeasons: () => dispatch(getSeasons()),
    initializeValues: () => dispatch(initializeValues()),
  };
};

const SelectLabels = connect(mapStateToProps, mapDispatchToProps)(SelectLabelsComponent);

export default SelectLabels;
