import { configureStore, combineReducers } from "@reduxjs/toolkit";
import logger from "redux-logger";
import * as thunk from "redux-thunk";
import postEventReducer from "./Reducers/PostEventReducer";
import preEventReducer from "./Reducers/PreEventReducer";
import SeasonSettingsReducer from "./Reducers/SeasonSettingsReducer";
import SharedReducer from "./Reducers/SharedReducer";

// Combines all the reducer as one and stores the value into a store
const rootreducer = combineReducers({ 
    preEvent: preEventReducer,
    postEvent: postEventReducer,
    seasonSettings: SeasonSettingsReducer,
    shared: SharedReducer,
 });
const Store = configureStore({ reducer: rootreducer, middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger) })

export default Store;