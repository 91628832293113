import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { Callout, ColorPicker, FontWeights, Modal, getTheme, mergeStyleSets } from "@fluentui/react";
import { Box, CircularProgress } from "@mui/material";
import { StyledEngineProvider } from "@mui/material/styles";
import "./style.css";

import { PreEventDetails } from "../PreEventDetails";
import Stepper from "../Stepper";

import { FiRrCloudUpload13 } from "../../../icons/FiRrCloudUpload13/FiRrCloudUpload13";
import { FiRrCommentInfo } from "../../../icons/FiRrCommentInfo/FiRrCommentInfo";
import { FiRrEdit12 } from "../../../icons/FiRrEdit12/FiRrEdit12";
import { FiRrEdit14 } from "../../../icons/FiRrEdit14/FiRrEdit14";
import { FiRrEdit8 } from "../../../icons/FiRrEdit8/FiRrEdit8";
import { FiRrPicture1 } from "../../../icons/FiRrPicture1/FiRrPicture1";
import { TemplateEmptyVuesaxLinearProperty332 } from "../../../icons/TemplateEmptyVuesaxLinearProperty332/TemplateEmptyVuesaxLinearProperty332";

import rightarrow from '../../../icons/ImageIcons/keyboard-arrow-right.svg';
import homeImage from '../../../icons/ImageIcons/material-symbols_home-rounded.svg';
import uploadIcon from '../../../icons/ImageIcons/upload-icon.png';

import { PropertyDefault } from "../../../components/PropertyDefault";

import { useBoolean } from "@fluentui/react-hooks";

import { Checkbox } from "../../../components/Checkbox";
import ImageModal from "../../../components/ImageCropper/ImageModal";
import { getClientData, toastError } from "../../../constants/Constants";
import { FetchCustomerClipConfig, completeStep, postCustomerClipConfig, setEventSuccess } from "../../../store/Actions/PreEventAction";

const fixedWidthForClass =20
const fixedWidthForYear = 25

const StudentClip = ({loading, FetchCustomerClipConfig, postCustomerClipConfig, eventMasterData, eventState, customerClipDetails, eventSuccess, setEventSuccess}) => {
  const navigate = useNavigate();
  const clientData = getClientData();
  const [sheetBackcolor, setsheetBackcolor] = React.useState("#f5f5f5");
  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] =
    useBoolean(false);
  const [isCalloutVisible_Images, { toggle: toggleIsCalloutVisible_Images }] =
    useBoolean(false);
  const [isCalloutVisible_Heading, { toggle: toggleIsCalloutVisible_Heading }] =
    useBoolean(false);
  const [
    isCalloutVisible_Subheading,
    { toggle: toggleIsCalloutVisible_Subheading },
  ] = useBoolean(false);
  const [isCalloutVisible_Year, { toggle: toggleIsCalloutVisible_Year }] =
    useBoolean(false);
  const [textchangeClass, setTextChangeClass] = React.useState("Student Name");
  const [textchangeHeading, setTextChangeHeading] =
    React.useState("CONGRATULATIONS");
  const [textchangeYear, setTextChangeYear] = React.useState("Optional Message");
  const [isbuttontoggle, setToggle] = useBoolean(false);
  const [imageBordercolor, setimageBordercolor] = React.useState('#6e6e6e');
  const [headingColor, setheadingColor] = React.useState('6e6e6e');
  const [subheadingColor, setsubheadingColor] = React.useState('6e6e6e');
  const [yearColor, setyearColor] = React.useState('6e6e6e');
  const [
    isModalOpen_Images,
    { setTrue: showModal_Images, setFalse: hideModal_Images },
  ] = useBoolean(false);
  const [
    isModalOpen_Images_logo,
    { setTrue: showModal_Images_logo, setFalse: hideModal_Images_logo },
  ] = useBoolean(false);
  const [preview_Images, setPreview_Images] = useState(null);
  const [preview_Images_temp, setPreview_Images_temp] = useState([]);
  const [preview_Images_logo, setPreview_Images_logo] = useState(null);
  const [isLogoUploaded, setIsLogoUploaded] = useState(false);
  const [isVideoUploaded, setIsVideoUploaded] = useState(false);
  const [latestFile, setLatestFile] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  // const [loading, setLoading] = useState(false);
  const dispatch = useDispatch()

  const [inputWidthForYear, setInputWidthForYear] = useState((textchangeYear.length + 1 )*fixedWidthForYear);
  const [inputWidthForClass, setInputWidthForClass] = useState((textchangeClass.length + 1 )*fixedWidthForClass);

  const [selectedImageForCropping, setSelectedImageForCropping] = useState(null);
  const [isCroppingForLogo, setIsCroppingForLogo] = useState(false);
  const [completedCrop, setCompletedCrop] = useState(null);

  useEffect(() => {
    if (!eventMasterData) {
      navigate('/PreEventDetailsDashboard')
    }
  }, [eventMasterData]);

  useEffect(() => {
    if(!eventMasterData?.result?.isDataAvailable){
      navigate('/SelectTemplate')
    }
  }, [eventMasterData])

  useEffect(() => {
    if (eventSuccess) {
      console.log("Event created successfully!");
      setEventSuccess(false)
      navigate("/CreateExitClip");
      dispatch(completeStep(4, true));
    }
  }, [eventSuccess]);

  useEffect(() => {
    FetchCustomerClipConfig({ clientId: eventMasterData?.result?.eventData?.clientId, eventId: eventMasterData?.result?.eventData?.eventId })
  }, []);

  useEffect(() => {
        setsheetBackcolor(customerClipDetails?.result?.customerSectionData?.customerVideoConfig?.frameBGColor || '#f5f5f5');
        setimageBordercolor(customerClipDetails?.result?.customerSectionData?.customerVideoConfig?.thinFrameColor || '#6e6e6e');
        if (customerClipDetails?.result?.customerSectionData?.customerVideoConfig?.textAsset) {
            setheadingColor(customerClipDetails?.result?.customerSectionData?.customerVideoConfig?.textAsset[0]?.textColor)
            setsubheadingColor(customerClipDetails?.result?.customerSectionData?.customerVideoConfig?.textAsset[1]?.textColor)
            setyearColor(customerClipDetails?.result?.customerSectionData?.customerVideoConfig?.textAsset[2]?.textColor)
            setTextChangeHeading(customerClipDetails?.result?.customerSectionData?.customerVideoConfig?.textAsset?.[0]?.textData || 'CONGRATULATIONS');
            setTextChangeClass(customerClipDetails?.result?.customerSectionData?.customerVideoConfig?.textAsset?.[1]?.textData || 'CLASS OF');
            setTextChangeYear(customerClipDetails?.result?.customerSectionData?.customerVideoConfig?.textAsset?.[2]?.textData || '2024');
            setInputWidthForYear(((customerClipDetails?.result?.customerSectionData?.customerVideoConfig?.textAsset?.[2]?.textData || '2024').length + 1) * fixedWidthForYear);
            setInputWidthForClass(((customerClipDetails?.result?.customerSectionData?.customerVideoConfig?.textAsset?.[1]?.textData || 'CLASS OF').length + 1) * fixedWidthForClass);
      
        } else {
            setheadingColor('6e6e6e')
            setsubheadingColor('6e6e6e')
            setyearColor('6e6e6e')
            setTextChangeHeading('CONGRATULATIONS');
            setTextChangeClass('Student Name');
            setTextChangeYear('Optional Message');
        }

        setPreview_Images_logo({ url: customerClipDetails?.result?.customerSectionData?.customerVideoConfig?.logoUri, uploadedData: true } || {});
        setPreview_Images({ name: customerClipDetails?.result?.customerSectionData?.videoAssetFileData, result: customerClipDetails?.customerSectionData?.videoAssetFileUri, uploadedData: true } || {});
        setLatestFile(customerClipDetails?.result?.customerSectionData?.videoAssetFileUri || null)

}, [customerClipDetails])

  // Changes selected colors based on user inputs
  const onChangeBackgroundColor = React.useCallback(
    (ev, colorObj) => setsheetBackcolor(colorObj.str),
    []
  );

  // Changes selected colors based on user inputs
  const onChangeBackgroundColor_Images = React.useCallback(
    (ev, colorObj) => setimageBordercolor(colorObj.str),
    []
  );

  // Changes selected colors based on user inputs
  const onChangeBackgroundColor_Heading = React.useCallback(
    (ev, colorObj) => setheadingColor(colorObj.str),
    []
  );

  // Changes selected colors based on user inputs
  const onChangeBackgroundColor_Subheading = React.useCallback(
    (ev, colorObj) => setsubheadingColor(colorObj.str),
    []
  );

  // Changes selected colors based on user inputs
  const onChangeBackgroundColor_Year = React.useCallback(
    (ev, colorObj) => setyearColor(colorObj.str),
    []
  );
 
  // Addds the texts based on user inputs
  const onChangeTexts = (e, text) => {
    if (text == "Class") {
      setTextChangeClass(e.target.value);
      setInputWidthForClass((e.target.value.length + 1) * fixedWidthForClass);
    }
    if (text == "Optional") {
      setTextChangeYear(e.target.value);
      setInputWidthForYear((e.target.value.length + 1) * fixedWidthForYear);
    }
  };

  // Adds the user selected video file to the UI
  const handleOnChange = (e) => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      const reader = new FileReader();

      const allowedTypes = ['video/mp4', 'video/quicktime'];
      if (!allowedTypes.includes(file.type)) {
        toastError('Invalid file type. Please select a .mp4 file.');
      } else {
        reader.onload = function () {
          const newArray = { name: file.name, result: reader.result, uploadedData: false };
          setPreview_Images(newArray);
          setPreview_Images_temp(newArray);
          setSelectedFiles(prevState => ({
            ...prevState,
            video: file
          }));
          setIsVideoUploaded(true);
          showModal_Images();
        };
        reader.readAsDataURL(file);
      }
    }
  };

  // Adds the user selected logo file to the UI
  const handleOnChange_logo = (e) => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      const reader = new FileReader();

      const allowedTypes = ['image/png'];
      if (!allowedTypes.includes(file.type)) {
        toastError('Invalid file type. Please select a .png file.');
      } else {

        reader.onload = function () {
          const image = new Image();
          image.onload = () => {
            const width = image.width;
            const height = image.height;
            if (width >= 500 && height >= 100) {
              const logo_result = { name: file.name, result: reader.result };
              setSelectedImageForCropping({name: file.name, url: reader.result});
              setIsLogoUploaded(true);
              showModal_Images_logo();
            } else {
              toastError('File is not in given dimensions');
            }
          };
          image.src = reader.result;
        };
        reader.readAsDataURL(file);
      }
    }
  };
  
  // Opens modal to upload video
  const render_preview_video = () => {
    setLatestFile(preview_Images_temp.result);
    setIsVideoUploaded(false);
    hideModal_Images();
  }

  // Closes the upload video modal
  const handleVideoCloseModal = () => {
    hideModal_Images();
    setIsVideoUploaded(false); // Reset logoUploaded state when modal is closed
  };

  // Opens modal to upload logo
  const preview_logo_click=()=>{
    setIsCroppingForLogo(true);
    setIsLogoUploaded(false);
    hideModal_Images_logo();
  }

  // Closes the upload logo modal
  const handleCloseModal = () => {
    hideModal_Images_logo();
    setIsLogoUploaded(false); // Reset logoUploaded state when modal is closed
  };

  // Opens file explorer to select the desired file
  const openUpload = () => {
    if (document.getElementById("image_upload") != undefined) {
      document.getElementById("image_upload").click();
    }
  };

  // Opens file explorer to select the desired file
  const openUpload_outer = () => {
    if (document.getElementById("image_upload_outer") != undefined) {
      document.getElementById("image_upload_outer").click();
    }
  };

  // Opens file explorer to select the desired file
  const openUpload_logo = () => {
    if (document.getElementById("image_upload_logo") != undefined) {
      document.getElementById("image_upload_logo").click();
    }
  };

  // const handleDrop = (e) => {
  //   const allowedTypes = new Set([e.target.accept]);
  //   if (!allowedTypes.has(e.dataTransfer.files[0].type)) {
  //     // stop event prepagation
  //     e.preventDefault();
  //   }
  // };

  // Submits the selected data and call function to send data to the API
  const handleSave = async () => {
    if(eventState){
      navigate("/CreateExitClip");
    } else {
    if ((!preview_Images_logo?.url && !selectedImageForCropping)) {
      toastError('Please select file for video and logo');
      return;
    }

    if(textchangeHeading === '' || textchangeClass === '' || textchangeYear === ''){
      toastError('One of the text field is empty.');
      return;
  }
    // setLoading(true)
    let logoDetails;

    if (preview_Images_logo?.uploadedData) {
      logoDetails = {
        logoName: customerClipDetails?.result?.customerSectionData?.customerVideoConfig?.logoName,
        logoPath: customerClipDetails?.result?.customerSectionData?.customerVideoConfig?.logoPath,
      };
    } else {
      logoDetails = {
        logoName: preview_Images_logo.name,
      };
    }

    let videoDetails;

    if (preview_Images?.uploadedData) {
      videoDetails = {
        videoAssetFileData: customerClipDetails?.result?.customerSectionData?.videoAssetFileData,
        videoAssetFileUri: customerClipDetails?.result?.customerSectionData?.videoAssetFileUri,
        videoAssetFilePath: customerClipDetails?.result?.customerSectionData?.videoAssetFilePath,
      };
    } else {
      videoDetails = {
        videoAssetFileData: preview_Images.name
      };
    }

    await postCustomerClipConfig(selectedFiles, {
      eventId: eventMasterData?.result?.eventData?.eventId,
      eventName: eventMasterData?.result?.eventData?.eventName,
      clientId: eventMasterData?.result?.eventData?.clientId,
      clientName: eventMasterData?.result?.eventData?.clientName,
      customerVideoConfig: {
        frameBGColor: sheetBackcolor,
        thinFrameColor: imageBordercolor,
        textAsset: [
          { textId: "1", textData: textchangeHeading, textFontStyle: "Bold", textColor: headingColor },
          { textId: "2", textData: textchangeClass, textFontStyle: "BoldItalic", textColor: subheadingColor },
          { textId: "3", textData: textchangeYear, textFontStyle: "BoldLayers", textColor: yearColor },
        ],
        ...logoDetails,
      },
      ...videoDetails,
      updatedOn: new Date(),
      updatedBy: clientData.userEmail,
    });
    // setLoading(false)
   
  }
  };

  // Closes the cropper modal
  const onCloseCropper = () => {
    setIsCroppingForLogo(false);
  }

  return (
    <div className="student-clip">
      <div className="div-3">
        <PreEventDetails></PreEventDetails>
        <div className="frame-12">
          <div className="frame-13">
            <div className="section-title">
              <div className="frame-14">
                <div className="page-title">
                  <div className="text-wrapper-4">Student Video Config</div>
                </div>
                <div className="breadcrum">
                  <img
                    className="material-symbols"
                    alt="Material symbols"
                    src={homeImage}
                  />
                  <img
                    className="keyboard-arrow-right"
                    alt="Keyboard arrow right"
                    src={rightarrow}
                  />
                  <div className="text-wrapper-5">Events</div>
                  <img
                    className="keyboard-arrow-right"
                    alt="Keyboard arrow right"
                    src={rightarrow}
                  />
                  <div className="text-wrapper-6">Student Clip</div>
                </div>
              </div>
            </div>
            <div className="frame-15">
              <div className="frame-16">
                <StyledEngineProvider injectFirst>
                  <Stepper gradReelType={eventMasterData?.result?.eventData?.template}/>
                </StyledEngineProvider>
                <div className="frame-16-1">
                  <div className="frame-17">
                    <div className="frame-18">
                      <div className="frame-19">
                        <div className="frame-20">
                          <div className="frame-21">
                            <div className="text-wrapper-7">
                              Create Student Video Config
                            </div>
                          </div>
                          <div className="frame-22">
                            <div className="frame-23">
                              <div className="component-wrapper">
                                <TemplateEmptyVuesaxLinearProperty332 className="template-empty" />
                                {/* <Component className="component-1" /> */}
                              </div>
                              <p className="video-tutorial-how">
                                <span className="span">Video Tutorial: </span>
                                <span className="text-wrapper-8">
                                  How to create an student video configuration?
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                        <FiRrCommentInfo
                          className="icon-instance-node"
                          color="#7E7E7E"
                        />
                      </div>
                      <div
                        className="group-2"
                        style={{
                          backgroundColor: sheetBackcolor != "" ? sheetBackcolor : "#091f3f",
                            pointerEvents: eventState ? 'none' : 'auto', opacity: eventState ? 0.5 : 1
                        }}
                      >
                        
                            <div
                              className="group-3"
                              style={{
                                borderColor:
                                  imageBordercolor != ""
                                    ? imageBordercolor
                                    : "#AF802D",
                               
                              }}
                            >
                              {latestFile != null ? (
                               <video style={{ width: '100%', height: '100%' }} controls>
                               <source src={latestFile} type="video/mp4" />
                               Your browser does not support the video tag.
                           </video>
                              ) : (
                                <div
                                  className="frame-241"
                                  onClick={showModal_Images}
                                >
                                  <FiRrPicture1 className="icon-instance-node-upload" />
                                  <div className="text-wrapper-9">
                                    Student recorded video will be played in this frame
                                  </div>
                                </div>
                              )}
                              <div
                              className="fi-rr-edit-12-wrapper"
                              id="callout-button-1"
                              onClick={toggleIsCalloutVisible_Images}
                            >
                              <FiRrEdit12 className="icon-instance-node-2" />
                            </div>
                            </div>
                            
                        <div className="frame-251">
                          <PropertyDefault
                            className="design-component-instance-node-2"
                            icon={
                              <FiRrEdit8 className="icon-instance-node-2" />
                            }
                            onclick={toggleIsCalloutVisible_Heading}
                            id="callout-button-2"
                            headvalue={headingColor}
                            value={textchangeHeading}
                            onChange={(newValue) => {
                              setTextChangeHeading(newValue);

                          }}
                          />
                          <div className="frame-27">
                            {/* <div
                              className="text-wrapper-101"
                              style={{
                                color:
                                  subheadingColor != ""
                                    ? subheadingColor
                                    : "#6e6e6e",
                              }}
                              contentEditable
                              onInput={(e) => onChangeTexts(e, "Class")}
                            >
                             {textchangeClass}
                            </div> */}
                            <input className="text-wrapper-101" style={{fontFamily:'Lato', color: subheadingColor != '' ? subheadingColor : '#6e6e6e', width: inputWidthForClass + 'px', maxWidth: '100%' }} maxLength={18} onChange={(e)=> onChangeTexts(e,'Class')} value={textchangeClass}/>

                            <div
                              className="fi-rr-edit-14-wrapper"
                              id="callout-button-3"
                              onClick={toggleIsCalloutVisible_Subheading}
                            >
                              <FiRrEdit14 className="icon-instance-node-2" />
                            </div>
                          </div>
                          <div className="frame-27">
                             <input className="text-wrapper-111" style={{fontFamily:'SeasideResortNF', color: yearColor != '' ? yearColor : '#6e6e6e', width: inputWidthForYear + 'px', maxWidth: '100%' }} maxLength={18} onChange={(e)=>onChangeTexts(e,'Optional')} value={textchangeYear}/>
                            {/* <div
                              className="text-wrapper-111"
                              style={{
                                color: yearColor != "" ? yearColor : "#6e6e6e",
                              }}
                              contentEditable
                              onInput={(e) => onChangeTexts(e, "Optional")}
                            >
                            {textchangeYear}
                            </div> */}
                            <div
                              className="fi-rr-edit-15-wrapper"
                              id="callout-button-4"
                              onClick={toggleIsCalloutVisible_Year}
                            >
                              <FiRrEdit14 className="icon-instance-node-2" />
                            </div>
                          </div>

                          {/* <div className="frame-26">
                            
                          </div> */}
                        
                        <div className="overlap-21">
                          <div className="rectangle-2" />

                          <div className="frame-28" style={{ width: '98%', left: preview_Images_logo?.url != null ? '2px' : '74px' }} onClick={showModal_Images_logo}>
                            {preview_Images_logo?.url != null ?
                              <img style={{ width: '100%', height: '56px' }} src={preview_Images_logo?.url} alt="Upload preview" />
                              :
                              <>
                                <div className="group-4">
                                  <div className="overlap-group-3">
                                    <div className="text-wrapper-12">Logo</div>
                                  </div>
                                </div>
                                <div className="text-wrapper-13">Your logo goes here</div>
                              </>
                            }

                          </div>
                          <div
                            className="fi-rr-cloud-upload-wrapper"
                            onClick={showModal_Images_logo}
                          >
                            <FiRrCloudUpload13
                              className="icon-instance-node-2"
                              color="#5C5C5C"
                            />
                          </div>
                          </div>
                          
                        </div>
                        <div className="fi-rr-edit-11-wrapper" id="callout-button"
                          onClick={toggleIsCalloutVisible}>
                            <FiRrEdit14 className="icon-instance-node-2" />
                        </div>
                      </div>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="frame-29">
            <button className="primary-BTN">
              
                <Link to="/CreateIntroClip"><button className="button-text">Back</button></Link>
              
            </button>
            <button className="button-text-wrapper">
              <button className="button" onClick={handleSave}>Continue
              </button>
            </button>
          </div>

          <ImageModal
            modalIsOpen={isCroppingForLogo}
            ratio={5}
            onClose={onCloseCropper}
            image={selectedImageForCropping}
            onCropCompleted={(area) => {
              setCompletedCrop(area);
            }}
            onUpload={(file) => {
              setSelectedFiles(prevState => ({
                ...prevState,
                logo: file
              }));
               
            }}
            onFileCropping={(file) => {
              if (completedCrop.width >= 500 && completedCrop.height >= 100) {
              setIsCroppingForLogo(false);
              setPreview_Images_logo({name: selectedImageForCropping.name, url: file.url, uploadedData: false })
              } else {
                toastError('Cropped image minimum dimensions must be 500x100');
              }
            }}
          />

          {isCalloutVisible && (
            <Callout
              className={styles1.callout}
              ariaLabelledBy={"callout-label"}
              ariaDescribedBy={"callout-description"}
              role="dialog"
              gapSpace={0}
              target={`#callout-button`}
              onDismiss={toggleIsCalloutVisible}
              setInitialFocus
            >
              <ColorPicker
                color={sheetBackcolor}
                onChange={onChangeBackgroundColor}
                alphaType="none"
                showPreview={false}
                styles={colorPickerStyles}
                strings={{
                  hueAriaLabel: "Hue",
                }}
              />
            </Callout>
          )}

          {isCalloutVisible_Images && (
            <Callout
              className={styles1.callout}
              ariaLabelledBy={"callout-label"}
              ariaDescribedBy={"callout-description"}
              role="dialog"
              gapSpace={0}
              target={`#callout-button-1`}
              onDismiss={toggleIsCalloutVisible_Images}
              setInitialFocus
            >
              <ColorPicker
                color={imageBordercolor}
                onChange={onChangeBackgroundColor_Images}
                alphaType="none"
                showPreview={false}
                styles={colorPickerStyles}
                strings={{
                  hueAriaLabel: "Hue",
                }}
              />
            </Callout>
          )}

          {isCalloutVisible_Heading && (
            <Callout
              className={styles1.callout}
              ariaLabelledBy={"callout-label"}
              ariaDescribedBy={"callout-description"}
              role="dialog"
              gapSpace={0}
              target={`#callout-button-2`}
              onDismiss={toggleIsCalloutVisible_Heading}
              setInitialFocus
            >
              <ColorPicker
                color={headingColor}
                onChange={onChangeBackgroundColor_Heading}
                alphaType="none"
                showPreview={false}
                styles={colorPickerStyles}
                strings={{
                  hueAriaLabel: "Hue",
                }}
              />
            </Callout>
          )}

          {isCalloutVisible_Subheading && (
            <Callout
              className={styles1.callout}
              ariaLabelledBy={"callout-label"}
              ariaDescribedBy={"callout-description"}
              role="dialog"
              gapSpace={0}
              target={`#callout-button-3`}
              onDismiss={toggleIsCalloutVisible_Subheading}
              setInitialFocus
            >
              <ColorPicker
                color={subheadingColor}
                onChange={onChangeBackgroundColor_Subheading}
                alphaType="none"
                showPreview={false}
                styles={colorPickerStyles}
                strings={{
                  hueAriaLabel: "Hue",
                }}
              />
            </Callout>
          )}

          {isCalloutVisible_Year && (
            <Callout
              className={styles1.callout}
              ariaLabelledBy={"callout-label"}
              ariaDescribedBy={"callout-description"}
              role="dialog"
              gapSpace={0}
              target={`#callout-button-4`}
              onDismiss={toggleIsCalloutVisible_Year}
              setInitialFocus
            >
              <ColorPicker
                color={yearColor}
                onChange={onChangeBackgroundColor_Year}
                alphaType="none"
                showPreview={false}
                styles={colorPickerStyles}
                strings={{
                  hueAriaLabel: "Hue",
                }}
              />
            </Callout>
          )}

          <Modal
            titleAriaId={"title"}
            isOpen={isModalOpen_Images}
            onDismiss={handleVideoCloseModal}
            isBlocking={false}
            containerClassName={contentStyles.container}
          // dragOptions={isDraggable ? dragOptions : undefined}
          >
            <div className="uploadModal">
              <div className="div-2">
                <button className="CTA" onClick={render_preview_video}
                  disabled={!isVideoUploaded} // Disable the button if logoUploaded is false
                  style={{
                    opacity: isVideoUploaded ? 1 : 0.5,
                    cursor: isVideoUploaded ? 'pointer' : 'not-allowed'
                  }}>
                  <div className="label">UPLOAD VIDEO</div>
                </button>
                <div className="heading-upload">
                  <div className="text-wrapper">Upload Video</div>
                </div>

                <div className="upload-file-area" onClick={openUpload}>
                  <div className="frame-2" style={{left: '110px'}}>
                    <img
                      className="upload-icon"
                      alt="Upload icon"
                      src={uploadIcon}
                    />
                    <div className="frame-3">
                      <div className="drag-drop-files">
                        <p className="drag-drop-files-or">
                          <span className="span">Drag &amp; drop files or </span>
                          {/* <span className="text-wrapper-2">&nbsp;</span> */}
                          <span className="text-wrapper-2">Browse</span>
                        </p>
                        <input
                          id="image_upload"
                          onChange={(e) => handleOnChange(e)}
                          style={{ display: "none" }}
                          type="file"
                          accept=".mp4"
                        />
                      </div>
                      <div className={`frame frame-1027`}>
                        <div className="div" style={{ display: 'flex', gap: '2px' }}>
                          <Checkbox className="checkbox-instance" property1="fi-sr-info" />
                          <p className="i-authorise">Please upload .mp4 video.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>

          <Modal
            titleAriaId={"title"}
            isOpen={isModalOpen_Images_logo}
            onDismiss={handleCloseModal}
            isBlocking={false}
            containerClassName={contentStyles.container}
          // dragOptions={isDraggable ? dragOptions : undefined}
          >
            <div className="uploadModal">
              <div className="div-2">
                <button className="CTA" onClick={preview_logo_click}
                  disabled={!isLogoUploaded} // Disable the button if logoUploaded is false
                  style={{
                    opacity: isLogoUploaded ? 1 : 0.5,
                    cursor: isLogoUploaded ? 'pointer' : 'not-allowed'
                  }}>
                  <div className="label">UPLOAD LOGO</div>
                </button>
                <div className="heading-upload">
                  <div className="text-wrapper">Upload Logo</div>
                </div>

                <div className="upload-file-area" onClick={openUpload_logo}>
                  <div className="frame-2">
                    <img
                      className="upload-icon"
                      alt="Upload icon"
                      src={uploadIcon}
                    />
                    <div className="frame-3">
                      <div className="drag-drop-files">
                        <p className="drag-drop-files-or">
                          <span className="span">Drag &amp; drop files or </span>
                          <span className="text-wrapper-3">Browse</span>
                        </p>
                        <input
                          id="image_upload_logo"
                          onChange={(e) => handleOnChange_logo(e)}
                          style={{ display: "none" }}
                          type="file"
                          accept="image/png"
                        />
                      </div>
                      <div className={`frame frame-1027`}>
                        <div className="div" style={{ display: 'flex', gap: '2px' }}>
                          <Checkbox className="checkbox-instance" property1="fi-sr-info" />
                          <p className="i-authorise">Kindly upload logos in .png format ensuring they have a<br /> transparent background. Ensure there is minimal<br /> blank space surrounding the logo, and that its dimensions<br /> are at least 500x100 pixels</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>

          
        </div>
        {/* <LeftNav className="left-nav-instance" frameClassName="left-nav-3" overlapGroupClassName="left-nav-2" /> */}
        {loading && (
                        <div className="loader-container">
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px' }}>
                                <CircularProgress />
                            </Box>
                        </div>
                    )}
      </div>
      
    </div>
  );
};

const colorPickerStyles = {
  panel: { padding: 12 },
  root: {
    maxWidth: 352,
    //minWidth: 352,
  },
  colorRectangle: { height: 268 },
};

const styles1 = mergeStyleSets({
  button: {
    width: 130,
  },
  callout: {
    width: 320,
    maxWidth: "90%",
    padding: "20px 24px",
  },
  title: {
    marginBottom: 12,
    fontWeight: FontWeights.semilight,
  },
  link: {
    display: "block",
    marginTop: 20,
  },
});

// Images Popup
const cancelIcon = { iconName: "Cancel" };

const theme = getTheme();
const contentStyles = mergeStyleSets({
  container: {
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "stretch",
    borderRadius: "20px",
  },
  header: [
    theme.fonts.xLargePlus,
    {
      flex: "1 1 auto",
      borderTop: `4px solid ${theme.palette.themePrimary}`,
      color: theme.palette.neutralPrimary,
      display: "flex",
      alignItems: "center",
      fontWeight: FontWeights.semibold,
      padding: "12px 12px 14px 24px",
    },
  ],
  heading: {
    color: theme.palette.neutralPrimary,
    fontWeight: FontWeights.semibold,
    fontSize: "inherit",
    margin: "0",
  },
  body: {
    flex: "4 4 auto",
    padding: "0 24px 24px 24px",
    overflowY: "hidden",
    selectors: {
      p: { margin: "14px 0" },
      "p:first-child": { marginTop: 0 },
      "p:last-child": { marginBottom: 0 },
    },
  },
});
const stackProps = {
  horizontal: true,
  tokens: { childrenGap: 40 },
  styles: { root: { marginBottom: 20 } },
};
const iconButtonStyles = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: "auto",
    marginTop: "4px",
    marginRight: "2px",
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};


const mapStateToProps = (state) => {
  return {
    loading: state.preEvent.loading,
    eventMasterData: state.preEvent.gradreelType,
    customerClipDetails: state.preEvent.customerClipDetails,
    eventState: state.preEvent.eventState,
    eventSuccess: state.preEvent.eventSuccess,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    FetchCustomerClipConfig: (eventData) => dispatch(FetchCustomerClipConfig(eventData)),
    postCustomerClipConfig: (files, customData) => dispatch(postCustomerClipConfig(files, customData)),
    setEventSuccess: (flag) => dispatch(setEventSuccess(flag)),
  };
};

const ConnectedStudentClip = connect(mapStateToProps, mapDispatchToProps)(StudentClip);

export { ConnectedStudentClip as StudentClip };
