import axios from "axios"
import { toast } from "react-toastify"
import { BlobServiceClient } from '@azure/storage-blob';
import { CREATE_EVENT_FAILURE_FOR_POST, CREATE_EVENT_START_FOR_POST, CREATE_EVENT_SUCCESS_FOR_POST, FAIL_REQUEST_FOR_POST, GET_CEREMONY_VIDEO_UPLOAD_URI, GET_POST_EVENT_DATA, GET_POST_EVENT_LIST, MAKE_REQUEST_FOR_POST, SET_EVENT_SUCCESS_FOR_POST, SET_POST_EVENT_DETAILS, SET_SEARCH_QUERY_FOR_POST, GET_VIDEO_PROCESSING_STATUS, GET_URL_FOR_CLIP, CANCEL_UPLOAD, UPLOAD_PROGRESS, UPLOAD_SUCCESS, UPLOAD_FAILED, GET_HANDSHAKE_DETAILS, GET_FIRST_CANDIDATE_ANNOUNCEMENT_TIME, START_UPLOAD, CANCEL_FILE, ADD_TO_QUEUE, PROCESS_QUEUE, START_UPLOAD_FROM_QUEUE, INITIALIZE_VALUES_OF_POSTEVENT } from "../ActionTypes/PostEventActionTypes";
import { toastSuccess, toastError, getClientData } from "../../constants/Constants";

export const makeRequest = () => {
    return {
        type: MAKE_REQUEST_FOR_POST,
    };
};
export const failRequest = (err) => {
    return {
        type: FAIL_REQUEST_FOR_POST,
        payload: err,
    };
};

export const createEventStart = () => ({
    type: CREATE_EVENT_START_FOR_POST,
});

export const createEventSuccess = () => ({
    type: CREATE_EVENT_SUCCESS_FOR_POST,
});

export const createEventFailure = (error) => ({
    type: CREATE_EVENT_FAILURE_FOR_POST,
    payload: error,
});

export const setEventSuccess = (flag) => {
    return {
        type: SET_EVENT_SUCCESS_FOR_POST,
        payload: flag,
    };
};

export const initializeValues = () => {
    return {
      type: INITIALIZE_VALUES_OF_POSTEVENT,
    }
}

export const getPostEventList = (data) => {
    return {
        type: GET_POST_EVENT_LIST,
        payload: data
    }
}

export const getPostEventData = (data) => {
    return {
        type: GET_POST_EVENT_DATA,
        payload: data
    }
}

export const setPostEventDetails = (details) => ({
    type: SET_POST_EVENT_DETAILS,
    payload: details,
});

export const setSearchQueryForPostevent = (searchQuery) => {
    return {
        type: SET_SEARCH_QUERY_FOR_POST,
        payload: searchQuery,
    };
};

export const getCeremonyVideoURI = (data) => {
    return {
        type: GET_CEREMONY_VIDEO_UPLOAD_URI,
        payload: data
    }
}

export const getHandshakeData = (data) => {
    return {
        type: GET_HANDSHAKE_DETAILS,
        payload: data
    }
}

export const getAnnouncementTime = (data) => {
    return {
        type: GET_FIRST_CANDIDATE_ANNOUNCEMENT_TIME,
        payload: data
    }
}

export const getVideoProcessingStatus = (data) => {
    return {
        type: GET_VIDEO_PROCESSING_STATUS,
        payload: data
    }
}

export const getUrlForCustomerClip = (data) => {
    return {
        type: GET_URL_FOR_CLIP,
        payload: data
    }
}

// Gets list of post events
export const FetchPostEventList = (clientId) => {
    return async (dispatch) => {
        dispatch(makeRequest());
        return await axios.get(`${window.APP.apiUrl}api/initialize/GetPostEventListByClientId`, {
            headers: {
                "X-API-Key": window.APP.apiKey,
            },
            params: {
                ClientId: clientId
            }
        }).then(res => {
            const eventlist = res.data;
            dispatch(getPostEventList(eventlist));
        }).catch(err => {
            dispatch(failRequest(err.message))
        })

    }
}

// Gets the data of post event based on event ID
export const fetchPostEventData = (clientId, eventId) => {
    return (dispatch) => {
        dispatch(makeRequest());
        // toast.info('Loading...');
        axios.get(`${window.APP.apiUrl}api/initialize/GetPostEventData`, {
            headers: {
                "X-API-Key": window.APP.apiKey,
            },
            params: {
                ClientId: clientId,
                EventId: eventId
            }
        }).then(res => {
            const eventData = res.data;
            dispatch(getPostEventData(eventData));
            // toast.dismiss();
        }).catch(err => {
            dispatch(failRequest(err.message))
            // toast.dismiss();
        })

    }
}

// Gets URI for ceremony video where we have to send the ceremony video
export const getCeremonyVideoUri = (eventId) => {
    return (dispatch) => {
        dispatch(makeRequest());
        axios.get(`${window.APP.apiUrl}api/initialize/GetCeremonyVideoUploadUri`, {
            headers: {
                "X-API-Key": window.APP.apiKey,
            },
            params: {
                EventId: eventId
            }
        }).then(res => {
            const uri = res.data;
            dispatch(getCeremonyVideoURI(uri));
        }).catch(err => {
            dispatch(failRequest(err.message))
        })

    }
}

const abortControllers = new Map();

export const updateUploadProgress = (progress, fileName) => ({
    type: UPLOAD_PROGRESS,
    payload: { progress, fileName }
});

export const uploadSuccess = (fileName) => ({
    type: UPLOAD_SUCCESS,
    payload: { fileName}
});

export const startUpload = (fileName, eventId) => ({
    type: START_UPLOAD,
    payload: { fileName, eventId }
});

export const uploadFailed = () => ({
    type: UPLOAD_FAILED
});

export const CancelledFileName = (fileName) => ({
    type: CANCEL_FILE,
    payload: fileName
})

export const cancelUpload = (fileName) => {
    const abortController = abortControllers.get(fileName);
    if (abortController) {
        abortController.abort();
        abortControllers.delete(fileName);
        toast.success("Cancelled file upload operation.", {
            autoClose: 3000,
        });
    }
    return (dispatch) => {
        dispatch({
            type: CANCEL_UPLOAD,
            payload: { fileName }
        });
        processUploadQueue(dispatch);
    };
};

export const addToQueue = (fileName, eventId) => ({
    type: ADD_TO_QUEUE,
    payload: { fileName, eventId }
});

export const startUploadFromQueue = (fileName) => ({
    type: START_UPLOAD_FROM_QUEUE,
    payload: { fileName }
});

export const processQueue = () => ({
    type: PROCESS_QUEUE
});

// Uploads the ceremony video using blob storage and calls function for submitting post event
const uploadQueue = [];
let activeUploads = 0;
const maxConcurrentUploads = 1;

const processUploadQueue = async (dispatch) => {
    while (uploadQueue.length > 0 && activeUploads < maxConcurrentUploads) {
        const { ceremonyVideoUrl, selectedFile, postEventData } = uploadQueue.shift();
        let combinedString = `${selectedFile.name} ${postEventData.result.postEventData.eventId}`;
        dispatch(startUploadFromQueue(combinedString));
        activeUploads++;
        try {
            await uploadFile(ceremonyVideoUrl, selectedFile, postEventData, dispatch);
        } finally {
            activeUploads--;
            processUploadQueue(dispatch); // Process next item in the queue
        }
    }
};

const uploadFile = async (ceremonyVideoUrl, selectedFile, postEventData, dispatch) => {
    try {
        if (selectedFile) {
            const parsedUrl = new URL(ceremonyVideoUrl);
            const blobServiceEndpoint = `${parsedUrl.protocol}//${parsedUrl.hostname}`;
            const sasToken = parsedUrl.search.slice(1);
            const containerPath = decodeURIComponent(parsedUrl.pathname);
            const containerName = containerPath.substring(1);
            const blobName = selectedFile.name;
            const connectionString = `BlobEndpoint=${blobServiceEndpoint};SharedAccessSignature=${sasToken}`;
            const blobServiceClient = BlobServiceClient.fromConnectionString(connectionString);
            const containerClient = blobServiceClient.getContainerClient(containerName);
            const blockBlobClient = containerClient.getBlockBlobClient(blobName);
            const chunks = selectedFile.size / (4 * 1024 * 1024);
            let combinedString = `${blobName} ${postEventData.result.postEventData.eventId}`;

            const abortController = new AbortController();
            abortControllers.set(combinedString, abortController);
            dispatch(startUpload(combinedString, postEventData.result.postEventData.eventId));
            const options = {
                blockSize: 4 * 1024 * 1024,
                concurrency: 20,
                abortSignal: abortController.signal,
                onProgress: (ev) => {
                    toast.dismiss();
                    const percentCompleted = Math.round((ev.loadedBytes / selectedFile.size) * 100);
                    dispatch(updateUploadProgress(percentCompleted, combinedString));
                }
            };

            await blockBlobClient.uploadData(selectedFile, options);
            toastSuccess(`Ceremony video submitted successfully`);
            dispatch(uploadSuccess(combinedString));
            const clientData = getClientData();
            if (clientData) {
                const eventData = {
                    eventId: postEventData.result.postEventData.eventId,
                    clientId: postEventData.result.postEventData.clientId,
                    videoFileName: selectedFile?.name,
                    isStreamingEvent: false,
                    streamingUrl: "",
                    submittedBy: clientData.userEmail
                };
                dispatch(submitPostEvent(eventData));
            }
        }
    } catch (error) {
        if (error.name !== 'AbortError') {
            toastError(`Error uploading file, please try again later.`);
            dispatch(uploadFailed());
        }
    } finally {
        abortControllers.delete(selectedFile.name);
    }
};

export const dispatchPostRequest = (ceremonyVideoUrl, selectedFile, postEventData) => {
    return (dispatch, getState) => {
        const state = getState();
        const uploads = state.postEvent.uploads;
        const uploadQueues = state.postEvent.uploadQueue;
        let combinedString = `${selectedFile.name} ${postEventData.result.postEventData.eventId}`;
        
        const isDuplicateUpload = uploads.some(upload => upload.eventId === postEventData.result.postEventData.eventId ) || 
                                  uploadQueues.some(upload => upload.eventId === postEventData.result.postEventData.eventId);

        if (isDuplicateUpload) {
            toastError("This video is already being uploaded for the same event.");
            return;
        }
        
        uploadQueue.push({ ceremonyVideoUrl, selectedFile, postEventData, dispatch });
        if(activeUploads >= maxConcurrentUploads) {
            dispatch(addToQueue(combinedString, postEventData.result.postEventData.eventId));
        }
            processUploadQueue(dispatch);
    };
};

export const getHandshakeDetails = (eventId, clientId) => {
    return async (dispatch) => {
      dispatch(makeRequest());
      try {
        const res = await axios.get(`${window.APP.apiUrl}api/initialize/GetHandshakeDetails`, {
          headers: {
            "X-API-Key": window.APP.apiKey,
          },
          params: {
            EventId: eventId,
            ClientId: clientId
          }
        });
        const data = res.data;
        dispatch(getHandshakeData(data));
        return data; // Return the data
      } catch (err) {
        dispatch(failRequest(err.message));
        throw err; // Rethrow the error
      }
    }
  }

  export const getFirstCandidateAnnouncementTime = (eventId, clientId) => {
    return async (dispatch) => {
      dispatch(makeRequest());
      try {
        const res = await axios.get(`${window.APP.apiUrl}api/initialize/GetFirstCandidateAnnoucementTime`, {
          headers: {
            "X-API-Key": window.APP.apiKey,
          },
          params: {
            EventId: eventId,
            ClientId: clientId
          }
        });
        const data = res.data;
        dispatch(getAnnouncementTime(data));
        if(data.result?.isDataAvailable && data.result?.message && data.result?.insightsStatus == "InProgress")
            toast.info(data.result.message);
        return data; // Return the data
      } catch (err) {
        dispatch(failRequest(err.message));
        throw err;
      }
    }
  }  

  export const postHandshakeDetailsWithCsv = (files, handshakeDetails) => {
    return async (dispatch) => {
        try {
            const payload = new FormData();
            if (files) {
                payload.append('files', files);
            }
            payload.append('handshakeDetails', JSON.stringify(handshakeDetails));
            const result = await axios.post(`${window.APP.apiUrl}api/initialize/PostHandshakeDetailsWithCsv`, payload, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'X-API-Key': window.APP.apiKey,
                },
            });

            if (result.data.isSuccess) {
                toastSuccess("Handshake Information Uploaded successfully!");
                return result.data.isSuccess
            } else {
                toastError(`Error: ${result.data.error || "Unknown error"}`);
                dispatch(failRequest(result.data.error));
            }
        } catch (error) {
            toastError(`Error: ${error.response.data.title || "Unknown error"}`);
            dispatch(failRequest(error.message));
        }
    };
};


export const postHandshakeDetailsWithInput = (handshakeDetails) => {
    return async (dispatch) => {
        try {
            const payload = new FormData();
            payload.append('handshakeDetails', JSON.stringify(handshakeDetails));
            const result = await axios.post(`${window.APP.apiUrl}api/initialize/PostHandshakeDetailsWithInput`, payload, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'X-API-Key': window.APP.apiKey,
                },
            });

            if (result.data.isSuccess) {
                toastSuccess("Handshake Information Uploaded successfully!");
                return result.data.isSuccess;
            } else {
                toastError(`Error: ${result.data.error || "Unknown error"}`);
                dispatch(failRequest(result.data.error));
            }
        } catch (error) {
            toastError(`Error: ${error.response.data.title || "Unknown error"}`);
            dispatch(failRequest(error.message));
        }
    };
};

// Invoke gradreel processing for video
export const invokeGradReelProcessing = (details) => {
    return async (dispatch) => {
        dispatch(createEventStart());

        try {
            const result = await axios.post(`${window.APP.apiUrl}api/initialize/InvokeGradReelProcessing`, JSON.stringify(details), {
                headers: {
                    'Content-Type': 'application/json',
                    "X-API-Key": window.APP.apiKey,
                }
            });

            if (result.data.isSuccess) {
                toastSuccess("Gradreel processing invoked successfully!");
                dispatch(createEventSuccess());
            } else {
                toastError(`Error: Internal server error occurred, please try again later`);
                dispatch(createEventFailure(result.statusText));
            }
        } catch (error) {
            toastError(`Error: ${error.message || "Unknown error"}`);
            dispatch(createEventFailure(error.message));
        }
    };
};

// Submits post event
export const submitPostEvent = (postEventDetails) => {
    return async (dispatch) => {
        dispatch(makeRequest());

        try {

            const result = await axios.post(`${window.APP.apiUrl}api/initialize/SubmitPostEventV1`, JSON.stringify(postEventDetails), {
                headers: {
                    'Content-Type': 'application/json',
                    "X-API-Key": window.APP.apiKey,
                },
                // body: JSON.stringify(postEventDetails),
            });

            if (result.data.isSuccess) {
                dispatch(createEventSuccess());

            } else {
                dispatch(createEventFailure(result.data.error));
            }
        } catch (error) {
            dispatch(createEventFailure(error.message));
        }
    };
};

// Gets video processing status for selected event
export const getVideoProcessingStatusForEvent = (clientId, eventId) => {
    return (dispatch) => {
        dispatch(makeRequest());
        axios.get(`${window.APP.apiUrl}api/initialize/GetVideoProcessingStatusForEvent`, {
            headers: {
                "X-API-Key": window.APP.apiKey,
            },
            params: {
                EventId: eventId,
                ClientId: clientId
            }
        }).then(res => {
            const data = res.data;
            dispatch(getVideoProcessingStatus(data));
        }).catch(err => {
            dispatch(failRequest(err.message))
        })

    }
};

// Gets SAS URL for clip for selected event using customer ID
export const getSasUrlForClip = (clientId, eventId, customerId) => {
    return (dispatch) => {
        dispatch(makeRequest());
        axios.get(`${window.APP.apiUrl}api/initialize/GetSASUrlForCustomerClip`, {
            headers: {
                "X-API-Key": window.APP.apiKey,
            },
            params: {
                EventId: eventId,
                ClientId: clientId,
                CustomerId: customerId
            }
        }).then(res => {
            const data = res.data;
            dispatch(getUrlForCustomerClip(data));
        }).catch(err => {
            dispatch(failRequest(err.message))
        })

    }
}