
import React,{useState} from "react";
import "./style.css";
import { Link } from 'react-router-dom';

export const Frame1026 = ({ className, videoUrl=null, onChecked=null,frametext, linktoURL }) => {
  
  const [isButtonSelected, setIsButtonSelected] = useState(false);

  const handleGradReelTypeChange = (isChecked) => {
    onChecked(isChecked)
    setIsButtonSelected(isChecked);
  };
  return (
    <>
    {!videoUrl && 
    <div className={`frame-1026 ${className}`}>
      <div className="frame-13">
      <span className="checkboxtext3">
                 <input type="checkbox" className="frame-instance3" onChange={(e) => handleGradReelTypeChange(e.target.checked)}/>
      </span>
        <p className="if-you-don-t-have-a">
          {frametext}
        </p>
      </div>
      
      <div className={`primary-BTN ${isButtonSelected ? "" : "disabled"}`}>
    <Link to={isButtonSelected ? linktoURL: "#"} className="button-text">
      Create
    </Link>
  </div>
    </div>
  }
  </>
  );
};
