import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { connect } from "react-redux";

import { Box, CircularProgress } from "@mui/material";
import { StyledEngineProvider } from '@mui/material/styles';
import "./style.css";

import { SeasonSettings } from "../SeasonSettings";
import Tabs from '../Tabs';

import homeImage from '../../../icons/ImageIcons/material-symbols_home-rounded.svg';
import rightarrow from '../../../icons/ImageIcons/keyboard-arrow-right.svg';

import DropDownSeasonSettings from "../../../components/DropDownSeasonSettings/DropDownSeasonSettings";
import { Frame1026 } from "../../../components/Frame1026";
import { UploadVideoGroup } from "../../../components/UploadVideoGroup";


import { getClientData, toastError } from "../../../constants/Constants";
import { createSeasonSettingsClientMaster, fetchClientMaster, getExitVideoForSettings, getSelectedSeason, postExitVideoForSettings, setEventSuccess } from "../../../store/Actions/SeasonSettingsAction";


const CreateExitClipSettings = ({ loading, clientMasterData, exitVideoDataForSettings, fetchClientMaster, getExitVideoData, postExitVideo, selectedSeason, getSelectedSeason, setEventSuccess, eventSuccess, createSeasonSettingsClientMaster }) => {
  const navigate = useNavigate();
  const [videoSrc, setVideoSrc] = useState("");
  const [template, setTemplate] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [seasonId, setSeasonId] = useState(0);
  const [seasonName, setSeasonName] = useState('');
  const [seasonStartPeriod, setSeasonStartPeriod] = useState('');
  const [seasonEndPeriod, setSeasonEndPeriod] = useState('');
  const [videoUrl, setVideoUrl] = useState(null);
  const [createChecked, setCreateChecked] = useState(false);  
  const [isButtonSelected, setIsButtonSelected] = useState(false);
  const clientData = getClientData();

  useEffect(() => {
    const fetchData = async () => {
      if (seasonName) {
        await fetchClientMaster({ clientId: clientData.clientID, seasonName: seasonName});
        await getExitVideoData({ ClientId: clientData.clientID, seasonName: seasonName});
      }
    };
  
    fetchData();
  }, [seasonName, selectedSeason]);

  useEffect(() => {
    if(exitVideoDataForSettings?.result?.data?.videoAssetFilePathSaSUri){
      setVideoUrl(exitVideoDataForSettings?.result?.data?.videoAssetFilePathSaSUri)
      setVideoSrc(exitVideoDataForSettings?.result?.data?.videoAssetFilePathSaSUri)
      setIsButtonSelected(true);
    } else {
      setIsButtonSelected(false);
    }
  }, [exitVideoDataForSettings?.result?.data?.videoAssetFilePathSaSUri])

  useEffect(() => {
    setTemplate(clientMasterData?.result?.data?.template)
  }, [clientMasterData])


  useEffect(() => {
    if (exitVideoDataForSettings?.result?.data?.createVideo) {
      navigate('/SeasonSettings/CreateExitClipFrame')
    }
  }, [exitVideoDataForSettings?.result?.data?.createVideo])

  useEffect(() => {
    if (eventSuccess && !loading) {
      console.log("Event created successfully!");
      setEventSuccess(false);
      navigate("/");
    }
  }, [eventSuccess, loading]);

  // Sets the uplaoded video file by the user
  const handleFileChange = (file) => {
    try {
      setSelectedFile(file)
      // Transform file into blob URL
      setVideoSrc(URL.createObjectURL(file));
      if (seasonId) {
        setIsButtonSelected(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Checks if the user want to create a video instead on uploading one
  const onCheckedHandler = (checked) => {
    setCreateChecked(checked);
  }

  // Set the selected season name
  const handleSeasonName = (seasonName, seasonId) => {
    setSeasonName(seasonName);
    getSelectedSeason(seasonId);
    setSelectedFile(null);
    setSeasonId(seasonId);
    setVideoUrl(null);
    setVideoSrc('');
  };

  // Set the selected season dates
  const handleSeasonDates = (startDate, endDate) => {
    setSeasonStartPeriod(startDate);
    setSeasonEndPeriod(endDate);
  }

  // Upload a video selected by user
  const handleSaveEvent = async (e) => {
    e.preventDefault();
    if (!isButtonSelected) {
      return;
    }
    if (!seasonName) {
      toastError('Please select Season from dropdown.');
      return;
    }
    if (selectedFile) {
      // setLoading(true);
      if(!clientMasterData?.result?.data?.template){
        await createSeasonSettingsClientMaster({
          itemType: "SeasonSettingsMaster",
          itemTypeDesc: "SeasonSettingsMaster",
          clientId: clientData.clientID,
          clientName: clientData.clientName,
          seasonId: seasonId,
          seasonName: seasonName,
          seasonStartDate: seasonStartPeriod,
          seasonEndDate: seasonEndPeriod,
          template: 'Standard',
          templateDesc: 'Standard',
        });
      }

      await postExitVideo(selectedFile, {
        clientId: clientData.clientID,
        clientName: clientData.clientName,
        seasonName: seasonName,
        seasonStartPeriod: seasonStartPeriod,
        seasonEndPeriod: seasonEndPeriod,
        createVideo: false,
        exitVideo: {
          frameBGColor: '',
          textAsset: [
            { textId: "", textData: '', textFontStyle: "", textColor: '' },
          ],
          logoName: '',
          generatedVideoPath: "",
          generatedVideoData: "",
        },
        updatedOn: new Date(),
        updatedBy: clientData.userEmail
      });
      // setLoading(false);
    } else if(videoUrl){
      navigate("/");
    } 
  }

  return (
    <div className="create-exit-clip-settings">
      <div className="div-2">
        <SeasonSettings />

        <div className="frame-19">
          <div className="frame-20">
            <div className="section-title">
              <div className="frame-21">
                <div className="page-title">
                  <div className="text-wrapper-3">Season Settings</div>
                </div>
                <div className="div-3">
                  <img
                    className="material-symbols"
                    alt="Material symbols"
                    src={homeImage}
                  />
                  <img
                    className="keyboard-arrow-right"
                    alt="Keyboard arrow right"
                    src={rightarrow}
                  />
                  <div className="text-wrapper-4">Settings</div>
                  <img
                    className="keyboard-arrow-right"
                    alt="Keyboard arrow right"
                    src={rightarrow}
                  />
                  <div className="text-wrapper-5">Create Exit Clip Settings</div>
                </div>
              </div>
            </div>
            <div className="label">
              <div className="event-ID">Select Season Name</div>
            </div>
            <StyledEngineProvider injectFirst>
              <DropDownSeasonSettings onSeasonsName={handleSeasonName} selectedSeason={selectedSeason} onSeasonDates={handleSeasonDates}/>
            </StyledEngineProvider>
            <StyledEngineProvider injectFirst>
              <Tabs />
            </StyledEngineProvider>
            <div className="frame-22">

              <div className="frame-26">
                <div className="frame-26">
                  <div className="frame-26">
                    <div className="frame-27">
                      <div className="frame-28">
                        <div className="text-wrapper-6">Selected Template</div>
                      </div>
                      <div className="div-3">
                        <div className="frame-29">
                          <div className="text-wrapper-7">{template}</div>
                        </div>
                      </div>
                    </div>
                    <div className={createChecked ? "frame-30 disabled" : "frame-30"}>
                      {/* {!videoUrl && */}
                        <div className={videoUrl ? "frame-31 disabled" : "frame-31"}>
                          <div className="frame-32">
                            <div className="text-wrapper-8">Exit Clip</div>
                          </div>
                          <div className="frame-33">
                            <div className="frame-34">
                              <UploadVideoGroup
                                className="group-37146"
                                divClassName="group-instance"
                                frame1027Text="Please upload .mp4 format only."
                                text="Upload Video"
                                text1="Upload"
                                visible={true}
                                handleFileChange={handleFileChange}
                              />
                            </div>

                          </div>
                        </div>
                      {/* } */}

                      <div className="frame-35">
                        <div className="text-wrapper-9">Preview</div>
                        <div className="group-wrapper">
                          <video src={videoSrc} controls width="100%" height="100%">
                            Sorry, your browser doesn't support embedded videos.
                          </video>

                        </div>
                      </div>
                    </div>
                    {videoUrl && <div className="delete-button button-text-wrapper"><button className="button" type="button" onClick={() => { setVideoUrl(''); setVideoSrc(''); setIsButtonSelected(false); }}>Delete</button></div>}
                  </div>
                  <Frame1026
                    checkboxPropertyFiRrClassName="frame-1026-instance"
                    className="frame-24"
                    videoUrl={videoUrl}
                    onChecked={onCheckedHandler}
                    frametext=" If you dont have a video, no worries! We can assist you in crafting a dynamic video by simply uploading
                      your photos."
                    linktoURL="/SeasonSettings/CreateExitClipFrame?createVideo=true"/>
                </div>
              </div>
            </div>
          </div>
          <div className="frame-36">
          <button className="primary-BTN-2">
                            <Link className="button-text-2" to="/StudentClipSettings" >Back</Link>
                        </button>
            <div className={`primary-BTN-3 ${isButtonSelected ? "" : "disabled"}`}>
              <Link onClick={handleSaveEvent} className="button-text-3">Save</Link>
            </div>
          </div>
          {loading && (
            <div className="loader-container">
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px' }}>
                <CircularProgress />
              </Box>
            </div>
          )}
        </div>

      </div>
    </div>
  );
};


const mapStateToProps = (state) => {
  return {
    loading: state.seasonSettings.loading,
    clientMasterData: state.seasonSettings.clientMasterData,
    selectedSeason: state.seasonSettings.selectedSeason,
    exitVideoDataForSettings: state.seasonSettings.exitVideoDataForSettings,
    eventSuccess: state.seasonSettings.eventSuccess,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchClientMaster: (eventData) => dispatch(fetchClientMaster(eventData)),
    getSelectedSeason: (eventData) => dispatch(getSelectedSeason(eventData)),
    getExitVideoData: (eventData) => dispatch(getExitVideoForSettings(eventData)),
    createSeasonSettingsClientMaster: (clientData) =>
      dispatch(createSeasonSettingsClientMaster(clientData)),
    postExitVideo: (files, exitData) => dispatch(postExitVideoForSettings(files, exitData)),
    setEventSuccess: (flag) => dispatch(setEventSuccess(flag)),
  };
};

const ConnectedCreateExitClipSettings = connect(mapStateToProps, mapDispatchToProps)(CreateExitClipSettings);

export { ConnectedCreateExitClipSettings as CreateExitClipSettings };