import React, { useEffect, useState,useReducer } from "react";
import { Link, useNavigate } from "react-router-dom";
import { connect, useDispatch } from "react-redux";

import Box from '@mui/material/Box';
import { StyledEngineProvider } from '@mui/material/styles';
import LinearProgress from '@mui/material/LinearProgress';
import "./style.css";

import { PreEventDetails } from "../PreEventDetails";
import Stepper from '../Stepper';

import homeImage from '../../../icons/ImageIcons/material-symbols_home-rounded.svg';
import rightarrow from '../../../icons/ImageIcons/keyboard-arrow-right.svg';

import { Property1Variant4 } from "../../../icons/Property1Variant4";
import { FiRrCheck1 } from "../../../icons/FiRrCheck1";

import { Player } from 'video-react';

import { PropertyGroup } from "../../../components/PropertyGroup";

import { completeStep, FetchGradreelType, generateVideo, publishClip, setEventSuccess, submitPreEvent } from "../../../store/Actions/PreEventAction";
import { getClientData, toastError } from "../../../constants/Constants";


const ConfirmPreEventDetails = (props) => {
    const [videoSrc, setVideoSrc] = useState("");
    const [isButtonSelected, setIsButtonSelected] = useState(false);
    const [isPublishIntroButtonSelected, setIsPublishIntroButtonSelected] = useState(false);
    const [isPublishExitButtonSelected, setIsPublishExitButtonSelected] = useState(false);
    const [isPublishForIntro, setIsPublishForIntro] = useState(false);
    const [isPublishForExit, setIsPublishForExit] = useState(false);
    const [isSubmitButtonSelected, setIsSubmitButtonSelected] = useState(false);
    const [areButtonsVisible, setAreButtonsVisible] = useState(true);
    const [isCheckboxDisabled, setIsCheckboxDisabled] = useState(false);
    const [isIntroClipPublished, setIsIntroClipPublished] = useState(false);
    const [isExitClipPublished, setIsExitClipPublished] = useState(false);
    const [genVideoDataForIntro, setGenVideoDataForIntro] = useState()
    const [genVideoDataForExit, setGenVideoDataForExit] = useState()
    const [introUrl, setIntroUrl] = useState()
    const [exitUrl, setExitUrl] = useState()
    const [introUrlLoading, setIntroUrlLoading] = useState(false)
    const [exitUrlLoading, setExitUrlLoading] = useState(false)
    const [eventDetails, SeteventDetails] = useState()
    const [errorForIntro, setErrorForIntro] = useState();
    const [errorForExit, setErrorForExit] = useState();
    const [introClipComplete, setIntroClipComplete] = useState()
    const [exitClipComplete, setExitClipComplete] = useState()
    const [backGroundMusicProvided, setBackGroundMusicProvided] = useState();
    const [errorMessageForIntro, setErrorMessageForIntro] = useState("");
    const [errorMessageForExit, setErrorMessageForExit] = useState("");
    const [loading, setLoading] = useState(false);
    const [loadingForGen, setLoadingForGen] = useState(false);
    const [loadingTextForGen, setLoadingTextForGen] = useState('');
    const [loadingEvent, setLoadingEvent] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
    const clietData = getClientData();
    
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        (async () => {
          let updateData;
          if (props?.eventDetails) {
            updateData = { eventId: props.eventDetails?.eventId, clientId: props.eventDetails?.clientId };
            await props.loadevent(updateData)
                .then(() => {
                    setLoadingEvent(false);
                })
                .catch(error => {
                    // Handle error if necessary
                    console.error('Error loading event:', error);
                });
          } else {
            navigate('/PreEventDetailsDashboard')
        }
        })();
      }, []);

      useEffect(() => {
        if(!props.gradreelTypeData?.result?.isDataAvailable){
          navigate('/SelectTemplate')
        }
      }, [props.gradreelTypeData])

      useEffect(() => {
        if (props.eventSuccess) {
          console.log("Event created successfully!");
          props.setEventSuccess(false);
          navigate('/PreEventDetailsDashboard') 
          if(props.gradreelTypeData?.result?.eventData?.template === 'Standard'){
            dispatch(completeStep(5, true));
          } else if(props.gradreelTypeData?.result?.eventData?.template === 'Custom'){
            dispatch(completeStep(6, true));
          }
        }
      }, [props.eventSuccess]);

    useEffect(() => {
        if(!loadingEvent){
        setIntroClipComplete(props.gradreelTypeData?.result?.eventData?.introClipComplete);
        setExitClipComplete(props.gradreelTypeData?.result?.eventData?.exitClipComplete);
        setBackGroundMusicProvided(props.gradreelTypeData?.result?.eventData?.backGroundMusicProvided);
        }

    }, [props.gradreelTypeData?.result?.eventData?.introClipComplete, props.gradreelTypeData?.result?.eventData?.exitClipComplete, props.gradreelTypeData?.result?.eventData?.backGroundMusicProvided, loadingEvent])

    useEffect(() => {
        if (props.gradreelTypeData?.result?.eventData?.introClipPublished && !loadingEvent) {
            setIsPublishForIntro(false)
            setIsIntroClipPublished(true)
            setIntroUrlLoading(false);
        }
        if (props.gradreelTypeData?.result?.eventData?.exitClipPublished && !loadingEvent) {
            setIsPublishForExit(false)
            setIsExitClipPublished(true)
            setExitUrlLoading(false);
        }
    }, [props.gradreelTypeData?.result?.eventData?.introClipPublished, props.gradreelTypeData?.result?.eventData?.exitClipPublished, loadingEvent])

    useEffect(() => {
        const generateVideo = async () => {
            const videoData = { eventId: props.eventDetails?.eventId, clientId: props.eventDetails?.clientId, new_key: 'no data' };
            SeteventDetails(props?.eventDetails);

            const generateIntroVideo = async () => {
                setLoading(true);
                setIntroUrlLoading(true);
                const params = { videotype: 'intro', preview: true, isseasonalsetting: false };
                const test = await props.generateVideo(videoData, params);
                setIntroUrlLoading(false);
            };

            const generateExitVideo = async () => {
                setLoading(true);
                setExitUrlLoading(true);
                const params = { videotype: 'exit', preview: true, isseasonalsetting: false };
                const test = await props.generateVideo(videoData, params);
                setExitUrlLoading(false);
            };
            if (!loadingEvent && introClipComplete && exitClipComplete && backGroundMusicProvided && !isIntroClipPublished && !isExitClipPublished && !props.gradreelTypeData?.result?.eventData?.introClipAssetPathSaSUri && !props.gradreelTypeData?.result?.eventData?.exitClipAssetPathSaSUri) {
                await Promise.all([generateIntroVideo(), generateExitVideo()]);
                setLoading(false);
            } 
            else if (!loadingEvent && introClipComplete && backGroundMusicProvided && !isIntroClipPublished && !props.gradreelTypeData?.result?.eventData?.introClipAssetPathSaSUri) {
                await  generateIntroVideo();
                setLoading(false);
            } else if (!loadingEvent && exitClipComplete && backGroundMusicProvided && !isExitClipPublished && !props.gradreelTypeData?.result?.eventData?.exitClipAssetPathSaSUri) {
                await generateExitVideo();
                setLoading(false);
            }  
            // else if (introClipComplete === false && exitClipComplete === false && backGroundMusicProvided === false) {
            //     toastError('Please configure videos for preview');
            // } 
            // else if (introClipComplete !== undefined || exitClipComplete !== undefined || backGroundMusicProvided !== undefined) {
            //     toastSuccess('Intro and Exit videos are already been generated.');
            // }
        };
    if(!loadingEvent){
        generateVideo();
        }

    }, [introClipComplete, exitClipComplete, backGroundMusicProvided, loadingEvent, props.gradreelTypeData?.result?.eventData?.introClipAssetPathSaSUri, props.gradreelTypeData?.result?.eventData?.exitClipAssetPathSaSUri]);

    useEffect(() => {
        if(props?.eventState && !loadingEvent){
            setIsPublishForExit(false);
            setIsPublishForIntro(false);
            setIsIntroClipPublished(true);
            setIsExitClipPublished(true);
            setIsCheckboxDisabled(true);
        }
    },[props?.eventState, loadingEvent])

    useEffect(() => {
        if (!loadingEvent && !backGroundMusicProvided) {
            setIsCheckboxDisabled(true);
            setErrorMessageForIntro('Please configure intro video for preview');
            setErrorMessageForExit('Please configure exit video for preview');
        } else {
            setIsCheckboxDisabled(false);
            setErrorMessageForIntro('');
            setErrorMessageForExit('');
        }
    
        if (!loadingEvent && backGroundMusicProvided) {
            if (!introClipComplete) {
                setErrorMessageForIntro('Please configure intro video for preview');
            }
            if (!exitClipComplete) {
                setErrorMessageForExit('Please configure exit video for preview');
            }
        }
    }, [introClipComplete, exitClipComplete, backGroundMusicProvided, loadingEvent]);

    useEffect(() => {
        if (isIntroClipPublished && isExitClipPublished && !loadingEvent) {
            setAreButtonsVisible(false);
            setIsCheckboxDisabled(true);
            setIsSubmitButtonSelected(true);
        }
    }, [isIntroClipPublished, isExitClipPublished, loadingEvent])

    useEffect(() => {
        setGenVideoDataForExit(props?.genVideoForExit)
        const blob = new Blob([props?.['genVideoForExit']?.['VideoGen response data']], { type: 'video/mp4' });
        const url = URL.createObjectURL(blob);
        setExitUrl(url)
        if (props?.['genVideoForExit']?.['VideoGen response data'] === '') {
            setErrorForExit('Error occurred while generating preview video')
        } else {
            setErrorForExit(null)
        }
    }, [props?.genVideoForExit])

    useEffect(() => {
        setGenVideoDataForIntro(props?.genVideoForIntro)
        const blob = new Blob([props?.['genVideoForIntro']?.['VideoGen response data']], { type: 'video/mp4' });
        const url = URL.createObjectURL(blob);
        setIntroUrl(url)
        if (props?.['genVideoForIntro']?.['VideoGen response data'] === '') {
            setErrorForIntro('Error occurred while generating preview video')
        } else {
            setErrorForIntro(null)
        }

    }, [props?.genVideoForIntro])

    // Opens a modal to ask user if he wants to publish the video or not
    const openModal = () => {
        setIsModalOpen(true);
    };

    // Closes the modal if user cancels the request of publishing the video
    const closeModal = () => {
        setIsModalOpen(false);
    };

    // Opens success modal when video is published
    const openSuccessModal = () => {
        closeModal();
        setIsSuccessModalOpen(true);
    };

    // Closes success modal of the video published
    const closeSuccessModal = () => {
        setIsSuccessModalOpen(false);
    };

    // Stops the propogation
    const stopPropagation = (event) => {
        event.stopPropagation();
    };

    // Checks if the user is authorizing the videos to publish
    const handleGradReelTypeChange = (isChecked) => {
        setIsButtonSelected(isChecked);
        if (isIntroClipPublished) {
            setIsPublishForIntro(false)
        } else {
            setIsPublishForIntro(isChecked);
        }

        if (isExitClipPublished) {
            setIsPublishForExit(false)
        } else {
            setIsPublishForExit(isChecked);
        }
    };

    // Opens a modal when user clicks on publish button
    const handlePublishButtonClick = async (clipType) => {
        if (isPublishForIntro && clipType === 'intro') {
            openModal();
            // setIsIntroClipPublished(true);
            setIsPublishIntroButtonSelected(true);
        } 
        else if (isPublishForExit && clipType === 'exit') {
            openModal();
            // setIsExitClipPublished(true);
            setIsPublishExitButtonSelected(true);
        } 
        else {
            return;
        }
    };
    
    // Publishes the intro and exit clips after generating video
    const handleSuccessButtonClick = async () => {
        closeModal();
        const eventData = { eventId: eventDetails?.eventId, clientId: eventDetails?.clientId, userEmail: eventDetails?.adminEmail}
        const videoData = { eventId: eventDetails?.eventId, clientId: eventDetails?.clientId, new_key: 'no data' }

        if (isPublishIntroButtonSelected) {
            setLoadingForGen(true);
            setLoadingTextForGen('Intro')
            setLoading(true);
            const params = { videotype: 'intro', preview: false, isseasonalsetting: false }
            if (!props.gradreelTypeData?.result?.eventData?.introClipAssetPathSaSUri) {
                await props.generateVideo(videoData, params)
            }
            await props.publishClip({ ...eventData, clipType: 'intro' })
            setIsIntroClipPublished(true);
            setIsPublishIntroButtonSelected(false);
            setIsPublishForIntro(false)
        }
        if (isPublishExitButtonSelected) {
            setLoadingForGen(true);
            setLoadingTextForGen('Exit')
            setLoading(true);
            const params = { videotype: 'exit', preview: false, isseasonalsetting: false }
            if (!props.gradreelTypeData?.result?.eventData?.exitClipAssetPathSaSUri) {
                await props.generateVideo(videoData, params)
            }
            await props.publishClip({ ...eventData, clipType: 'exit' })
            setIsExitClipPublished(true);
            setIsPublishExitButtonSelected(false);
            setIsPublishForExit(false);
        }
        setLoading(false);
        setLoadingForGen(false)

        openSuccessModal();

        setTimeout(() => {
            closeSuccessModal();

            if (isIntroClipPublished && isExitClipPublished) {
                setAreButtonsVisible(false);
                setIsCheckboxDisabled(true);
                setIsSubmitButtonSelected(true);
            }
        }, 3000);
    };

    // Redirects the page to respective pages when user selects to edit intro or exit video
    const handleEditButtonClick = (buttonNumber) => {
        if ((buttonNumber === 1 && isIntroClipPublished) || (buttonNumber === 2 && isExitClipPublished)) {
            // If the clip is published, return early without performing any operation
            return;
        }
        // Use a switch statement or if-else to determine which button was clicked
        switch (buttonNumber) {
            case 1:
                navigate("/CreateIntroClip");
                break;
            case 2:
                navigate("/CreateExitClip");
                break;
            default:
                // Handle other cases or provide a default route
                break;
        }
    };

    const handleFileChange = (file) => {
        try {
            // Transform file into blob URL
            setVideoSrc(URL.createObjectURL(file));
        } catch (error) {
            console.error(error);
        }
    };

    // Submits the pre event as its ready
    const handleSubmit = async (e) => {
        e.preventDefault();
        if(props?.eventState){
            navigate('/PreEventDetailsDashboard')
        } else {
        if (errorForExit || errorForIntro) {
            return;
        }
        if (!props.gradreelTypeData?.result?.eventData?.studentDataUploaded) {
            toastError('Please upload student information.')
            return;
        }
        if (!isIntroClipPublished || !isIntroClipPublished) {
            return;
          }
        // openModal();
        const eventData = { eventId: eventDetails?.eventId, clientId: eventDetails?.clientId, userEmail: clietData.userEmail }
        await props.submitPreEvent(eventData)
    }
    }

    return (
        <div className="Confirm-Pre-Event-Details">
            <div className="div-2">
                <PreEventDetails />

                <div className="frame-19">
                    <div className="frame-20">
                        <div className="section-title">
                            <div className="frame-21">
                                <div className="page-title">
                                    <div className="text-wrapper-3">Confirm</div>
                                </div>
                                <div className="div-3">
                                    <img
                                        className="material-symbols"
                                        alt="Material symbols"
                                        src={homeImage}
                                    />
                                    <img
                                        className="keyboard-arrow-right"
                                        alt="Keyboard arrow right"
                                        src={rightarrow}
                                    />
                                    <div className="text-wrapper-4">Events</div>
                                    <img
                                        className="keyboard-arrow-right"
                                        alt="Keyboard arrow right"
                                        src={rightarrow}
                                    />
                                    <div className="text-wrapper-5">Confirm</div>
                                </div>
                            </div>
                        </div>
                        <div className="frame-22">
                            <div className="frame-23">
                                <StyledEngineProvider injectFirst>
                                    <Stepper gradReelType={props.gradreelTypeData?.result?.eventData?.template}/>
                                </StyledEngineProvider>
                            </div>
                            <div className="frame">
                                <div className="div">
                                    <span className="checkboxtextconfirm">
                                        <input type="checkbox" className="frame-instance-confirm" onChange={(e) => handleGradReelTypeChange(e.target.checked)}
                                            disabled={isCheckboxDisabled} />
                                        <span className="checkbox-label-confirm">I authorize GradImages to use the brand assets and images shared for Graduate Clips creation purpouses.</span>
                                    </span>

                                </div>
                                <div className="frame-wrapper">
                                    <div className="div-3">
                                        {errorMessageForIntro ? (
                                            <div className="loader" style={{fontFamily: 'Poppins'}}>
                                                Please configure intro video for preview.
                                            </div>
                                        ) : introUrlLoading ? (
                                            <div className="loader">
                                                {/* Generating intro video for preview... */}
                                            </div>
                                        ) : (
                                            <>
                                                {errorForIntro ? (
                                                    <div className="error-message">
                                                        <p style={{fontFamily: 'Poppins'}}>{errorForIntro}</p>
                                                    </div>
                                                ) : (
                                                    <div className="div-4">
                                                        <div className="text-wrapper">
                                                            Intro Clip Preview
                                                        </div>
                                                        <div className="div-5">
                                                            <div className="group">
                                                                <div className="overlap-group">
                                                                    <Player>
                                                                        <source
                                                                            src={
                                                                                props.gradreelTypeData?.result?.eventData?.introClipAssetPathSaSUri ||
                                                                                `data:video/mp4;base64,${props?.['genVideoForIntro']?.['VideoGen response data']}`
                                                                            }
                                                                            type="video/mp4"
                                                                        />
                                                                    </Player>
                                                                </div>
                                                            </div>
                                                            <div className="div-7">
                                                                {areButtonsVisible && (
                                                                    <>
                                                                        <button
                                                                            className={`primary-BTN ${!isIntroClipPublished ? "" : "disabled"}`}
                                                                            onClick={() => handleEditButtonClick(1)}
                                                                        >
                                                                            <span className="button-text">Edit</span>
                                                                        </button>
                                                                        <button
                                                                            className={`button-text-wrapper ${isPublishForIntro ? "" : "disabled"}`}
                                                                            onClick={() => handlePublishButtonClick('intro')}
                                                                        >
                                                                            <span className="button">Publish</span>
                                                                        </button>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                        )}


                                        {errorMessageForExit ? (
                                            <div className="loader" style={{fontFamily: 'Poppins'}}>
                                                Please configure exit video for preview.
                                            </div>
                                        ) : (
                                            <>
                                                {exitUrlLoading ? (
                                                    <div className="loader">
                                                        {/* Generating exit video for preview... */}
                                                    </div>
                                                ) : (
                                                    <>
                                                        {errorForExit ? (
                                                            <div className="error-message">
                                                                <p style={{fontFamily: 'Poppins'}}>{errorForExit}</p>
                                                            </div>
                                                        ) : (
                                                            <div className="div-4">
                                                                <div className="text-wrapper">
                                                                    Exit Clip Preview
                                                                </div>
                                                                <div className="div-5">
                                                                    <div className="group">
                                                                        <div className="overlap-group">
                                                                            <Player>
                                                                                <source
                                                                                    src={
                                                                                        props.gradreelTypeData?.result?.eventData?.exitClipAssetPathSaSUri ||
                                                                                        `data:video/mp4;base64,${props?.['genVideoForExit']?.['VideoGen response data']}`
                                                                                    }
                                                                                    type="video/mp4"
                                                                                />
                                                                            </Player>
                                                                        </div>
                                                                    </div>
                                                                    <div className="div-7">
                                                                        {areButtonsVisible && (
                                                                            <>
                                                                                <button
                                                                                    className={`primary-BTN ${!isExitClipPublished ? "" : "disabled"}`}
                                                                                    onClick={() => handleEditButtonClick(2)}
                                                                                >
                                                                                    <span className="button-text">Edit</span>
                                                                                </button>
                                                                                <button
                                                                                    className={`button-text-wrapper ${isPublishForExit ? "" : "disabled"}`}
                                                                                    onClick={() => handlePublishButtonClick('exit')}
                                                                                >
                                                                                    <span className="button">Publish</span>
                                                                                </button>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </>
                                                )}
                                            </>
                                        )}


                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="frame-36">
                        <Link to="/CreateExitClip" className="button-text-2">
                            <div className="primary-BTN-2">
                                Back
                            </div>
                        </Link>
                        <Link
                            className="button-text-3"
                            onClick={handleSubmit}
                        >
                            <div className={`primary-BTN-3 ${isSubmitButtonSelected ? "" : "disabled"}`}>
                                Submit
                            </div>
                        </Link>
                    </div>

                </div>
            </div>
            {isModalOpen && (
                <div className="video-modal" onClick={closeModal}>
                    <div className="video-player-container" onClick={stopPropagation}>
                        <button className="close-button" onClick={closeModal}>
                            &times;
                        </button>
                        <div className="frame-model">
                            <div className="div-model">
                                <Property1Variant4 className="failed-loader" color="#EA690C" />
                                <p className="once-published">
                                    <span className="text-wrapper">
                                        Once published, videos cannot be edited further.
                                        <br />
                                    </span>
                                    <span className="span">Do you want to continue?</span>
                                </p>
                            </div>
                            <div className="div-2-model">
                                <div className="primary-BTN">
                                    <div className="button-text" onClick={closeModal}>No</div>
                                </div>
                                <div className="button-text-wrapper" onClick={handleSuccessButtonClick}>
                                    <div className="button-text-2">Yes</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {isSuccessModalOpen && (
                <div className="video-modal" onClick={closeSuccessModal}>
                    <div className="video-player-container" onClick={stopPropagation}>
                        <button className="close-button" onClick={closeSuccessModal}>
                            &times;
                        </button>
                        <div className="frame-success-model">
                            <PropertyGroup
                                className="sucess-loader"
                                ellipseClassName="property-group-instance"
                                icon={<FiRrCheck1 className="fi-rr-check-instance" />}
                                overlapGroupClassName="property-1-group-37324"
                            />
                            <div className="text-wrapper-success">Successful</div>
                        </div>
                    </div>
                </div>
            )}
            {loading && (
                <div className="video-modal">
                <div className="video-player-container">
                    
                    <div className="frame-success-model">
                    <Box sx={{justifyContent: 'center', marginTop: '10px', width: '50%' }}>
                        <LinearProgress />
     </Box>
                        {loadingForGen ? <div style={{ textAlign: 'center', marginTop: '10px', fontFamily: 'Poppins', fontSize: '1.2em' }} className="text-wrapper">Preparing your {loadingTextForGen} Clip for generating you GradReels!</div>
                     : <div style={{ textAlign: 'center', marginTop: '10px', fontFamily: 'Poppins', fontSize: '1.2em' }} className="text-wrapper">We're crafting your personalized video based on your input. <br /><br />Sit back, relax, and we'll let you know when it's ready!</div>
                    }
                        {/* <div style={{ textAlign: 'center', marginTop: '10px', fontFamily: 'Poppins', fontSize: '1.2em' }} className="text-wrapper">We're using AI to craft your personalized video based on your input. <br /><br />Sit back, relax, and we'll let you know when it's ready!</div> */}
                    </div>
                </div>
            </div>
            )}
            
        </div>
    );
};


const mapStateToProps = (state) => {
    return {
        eventSuccess: state.preEvent.eventSuccess,
        eventlist: state.preEvent.eventlist,
        eventDetails: state.preEvent.eventDetails,
        gradreelTypeData: state.preEvent.gradreelType,
        genVideoForIntro: state.preEvent.genVideoForIntro,
        genVideoForExit: state.preEvent.genVideoForExit,
        eventState: state.preEvent.eventState,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        generateVideo: (videoData, params) => dispatch(generateVideo(videoData, params)),
        loadevent: (eventData) => dispatch(FetchGradreelType(eventData)),
        publishClip: (eventData) => dispatch(publishClip(eventData)),
        submitPreEvent: (eventData) => dispatch(submitPreEvent(eventData)),
        setEventSuccess: (flag) => dispatch(setEventSuccess(flag)),

    };
};

const ConnectedConfirmPreEventDetails = connect(mapStateToProps, mapDispatchToProps)(ConfirmPreEventDetails);

export { ConnectedConfirmPreEventDetails as ConfirmPreEventDetails };