/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const FiRrGraduationCap6 = ({ color = "#4A3AFF", className }) => {
  return (
    <svg
      className={`fi-rr-graduation-cap-6 ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_732_317)">
        <path
          className="path"
          d="M22.057 5.229L14.683 1.716C13.0598 0.746438 11.0395 0.729235 9.40003 1.671L1.94306 5.229C1.91508 5.24302 1.88606 5.25802 1.85906 5.274C0.0894372 6.28584 -0.524907 8.54067 0.486937 10.3103C0.829921 10.9102 1.33444 11.4017 1.94306 11.729L4.00008 12.709V17.609C4.0013 19.8001 5.427 21.7358 7.51908 22.387C8.97497 22.8082 10.4846 23.0144 12.0001 22.999C13.5154 23.016 15.025 22.8115 16.4811 22.392C18.5732 21.7409 19.9989 19.8051 20.0001 17.614V12.707L22.0001 11.751V19.999C22.0001 20.5513 22.4478 20.999 23.0001 20.999C23.5524 20.999 24.0001 20.5513 24.0001 19.999V7.99898C24.0067 6.82472 23.0796 5.73994 22.057 5.229ZM18 17.614C18.0006 18.9246 17.1502 20.0839 15.9 20.477C14.6323 20.8393 13.3185 21.0151 12 20.999C10.6816 21.0151 9.36783 20.8393 8.10005 20.477C6.84984 20.0838 5.99953 18.9246 6.00005 17.614V13.662L9.31706 15.2421C10.1355 15.7281 11.0702 15.9834 12.0221 15.981C12.9281 15.9875 13.8188 15.7476 14.5991 15.2871L18 13.662V17.614ZM21.2001 9.924L13.6581 13.524C12.6065 14.1363 11.303 14.119 10.2681 13.479L2.88905 9.969C2.06653 9.52547 1.75931 8.49914 2.20284 7.67667C2.35284 7.39852 2.57775 7.16794 2.85206 7.011L10.347 3.43102C11.399 2.82005 12.7017 2.83734 13.737 3.47602L21.111 6.98902C21.6533 7.29014 21.9926 7.85878 22 8.47903C22.001 9.06656 21.6985 9.61294 21.2001 9.924Z"
          fill={color}
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_732_317">
          <rect className="rect" fill="white" height="24" width="24" />
        </clipPath>
      </defs>
    </svg>
  );
};

FiRrGraduationCap6.propTypes = {
  color: PropTypes.string,
};
