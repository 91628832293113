/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const PlayIcon2 = ({ className }) => {
  return (
    <svg
      className={`play-icon-2 ${className}`}
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M7.98014 14.6663C11.662 14.6663 14.6468 11.6816 14.6468 7.99967C14.6468 4.31778 11.662 1.33301 7.98014 1.33301C4.29824 1.33301 1.31348 4.31778 1.31348 7.99967C1.31348 11.6816 4.29824 14.6663 7.98014 14.6663Z"
        stroke="#292D32"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        className="path"
        d="M5.82666 8.15374V7.04041C5.82666 5.65374 6.80666 5.08707 8.00666 5.78041L8.97333 6.34041L9.93999 6.90041C11.14 7.59374 11.14 8.72708 9.93999 9.42041L8.97333 9.98041L8.00666 10.5404C6.80666 11.2337 5.82666 10.6671 5.82666 9.28041V8.15374Z"
        stroke="#292D32"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
      />
    </svg>
  );
};
