import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Check from '@mui/icons-material/Check';
import { styled } from '@mui/material/styles';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepButton from '@mui/material/StepButton';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';

import Tooltip from '@mui/material/Tooltip';
import { completeStep } from '../../store/Actions/PreEventAction';

const stepsStandard = ['Event Details', 'Select Template', 'Media Details', 'Create Intro Clip', 'Create Exit Clip', 'Confirm'];
const stepUrlsStandard = ['/EventOverview', '/SelectTemplate', '/MediaDetails', '/CreateIntroClip', '/CreateExitClip', '/ConfirmPreEventDetails'];

const stepsCustom = ['Event Details', 'Select Template', 'Media Details', 'Create Intro Clip', 'Student Clip', 'Create Exit Clip', 'Confirm'];
const stepUrlsCustom = ['/EventOverview', '/SelectTemplate', '/MediaDetails', '/CreateIntroClip', '/StudentClip', '/CreateExitClip', '/ConfirmPreEventDetails'];

const QontoConnector = styled(StepConnector)((props) => {
  return ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      left: 'calc(-50% + 16px)',
      right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
          borderColor: '#1976d2',
      },
  },
  [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
          borderColor: '#008744',
      },
  },
  [`&.${stepConnectorClasses.disabled}`]: {
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#eaeaf0',
    },
},
    [`& .${stepConnectorClasses.line}`]: {
    
      borderColor: props.completed[1] === null || props.completed[1] === false ? '#eaeaf0' : '#e67300',
      borderTopWidth: 3,
      borderRadius: 1,
  },

})});


const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    color: '#784af4',
  }),
  '& .QontoStepIcon-completedIcon': {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18,
  },
  '& .QontoStepIcon-circle': {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

export default function HorizontalNonLinearStepper({ gradReelType, handleGradReelTypeChange }) {
  const [activeStep, setActiveStep] = React.useState(0);
  // const [completed, setCompleted] = React.useState({});
  const location = useLocation();// Default to standard

  const completed = useSelector((state) => state.preEvent.completed);
  const dispatch = useDispatch();

  React.useEffect(() => {
    const index = gradReelType === 'Custom'
      ? stepUrlsCustom.findIndex((url) => location.pathname.includes(url))
      : stepUrlsStandard.findIndex((url) => location.pathname.includes(url));

    if (index > 0) {
      setActiveStep(index);
      for (let i = 0; i < index; i++) {
        if (!(i in completed) || completed[i] === null) {
          dispatch(completeStep(i, false));
        }
      }
    }
  }, [location, gradReelType]);

  const totalSteps = () => {
    return gradReelType === 'Custom' ? stepsCustom.length : stepsStandard.length;
  };



  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? (gradReelType === 'Custom'
          ? stepsCustom
          : stepsStandard
        ).findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper nonLinear activeStep={activeStep} alternativeLabel>
        {gradReelType === 'Custom' ? (
          stepsCustom.map((label, index) => (
            <Step key={label} completed={completed[index]}
            sx={{
              '& .MuiStepLabel-root .Mui-completed': {
                color: '#008744',
                border: '0px', // circle color (COMPLETED)
              },
              '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
              {
                color: 'grey.500', // Just text label (COMPLETED)
              },

              '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
              {
                color: 'rgba(0, 0, 0, 0.87)', // Just text label (ACTIVE)
              },
              '& .MuiStepLabel-root .Mui-active': {
                color: '#1976d2',
              },
              '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
                fill: '#FFF', // circle's number (ACTIVE)
              },
              '& .MuiStepIcon-root': {
                color: activeStep === index ? '#1976d2' : completed[index] === false ? '#fa9150' : '#FFF',
                borderRadius: '50%',
                border: activeStep === index ? '' : completed[index] === false ? '' : '1px solid rgba(0, 0, 0, 0.29)',
              },
              '& .MuiStepIcon-text': {
                color: activeStep === index ? '#FFF' : completed[index] === false ? 'unset' : '#000',
                fill: activeStep === index ? '#FFF' : completed[index] === false ? '#FFF' : 'unset',
              },
              "& .MuiStepConnector-line": {
                borderColor: '#fa9150',
                borderTopWidth: "4px",
              },

              [`& .${stepConnectorClasses.line}`]: {
                borderColor: activeStep === index ? '#1976d2' : completed[index] === false ? '#fa9150' : '#eaeaf0',
                borderTopWidth: 3,
                borderRadius: 1,
              },

              [`&.${stepConnectorClasses.active}`]: {
                [`& .${stepConnectorClasses.line}`]: {
                  borderColor: '#1976d2',
                },
              },
              [`&.${stepConnectorClasses.completed}`]: {
                [`& .${stepConnectorClasses.line}`]: {
                  borderColor: '#008744',
                },
              },

              [`&.${stepConnectorClasses.disabled}`]: {
                [`& .${stepConnectorClasses.line}`]: {
                  borderColor: '#eaeaf0',
                },
              },
            }}>
              <Tooltip title={completed[index] ? 'Completed' : 'Incomplete'}>
                <StepButton component={Link} onClick={handleStep(index)} to={stepUrlsCustom[index]}>
                  <StepLabel>{label}</StepLabel>
                </StepButton>
              </Tooltip>
            </Step>
          ))
        ) : (
          stepsStandard.map((label, index) => (
            <Step key={label} completed={completed[index]}
            sx={{
              '& .MuiStepLabel-root .Mui-completed': {
                color: '#008744',
                border: '0px', // circle color (COMPLETED)
              },
              '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
              {
                color: 'grey.500', // Just text label (COMPLETED)
              },

              '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
              {
                color: 'rgba(0, 0, 0, 0.87)', // Just text label (ACTIVE)
              },
              '& .MuiStepLabel-root .Mui-active': {
                color: '#1976d2',
              },
              '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
                fill: '#FFF', // circle's number (ACTIVE)
              },
              '& .MuiStepIcon-root': {
                color: activeStep === index ? '#1976d2' : completed[index] === false ? '#fa9150' : '#FFF',
                borderRadius: '50%',
                border: activeStep === index ? '' : completed[index] === false ? '' : '1px solid rgba(0, 0, 0, 0.29)',
              },
              '& .MuiStepIcon-text': {
                color: activeStep === index ? '#FFF' : completed[index] === false ? 'unset' : '#000',
                fill: activeStep === index ? '#FFF' : completed[index] === false ? '#FFF' : 'unset',
              },
              "& .MuiStepConnector-line": {
                borderColor: '#fa9150',
                borderTopWidth: "4px",
              },

              [`& .${stepConnectorClasses.line}`]: {
                borderColor: activeStep === index ? '#1976d2' : completed[index] === false ? '#fa9150' : '#eaeaf0',
                borderTopWidth: 3,
                borderRadius: 1,
              },

              [`&.${stepConnectorClasses.active}`]: {
                [`& .${stepConnectorClasses.line}`]: {
                  borderColor: '#1976d2',
                },
              },
              [`&.${stepConnectorClasses.completed}`]: {
                [`& .${stepConnectorClasses.line}`]: {
                  borderColor: '#008744',
                },
              },

              [`&.${stepConnectorClasses.disabled}`]: {
                [`& .${stepConnectorClasses.line}`]: {
                  borderColor: '#eaeaf0',
                },
              },
            }}>
              <Tooltip title={completed[index] ? 'Completed' : 'Incomplete'}>
                <StepButton component={Link} onClick={handleStep(index)} to={stepUrlsStandard[index]}>
                  <StepLabel>{label}</StepLabel>
                </StepButton>
              </Tooltip>
            </Step>
          ))
        )}
      </Stepper>
    </Box>
  );
}
