import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { connect } from "react-redux";
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import { Box, CircularProgress } from "@mui/material";

import "./style.css";

import { PostEventDetails } from "../PostEventDetails";

import { RadioButtons4 } from "../../../icons/RadioButtons4";
import { RadioButtons5 } from "../../../icons/RadioButtons5";

import homeImage from '../../../icons/ImageIcons/material-symbols_home-rounded.svg';
import rightarrow from '../../../icons/ImageIcons/keyboard-arrow-right.svg';

import { Group } from "../../../components/Group";
import { UploadMediaClipSection } from "../../../components/UploadMediaClipSection";

import { getClientData, toastError, toastSuccess } from "../../../constants/Constants";
import { dispatchPostRequest, fetchPostEventData, getCeremonyVideoUri, initializeValues, setEventSuccess, submitPostEvent } from "../../../store/Actions/PostEventAction";
import { FetchGradreelType } from "../../../store/Actions/PreEventAction";
import UploadProgressModal from "../../../components/UploadProgressModal/UploadProgressModal";

const PostEventOverview = ({ loading, initializeValues, eventMaster, postEventDetails, postEventData, getCeremonyVideoUri, ceremonyVideoUri, submitPostEvent, dispatchPostRequest, eventSuccess, fetchPostEventData, getEventMaster, uploadedProgress, uploadInProgress, setEventSuccess }) => {

    const navigate = useNavigate();
    const [dateTime, setDateTime] = useState(new Date(postEventDetails?.eventDateTime));
    const [eventId, setEventId] = useState(postEventDetails?.eventId);
    const [eventName, setEventName] = useState(postEventDetails?.eventName);
    const [selectedFile, setSelectedFile] = useState(null);
    const [isButtonSelected, setIsButtonSelected] = useState(false);
    const [streamingURL, setStreamingURL] = useState();
    const [ceremonyVideoUrl, setCeremonyVideoUrl] = useState(ceremonyVideoUri?.result)
    const [uploadProgress, setUploadProgress] = useState(uploadedProgress || 0);
    const [eventState, setEventState] = useState('');

    const [isStreamingEvent, setIsStreamingEvent] = useState();
    const [selectedRadioButton, setSelectedRadioButton] = useState(isStreamingEvent ? "variant-2" : "default");

    const date = dateTime.toLocaleDateString();
    const time = dateTime.toLocaleTimeString();

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (!postEventDetails) {
                    navigate('/PostEventDetailsDashboard');
                    return;
                }
                // Dispatch all three API calls simultaneously
                await Promise.all([
                    fetchPostEventData(postEventDetails.clientId, postEventDetails.eventId),
                    getEventMaster({ clientId: postEventDetails.clientId, eventId: postEventDetails.eventId }),
                    getCeremonyVideoUri(postEventDetails?.eventId)
                ]);
            } catch (error) {
                console.error('Error fetching data:', error);
                // Handle error if necessary
            }
        };
        initializeValues();
        fetchData();
    }, []);

    useEffect(() => {
        if (eventSuccess) {
            setEventSuccess(false);
            toastSuccess(`Post event details submitted successfully`);
            // setTimeout(() => {
                const path = isButtonSelected ? "/postEventDetailsDashboard" : "#";
                navigate(path);
            // }, 0);
        }
    }, [eventSuccess]);

    useEffect(() => {
        setStreamingURL(postEventData?.result?.postEventData?.streamingUrl)
        setIsStreamingEvent(postEventData?.result?.postEventData?.isStreamingEvent)
    }, [postEventData?.result?.postEventData])

    useEffect(() => {
        setCeremonyVideoUrl(ceremonyVideoUri?.result)
    }, [ceremonyVideoUri?.result])

    useEffect(() => {
        setEventState(eventMaster?.result?.eventData?.eventState)
        // if (['ProcessingInProgress', 'ProcessingFailed', 'PartialSuccess', 'AssetPublished', 'RequestForProcessing'].includes(eventMaster?.result?.eventData?.eventState)) {
        //     setIsButtonSelected(false);
        // }
        // if (eventMaster?.result?.eventData?.eventStage === 'PostEvent' && eventMaster?.result?.eventData?.eventState !== "RequestForProcessing") {
        //     setIsButtonSelected(false)
        // }
    }, [eventMaster?.result?.eventData?.eventState])

    useEffect(() => {
        if (!uploadInProgress) {
            setIsButtonSelected(true);
        }

    }, [uploadInProgress])

    // Sets the ceremony video file selected by user
    const handleFileChange = (file) => {
        setSelectedFile(file);
        setIsButtonSelected(true);
        setIsStreamingEvent(false);
    };

    // Sets Url added by user
    const streamingURLChange = (url) => {
        setStreamingURL(url)
    }

    // Uploads the video or url and submit post event
    const handleSavingPostEvent = async (e) => {
        e.preventDefault();
        if (['ProcessingInProgress', 'ProcessingFailed', 'PartialSuccess', 'AssetPublished', 'RequestForProcessing'].includes(eventMaster?.result?.eventData?.eventState)) {
            navigate('/postEventDetailsDashboard');
        } else {

            if (!isButtonSelected) {
                return;
            }
            setUploadProgress(0);

            const clientData = getClientData();
            if (clientData) {
                if (selectedFile) {
                    // setIsButtonSelected(false);
                    await dispatchPostRequest(ceremonyVideoUrl, selectedFile, postEventData);
                    setIsButtonSelected(true);
                } else if (selectedRadioButton === 'variant-2' || streamingURL) {
                    // setIsButtonSelected(false);
                    const eventData = {
                        eventId: postEventData.result.postEventData.eventId,
                        clientId: postEventData.result.postEventData.clientId,
                        videoFileName: "",
                        isStreamingEvent: true,
                        streamingUrl: streamingURL,
                        submittedBy: clientData.userEmail
                    };
                    await submitPostEvent(eventData);
                    setIsButtonSelected(true);
                } else {
                    toastError('Please select a file or streaming URL.')
                }
            };
        }
    };

    // Redirects to handshake page when user clicks on it
    const handleHandshake = (e) => {
        e.preventDefault();
        window.open(`/HandshakeTimestampComponent?eventId=${postEventDetails?.eventId}&clientId=${postEventDetails?.clientId}`, '_blank');
    }

    return (

        <div className="post-event-overview">
            <div className="div-2">
                <PostEventDetails
                    isStreamingEvent={isStreamingEvent}
                    selectedRadioButton={selectedRadioButton}
                />
                <div className="frame-19">
                    <div className="frame-20">
                        <div className="section-title">
                            <div className="frame-21">
                                <div className="page-title">
                                    <div className="text-wrapper-3">Post Event Details</div>
                                </div>
                                <div className="breadcrum">
                                    <img
                                        className="material-symbols"
                                        alt="Material symbols"
                                        src={homeImage}
                                    />
                                    <img
                                        className="keyboard-arrow-right"
                                        alt="Keyboard arrow right"
                                        src={rightarrow}
                                    />
                                    <div className="text-wrapper-4">Events</div>
                                    <img
                                        className="keyboard-arrow-right"
                                        alt="Keyboard arrow right"
                                        src={rightarrow}
                                    />
                                    <div className="text-wrapper-5">Post Event Details</div>
                                </div>
                            </div>
                        </div>
                        <div className="frame-22">

                            <div className="frame-27">
                                <div className="frame-28">
                                    <div className="frame-29">
                                        <div className="text-wrapper-6">Event details</div>
                                    </div>

                                    <div className="frame-30">
                                        <div className="frame-31">

                                            <Group
                                                className="group-37146"
                                                divClassName="group-37146-instance"
                                                divClassNameOverride="group-2"
                                                frameClassName="group-3"
                                                text={eventName}
                                                text1="Event Name"
                                                visible={false}
                                                visible1={false}
                                            />
                                            <Group
                                                className="group-37146"
                                                divClassName="group-37146-instance"
                                                divClassNameOverride="group-2"
                                                frameClassName="group-3"
                                                text={eventId}
                                                text1="Event ID"
                                                visible={false}
                                                visible1={false}
                                            />
                                        </div>

                                        <div className="frame-31">
                                            <Group
                                                className="group-37146"
                                                divClassName="group-37146-instance"
                                                divClassNameOverride="group-2"
                                                frameClassName="group-3"
                                                text={date}
                                                text1="Event Date"
                                                visible={false}
                                                visible1={false}
                                            />
                                            <Group
                                                className="group-37146"
                                                divClassName="group-37146-instance"
                                                divClassNameOverride="group-2"
                                                frameClassName="group-3"
                                                text={time}
                                                text1="Event Time"
                                                visible={false}
                                                visible1={false}
                                            />
                                        </div>
                                    </div>
                                    <div className="frame-31">

                                        <div className="frame-33">
                                            <div className={"frame-34"}>
                                                <UploadMediaClipSection
                                                    className="design-component-instance-node-2"
                                                    frameWrapperCheckboxPropertyFiRrClassName="audio-clip-section-instance"
                                                    override={<RadioButtons5 className="icon-instance-node" />}
                                                    property1="default"
                                                    radioBtnMusicClipRadioOptionIcon={<RadioButtons4 className="icon-instance-node" />}
                                                    onFileChange={handleFileChange}
                                                    selectedRadioButton={selectedRadioButton}
                                                    onRadioButtonChange={(value) => setSelectedRadioButton(value)}
                                                    isStreamingEvent={isStreamingEvent}
                                                    streamingURLChange={streamingURLChange}
                                                    eventState={eventState}
                                                    submitButtonEnabled={setIsButtonSelected}
                                                />
                                            </div> <br /><br />
                                            {uploadProgress > 0 &&
                                                <UploadProgressModal />
                                            }
                                            {/* {uploadProgress === 0 && <p style={{ fontFamily: 'Poppins', color: 'blue' }}>Preparing for upload...</p>}

                                            {uploadProgress > 0 && uploadProgress < 100 && (
                                                <Box sx={{ width: '100%' }}>
                                                    <LinearProgressWithLabel value={uploadProgress} />
                                                </Box>
                                            )}

                                            {uploadProgress === 100 && <p style={{ fontFamily: 'Poppins', color: '#3a7848' }}>File uploaded successfully!</p>} */}
                                        </div>
                                    </div>
                                    <br /><br />
                                    <div style={{ fontFamily: 'Poppins' }}>To Upload Handshake Data &nbsp;
                                        <Link className="event-ID group-4" style={{ fontFamily: 'Poppins', color: 'blue' }} onClick={handleHandshake}>Click Here</Link>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                    <div className="primary-BTN-wrapper">
                        <div className="primary-BTN-2">
                            <Link to="/PostEventDetailsDashboard" className="button-text-2">Back</Link>
                        </div>
                        <div className={`primary-BTN ${isButtonSelected ? "" : "disabled"}`} onClick={handleSavingPostEvent}>
                            <Link className="button-text">Submit</Link>
                        </div>
                    </div>
                    {loading && (
                        <div className="loader-container">
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px' }}>
                                <CircularProgress />
                            </Box>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

function LinearProgressWithLabel(props) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

const mapStateToProps = (state) => {
    return {
        loading: state.postEvent.loading,
        postEventDetails: state.postEvent.postEventDetails,
        postEventData: state.postEvent.postEventData,
        ceremonyVideoUri: state.postEvent.ceremonyVideoUri,
        eventMaster: state.preEvent.gradreelType,
        eventSuccess: state.postEvent.eventSuccess,
        uploadedProgress: state.postEvent.uploadProgress,
        uploadInProgress: state.postEvent.uploadInProgress,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        initializeValues: () => dispatch(initializeValues()),
        getCeremonyVideoUri: (file, eventData) => dispatch(getCeremonyVideoUri(file, eventData)),
        submitPostEvent: (eventData) => dispatch(submitPostEvent(eventData)),
        dispatchPostRequest: (url, file, eventData) => dispatch(dispatchPostRequest(url, file, eventData)),
        setEventSuccess: (flag) => dispatch(setEventSuccess(flag)),
        fetchPostEventData: (clientId, eventId) => dispatch(fetchPostEventData(clientId, eventId)),
        getEventMaster: (eventData) => dispatch(FetchGradreelType(eventData)),
    };
};

const ConnectedPostEventOverview = connect(mapStateToProps, mapDispatchToProps)(PostEventOverview);

export { ConnectedPostEventOverview as PostEventOverview };