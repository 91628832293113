// import React, { useState } from 'react';

// export const ExcelUploadComponent = () => {
//   const [file, setFile] = useState(null);
//   const [timestamp, setTimestamp] = useState('');

//   const handleFileChange = (event) => {
//     const selectedFile = event.target.files[0];
//     setFile(selectedFile);
//   };

//   const handleTimestampChange = (event) => {
//     const newTimestamp = event.target.value;
//     setTimestamp(newTimestamp);
//   };

//   const handleUpload = () => {
//     // You can perform upload logic here, e.g., send file and timestamp to the server
//     console.log('File:', file);
//     console.log('Timestamp:', timestamp);
//     // Reset state after upload if needed
//     setFile(null);
//     setTimestamp('');
//   };

//   return (
//     <div>
//       <h2>Handshake Data:</h2>
//       <div>
//         <div className="frame-17">Upload Excel File (CSV): 

        
//         </div>
//         <input
//           type="file"
//           id="fileInput"
//           accept=".csv"
//           onChange={handleFileChange}
//         />
//       </div>
//       <div>
//         <label htmlFor="timestampInput">Timestamp: </label>
//         <input
//           type="text"
//           id="timestampInput"
//           value={timestamp}
//           onChange={handleTimestampChange}
//         />
//       </div>
//       <button onClick={handleUpload}>Upload</button>
//     </div>
//   );
// };

// export default ExcelUploadComponent;



import PropTypes from "prop-types";

import React, { useState }  from "react";
import { Expand } from "../Expand";
import { FrameWrapper3 } from "../FrameWrapper3";
import "./style.css";

import { FiRrCloudUpload } from "./../../icons/FiRrCloudUpload";
import { toastError } from "../../constants/Constants";
export const ExcelUploadComponent = ({
  property1,
  className,
  divClassName,
  text = "Handshake Data",
  text1 = "",
  visible = true,
  visibleupload = true,
  frameWrapperVisible,
  frameWrapperVisibleinfo,
  frameWrapperText = "Please upload MP3 format only.",     
  expandPolygon = "/img/polygon-1-3.svg",
  expandPolygonClassName,
  frameWrapperCheckboxPropertyFiRrClassName,
  getSelectedFile = null
}) => {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleDivClick = () => {
    document.getElementById('fileInput').click();
  };
  const [set, setSrc] = useState("");
  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Check if the selected file's type is allowed
      const allowedTypes = ['text/csv', 'application/vnd.ms-excel'];
      if (allowedTypes.includes(file.type)) {
        setSelectedFile(file);
        getSelectedFile(file);
        try {
          // Transform file into blob URL
          setSrc(URL.createObjectURL(file));
        } catch (error) {
          console.error(error);
        }
      }
     else {
        // If the selected file type is not allowed, display an error message or handle it accordingly
        toastError('Invalid file type. Please select a .csv file.');
      }
    }
  };

  return (
    <div className={`excel-upload-component property-1-0-${property1} ${className}`}>
      <div className="frame-15">
        <div className="event-ID-wrapper">
          <div className={`event-ID ${divClassName}`}>{text}</div>
        </div>
        <div className="frame-16">
          <div className="frame-17" onClick={handleDivClick}>
            
          {["default", "variant-3"].includes(property1) && (
              <>
                <div className="frame-18">
                <div className="element-wrapper">
                <div className="element" style={{fontFamily: 'Poppins'}}>{selectedFile ? selectedFile.name : text1}</div>
                <input
                id="fileInput"
                type="file"
                className="element"
                name="FileUpload"
                accept=".csv"
                onChange={handleFileInputChange}
                style={{ display: 'none' }}
              />
            
                </div>
                </div>{visibleupload &&<FiRrCloudUpload />}
                <>{visible && <Expand polygon={expandPolygon} polygonClassName={expandPolygonClassName} />}</>
              </>
            )}

            {property1 === "variant-2" && (
              <>
                <div className="frame-19">
                  <div className="frame-18">
                    <div className="text-wrapper-4">{text1}</div>
                  </div>
                  <Expand polygon="/img/polygon-1-3.svg" />
                </div>
                <div className="frame-20"> 
                  
                </div>
              </>
            )}
          </div> 
          <FrameWrapper3
            checkboxPropertyFiRrClassName={frameWrapperCheckboxPropertyFiRrClassName}
            className="frame-1027"
            text={frameWrapperText}
            visible={frameWrapperVisible}
            visibleinfo={frameWrapperVisibleinfo}
          /> 
        </div>
      </div>
    </div>
  );
};

ExcelUploadComponent.propTypes = {
  property1: PropTypes.oneOf(["variant-2", "variant-3", "default"]),
  text: PropTypes.string,
  text1: PropTypes.string,
  visible: PropTypes.bool,
  frameWrapperVisible: PropTypes.bool,
  frameWrapperText: PropTypes.string,
  expandPolygon: PropTypes.string,
};
