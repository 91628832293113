import React, { useEffect, useState } from "react";
import { TimestampFieldComponent } from "../../components/TimestampFieldComponent";
import "./style.css";
import { Box, CircularProgress } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import GaugeComponent from "react-gauge-component";
import { connect } from "react-redux";
import { ExcelUploadComponent } from "../../components/ExcelUploadComponent/ExcelUploadComponent";
import {
    getClientData,
    toastError,
    toastSuccess,
} from "../../constants/Constants";
import {
    getFirstCandidateAnnouncementTime,
    getHandshakeDetails,
    invokeGradReelProcessing,
    postHandshakeDetailsWithCsv,
    postHandshakeDetailsWithInput,
} from "../../store/Actions/PostEventAction";
import { FetchGradreelType } from "../../store/Actions/PreEventAction";

const HandshakeTimestampComponent = ({
  visible = true,
  Loading,
  handshakeDetails,
  announcementTime,
  getHandshakeDetails,
  getFirstCandidateAnnouncementTime,
  postHandshakeDetailsWithCsv,
  postHandshakeDetailsWithInput,
  invokeGradReelProcessing,
  eventMasterData,
  getEventMaster
}) => {
  const [loading, setLoading] = useState(Loading);
  const [selectedFile, setSelectedFile] = useState("");
  const [timestamp, setTimestamp] = useState("");
  const [endHandshakeTimestamp, setEndHandshakeTimestamp] = useState(null);
  const [beginningHandshakeTimestamp, setBeginningHandshakeTimestamp] =
    useState(null);
  const [isCsvButtonSelected, setIsCsvButtonSelected] = useState(false);
  const [isButtonSelected, setIsButtonSelected] = useState(false);
  const [status, setStatus] = useState(
    handshakeDetails?.result?.isDataAvailable
  );
  const queryParams = new URLSearchParams(window.location.search);
  const eventId = queryParams.get("eventId");
  const clientId = queryParams.get("clientId");
  const theme = useTheme();
  const [countdown, setCountdown] = useState(5);
  const [showCountdown, setShowCountdown] = useState(false);

  useEffect(() => {
    // setLoading(Loading);
    setStatus(handshakeDetails?.result?.isDataAvailable);
    //setTimestamp(announcementTime?.result?.confidenceScore > 0 ? announcementTime?.result.firstStudentAnnouncementTime : '');
  }, [Loading, handshakeDetails, announcementTime]);

  useEffect(() => {
    (async () => {
      try {
        const data = await getHandshakeDetails(eventId, clientId);

        if (data.result.isDataAvailable) {
          toastSuccess("Handshake Details are available.");
        }
      } catch (error) {
        console.error("Error loading event:", error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (handshakeDetails?.result?.isDataAvailable) {
      (async () => {
        try {
          setLoading(true);
          const announcementTimeData = await getFirstCandidateAnnouncementTime(
            eventId,
            clientId
          );
          await getEventMaster({ clientId: clientId, eventId: eventId })
          // if (!announcementTimeData?.result.isDataAvailable) {
          //   toastError(announcementTimeData?.result.message);
          // }
        } catch (error) {
          console.error("Error loading announcement time:", error);
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [handshakeDetails]);

  useEffect(() => {
    // Enable or disable the button based on conditions
    setIsButtonSelected(
      timestamp &&
        beginningHandshakeTimestamp !== null &&
        endHandshakeTimestamp !== null
    );
  }, [timestamp, beginningHandshakeTimestamp, endHandshakeTimestamp]);

  useEffect(() => {
    if (handshakeDetails?.result?.handshakeData?.firstHandshakeVideoTimeStamp && eventMasterData?.result?.eventData?.eventStage === 'PostEvent' && eventMasterData?.result?.eventData?.eventState !== 'RequestForProcessing') {
      setShowCountdown(true);
      const interval = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown === 1) {
            clearInterval(interval);
            window.close();
            if (!window.closed) {
              alert("Please close this window manually.");
            }
          }
          return prevCountdown - 1;
        });
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [eventMasterData, handshakeDetails]);

  // Submits the handshake data
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !timestamp ||
      beginningHandshakeTimestamp === null ||
      endHandshakeTimestamp === null
    ) {
      return;
    }

    setLoading(true);
    const handshakeDetails = {
      EventId: eventId,
      ClientId: clientId,
      FirstHandshakeVideoTimeStamp: timestamp,
      TimeToAddAtBeginningHandshake: beginningHandshakeTimestamp,
      TimeToAddAtEndHandshake: endHandshakeTimestamp,
      HandshakeClipDurationInSeconds: "",
    };
    const result = await postHandshakeDetailsWithInput(handshakeDetails);
    if (result) {
      const clientData = getClientData();
      if (clientData) {
        const details = {
          eventId: eventId,
          clientId: clientId,
          firstHandshakeVideoTimeStampInhhmmss: timestamp,
          isHandShakeDataAvailable: true,
          submittedBy: clientData.userEmail,
        };
        await invokeGradReelProcessing(details);
      }
    }
    await getHandshakeDetails(eventId, clientId);
    setLoading(false);
  };

  const handleCsvSubmit = async (e) => {
    e.preventDefault();
    if (!selectedFile) {
      return;
    }
    setLoading(true);
    const handshakeDetails = {
      EventId: eventId,
      ClientId: clientId,
    };
    const result = await postHandshakeDetailsWithCsv(
      selectedFile,
      handshakeDetails
    );
    if (result) {
      setStatus(true);
      await getHandshakeDetails(eventId, clientId);
    }
    setLoading(false);
  };

  // Sets the selected excel file by the user
  const handleSelectedFile = (file) => {
    setSelectedFile(file);
    setIsCsvButtonSelected(true);
  };

  // Sets the selected timestamp
  const handleSelectedTimestamp = (timestamp) => {
    setTimestamp(timestamp);
  };


  // Sets the beginning timestamps
  const handleBeginningTimestampChange = (event) => {
    let newValue = parseInt(event.target.value, 10);

    // Check if newValue is within the specified range
    if (isNaN(newValue)) {
      // If the input is not a number, reset it to the previous valid value
      newValue = event.target.value;
    } else {
      newValue = Math.min(Math.max(0, newValue), 60); // Ensure the value is within the range [0, 60]
    }
    // Now update the state or perform any other necessary actions
    setBeginningHandshakeTimestamp(newValue);
  };

  // Sets the end timestamp
  const handleEndTimestampChange = (event) => {
    let newValue = parseInt(event.target.value, 10);

    // Check if newValue is within the specified range
    if (isNaN(newValue)) {
      // If the input is not a number, reset it to the previous valid value
      newValue = event.target.value;
    } else {
      newValue = Math.min(Math.max(0, newValue), 60); // Ensure the value is within the range [0, 60]
    }
    setEndHandshakeTimestamp(newValue);
  };

  return (
    <div className="handshake-template">
      <div className="frame-22">
      {showCountdown ? (
        <div className="countdown-message">
          <Typography variant="h6" align="center">
            The handshake details are submitted successfully. Closing the page in {countdown}...
          </Typography>
        </div>
      ) : (
        <>
          <h2 className="text-wrapper">Event Handshake Details:</h2>
          <div className="timestamp-field-wrapper">
            <div>
              <label
                htmlFor="ClientIdInput"
                className="ClientId-label"
                style={{ fontFamily: "Poppins" }}
              >
                Client ID:{" "}
              </label>
              <br />
              <input
                style={{ fontFamily: "Poppins" }}
                disabled
                type="text"
                placeholder="Enter Client Id"
                id="clientId"
                value={clientId}
                className="clientId-input"
              />
            </div>
          </div>
          <div className="timestamp-field-wrapper">
            <div>
              <label
                htmlFor="ClientIdInput"
                className="ClientId-label"
                style={{ fontFamily: "Poppins" }}
              >
                Event ID:{" "}
              </label>
              <br />
              <input
                style={{ fontFamily: "Poppins" }}
                disabled
                type="text"
                placeholder="Enter Event Id"
                id="eventId"
                value={eventId}
                className="clientId-input"
              />
            </div>
          </div>

          {status ? (
            <>
              <div className="timestamp-field-wrapper">
                <div style={{ width: '100%', display: "flex", flexDirection: 'row' }}>
                  <div style={{ width: announcementTime?.result?.confidenceScore > 0 ? '60%' : '100%', display: "flex", alignItems: 'center' }}>
                    <TimestampFieldComponent
                      getTimestamp={handleSelectedTimestamp}
                      timestamps={timestamp}
                    />
                  </div>
                  {announcementTime?.result?.confidenceScore > 0 && (
                    <div style={{ width: '40%', display: "flex", alignItems: 'center' }}>
                      <div className="gauge-wrapper" style={{ width: "100%" }}>
                        <p style={{ alignItems: "center" }}>
                          <GaugeComponent
                            style={{ width: 200 }}
                            value={announcementTime?.result?.confidenceScore}
                            type="radial"
                            labels={{
                              tickLabels: {
                                type: "inner",
                                ticks: [
                                  { value: 20 },
                                  { value: 40 },
                                  { value: 60 },
                                  { value: 80 },
                                  { value: 100 },
                                ],
                              },
                              defaultTickValueConfig: {
                                style: {
                                  fontFamily: "DSDigital",
                                },
                              },
                              valueLabel: {
                                matchColorWithArc: true,
                                style: {
                                  fontFamily: "DSDigital",
                                  textShadow: "none",
                                  fontSize: "60px",
                                },
                                formatTextValue: function () {
                                  return announcementTime?.result
                                    ?.firstStudentAnnouncementTime;
                                },
                              },
                            }}
                            arc={{
                              colorArray: [
                                "#D2222D",
                                "#EA4228",
                                "#FFBF00",
                                "#5BE12C",
                                "#007000",
                              ],
                              subArcs: [{ limit: 10 }, { limit: 30 }, {}, {}, {}],
                              padding: 0,
                              cornerRadius: 0,
                              width: 0.2,
                            }}
                            pointer={{
                              elastic: true,
                              animationDelay: 0,
                            }}
                          />
                        </p>
                        <p className="confScore">
                          AI Video Indexer <br /> Confidence Score:{" "}
                          <span className="confScore-inner">
                            {announcementTime?.result?.confidenceScore}%
                          </span>
                        </p>
                      </div>
                    </div>
                  )}
                </div>
                <br />
              </div>
              <div className="timestamp-field-wrapper">
                <br />
                <div>
                  <label
                    htmlFor="ClientIdInput"
                    className="ClientId-label"
                    style={{ fontFamily: "Poppins" }}
                  >
                    Beginning Offset Seconds:{" "}
                  </label>
                  <br />
                  <br />
                  <input
                    type="number"
                    placeholder="0-60"
                    id="beginningHandshakeTimestamp"
                    value={beginningHandshakeTimestamp}
                    onChange={handleBeginningTimestampChange}
                    className="clientId-input"
                  />
                </div>
                <div>
                  <label
                    htmlFor="ClientIdInput"
                    className="ClientId-label"
                    style={{ fontFamily: "Poppins" }}
                  >
                    Ending Offset Seconds:{" "}
                  </label>
                  <br />
                  <br />
                  <input
                    type="number"
                    placeholder="0-60"
                    id="endHandshakeTimestamp"
                    value={endHandshakeTimestamp}
                    onChange={handleEndTimestampChange}
                    className="clientId-input"
                  />
                </div>
              </div>
              <div className="timestamp-field-wrapper">
                <div>
                  <button
                    className={`submit-button ${
                      isButtonSelected ? "" : "disabled"
                    }`}
                    style={{
                      backgroundColor: `${isButtonSelected ? "" : "#7A7979"}`,
                      cursor: `${isButtonSelected ? "" : "not-allowed"}`,
                    }}
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </>
          ) : (
            <>
            <div className="frame-37">
              <div className="div-3">
                <ExcelUploadComponent
                  className="group-3"
                  divClassName="group-4"
                  frameWrapperText="Please choose .CSV file to upload"
                  frameWrapperVisible={false}
                  property1="default"
                  text="Handshake Data:"
                  text1="Upload"
                  visible={false}
                  getSelectedFile={handleSelectedFile}
                />
              </div>
             
            </div>
            <button
                className={`submit-button ${
                  isCsvButtonSelected ? "" : "disabled"
                }`}
                style={{
                  backgroundColor: `${isCsvButtonSelected ? "" : "#7A7979"}`,
                  cursor: `${isCsvButtonSelected ? "" : "not-allowed"}`,
                }}
                onClick={handleCsvSubmit}
              >
                Submit
              </button>
            </>
          )}

          {(loading || Loading) && (
            <div className="loader-container">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "10px",
                }}
              >
                <CircularProgress />
              </Box>
            </div>
          )}
        </>
      )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    Loading: state.postEvent.loading,
    handshakeDetails: state.postEvent.handshakeDetails,
    announcementTime: state.postEvent.announcementTime,
    eventMasterData: state.preEvent.gradreelType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getHandshakeDetails: (eventId, clientId) =>
      dispatch(getHandshakeDetails(eventId, clientId)),
    getFirstCandidateAnnouncementTime: (eventId, clientId) =>
      dispatch(getFirstCandidateAnnouncementTime(eventId, clientId)),
    postHandshakeDetailsWithCsv: (files, handshakeDetails) =>
      dispatch(postHandshakeDetailsWithCsv(files, handshakeDetails)),
    postHandshakeDetailsWithInput: (handshakeDetails) =>
      dispatch(postHandshakeDetailsWithInput(handshakeDetails)),
    invokeGradReelProcessing: (details) =>
      dispatch(invokeGradReelProcessing(details)),
      getEventMaster: (eventData) => dispatch(FetchGradreelType(eventData)),
  };
};

const ConnectedHandshakeTimestampComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(HandshakeTimestampComponent);

export { ConnectedHandshakeTimestampComponent as HandshakeTimestampComponent };

function LinearProgressWithLabel(props) {
  let color = "";
  if (props.value <= 33) {
    color = "red";
  } else if (props.value <= 66) {
    color = "orange";
  } else {
    color = "green";
  }

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress
          variant="determinate"
          {...props}
          sx={{ "& .MuiLinearProgress-bar": { backgroundColor: color } }}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography
          variant="body2"
          color="text.secondary"
        >{`${props.value}%`}</Typography>
      </Box>
    </Box>
  );
}
