import React from "react";
import PropTypes from "prop-types";

import "./style.css";
import { Checkbox } from "../Checkbox";

export const FrameWrapperTemplate = ({
  className,
  onClick,
  frameClassName,
  visible = true,
  text = "I authorise GradImages to use the brand assets and images shared for Graduate Clips creation purposes.",
  checkboxPropertyFiRrClassName,
}) => {
  return (
    <div className={`frame-wrapper-template frame-instance`}>
      <div className={`frame-8 ${className}`} onClick={onClick}>
      <div className={`frame-8 ${frameClassName}`}>
        {visible && <Checkbox className={checkboxPropertyFiRrClassName} property1="fi-sr-info" />}

        <p className="i-authorise">{text}</p>
      </div>
      </div>
    </div>
  )
};

FrameWrapperTemplate.propTypes = {
  onClick: PropTypes.func,
  visible: PropTypes.bool,
  text: PropTypes.string,
};
