/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React, { useState } from "react";
import { Checkbox } from "../Checkbox";
import "./style.css";

export const FrameWrapper3 = ({
  
  className,
  visible = true,
  visibleinfo = true,
  text = "I authorise GradImages to use the brand assets and images shared for Graduate Clips creation purposes.",
  checkboxPropertyFiRrClassName,
}) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  return (
    <div className={`frame-wrapper3 ${className}`}>
      <div className="frame-14">
        {visible && (
           <Checkbox
           checked={isChecked}
           onChange={handleCheckboxChange}
           className={checkboxPropertyFiRrClassName}
           property1="fi-rr-checkbox"
         />
          
        )}
         {visibleinfo && (
        <div className="checkbox fi-sr-info class-2"></div>)}

        <p className="i-authorise">{text}</p>
      </div>
    </div>
  );
};

FrameWrapper3.propTypes = {
  visible: PropTypes.bool,
  visibleinfo: PropTypes.bool,
  text: PropTypes.string,
  checkboxPropertyFiRrClassName: PropTypes.string,
};
