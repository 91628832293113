/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const FiRrEdit12 = ({ className }) => {
  return (
    <svg
      className={`fi-rr-edit-12 ${className}`}
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >

      <g className="g" clipPath="url(#clip0_422_101565)">
        <path
          className="path"
          d="M12.4375 0.62013L4.30951 8.74813C3.99907 9.05689 3.75296 9.42417 3.58543 9.82869C3.4179 10.2332 3.33227 10.667 3.33351 11.1048V12.0001C3.33351 12.1769 3.40375 12.3465 3.52877 12.4715C3.6538 12.5966 3.82336 12.6668 4.00018 12.6668H4.89551C5.33335 12.668 5.76709 12.5824 6.17161 12.4149C6.57614 12.2473 6.94341 12.0012 7.25218 11.6908L15.3802 3.5628C15.7698 3.17224 15.9886 2.64311 15.9886 2.09146C15.9886 1.53981 15.7698 1.01068 15.3802 0.62013C14.984 0.241392 14.457 0.0300293 13.9088 0.0300293C13.3607 0.0300293 12.8337 0.241392 12.4375 0.62013ZM14.4375 2.62013L6.30951 10.7481C5.93359 11.1218 5.42551 11.3321 4.89551 11.3335H4.66684V11.1048C4.66823 10.5748 4.87855 10.0667 5.25218 9.6908L13.3802 1.5628C13.5226 1.42677 13.7119 1.35086 13.9088 1.35086C14.1058 1.35086 14.2951 1.42677 14.4375 1.5628C14.5775 1.70314 14.6561 1.89326 14.6561 2.09146C14.6561 2.28967 14.5775 2.47979 14.4375 2.62013Z"
        />
      </g>
      <g className="g" clipPath="url(#clip0_424_3305)">
        <path
          className="path"
          d="M12.4375 0.619885L4.30951 8.74789C3.99907 9.05665 3.75296 9.42392 3.58543 9.82845C3.4179 10.233 3.33227 10.6667 3.33351 11.1046V11.9999C3.33351 12.1767 3.40375 12.3463 3.52877 12.4713C3.6538 12.5963 3.82336 12.6666 4.00018 12.6666H4.89551C5.33335 12.6678 5.76709 12.5822 6.17161 12.4146C6.57614 12.2471 6.94341 12.001 7.25218 11.6906L15.3802 3.56255C15.7698 3.172 15.9886 2.64287 15.9886 2.09122C15.9886 1.53957 15.7698 1.01044 15.3802 0.619885C14.984 0.241148 14.457 0.0297852 13.9088 0.0297852C13.3607 0.0297852 12.8337 0.241148 12.4375 0.619885ZM14.4375 2.61989L6.30951 10.7479C5.93359 11.1215 5.42551 11.3318 4.89551 11.3332H4.66684V11.1046C4.66823 10.5745 4.87855 10.0665 5.25218 9.69055L13.3802 1.56255C13.5226 1.42652 13.7119 1.35061 13.9088 1.35061C14.1058 1.35061 14.2951 1.42652 14.4375 1.56255C14.5775 1.7029 14.6561 1.89301 14.6561 2.09122C14.6561 2.28942 14.5775 2.47954 14.4375 2.61989Z"
          fill="#5C5C5C"
        />
        <path
          className="path"
          d="M15.3333 5.98576C15.1565 5.98576 14.987 6.05599 14.8619 6.18102C14.7369 6.30604 14.6667 6.47561 14.6667 6.65242V9.99976H12C11.4696 9.99976 10.9609 10.2105 10.5858 10.5855C10.2107 10.9606 10 11.4693 10 11.9998V14.6664H3.33333C2.8029 14.6664 2.29419 14.4557 1.91912 14.0806C1.54405 13.7056 1.33333 13.1969 1.33333 12.6664V3.33309C1.33333 2.80266 1.54405 2.29395 1.91912 1.91888C2.29419 1.5438 2.8029 1.33309 3.33333 1.33309H9.36133C9.53815 1.33309 9.70771 1.26285 9.83274 1.13783C9.95776 1.0128 10.028 0.843234 10.028 0.666423C10.028 0.489612 9.95776 0.320042 9.83274 0.195018C9.70771 0.0699938 9.53815 -0.000244141 9.36133 -0.000244141L3.33333 -0.000244141C2.4496 0.000814431 1.60237 0.352344 0.97748 0.977236C0.352588 1.60213 0.00105857 2.44936 0 3.33309L0 12.6664C0.00105857 13.5502 0.352588 14.3974 0.97748 15.0223C1.60237 15.6472 2.4496 15.9987 3.33333 15.9998H10.8953C11.3333 16.001 11.7671 15.9154 12.1718 15.7479C12.5764 15.5803 12.9438 15.3342 13.2527 15.0238L15.0233 13.2518C15.3338 12.943 15.58 12.5757 15.7477 12.1712C15.9153 11.7667 16.0011 11.333 16 10.8951V6.65242C16 6.47561 15.9298 6.30604 15.8047 6.18102C15.6797 6.05599 15.5101 5.98576 15.3333 5.98576ZM12.31 14.0811C12.042 14.3484 11.7031 14.5335 11.3333 14.6144V11.9998C11.3333 11.8229 11.4036 11.6534 11.5286 11.5284C11.6536 11.4033 11.8232 11.3331 12 11.3331H14.6167C14.5342 11.7021 14.3493 12.0404 14.0833 12.3091L12.31 14.0811Z"
          fill="#5C5C5C"
        />
      </g>
      <defs className="defs">

        <clipPath className="clip-path" id="clip0_422_101565">
          <rect className="rect" fill="white" height="16" width="16" />
        </clipPath>
        <clipPath className="clip-path" id="clip0_424_3305">
          <rect className="rect" fill="white" height="16" transform="translate(0 -0.000244141)" width="16" />

        </clipPath>
      </defs>
    </svg>
  );
};
