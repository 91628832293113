import * as React from "react";
import { useState } from "react";
import { Player } from "video-react";

import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import PlayArrowIcon from "@mui/icons-material/OndemandVideo";
import Tooltip from "@mui/material/Tooltip";
import { DataGrid } from "@mui/x-data-grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import TestVideo from "./TestVideo.MOV";
import TestVideo_Thumbnail from "./play-button.png";
import { getSasUrlForClip } from "../../store/Actions/PostEventAction";
import { connect } from "react-redux";
import { getClientData } from "../../constants/Constants";
import { PlayIcon2 } from "../../icons/PlayIcon2";

const DataGridVideoProcessing = ({
  rows,
  getSasUrlForClip,
  postEventDetails,
  sasUrlForClip,
}) => {
  // State for modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const clientData = getClientData();

  // Function to open modal
  const openModal = async (customerId) => {
    await getSasUrlForClip(
      clientData.clientID,
      postEventDetails?.eventId,
      customerId
    );
    setIsModalOpen(true);
  };

  // Function to close modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Function to stop event propagation
  const stopPropagation = (event) => {
    event.stopPropagation();
  };

  // Gets the status color based on percentage
  const getChipColor = (status) => {
    if (status === "Successful") {
      return {
        chipColor: "#1BA421",
        textColor: "#1BA421",
        progressColor: "#1BA421",
      }; // Green for Successful
    } else if (status === "Pending") {
      return {
        chipColor: "#FB6C05",
        textColor: "#FB6C05",
        progressColor: "#FB6C05",
      }; // Orange for 75% and 50%
    } else if (status === "Queue") {
      return {
        chipColor: "#9ba41b",
        textColor: "#9ba41b",
        progressColor: "#9ba41b",
      }; // Red for others (25%)
    } else {
      return {
        chipColor: "#EA0C0C",
        textColor: "#EA0C0C",
        progressColor: "#EA0C0C",
      }; // Red for others (25%)
    }
  };

  // Renders the status column based on text and color
  const renderStatusColumn = (params) => {
    const status = params.row.encodingStatus;
    const { chipColor, textColor } = getChipColor(status);

    return (
      <Chip
        label={params.value || 'Not Started'}
        variant="outlined"
        style={{
          borderColor: chipColor,
          color: textColor,
          height: "24px",
          fontFamily: "SF Pro Rounded",
          fontsize: "12px",
          fontWeight: "400",
          lineHeight: "16px",
          textAlign: "center",
        }}
      />
    );
  };

  // Function to render Video/Preview column
  const renderVideoPreviewColumn = (params) => {
    const status = params.row.encodingStatus;
    const isClipAvailable = params.row.isClipAvailable;

    if (status === "Successful" && isClipAvailable) {
      return (
        <Tooltip title="Click here to view video">
          {/* <PlayArrowIcon
            onClick={() => {
              openModal(params.row.customerId);
            }}
            style={{ transform: "scale(1.5)", cursor: "pointer" }}
          /> */}
         <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
          <img src={TestVideo_Thumbnail} alt="Video Thumbnail" style={{ width: '24px', cursor: 'pointer' }} onClick={() => { openModal(params.row.customerId) }} />
        </div>
        </Tooltip>
      );
    } else {
      return null;
    }
  };

  const theme = createTheme();

  // Override MUI theme styles for DataGrid
  const customTheme = createTheme({
    components: {
      MuiDataGrid: {
        styleOverrides: {
          root: {
            border: "none",
          },
          columnHeaderTitleContainer: {
            '&:last-child': {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            },
          },
          row: {
            "&:nth-of-type(even)": {
              backgroundColor: "#F8F8F8", // Alternate row color
            },
            "&:nth-of-type(odd)": {
              backgroundColor: "#FFFFFF", // Starting row color
            },
            fontWeight: "normal", // Change font weight to normal
          },
          header: {
            backgroundColor: "#E4E4E4", // Header background color
            borderBottom: `1px solid ${theme.palette.divider}`, // Bottom border for header
          },
          cell: {
            borderBottom: "none" // No bottom border for cells
          },
        },
      },
    },
  });

  const columns = [
    {
      field: "customerId",
      headerName: "Customer ID",
      flex: 1,
    },
    {
      field: "customerName",
      headerName: "Customer Name",
      flex: 1,
    },
    {
      field: "encodingStatus",
      headerName: "Status",
      flex: 1,
      renderCell: renderStatusColumn,
    },
    {
      field: "expectedPath",
      headerName: "Video Preview",
      flex: 1,
      renderCell: renderVideoPreviewColumn,
      sortable: false,
      disableColumnMenu: true,
    },
  ];

  return (
    <ThemeProvider theme={customTheme}>
      <Box sx={{ height: "100%", width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          getRowHeight={() => 65}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          disableRowSelectionOnClick
          pageSizeOptions={[10, 20]}
          sx={{
            "& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell": {
              fontWeight: 400,
              fontSize: "14px",
              fontFamily: "Poppins",
              color: "#717171",
            },
            "& .MuiDataGrid-columnHeader": {
              backgroundColor: "#F8F8F8",
              fontSize: "16px",
              fontFamily: "Poppins",
              color: "#171B1E",
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              fontWeight: "600",
            },
            "& .MuiDataGrid-columnHeadersInner": {
              width: "100%",
              backgroundColor: "rgb(248, 248, 248)",
            },
            "& .MuiDataGrid-columnHeader,& .MuiDataGrid-cell": {
              padding: "0 20px",
            },
          }}
        />
      </Box>
      {isModalOpen && (
        <div className="video-modal" onClick={closeModal}>
          <div className="video-player-container" onClick={stopPropagation}>
            <button className="close-button" onClick={closeModal}>
              &times;
            </button>
            <div className="video-player">
              <Player src={sasUrlForClip?.result?.result?.gradReelSasUrl} />
            </div>
          </div>
        </div>
      )}
    </ThemeProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.postEvent.loading,
    postEventDetails: state.postEvent.postEventDetails,
    sasUrlForClip: state.postEvent.sasUrlForClip,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSasUrlForClip: (clientId, eventId, customerId) =>
      dispatch(getSasUrlForClip(clientId, eventId, customerId)),
  };
};

const ConnectedDataGridVideoProcessing = connect(
  mapStateToProps,
  mapDispatchToProps
)(DataGridVideoProcessing);

export { ConnectedDataGridVideoProcessing as DataGridVideoProcessing };
