import PropTypes from "prop-types";
import React, { useState } from "react";

import "./style.css";
import { toastError } from '../../constants/Constants';
import { Frame1027 } from "../Frame1027";

import { FiRrCloudUpload } from "./../../icons/FiRrCloudUpload";

export const UploadVideoGroup = ({
  className,
  divClassName,
  text = "",
  text1 = "",
  visible = true,
  frame1027Text = "",
  handleFileChange,
}) => {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleDivClick = () => {
    document.getElementById('fileInput').click();
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const allowedTypes = ['video/mp4'];
      if (allowedTypes.includes(file.type)) {
        setSelectedFile(file);
        handleFileChange(file);
      } else {
        toastError('Invalid file type. Please select a .mp4 file.');
      }
    }
  };

  return (
    <div className={`group ${className}`}>
      <div className="frame-10">
        <div className="event-ID-wrapper">
          <div className={`event-ID ${divClassName}`}>{text}</div>
        </div>
        <div className="frame-11">
          <div className="frame-12" onClick={handleDivClick}>
            <div className="element-wrapper">
              <div className="element" title={selectedFile ? selectedFile.name : ""}>
                {selectedFile ? selectedFile.name : text1}</div>
              <input
                id="fileInput"
                type="file"
                className="element"
                accept=".mp4"
                name="FileUpload"
                onChange={handleFileInputChange}
                style={{ display: 'none' }}
              />
            </div>
            {visible && <FiRrCloudUpload />}
          </div>
          <Frame1027 className="frame-1027-instance" hasCheckbox={true} text={frame1027Text} />
        </div>
      </div>
      
    </div>
  );
};

UploadVideoGroup.propTypes = {
  text: PropTypes.string,
  text1: PropTypes.string,
  visible: PropTypes.bool,
  frame1027Text: PropTypes.string,
  handleFileChange: PropTypes.func.isRequired,
};