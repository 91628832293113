import React from "react";
import "./style.css";

export const ReasonForRejection = ({onClose, onReason, confirmReject}) => {
  
    // Stop propagation of certain keyboard events
    const handleKeyDown = (event) => {
      if (event.key === 'ArrowUp' || event.key === 'ArrowDown' || event.key === 'ArrowLeft' || event.key === 'ArrowRight' || event.key === " ") {
        event.stopPropagation();
      }
   };
  return (
    
    <div className="index-reason" onKeyDown={handleKeyDown}>
      <div className="frame-wrapper">
        <div className="frame">
          <div className="div-wrapper">
            <div className="div">
              <div className="frame-2">
                <div className="frame-3">
                  <p className="reason-for-rejection">
                    <span className="text-wrapper">Reason for Rejection:</span>
                  </p>
                  <div className="rectangle">
                  <textarea
                     // value={rejectionReason}
                      onChange={(e) => onReason(e.target.value)}
                      //placeholder="Enter rejection reason..."
                      className="textarea-css"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
      
          <div className="frame-4">
            <button className="primary-BTN">
              <p className="button-text" onClick={onClose}>
                <span className="span">Cancel</span>
              </p>
            </button>
            <button className="button-text-wrapper">
              <p className="span-wrapper">
                <span className="text-wrapper-2" onClick={confirmReject}>Send</span>
              </p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};