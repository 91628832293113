import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { connect, useDispatch } from "react-redux";
import { Box, CircularProgress } from "@mui/material";

import { StyledEngineProvider } from '@mui/material/styles';
import "./style.css";

import { PreEventDetails } from "../PreEventDetails";
import Stepper from '../Stepper';

import homeImage from '../../../icons/ImageIcons/material-symbols_home-rounded.svg';
import rightarrow from '../../../icons/ImageIcons/keyboard-arrow-right.svg';

import { Group } from "../../../components/Group";
import { completeStep, FetchCustomerClipConfig, FetchGradreelType, getEventState } from "../../../store/Actions/PreEventAction";


const EventOverview = ({ loading, eventDetails, loadevent, eventMaster, getEventState, FetchCustomerClipConfig, customerClipDetails}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dateTime, setDateTime] = useState(new Date(eventDetails?.eventDateTime));
  const [isLoadEventCompleted, setIsLoadEventCompleted] = useState(false);

  useEffect(() => {
    (async () => {
        let updateData;
        if (eventDetails) {
            updateData = { eventId: eventDetails.eventId, clientId: eventDetails.clientId };
            await loadevent(updateData);
            await FetchCustomerClipConfig(updateData);
            setIsLoadEventCompleted(true); // Set to true when loadevent completes
        } else {
            navigate('/PreEventDetailsDashboard');
            return;
        }
    })();

    // Return a cleanup function to cancel the effect if it's still running when unmounted
    return () => {
      setIsLoadEventCompleted(true); // Set to true to prevent further execution
    };
  }, [eventDetails, loadevent]);

  useEffect(() => {
    if((eventMaster?.result?.eventData?.eventState === "Draft" && eventMaster?.result?.eventData?.eventStage === "PreEvent") || !eventMaster?.result?.isDataAvailable){
      getEventState(false);
    } else {
      getEventState(true);
    }
  },[eventMaster?.result?.eventData])

  // Updates the steps status of completed or in progress
  useEffect(() => {
    if (!isLoadEventCompleted) return;
    if(eventMaster?.result?.isDataAvailable){
    if(eventMaster?.result?.eventData?.template){
      dispatch(completeStep(1, true));
      if(eventMaster?.result?.eventData?.template === 'Standard'){
        if(eventMaster?.result?.eventData?.backGroundMusicProvided !== null &&
          eventMaster?.result?.eventData?.studentDataUploaded !== null &&
          eventMaster?.result?.eventData?.backGroundMusicProvided &&
          eventMaster?.result?.eventData?.studentDataUploaded){
          dispatch(completeStep(2, true));
        } else {
          dispatch(completeStep(2, false));
        }
        if(eventMaster?.result?.eventData?.introClipComplete){
          dispatch(completeStep(3, true));
        } else {
          dispatch(completeStep(3, false));
        }
        if(eventMaster?.result?.eventData?.exitClipComplete){
          dispatch(completeStep(4, true));
        } else {
          dispatch(completeStep(4, false));
        }
        if(eventMaster?.result?.eventData?.introClipPublished !== null &&
          eventMaster?.result?.eventData?.exitClipPublished !== null &&
          eventMaster?.result?.eventData?.introClipPublished &&
          eventMaster?.result?.eventData?.exitClipPublished){
          dispatch(completeStep(5, true));
        } else {
          dispatch(completeStep(5, false));
        }
      } else {
        if(eventMaster?.result?.eventData?.backGroundMusicProvided !== null &&
          eventMaster?.result?.eventData?.studentDataUploaded !== null &&
          eventMaster?.result?.eventData?.backGroundMusicProvided &&
          eventMaster?.result?.eventData?.studentDataUploaded){
          dispatch(completeStep(2, true));
        } else {
          dispatch(completeStep(2, false));
        }
        if(eventMaster?.result?.eventData?.introClipComplete){
          dispatch(completeStep(3, true));
        } else {
          dispatch(completeStep(3, false));
        }
        if(customerClipDetails && customerClipDetails?.result?.isDataAvailable){
          dispatch(completeStep(4, true));
        } else {
          dispatch(completeStep(4, false));
        }
        if(eventMaster?.result?.eventData?.exitClipComplete){
          dispatch(completeStep(5, true));
        } else {
          dispatch(completeStep(5, false));
        }
        if(eventMaster?.result?.eventData?.introClipPublished !== null &&
          eventMaster?.result?.eventData?.exitClipPublished !== null &&
          eventMaster?.result?.eventData?.introClipPublished &&
          eventMaster?.result?.eventData?.exitClipPublished){
          dispatch(completeStep(6, true));
        } else {
          dispatch(completeStep(6, false));
        }
      }
    }
  }
  }, [eventMaster?.result?.eventData, customerClipDetails])

  const date = dateTime.toLocaleDateString();
  const time = dateTime.toLocaleTimeString();

  return (
    <div className="event-overview">
      <div className="div-2">
       <PreEventDetails />
        <div className="frame-19">
          <div className="frame-20">
            <div className="section-title">
              <div className="frame-21">
                <div className="page-title">
                  <div className="text-wrapper-3">Event Details</div>
                </div>
                <div className="breadcrum">
                  <img
                    className="material-symbols"
                    alt="Material symbols"
                    src={homeImage}
                  />
                  <img
                    className="keyboard-arrow-right"
                    alt="Keyboard arrow right"
                    src={rightarrow}
                  />
                  <div className="text-wrapper-4">Events</div>
                  <img
                    className="keyboard-arrow-right"
                    alt="Keyboard arrow right"
                    src={rightarrow}
                  />
                  <div className="text-wrapper-5">Event Details</div>
                </div>
              </div>
            </div>
            <div className="frame-22">
              <div className="frame-23">
                            <StyledEngineProvider injectFirst>
                    <Stepper gradReelType={eventMaster?.result?.eventData?.template}/>
                  </StyledEngineProvider>
              </div>
              <div className="frame-27">
                <div className="frame-28">
                  <div className="frame-29">
                    <div className="text-wrapper-6">Event details</div>
                  </div>
                 
                  <div className="frame-30">
                    <div className="frame-31">
                     
                      <Group
                        className="group-37146"
                        divClassName="group-37146-instance"
                        divClassNameOverride="group-2"
                        frameClassName="group-3"
                        text={eventDetails?.eventName}
                        text1="Event Name"
                        visible={false}
                        visible1={false}
                      />
                      <Group
                        className="group-37146"
                        divClassName="group-37146-instance"
                        divClassNameOverride="group-2"
                        frameClassName="group-3"
                        text={eventDetails?.eventId}
                        text1="Event ID"
                        visible={false}
                        visible1={false}
                      />
                    </div>
                    
                    <div className="frame-31">
                      <Group
                        className="group-37146"
                        divClassName="group-37146-instance"
                        divClassNameOverride="group-2"
                        frameClassName="group-3"
                        text={date}
                        text1="Event Date"
                        visible={false}
                        visible1={false}
                      />
                      <Group
                        className="group-37146"
                        divClassName="group-37146-instance"
                        divClassNameOverride="group-2"
                        frameClassName="group-3"
                        text={time}
                        text1="Event Time"
                        visible={false}
                        visible1={false}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
         <Link to="/SelectTemplate" className="button-text">
            <div className="primary-BTN-wrapper">
              <div className="primary-BTN">
                Continue
              </div>
            </div>
          </Link>
        </div>
        {loading && (
            <div className="loader-container">
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px' }}>
                <CircularProgress />
              </Box>
            </div>
          )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.preEvent.loading,
    eventDetails: state.preEvent.eventDetails,
    eventMaster: state.preEvent.gradreelType,
    customerClipDetails: state.preEvent.customerClipDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadevent: (eventData) => dispatch(FetchGradreelType(eventData)),
    getEventState: (eventState) => dispatch(getEventState(eventState)),
    FetchCustomerClipConfig: (eventData) => dispatch(FetchCustomerClipConfig(eventData)),
  };
};

const ConnectedEventOverview = connect(mapStateToProps, mapDispatchToProps)(EventOverview);

export { ConnectedEventOverview as EventOverview };