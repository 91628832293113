import PropTypes from "prop-types";
import React from "react";
import "./style.css";

import { Checkbox } from "../Checkbox";

export const Frame1027 = ({
  className,
  hasCheckbox = true,
  text = "I authorise GradImages to use the brand assets and images shared for Graduate Clips creation purposes.",
  text2 = ''
}) => {
  return (
    <div className={`frame-1027 ${className}`}>
      <div className="frame-18">
        {hasCheckbox && <Checkbox className="checkbox-instance" property1="fi-sr-info" />}

        <p className="i-authorise">{text}<br/>{text2}</p>
      </div>
    </div>
  );
};

Frame1027.propTypes = {
  hasCheckbox: PropTypes.bool,
  text: PropTypes.string,
};
