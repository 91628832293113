/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { Expand } from "../Expand";
import { Frame1027 } from "../Frame1027";
import "./style.css";

export const Group = ({
  className,
  frameClassName,
  divClassName,
  text = "37117",
  visible = true,
  visible1 = true,
  divClassNameOverride,
  text1 = "Event ID",
}) => {
  return (
    <div className={`group ${className}`}>
      <div className={`frame-14 ${frameClassName}`}>
        <div className="event-ID-wrapper">
          <div className={`event-ID ${divClassNameOverride}`}>{text1}</div>
        </div>
        <div className="frame-15">
          <div className="frame-16">
            <div className="frame-17">
              <div className={`element-2 ${divClassName}`}>{text}</div>
            </div>
            {visible && <Expand />}
          </div>
          {visible1 && (
            <Frame1027 className="frame-1027-instance" hasCheckbox={false} text="Please upload MP3 format only." />
          )}
        </div>
      </div>
    </div>
  );
};

Group.propTypes = {
  text: PropTypes.string,
  visible: PropTypes.bool,
  visible1: PropTypes.bool,
  text1: PropTypes.string,
};
