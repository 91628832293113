import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { AudioClip2 } from "../../icons/AudioClip2";
import { AudioClip4 } from "../../icons/AudioClip4";
import { RadioButtons4 } from "../../icons/RadioButtons4";
import { RadioButtons5 } from "../../icons/RadioButtons5";
import { Radio, RadioGroup, FormControlLabel, FormControl, FormLabel } from '@mui/material';

import { PropertyDefaultWrapperMusic } from "../PropertyDefaultWrapperMusic";
import { RadioBtnMusicClip } from "../RadioBtnMusicClip";
import "./style.css";

export const AudioClipSection = ({
  property1,
  className,
  radioBtnMusicClipRadioOptionIcon = <RadioButtons4 className="radio-buttons-2" />,
  override = <RadioButtons5 className="radio-buttons-2" />,
  frameWrapperCheckboxPropertyFiRrClassName,
  isChecked,
  onCheckboxChange,
  onFileChange,
  onMusicClipOptionChange,
  onSelectMusicClip = null,
  selectedMusicId = null
}) => {
  const [clipId, setClipId] = useState('');
  const [clipName, setClipName] = useState('');
  const [variant, setVariant] = useState(property1 || "default");
  const [currentPlaying, setCurrentPlaying] = useState(null); // Track currently playing clip

  // Changes the option between upload and select music clip
  const handleMusicClipOptionChange = (isChooseMusicClip) => {
    onMusicClipOptionChange(isChooseMusicClip);
    setClipId('');
  };

  useEffect(() => {
    onSelectMusicClip(clipId, clipName)
  }, [clipId, clipName])

  useEffect(() => {
    if (selectedMusicId) {
      if(selectedMusicId ==1){
        selectedMusicId = "2";
        setClipName('/Pomp and Circumstance Graduation Walking March (1).mp3');
      }
      setClipId(selectedMusicId);
      setVariant("variant-2");
    }
    // else {
    //   setVariant("default");
    // }
  }, [selectedMusicId])

  // Set the clip ID based on selection
  const handleChange = (event) => {
    setClipId(event.target.value);
  };

  const getMusicClipName = (clipName) => {
    setClipName(clipName)
  }
  // Function to pause all other clips except the one currently playing
  const pauseOtherClips = (clipId) => {
    const audioElements = document.getElementsByTagName('audio');
    for (let audio of audioElements) {
      if (audio.id !== clipId) {
        audio.pause();
      }
    }
  };

  // Event handler for when a clip starts playing
  const handlePlay = (clipId) => {
    setCurrentPlaying(clipId);
    pauseOtherClips(clipId);
  };

  return (
    <div className={`audio-clip-section property-1-3-${variant} ${className}`}>
      <div className="frame-23">
        {variant === "variant-2" && <div className="text-wrapper-6">Background Music Details</div>}

        {variant === "default" && (
          <>
            <div className="frame-24">
              <div className="text-wrapper-6">Background Music Details</div>
            </div>
            <div className="frame-25">
              <RadioBtnMusicClip
                className="radio-btn-music-clip-instance"
                property1="default"
                radioOptionIcon={radioBtnMusicClipRadioOptionIcon}
                radioOptionItemClassName="radio-btn-music-clip-2"
                radioOptionText="Upload Music Clip"
              />
              <RadioBtnMusicClip
                className="radio-btn-music-clip-instance"
                onClick={() => {
                  setVariant("variant-2");
                  handleMusicClipOptionChange(true)
                }}
                property1="default"
                radioOptionIcon={override}
                radioOptionItemClassName="radio-btn-music-clip-3"
                radioOptionText="Choose Music Clip"
              />
            </div>
            <div className="frame-26">
              <div className="group-wrapper">

                <PropertyDefaultWrapperMusic
                  className="group-3"
                  divClassName="group-4"
                  frameWrapperText="You can only use royalty-free music or a copyrighted music clip."
                  frameWrapperVisible={false}
                  property1="default"
                  text="Upload Background Music Clip"
                  text1="Upload"
                  visible={false}
                  onFileChange={onFileChange}
                />
              </div>

            </div>
          </>
        )}
      </div>
      {variant === "variant-2" && (
        <>
          <div className="frame-25">
            <RadioBtnMusicClip
              className="radio-btn-music-clip-instance"
              onClick={() => {
                setVariant("default");
                handleMusicClipOptionChange(false);
              }}
              property1="default"
              radioOptionIcon={<RadioButtons5 className="radio-buttons-2" />}
              radioOptionItemClassName="radio-btn-music-clip-2"
              radioOptionText="Upload Music Clip"
            />
            <RadioBtnMusicClip
              className="radio-btn-music-clip-instance"
              property1="default"
              radioOptionIcon={<RadioButtons4 className="radio-buttons-2" />}
              radioOptionItemClassName="radio-btn-music-clip-3"
              radioOptionText="Choose Music Clip"
            />
          </div>
          <div className="frame-27">
            <div className="audio-clip-container">
              <FormControl component="fieldset"
                sx={{
                  '& .MuiFormControlLabel-root': {
                    marginRight: '0px'
                  }
                }}>
                <RadioGroup
                  aria-label="audio-clip"
                  name="audio-clip"
                  value={clipId}
                  onChange={handleChange}
                  style={{ display: 'flex', flexDirection: 'row' }}
                >
                  {/* <div className="frame-26">
                    <FormControlLabel value="1" control={<Radio />} onClick={() => setClipName('/UAB Intro Music 3.mp3')} />
                    <audio id='1' controls onPlay={() => handlePlay("1")}>
                      <source src='/UAB Intro Music 3.mp3' type="audio/ogg" />
                    </audio>
                  </div> */}
                  <div className="frame-26">
                    <FormControlLabel value="2" control={<Radio />} onClick={() => setClipName('/Pomp and Circumstance Graduation Walking March (1).mp3')} />
                    {/* <AudioClip4 className="audio-clip"/> */}
                    <audio id='2' controls onPlay={() => handlePlay("2")}>
                      <source src="/Pomp and Circumstance Graduation Walking March (1).mp3" type="audio/ogg" />
                    </audio>
                  </div>
                </RadioGroup>
              </FormControl>
            </div>
          </div>

        </>
      )}
    </div>
  );
};

AudioClipSection.propTypes = {
  property1: PropTypes.oneOf(["variant-2", "default"]),
};
