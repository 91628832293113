import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";

import { Box, CircularProgress } from "@mui/material";
import { StyledEngineProvider } from "@mui/material/styles";
import "./style.css";

import { SeasonSettings } from "../SeasonSettings";
import Tabs from "../Tabs";

import homeImage from '../../../icons/ImageIcons/material-symbols_home-rounded.svg';
import rightarrow from '../../../icons/ImageIcons/keyboard-arrow-right.svg';

import DropDownSeasonSettings from "../../../components/DropDownSeasonSettings/DropDownSeasonSettings";
import { FrameSelectTemplate } from "../../../components/FrameSelectTemplate/FrameSelectTemplate";


import { getClientData, toastError } from "../../../constants/Constants";
import { createSeasonSettingsClientMaster, fetchClientMaster, getSelectedSeason, postSeasonSettingsTemplateData, setEventSuccess } from "../../../store/Actions/SeasonSettingsAction";

const SelectTemplateSettings = ({
  loading,
  fetchClientMaster,
  clientMasterData,
  createSeasonSettingsClientMaster,
  postSeasonSettingsTemplateData,
  getSelectedSeason,
  selectedSeason,
  eventSuccess,
  setEventSuccess
}) => {
  const navigate = useNavigate();
  const clientData = getClientData();

  const [gradReelType, setGradReelType] = useState("Standard"); // Default to standard
  const [seasonName, setSeasonName] = useState("");
  const [seasonId, setSeasonId] = useState(0);
  const [isButtonSelected, setIsButtonSelected] = useState(false);
  const [masterData, setMasterData] = useState(false);
  const [seasonStartPeriod, setSeasonStartPeriod] = useState('');
  const [seasonEndPeriod, setSeasonEndPeriod] = useState('');
    
  useEffect(() => {
    const fetchData = async () => {
      setGradReelType("");
      if (seasonName) {
        await fetchClientMaster({
          clientId: clientData.clientID,
          seasonName: seasonName,
        });
      }
    };
  
    fetchData();
  }, [seasonName, selectedSeason]);

  useEffect(() => {
    if (eventSuccess) {
      console.log("Event created successfully!");
      setEventSuccess(false)
      const path = isButtonSelected ? "/MediaDetailsSettings" : "#";
      navigate(path);
    }
  }, [eventSuccess, isButtonSelected]);

  useEffect(() => {
    if (clientMasterData?.result?.isDataAvailable) {
      setMasterData(clientMasterData);
      setGradReelType(clientMasterData?.result?.data?.template);
      if (clientMasterData?.result?.data?.template && seasonId) {
        setIsButtonSelected(true);
      }
    } else {
      setGradReelType("Standard");
      setIsButtonSelected(true);
    }
  }, [clientMasterData, seasonName]);

  // Changes the choice of selecting gradreel template option
  const handleGradReelTypeChange = (type) => {
    setGradReelType(type);
    if (seasonId) {
      setIsButtonSelected(true);
    }
  };

  // Set the selected season name
  const handleSeasonName = (seasonName, seasonId) => {
    setSeasonName(seasonName);
    getSelectedSeason(seasonId);
    setSeasonId(seasonId);
  };

  // Set the selected season dates
  const handleSeasonDates = (startDate, endDate) => {
    setSeasonStartPeriod(startDate);
    setSeasonEndPeriod(endDate);
  }

  // Creates a event or update the existing event
  const handleSaveEvent = async () => {
    if (!isButtonSelected) {
      return;
    }

    if (!seasonName) {
      toastError('Please select Season from dropdown.');
      return;
    }

    try {
      // setLoading(true);
      if (!clientMasterData?.result?.isDataAvailable) {
        await createSeasonSettingsClientMaster({
          itemType: "SeasonSettingsMaster",
          itemTypeDesc: "SeasonSettingsMaster",
          clientId: clientData.clientID,
          clientName: clientData.clientName,
          seasonId: seasonId,
          seasonName: seasonName,
          seasonStartDate: seasonStartPeriod,
          seasonEndDate: seasonEndPeriod,
          template: gradReelType,
          templateDesc: gradReelType,
        });
      } else {
        const updatedData = {
          ClientId: clientData.clientID,
          SeasonId: seasonId,
          TemplateType: gradReelType,
          userEmail: clientData.userEmail,
        };
        await postSeasonSettingsTemplateData(updatedData);
      }
      // setLoading(false);

    } catch (error) {
      console.error("Error:", error?.message);
    }
  };

  return (
    <div className="select-template-settings">
      <div className="div-2">
        <SeasonSettings />

        <div className="frame-19">
          <div className="frame-20">
            <div className="section-title">
              <div className="frame-21">
                <div className="page-title">
                  <div className="text-wrapper-3">Season Settings</div>
                </div>
                <div className="div-3">
                  <img
                    className="material-symbols"
                    alt="Material symbols"
                    src={homeImage} />
                  <img
                    className="keyboard-arrow-right"
                    alt="Keyboard arrow right"
                    src={rightarrow}
                  />
                  <div className="text-wrapper-4">Settings</div>
                  <img
                    className="keyboard-arrow-right"
                    alt="Keyboard arrow right"
                    src={rightarrow}
                  />
                  <div className="text-wrapper-5">Select Template</div>
                </div>
              </div>
            </div>
            <div className="label">
              <div className="event-ID">Select Season Name</div>
            </div>
            <StyledEngineProvider injectFirst>
              <DropDownSeasonSettings onSeasonsName={handleSeasonName} selectedSeason={selectedSeason} onSeasonDates={handleSeasonDates}/>
            </StyledEngineProvider>
            <StyledEngineProvider injectFirst>
              <Tabs />
            </StyledEngineProvider>
            <div className="frame-22">
              <FrameSelectTemplate
                onGradReelTypeChange={handleGradReelTypeChange}
                selectedType={gradReelType}
                selectedSeason={selectedSeason}
              />
            </div>
          </div>
          <div className="frame-36">
            <div
              className={`primary-BTN-3 ${isButtonSelected ? "" : "disabled"}`}
            >
              <Link
                // to={isButtonSelected ? "/MediaDetailsSettings" : "#"}
                onClick={handleSaveEvent}
                className="button-text-3"
              >
                Save
              </Link>
            </div>
          </div>
          {loading && (
            <div className="loader-container">
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px' }}>
                <CircularProgress />
              </Box>
            </div>
          )}
        </div>
      </div>
      
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.seasonSettings.loading,
    eventSuccess: state.seasonSettings.eventSuccess,
    clientMasterData: state.seasonSettings.clientMasterData,
    selectedSeason: state.seasonSettings.selectedSeason,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchClientMaster: (eventData) => dispatch(fetchClientMaster(eventData)),
    getSelectedSeason: (eventData) => dispatch(getSelectedSeason(eventData)),
    createSeasonSettingsClientMaster: (clientData) =>
      dispatch(createSeasonSettingsClientMaster(clientData)),
    postSeasonSettingsTemplateData: (clientData) =>
      dispatch(postSeasonSettingsTemplateData(clientData)),
      setEventSuccess: (flag) => dispatch(setEventSuccess(flag)),
  };
};

const ConnectedSelectTemplateSettings = connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectTemplateSettings);

export { ConnectedSelectTemplateSettings as SelectTemplateSettings };
