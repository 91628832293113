import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

function samePageLinkNavigation(event) {
  if (
    event.defaultPrevented ||
    event.button !== 0 || // ignore everything but left-click
    event.metaKey ||
    event.ctrlKey ||
    event.altKey ||
    event.shiftKey
  ) {
    return false;
  }
  return true;
}

function LinkTab(props) {
  return (
    <Tab
      component={Link}
      to={props.href}
      onClick={(event) => {
        // No need to preventDefault with React Router
      }}
      aria-current={props.selected && 'page'}
      {...props}
    />
  );
}

LinkTab.propTypes = {
  selected: PropTypes.bool,
};

export default function NavTabs() {
  const location = useLocation();
  const [value, setValue] = React.useState();

  const handleChange = (event, newValue) => {
    // event.type can be equal to focus with selectionFollowsFocus.
    if (
      event.type !== 'click' ||
      (event.type === 'click' && samePageLinkNavigation(event))
    ) {
      setValue(newValue);
    }
  };

  useEffect(() => {
    // Parse the current route and set the selected tab accordingly
    const pathname = location.pathname;
    if (pathname.includes('/SelectTemplateSettings')) {
      setValue(0);
    } else if (pathname.includes('/MediaDetailsSettings')) {
      setValue(1);
    } else if (pathname.includes('/CreateIntroClipSettings')) {
      setValue(2);
    } else if (pathname.includes('/CreateIntroClipFrame')) {
      setValue(2);
    } else if (pathname.includes('/StudentClipSettings')) {
      setValue(3);
    } else if (pathname.includes('/CreateExitClipSettings')) {
      setValue(4);
    }else if (pathname.includes('/SeasonSettings/CreateExitClipFrame')) {
      setValue(4);
    }
  }, [location.pathname]);

  return (
    <Box sx={{ width: '100%', backgroundColor: 'white',borderRadius:'8px' ,paddingTop:'7px', transitionDuration:'1s', minHeight:'15px',marginTop:'10px',height:'60px'}}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="nav tabs example"
        role="navigation"
        indicator={false}
        indicatorColor='transparent'
        sx={{
          '& .Mui-selected': {
            backgroundColor: '#4A3AFF',
            color: '#FFF !important', // Adjust the border radius as needed
            minHeight: '10px',
            borderRadius:'10px',
            transitionDuration:'1s',
            height: '40px',
            


          },
          '& .MuiButtonBase-root-MuiTab-root':{
            height:'35px',
            minHeight:'15px',

          },
          '& .MuiTabs-flexContainer':{
            marginTop:'7px',
            marginLeft:'10px'
          }
        }}
      >
        <LinkTab label="Template" href="/SelectTemplateSettings" />
        <LinkTab label="Media" href="/MediaDetailsSettings" />
        <LinkTab label="Intro Clip" href="/CreateIntroClipSettings" />
        <LinkTab label="Student Clip" href="/StudentClipSettings" />
        <LinkTab label="Exit Clip" href="/CreateExitClipSettings" />
      </Tabs>
    </Box>
  );
}