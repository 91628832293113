import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import { useNavigate, createSearchParams } from "react-router-dom";
import { connect } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { setEventDetails } from '../../store/Actions/PreEventAction';


const DataGridDemo = ({ setSelectedRowCount, selectedRowCount, eventlist, rows, setEventDetails }) => {
    const navigate = useNavigate();

    React.useEffect(() => {
      // Using setSelectedRowCount and selectedRowCount instead of props.setEventDetails and props.event
      setSelectedRowCount([]);
    }, [])
  
    // Selection of rows and redirect to /PendingVideoDashboard page for the selected event
    const handleOnCellClick = (params) => {
      if(!params.value){
        setSelectedRowCount([...selectedRowCount, params.row.id]);
      }else{
        setSelectedRowCount(selectedRowCount.filter((id)=>id!=params.row.id));
      }

     if (params.field === '__check__') {
      // Prevent navigation
      return;
    }
      
    const currentRow = params.row;
    setEventDetails(currentRow);
    navigate("/PendingVideoDashboard", { replace: true });
    };

    // Gets the status color based on percentage
    const getChipColor = (percentage) => {
      if (percentage === 0) {
          return { chipColor: '#EA0C0C', textColor: '#EA0C0C', progressColor: '#EA0C0C', status: 'Pending' }; // Red for others (25%)
      } else if (parseInt(percentage) > 0 && parseInt(percentage) < 100) {
          return { chipColor: '#FB6C05', textColor: '#FB6C05', progressColor: '#FB6C05', status: 'Pending' }; // Orange for 75% and 50%
      } else if (percentage === 100) {
          return { chipColor: '#1BA421', textColor: '#1BA421', progressColor: '#1BA421', status: 'Complete' }; // Green for Completed
      }
  };
  
  // Renders the status column based on text and color
    const renderStatusColumn = (params) => {
      const percentage = params.row.eventstatuspercent;
      const { chipColor, textColor, status} = getChipColor(percentage);
  
      return (
        <Chip
          label={status}
          variant="outlined"
          style={{ borderColor: chipColor, color: textColor,height:'24px',fontFamily: 'SF Pro Rounded',
          fontsize: '12px',
          fontWeight: '400',
          lineHeight: '16px',
          textAlign: 'center',
           }}
           />
      );
    };
  
    // Renders status percent column and color for it
    const renderStatusPercentColumn = (params) => {
      const percentage = params.value;
      const { textColor} = getChipColor(percentage);
  
      return (
        <div style={{color: textColor,fontFamily: 'Raleway',fontSize: '12px',left:'-50px',fontWeight: '600'
          }}>{percentage}%</div>
      );
    };
  

const theme = createTheme();

// Override MUI theme styles for DataGrid
const customTheme = createTheme({
    components: {
      MuiDataGrid: {
        styleOverrides: {
          root: {
            border: 'none',
          },
          row: {
            '&:nth-of-type(even)': {
              backgroundColor: '#F8F8F8', // Alternate row color
            },
            '&:nth-of-type(odd)': {
              backgroundColor: '#FFFFFF', // Starting row color
            },
            fontWeight: 'normal', // Change font weight to normal
          },
          header: {
            backgroundColor: '#E4E4E4', // Header background color
            borderBottom: `1px solid ${theme.palette.divider}`, // Bottom border for header
          },
          cell: {
            borderBottom: 'none', // No bottom border for cells
          },
        },
      },
    },
  });

const columns = [
  {
    field: 'eventName',
    headerName: 'Event Name',
    flex:1,
  },
  {
    field: 'eventId',
    headerName: 'Event ID',
  },
  {
    field: 'eventDateTime',
    headerName: 'Event Date & Time',
    type: 'dateTime',
    valueGetter: ({ value }) => value && new Date(value),
    flex:1,
  },
  {
    field: 'clientName',
    headerName: 'Client Name',
    flex:1,
  },
  {
    field: 'template',
    headerName: 'Template',
    flex:1,
  },
  {
    field: 'eventstatus',
    headerName: 'Video Review Status',
    flex:1,
    renderCell: renderStatusColumn,
  },
  {
    field: 'eventstatuspercent',
    headerName: '',
    flex:1,
    renderCell: renderStatusPercentColumn,
    hide : true,
    disableColumnMenu: true, // Disable column menu for this column
    sortable: false,
  },
];

  return (
    <ThemeProvider theme={customTheme}>
      <Box sx={{ height: '100%', width: '100%' }}>
        <DataGrid
         onCellClick={handleOnCellClick}
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10, 20]}
          checkboxSelection
          
          disableRowSelectionOnClick
          sx={{
            '& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell': {
             fontWeight: 400,fontSize:'14px',fontFamily:'Poppins',color:'#717171'
          },'& .MuiDataGrid-columnHeader': {
            backgroundColor: '#F8F8F8',fontSize:'16px',fontFamily:'Poppins',color:'#171B1E'
         },'& .MuiDataGrid-columnHeaderTitle': {
          fontWeight:'600'
       },'& .MuiDataGrid-columnHeadersInner': {
        width: '100%', backgroundColor: 'rgb(248, 248, 248)'
     },'& .MuiDataGrid-virtualScrollerRenderZone': {
      cursor:'pointer'
   },
        }}
        />
      </Box>
      
    </ThemeProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    eventlist: state.preEvent.eventlist,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setEventDetails: (details) => dispatch(setEventDetails(details)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DataGridDemo);