import React from "react";
import "./style.css";
import polygon from "../../icons/ImageIcons/polygon-1.svg"

export const Expand = () => {
  return (
    <div className="expand">
      <img className="polygon" alt="Polygon" src={polygon} />
    </div>
  );
};
