import * as React from 'react';
import { connect } from 'react-redux';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { getSeasons } from '../../store/Actions/SharedAction';
import { getSeasonIdForPreEvent, setSeasonIdForPreEvent } from '../../constants/Constants';

function SelectLabelsComponent({ getSeasons, seasonList, onChangeSeason }) {
    const seasonIdForPreEvent = getSeasonIdForPreEvent();
    const [seasonId, setSeasonId] = React.useState(seasonIdForPreEvent || '');

    React.useEffect(() => {
        getSeasons();
    }, []);

    React.useEffect(() => {
        if (!seasonIdForPreEvent) {
            // Get today's date
            const today = new Date();
            // Find the current season based on today's date
            const currentSeason = seasonList?.result?.find(season => {
                const startDate = new Date(season.startDate);
                const endDate = new Date(season.endDate);
                return today >= startDate && today <= endDate;
            });
            // Set the seasonId of the current season as the default value
            if (currentSeason) {
                setSeasonId(currentSeason.seasonId);
            }
        }
    }, [seasonList]);

    const handleChange = (event) => {
        setSeasonId(event.target.value);
        setSeasonIdForPreEvent(event.target.value);
    };
    React.useEffect(() => {
        onChangeSeason(seasonId);
    }, [seasonId])
    return (
        <div>
            <FormControl sx={{ m: 1, minWidth: 100, float: 'right' }}>
                <InputLabel id="demo-simple-select-helper-label">Season</InputLabel>
                <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={seasonId}
                    label="Season"
                    onChange={handleChange}
                    sx={{ height: '44px', width: '250px' }}
                >
                    <MenuItem value='0'>
                        <em>All</em>
                    </MenuItem>
                    {seasonList?.result?.map(season => (
                        <MenuItem key={season.seasonId} value={season.seasonId}>
                            {season.seasonName}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        seasonList: state.shared.seasonList
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getSeasons: () => dispatch(getSeasons()),
    };
};

const SelectLabels = connect(mapStateToProps, mapDispatchToProps)(SelectLabelsComponent);

export default SelectLabels;