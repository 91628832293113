import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";

import Box from '@mui/material/Box';
import "./style.css";

import { PopupStartupAuth } from "./PopupStartupAuth";

import infoimg from "../../../icons/ImageIcons/info.svg"

import { Header } from "../../../components/Header";

import { clearStorage, getClientData, setClientData } from "../../../constants/Constants";
import { getLoginUser, getUserAcceptance, postUserAcceptance } from "../../../store/Actions/SharedAction";
import { LeftNav } from "../../../components/LeftNav";

const StartUp = ({ postUserAcceptance, getUserAcceptance, getUserAcceptanceDetails, loginUserDetails, getLoginUser }) => {

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const queryValue = queryParams.get('Query');
  const [loading, setLoading] = useState(false);
  const [uatUser, setUatUser] = useState(false);
  const [userAcceptance, setUserAcceptance] = useState(false);
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (queryValue) {
        await getLoginUser(queryValue);
      } else {
        setLoading(true);
      }
    };

    fetchData();
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      if (loginUserDetails) {
        clearStorage();
        setClientData(loginUserDetails?.result);
      }
      const clientData = getClientData();
      if (clientData) {
        setLoading(false);
        if (clientData.isUATUser) {
          if (window.APP.environment === 'dev' || window.APP.environment === 'qa') {
            setUatUser(true);
            setLoading(true);
            return;
          }
        }
        await getUserAcceptance(
          clientData.clientID,
          clientData.userEmail
        ).then(() => {
          setUserAcceptance(true);
        });
        
      } else if (loginUserDetails?.isSuccess === false) {
        setLoading(true);
      }
    };
  
    fetchData();
  }, [loginUserDetails?.isSuccess]);
  

  useEffect(() => {
    if (getUserAcceptanceDetails?.result?.isDataAvailable === false && userAcceptance) {
      openModal();
    }
  }, [getUserAcceptanceDetails?.result?.isDataAvailable, userAcceptance])

  // Opens the modal to accept the agreement
  const openModal = () => {
    setIsModalOpen(true);
  };

  // Closes the modal 
  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Function to stop event propagation
  const stopPropagation = (event) => {
    event.stopPropagation();
  };

  // Handles declined opeartion of user acceptance
  const handleCancel = () => {
    closeModal(); // Close the modal upon cancel
    navigate(-1); // Go back to the previous screen
  };

  // Submits user acceptance and closes the modal
  const handleConfirm = async () => {
    const clientData = getClientData();
    await postUserAcceptance({
      userEmailId: clientData.userEmail,
      userName: clientData.userName,
      clientid: clientData.clientID,
      clientName: clientData.clientName,
      agreementMessage: 'I agree',
      accepted: true,
      acceptedOn: new Date()
    })
    closeModal();
  };

  return (
    <div className="startup">
      {loading === true ? (
        <div className="video-modal">
          <div className="video-player-container">
            <div className="frame-success-model">
              <Box sx={{ justifyContent: 'center', marginTop: '10px', width: '50%' }}></Box>
              {uatUser ? <div style={{ textAlign: 'center', marginTop: '10px', fontFamily: 'Poppins', fontSize: '1.2em' }} className="text-wrapper">It seems that you are trying to access DEV or QA environment.<br /><br /> Kindly refer to UAT environment for further testing.</div>
                : <div style={{ textAlign: 'center', marginTop: '10px', fontFamily: 'Poppins', fontSize: '1.2em' }} className="text-wrapper">You are not logged in.<br /><br /> To log in please <Link className="event-ID group-4" style={{ fontFamily: 'Poppins', color: 'blue' }} onClick={() => { window.location.href = `${window.APP.loginUrl}` }}>Click Here</Link></div>
              }
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="div-2">
            <Header className="header-instance" />

            <LeftNav
              className="left-nav-instance"
              frameClassName="left-nav-3"
              homeHasGroup={false}
              homeText="65%"
              homeText1="85%"
              overlapGroupClassName="left-nav-2"
              tab='startUp' // Indicate that this is pre-event
            />

            <div className="frame-19">
              <div className="frame-20">
                <div className="frame-seasonsettings">
                  <div className="frame-settings-alert">

                    <div className="frameinfo">
                      <div className="divinfo">


                        <img className="info" alt="Info" src={infoimg} />
                        <div className="please-configure-the-wrapper">
                          <p className="please-configure-the">
                            Please configure the settings for all season events. You can always change the settings later, or you can
                            configure each event or multiple events independently.
                          </p>
                        </div>

                        <div className="primary-BTN-wrapper">
                          <div className="primary-BTN">
                            <Link to="/SelectTemplateSettings" className="button-text">Continue</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>

          {isModalOpen && (
            <div className="video-modal" onClick={closeModal}>
              <div className="video-player-container" onClick={stopPropagation}>
                <button className="close-button" onClick={closeModal}>
                  &times;
                </button>
                <PopupStartupAuth
                  onConfirm={handleConfirm} onCancel={handleCancel}
                />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );

};

const mapStateToProps = (state) => {
  return {
    getUserAcceptanceDetails: state.shared.getUserAcceptanceDetails,
    loginUserDetails: state.shared.loginUserDetails
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    postUserAcceptance: (userDetails) => dispatch(postUserAcceptance(userDetails)),
    getUserAcceptance: (clientID, userEmail) => dispatch(getUserAcceptance(clientID, userEmail)),
    getLoginUser: (query) => dispatch(getLoginUser(query)),
  };
};

const ConnectedStartUp = connect(mapStateToProps, mapDispatchToProps)(StartUp);

export { ConnectedStartUp as StartUp };
