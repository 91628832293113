import {
  CREATE_EVENT_FAILURE,
  CREATE_EVENT_START,
  CREATE_EVENT_SUCCESS,
  FAIL_REQUEST,
  GET_SEASON_SETTINGS_CLIENT_MASTER,
  GET_SEASON_SETTINGS_EXIT_VIDEO,
  GET_SEASON_SETTINGS_INTRO_VIDEO,
  GET_SEASON_SETTINGS_MEDIA_DETAILS,
  GET_SEASON_SETTINGS_STUDENT_VIDEO,
  GET_SELECTED_SEASON,
  INITIALIZE_VALUES_OF_SEASON_SETTINGS,
  MAKE_REQUEST,
  SET_EVENT_SUCCESS,
} from "../ActionTypes/SeasonSettingsActionTypes";

// Initial values for season settings event
const initialState = {
  loading: true,
  eventSuccess: false,
  errmessage: "",
  selectedSeason: null,
  clientMasterData: null,
  mediaDetailsForSettings: null,
  introVideoDataForSettings: null,
  exitVideoDataForSettings: null,
  customVideoDataForSettings: null,
};

// Stores the fetched or modified values in store
const SeasonSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case MAKE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FAIL_REQUEST:
      return {
        ...state,
        loading: false,
        errmessage: action.payload,
      };
    case SET_EVENT_SUCCESS:
      return {
        ...state,
        eventSuccess: false,
      };
    case CREATE_EVENT_START:
      return {
        ...state,
        loading: true,
      };
    case CREATE_EVENT_SUCCESS:
      return {
        ...state,
        loading: false,
        eventSuccess: true,
      };

    case CREATE_EVENT_FAILURE:
      return {
        ...state,
        loading: false,
        errmessage: action.payload,
        eventSuccess: false,
      };
    case GET_SELECTED_SEASON:
      return {
        ...state,
        errmessage: "",
        selectedSeason: action.payload,
      };
    case GET_SEASON_SETTINGS_CLIENT_MASTER:
      return {
        ...state,
        loading: false,
        clientMasterData: action.payload,
      };
    case GET_SEASON_SETTINGS_MEDIA_DETAILS:
      return {
        ...state,
        loading: false,
        mediaDetailsForSettings: action.payload,
      };

    case GET_SEASON_SETTINGS_INTRO_VIDEO:
      return {
        ...state,
        loading: false,
        errmessage: "",
        introVideoDataForSettings: action.payload,
      };
    case GET_SEASON_SETTINGS_EXIT_VIDEO:
      return {
        ...state,
        loading: false,
        errmessage: "",
        exitVideoDataForSettings: action.payload,
      };
    case GET_SEASON_SETTINGS_STUDENT_VIDEO:
      return {
        ...state,
        loading: false,
        errmessage: "",
        customVideoDataForSettings: action.payload,
      };
      case INITIALIZE_VALUES_OF_SEASON_SETTINGS:
        return {
          ...state,
          mediaDetailsForSettings: null,
          clientMasterData: null,
          introVideoDataForSettings: null,
          customVideoDataForSettings: null,
          exitVideoDataForSettings: null,
        };
    default:
      return state;
  }
};

export default SeasonSettingsReducer;
