/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const TemplateEmptyVuesaxLinearProperty332 = ({ className }) => {
  return (
    <svg
      className={`template-empty-vuesax-linear-property-33-2 ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect className="rect" fill="white" height="24" width="24" />
      <path
        className="path"
        d="M11.97 22C17.4928 22 21.97 17.5228 21.97 12C21.97 6.47715 17.4928 2 11.97 2C6.44712 2 1.96997 6.47715 1.96997 12C1.96997 17.5228 6.44712 22 11.97 22Z"
        stroke="#4A3AFF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        className="path"
        d="M8.73999 12.2301V10.5601C8.73999 8.48012 10.21 7.63012 12.01 8.67012L13.46 9.51012L14.91 10.3501C16.71 11.3901 16.71 13.0901 14.91 14.1301L13.46 14.9701L12.01 15.8101C10.21 16.8501 8.73999 16.0001 8.73999 13.9201V12.2301Z"
        stroke="#4A3AFF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
      />
    </svg>
  );
};
