import { toast } from 'react-toastify';
export const API_KEY = "72A921EECC93435F8E8349C7D57A78C2"

export const URI = 'https://gradreelsdevapi.azurewebsites.net/'

// export const VIDEO_GEN_URI = 'https://testfuncsrav.azurewebsites.net/'
export const VIDEO_GEN_URI = 'https://func-grvgen-dev-cus-001.azurewebsites.net/'

export const ClientId = 109652
export const ContactId = 154237

export const UserEmail = 'mahesh'
export const SeasonName = 'Winter'
export const AdminEmail = 'mpandeti@balfour.com'
export const ClientName = "Florida International University"

// Gets the items from session storage
function getStorageItem(item) {
  return sessionStorage.getItem(item)
}

// Sets the items in session storage
function setStorage(name, data) {
  sessionStorage.setItem(name, data)
}

// CLeares the session storage
export function clearStorage() {
  sessionStorage.clear();
}

// Remove a specific item from session storage
export function removeItem(key){
  sessionStorage.removeItem(key);
}

// Gets data of client from session storage
export function getClientData() {
  return JSON.parse(getStorageItem('clientData'));
}

// Sets data of client in session storage
export function setClientData(data) {
  setStorage('clientData', JSON.stringify(data));
}

// Gets data of season ID from session storage
export function setSeasonIdForPreEvent(id) {
  setStorage('seasonIdForPreEvent', id);
}

// Sets data of season ID in session storage
export function getSeasonIdForPreEvent() {
  return getStorageItem('seasonIdForPreEvent')
}

// Toaster for success with autoclose with 5 sec
export function toastSuccess(msg) {
  toast.success(msg, {
    autoClose: 5000,
  });
}

// Toaster for error with autoclose with 10 sec
export function toastError(msg) {
  toast.error(msg, {
    autoClose: 10000,
  });
}