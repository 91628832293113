import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import "./style.css";

import { MusicUpload } from "../../icons/MusicUpload";
import { PlayIcon2 } from "../../icons/PlayIcon2";
import { FiRrCloudUpload } from "../../icons/FiRrCloudUpload";

import { Expand } from "../Expand";
import { FrameWrapper3 } from "../FrameWrapper3";
import { toastError } from '../../constants/Constants';

export const PropertyDefaultWrapperMusic = ({
  property1,
  className,
  divClassName,
  text = "Event ID",
  text1 = "",
  visible = true,
  visibleupload = true,
  frameWrapperVisible,
  frameWrapperVisibleinfo,
  frameWrapperText = "Please upload MP3 format only.",
  divClassNameOverride,
  expandPolygon = "/img/polygon-1-3.svg",
  expandPolygonClassName,
  frameWrapperCheckboxPropertyFiRrClassName,
  onFileChange,
}) => {
  const [selectedFile, setSelectedFile] = useState(null);

  // Opens file explorer to select the desired file
  const handleDivClick = () => {
    document.getElementById('fileInputMusic').click();
  };

  // Adds the user selected image file to the UI
  const handleFileInputChange = (eventFile) => {
    const file = eventFile.target.files[0];
    if (file) {
      let allowedTypes;
      if (text1 === 'Upload Video') {
        allowedTypes = ['video/mp4'];
      } else {
        allowedTypes = ['audio/mpeg'];
      }
      if (allowedTypes.includes(file.type)) {
        setSelectedFile(file);
        onFileChange(file);
      } else {
        toastError(`Invalid file type. Please select a ${text1 === 'Upload Video' ? '.mp4' : '.mp3'}  file`);
      }
    }
  };

  return (
    <div className={`property-default-wrapper-music property-1-0-${property1} ${className}`}>
      <div className="frame-15">
        <div className="event-ID-wrapper">
          <div className={`event-ID ${divClassName}`}>{text}</div>
        </div>
        <div className="frame-16">
          <div className="frame-17" onClick={handleDivClick}>

            {["default", "variant-3"].includes(property1) && (
              <>
                <div className="frame-18">
                  <div className="element-wrapper">
                    <div className="element" title={selectedFile ? selectedFile.name : ""}>{selectedFile ? selectedFile.name : text1}</div>
                    <input
                      id="fileInputMusic"
                      type="file"
                      accept={text1 === 'Upload Video' ? ".mp4" : ".mp3"}
                      className="element"
                      name="FileUpload"
                      onChange={handleFileInputChange}
                      style={{ display: 'none' }}
                    />
                  </div>
                </div>{visibleupload && <FiRrCloudUpload />}
                <>{visible && <Expand polygon={expandPolygon} polygonClassName={expandPolygonClassName} />}</>
              </>
            )}

            {property1 === "variant-2" && (
              <>
                <div className="frame-19">
                  <div className="frame-18">
                    <div className="text-wrapper-4">{text1}</div>
                  </div>
                  <Expand polygon="/img/polygon-1-3.svg" />
                </div>
                <div className="frame-20">
                  <div className="frame-21">
                    <div className="frame-18">
                      <div className="text-wrapper-4">background-music.mp3</div>
                    </div>
                    <PlayIcon2 className="instance-node" />
                  </div>
                  <div className="frame-21">
                    <div className="frame-18">
                      <div className="text-wrapper-4">background-music.mp3</div>
                    </div>
                    <PlayIcon2 className="instance-node" />
                  </div>
                  <div className="frame-21">
                    <div className="frame-18">
                      <div className="text-wrapper-4">background-music.mp3</div>
                    </div>
                    <PlayIcon2 className="instance-node" />
                  </div>
                  <div className="frame-22">
                    <div className="frame-18">
                      <div className="text-wrapper-5">Upload background music clip</div>
                    </div>
                    <MusicUpload className="instance-node" />
                  </div>
                </div>
              </>
            )}


          </div>
          <FrameWrapper3
            checkboxPropertyFiRrClassName={frameWrapperCheckboxPropertyFiRrClassName}
            className="frame-1027"
            text={frameWrapperText}
            visible={frameWrapperVisible}
            visibleinfo={frameWrapperVisibleinfo}
          />
        </div>
      </div>
    </div>
  );
};

PropertyDefaultWrapperMusic.propTypes = {
  property1: PropTypes.oneOf(["variant-2", "variant-3", "default"]),
  text: PropTypes.string,
  text1: PropTypes.string,
  visible: PropTypes.bool,
  frameWrapperVisible: PropTypes.bool,
  frameWrapperText: PropTypes.string,
  expandPolygon: PropTypes.string,
};
