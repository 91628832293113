import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { connect, useDispatch } from "react-redux";

import { StyledEngineProvider } from '@mui/material/styles';
import { Box, CircularProgress } from '@mui/material';
import "./style.css";

import { PreEventDetails } from "../PreEventDetails";
import DatagridPreEvent from "../DatagridPreEvent";

import DropDown from "../../../components/DropDown/DropDown";

import homeImage from '../../../icons/ImageIcons/material-symbols_home-rounded.svg';
import rightarrow from '../../../icons/ImageIcons/keyboard-arrow-right.svg';
import searchicon from '../../../icons/ImageIcons/searchicon.png';

import { removeItem, getClientData, setClientData } from "../../../constants/Constants";
import { applySeasonSettings, completeStep, FetchEventList, initializeValues, setSearchQueryForPreevent } from "../../../store/Actions/PreEventAction";
import { getLoginUser } from "../../../store/Actions/SharedAction";
import { Property1Variant4 } from "../../../icons/Property1Variant4";

const PreEventDetailsDashboard = (props) => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const queryValue = queryParams.get('Query');

    const [searchQuery, setSearchQuery] = useState(props.searchQueryForPre || "");
    const [seasonId, setSeasonId] = useState(0);
    const [filteredData, setFilteredData] = useState([]);
    const [rows, setRows] = useState([]);
    const [selectedRowCount, setSelectedRowCount] = useState([]);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [uatUser, setUatUser] = useState(false);
    const [loadingOperation, setLoadingOperation] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const clientData = getClientData();

    useEffect(() => {
        const fetchData = async () => {
            if (queryValue) {
                await props.getLoginUser(queryValue);
            } else {
                setLoading(true);
            }
        };

        fetchData();

        for (let step = 1; step <= 6; step++) {
            dispatch(completeStep(step, null));
        }
        props.initializeValues();
    }, [])

    useEffect(() => {
        filterDataFun(searchQuery, seasonId);
    }, [seasonId, rows])

  useEffect(() => {
    const fetchData = async () => {
      setLoadingOperation(true);
      if (props.loginUserDetails) {
        setLoading(false);
        if (props.loginUserDetails?.result.isUATUser) {
          if (window.APP.environment === 'dev' || window.APP.environment === 'qa') {
            setUatUser(true);
            setLoading(true);
            return;
          }
        }
        removeItem('clientData');
        setClientData(props.loginUserDetails?.result);
        await props.loadevent(
          props.loginUserDetails.result.clientID,
          props.loginUserDetails.result.contactID
        ).then(() => {
          setLoadingOperation(false);
        })
          .catch(error => {
            // Handle error if necessary
            console.error('Error loading event:', error);
          });

      } else if (clientData) {
        setLoading(false);
        if (clientData.isUATUser) {
          if (window.APP.environment === 'dev' || window.APP.environment === 'qa') {
            setUatUser(true);
            setLoading(true);
            return;
          }
        }
        await props.loadevent(
          clientData.clientID,
          clientData.contactID
        ).then(() => {
          setLoadingOperation(false);
        })
          .catch(error => {
            // Handle error if necessary
            console.error('Error loading event:', error);
          });
      }
      else if (props.loginUserDetails?.isSuccess === false) {
        setLoading(true);
      }
    };

    fetchData();
  }, [props.loginUserDetails?.isSuccess]);
  

    useEffect(() => {
        setRows((Array.isArray(props.eventlist?.result) ? props.eventlist?.result : []).map(row => ({
            id: row.eventId,
            eventName: row.eventName,
            eventDateTime: row.EventDateTime,
            clientName: row.clientName,
            template: row.template,
            clientId: row.clientId,
            eventId: row.eventId,
            eventstatus: row.status, // Assuming 'status' is the field for event status
            eventstatuspercent: `${(row.percentComplete * 100).toFixed(0)}%`, // Assuming percentComplete is a value between 0 and 1
            seasonName: row.seasonName,
            adminEmail: row.adminEmail,
            userEmail: row.userEmail,
            seasonId: row.seasonId
        })));
    }, [props?.eventlist])

  // Opens modal for appl season settings
  const openModal = () => {
    setIsModalOpen(true);
  };

  // Closes modal for appl season settings
  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Set the input value added by user into search query
  const handleSearch = (event) => {
    const query = event.target.value;
    filterDataFun(query, seasonId);
    props.setSearchQueryForPreevent(query);
  };

  // Filters the rows based on seasons and input value
  const filterDataFun = (query, seasonId) => {
    console.log("rows", query, seasonId, rows);
    setSearchQuery(query);

    if (seasonId === '0') {
      const filteredRows = rows.filter(row => {
        // Customize the search logic based on your requirements
        return (
          (row.eventName.toLowerCase().includes(query.toLowerCase()) ||
            row.eventId.toString().includes(query) ||
            row.template.toLowerCase().includes(query.toLowerCase()))
        );
      });
      setFilteredData(filteredRows);
    } else {
      const filteredRows = rows.filter(row => {
        // Customize the search logic based on your requirements
        return (
          (row.eventName.toLowerCase().includes(query.toLowerCase()) ||
            row.eventId.toString().includes(query) ||
            row.template.toLowerCase().includes(query.toLowerCase()))
          && (
            (seasonId > 0 && row.seasonId == seasonId) || !seasonId
          )
        );
      });
      setFilteredData(filteredRows);
    }
  }

  // Changes the choice of season option
  const onChangeSeason = (id) => {
    setSeasonId(id);
    setSearchQuery('');
  }

  // Filtering events based on current season
  const filterEventsBySeason = (events) => {
    let selectedSeason;

    if (seasonId) {
      // Find the season object based on the seasonId
      selectedSeason = props.seasons?.find(season => season.seasonId === seasonId);
    } else {
      const currentDate = new Date();
      selectedSeason = props.seasons?.find(season => {
        const startDate = new Date(season.startDate);
        const endDate = new Date(season.endDate);
        return currentDate >= startDate && currentDate <= endDate;
      });
    }

    if (selectedSeason) {
      return events.filter(event => {
        const eventDate = new Date(event.eventDateTime);
        return eventDate >= new Date(selectedSeason.startDate) && eventDate <= new Date(selectedSeason.endDate);
      });
    }

    // If no season is selected or found, return all events
    return events;
  };

  const filteredEvents = filterEventsBySeason(searchQuery.trim() !== "" || seasonId > 0 ? filteredData : rows);

  // Apply season setting for selected event ID
  const handleApplySeasonSettings = async () => {
    closeModal();
    setLoadingOperation(true);
    const selectedSeason = props.seasons.find(season => season.seasonId == seasonId);
    const details = {
      ClientId: clientData.clientID,
      SeasonName: selectedSeason.seasonName,
      StartPeriod: selectedSeason.startDate,
      EndPeriod: selectedSeason.endDate,
      EventIds: selectedRowCount,
      ContactId: clientData.contactID
    }
    await props.applySeasonSettings(details);
    await props.loadevent(
      clientData.clientID,
      clientData.contactID
    );
    setLoadingOperation(false);
  }

    return (
<>
    <div className="pre-event-details-dashboard">
      <div className="div-2">
        <PreEventDetails />
        <div className="frame-19">
          <div className="frame-20">
            <div className="section-title">
              <div className="frame-21">
                <div className="page-title">
                  <div className="pagetitle">Pre Event Details</div>
                </div>
                <div className="breadcrum">
                  <img
                    className="material-symbols"
                    alt="Material symbols"
                    src={homeImage}
                  />
                  <img
                    className="keyboard-arrow-right"
                    alt="Keyboard arrow right"
                    src={rightarrow}
                  />
                  <div className="text-wrapper-4">Pre Event Details</div>
                  <img
                    className="keyboard-arrow-right"
                    alt="Keyboard arrow right"
                    src={rightarrow} />
                  <div className="text-wrapper-5">Event Dashboard</div>
                </div>
              </div>
            </div>
            <div className="frame-data">

              <div className="frame-datasearch">

                <div className="frame-datasearch2">
                  <div className="search">
                    <p className="label-and">
                      <input className="input"
                        placeholder="Search"
                        type="text"
                        value={searchQuery}
                        onChange={handleSearch} />
                    </p>
                    <img
                      className="generic"
                      alt="Generic"
                      src={searchicon}
                    />
                  </div>

                </div>

                <div className="frame-datafilter">
                  <StyledEngineProvider injectFirst>
                                        <DropDown onChangeSeason={onChangeSeason} />
                  </StyledEngineProvider>
                </div>
                <div className="frame-datafilter2">
                  <button type="button" class="apply-season-settings" disabled={selectedRowCount.length == 0 || seasonId === '0'} onClick={openModal}>Apply Season Settings</button>
                </div>
              </div>

              <div className="frame-datagrid">
                <StyledEngineProvider injectFirst>
                  <DatagridPreEvent rows={filteredEvents} setSelectedRowCount={setSelectedRowCount} selectedRowCount={selectedRowCount} />
                </StyledEngineProvider>
              </div>

            </div>

          </div>

        </div>
      </div>
      {loading && (
        <div className="video-modal">
          <div className="video-player-container">

            <div className="frame-success-model">
              <Box sx={{ justifyContent: 'center', marginTop: '10px', width: '50%' }}></Box>
              {uatUser ? <div style={{ textAlign: 'center', marginTop: '10px', fontFamily: 'Poppins', fontSize: '1.2em' }} className="text-wrapper">It seems that you are trying to access DEV or QA environment.<br /><br /> Kindly refer to UAT environment for furthur testing.</div> 
              : <div style={{ textAlign: 'center', marginTop: '10px', fontFamily: 'Poppins', fontSize: '1.2em' }} className="text-wrapper">You are not logged in.<br /><br /> To log in please <Link className="event-ID group-4" style={{ fontFamily: 'Poppins', color: 'blue' }} onClick={() => { window.location.href = `${ window.APP.loginUrl}` }}>Click Here</Link></div>
              }
            </div>
          </div>
        </div>
      )}
      {loadingOperation && (
            <div className="loader-container">
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px' }}>
              <CircularProgress />
            </Box>
        </div>
          )}
    </div>
    {isModalOpen && (
      <div className="video-modal" onClick={closeModal}>
        <div className="video-player-container">
          <button className="close-button" onClick={closeModal}>&times;</button>
          <div className="frame-model">
            <div className="div-model">
              <Property1Variant4 className="failed-loader" color="#EA690C" />
              <p className="once-published">
                <span className="text-wrapper">
                  This change will overwrite all of your changes from the event.
                  <br />
                  Do you still want to continue?
                </span>
              </p>
            </div>
            <div className="div-2-model">
              <div className="no-BTN">
                <div className="button-text-2" onClick={closeModal}>No</div>
              </div>
              <div className="button-text-wrapper" onClick={handleApplySeasonSettings}>
                <div className="button-text-2">Yes</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )}
    </>
  );
};

const mapStateToProps = (state) => {
    return {
        eventlist: state.preEvent.eventlist,
        loginUserDetails: state.shared.loginUserDetails,
        seasons: state.shared.seasonList?.result,
        selectedSeasonForPre: state.preEvent.selectedSeasonForPre,
        searchQueryForPre: state.preEvent.searchQueryForPre,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        loadevent: (clientId, contactId) => dispatch(FetchEventList(clientId, contactId)),
        getLoginUser: (query) => dispatch(getLoginUser(query)),
        applySeasonSettings: (data) => dispatch(applySeasonSettings(data)),
        setSearchQueryForPreevent: (seasonId) => dispatch(setSearchQueryForPreevent(seasonId)),
        initializeValues: () => dispatch(initializeValues()),
    };
};

const ConnectedPreEventDetailsDashboard = connect(mapStateToProps, mapDispatchToProps)(PreEventDetailsDashboard);

export { ConnectedPreEventDetailsDashboard as PreEventDetailsDashboard };