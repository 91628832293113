import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Provider, useSelector } from "react-redux";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Store from "./store";

import { StartUp } from "./screens/SeasonSettings/StartUp";
import { SelectTemplateSettings } from "./screens/SeasonSettings/SelectTemplateSettings";
import { MediaDetailsSettings } from "./screens/SeasonSettings/MediaDetailsSettings";
import { CreateIntroClipSettings } from "./screens/SeasonSettings/CreateIntroClipSettings";
import { CreateIntroClipFrameSeasonSettings } from "./screens/SeasonSettings/CreateIntroClipFrame/CreateIntroClipFrame";
import { CreateStudentClipSettings } from "./screens/SeasonSettings/CreateStudentClipSettings";
import { CreateExitClipSettings } from "./screens/SeasonSettings/CreateExitClipSettings";
import { CreateExitClipFrameSeasonSettings } from "./screens/SeasonSettings/CreateExitClipFrame/CreateExitClipFrame";

import { PreEventDetailsDashboard } from "./screens/PreEventDetails/PreEventDetailsDashboard/PreEventDetailsDashboard";
import { EventOverview } from "./screens/PreEventDetails/EventOverview";
import { SelectTemplate } from "./screens/PreEventDetails/SelectTemplate";
import { MediaDetails } from "./screens/PreEventDetails/MediaDetails";
import { CreateIntroClip } from "./screens/PreEventDetails/CreateIntroClip";
import { CreateIntroClipFrame } from "./screens/PreEventDetails/CreateIntroClipFrame/CreateIntroClipFrame";
import { StudentClip } from "./screens/PreEventDetails/StudentClip/StudentClip";
import { CreateExitClip } from "./screens/PreEventDetails/CreateExitClip";
import { CreateExitClipFrame } from "./screens/PreEventDetails/CreateExitClipFrame";
import { ConfirmPreEventDetails } from "./screens/PreEventDetails/ConfirmPreEventDetails";

import { CustomVideoReviewDashboard } from "./screens/CustomVideoReview/CustomVideoReviewDashboard/";
import { PendingVideoDashboard } from "./screens/CustomVideoReview/PendingVideosDashboard/PendingVideoDashboard"

import { PostEventDetailsDashboard } from "./screens/PostEventDetails/PostEventDetailsDashboard/PostEventDetailsDashboard"
import { PostEventOverview } from "./screens/PostEventDetails/PostEventOverview";
import { PostEventVideoProcessing } from "./screens/PostEventDetails/PostEventVideoProcessing";

import { HandshakeTimestampComponent } from "./screens/HandshakeTimestampComponent/HandshakeTimestampComponent";
import UploadProgressModal from "./components/UploadProgressModal/UploadProgressModal";
import useBeforeUnload from "./userBeforeUnload";

const App = () => {
    return (
        <Provider store={Store}>
            <MainApp />
            <UploadProgressModal />
            <ToastContainer autoClose='10000' />
        </Provider>
    );
};

const MainApp = () => {
    // Access the Redux state within the App component
    const uploads = useSelector((state) => state.postEvent.uploads);
    
    // Use the useBeforeUnload hook with a callback that checks the uploads state
    useBeforeUnload(() => uploads.length > 0);

    return (
        <Router>
                <Routes>
                    <Route path="/" element={<StartUp />} />
                    <Route path="/SelectTemplateSettings" element={<SelectTemplateSettings />} />
                    <Route path="/MediaDetailsSettings" element={<MediaDetailsSettings />} />
                    <Route path="/CreateIntroClipSettings" element={<CreateIntroClipSettings />} />
                    <Route path="/SeasonSettings/CreateIntroClipFrame" element={<CreateIntroClipFrameSeasonSettings />}></Route>
                    <Route path="/StudentClipSettings" element={<CreateStudentClipSettings />} />
                    <Route path="/CreateExitClipSettings" element={<CreateExitClipSettings />} />
                    <Route path="/SeasonSettings/CreateExitClipFrame" element={<CreateExitClipFrameSeasonSettings />}></Route>

                    <Route path="/PreEventDetailsDashboard" element={<PreEventDetailsDashboard />} />
                    <Route path="/EventOverview" element={<EventOverview />} />
                    <Route path="/SelectTemplate" element={<SelectTemplate />} />
                    <Route path="/MediaDetails" element={<MediaDetails />} />
                    <Route path="/CreateIntroClip" element={<CreateIntroClip />} />
                    <Route path="/CreateIntroClipFrame" element={<CreateIntroClipFrame />} />
                    <Route path="/StudentClip" element={<StudentClip />} />
                    <Route path="/CreateExitClip" element={<CreateExitClip />} />
                    <Route path="/CreateExitClipFrame" element={<CreateExitClipFrame />} />
                    <Route path="/ConfirmPreEventDetails" element={<ConfirmPreEventDetails />} />

                    <Route path="/CustomVideoReviewDashboard" element={<CustomVideoReviewDashboard />} />
                    <Route path="/PendingVideoDashboard" element={<PendingVideoDashboard />} />

                    <Route path="/PostEventDetailsDashboard" element={<PostEventDetailsDashboard />} />
                    <Route path="/PostEventOverview" element={<PostEventOverview />} />
                    <Route path="/PostEventVideoProcessing" element={<PostEventVideoProcessing />} />

                    <Route path="/HandshakeTimestampComponent" element={<HandshakeTimestampComponent />}></Route>

                </Routes>
            </Router>
    );
};

export default App;
