import PropTypes from "prop-types";
import React, { useState } from "react";
import "./style.css";

export const Subitem = ({ property1, className, divClassName, text = "Home" }) => {
  const [variant, setVariant] = useState(property1 || "frame-966");

  const handleMouseEnter = () => {
    if (variant === "frame-966") {
      setVariant("variant-2");
    }
  };

  const handleMouseLeave = () => {
    if (variant === "variant-2") {
      setVariant("frame-966");
    }
  };

  const handleClick = () => {
    if (variant === "variant-2") {
      setVariant("variant-3");
    } else if (variant === "variant-3") {
      setVariant("frame-966");
    }
  };

  return (
    <div
      className={`subitem ${variant} ${className}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    >
      <div className={`home-2 ${divClassName}`}>{text}</div>
    </div>
  );
};

Subitem.propTypes = {
  property1: PropTypes.oneOf(["variant-2", "variant-3", "frame-966"]),
  text: PropTypes.string,
};
