import React,{useEffect, useState} from "react";
import { Link, useNavigate } from 'react-router-dom';
import { connect, useDispatch } from "react-redux";

import { StyledEngineProvider } from '@mui/material/styles';
import { Box, CircularProgress } from "@mui/material";
import "./style.css";
import { toastError } from '../../../constants/Constants';
import { PreEventDetails } from "../PreEventDetails";
import Stepper from '../Stepper';

import homeImage from '../../../icons/ImageIcons/material-symbols_home-rounded.svg';
import rightarrow from '../../../icons/ImageIcons/keyboard-arrow-right.svg';

import { FrameSelectTemplate } from "../../../components/FrameSelectTemplate/FrameSelectTemplate";

import { completeStep, createEventMaster, FetchGradreelType, postTemplateData, setEventSuccess } from "../../../store/Actions/PreEventAction";


const SelectTemplate = ({loading, loadevent, eventSuccess, eventDetails, gradreelTypeData, createEventMaster, postTemplateData, setEventSuccess, eventState, seasonList }) => {
  const navigate = useNavigate();
  const [gradReelType, setGradReelType] = useState(null);
  const [eventData, setEventData] = useState({});
  const [isButtonSelected, setIsButtonSelected] = useState(false);
  // const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      let updateData;
      if (eventDetails) {
        updateData = { eventId: eventDetails.eventId, clientId: eventDetails.clientId };
        await loadevent(updateData);
      }
    })();
  }, [])

  useEffect(() => {
      if (!eventDetails) {       
        navigate('/PreEventDetailsDashboard')
      }
  }, [eventDetails]);

  useEffect(() => {
    if (eventSuccess) {
      console.log("Event created successfully!");
      setEventSuccess(false)
      const path = isButtonSelected ? "/MediaDetails" : "#";
      navigate(path);
      dispatch(completeStep(1, true));
    }
  }, [eventSuccess, isButtonSelected]);


  useEffect(() => {
    setEventData(gradreelTypeData);
    setGradReelType(gradreelTypeData?.result?.eventData?.template);
    if(gradreelTypeData?.result?.eventData?.template){
    setIsButtonSelected(true);
    }else{
      setGradReelType("");
      setIsButtonSelected(false);
    }
  }, [gradreelTypeData]); 

  // Changes the choice of selecting gradreel template option
  const handleGradReelTypeChange = (type) => {
    setGradReelType(type);
    setIsButtonSelected(true);
  };

   // Creates a event or update the existing event
  const handleSaveAndContinue = async () => {
    if (!isButtonSelected) {
      return;
    }
    try {
      if (eventState) {
        navigate("/MediaDetails");
      } else {
        if (!eventData.result.isDataAvailable) {
          // setLoading(true);
          const selectedSeason = seasonList?.find(season => season.seasonId === eventDetails?.seasonId);
          const updatedEventData = {
            clientId: eventDetails?.clientId,
            clientName: eventDetails?.clientName,
            eventId: eventDetails?.eventId,
            eventName: eventDetails?.eventName,
            seasonName: eventDetails?.seasonName,
            seasonId: eventDetails?.seasonId,
            seasonStartDate: selectedSeason?.startDate,
            seasonEndDate: selectedSeason?.endDate,
            template: gradReelType,
            adminEmail: eventDetails?.adminEmail,
            userEmail: eventDetails?.adminEmail,
            eventDateTime: eventDetails.eventDateTime
          }
          await createEventMaster(updatedEventData);
        } else {
          // setLoading(true);

          const updatedData = {
            userEmail: eventDetails?.adminEmail,
            clientId: gradreelTypeData?.result?.eventData?.clientId,
            eventId: gradreelTypeData?.result?.eventData?.eventId,
            templateType: gradReelType,
          };
          await postTemplateData(updatedData);
        }
        // setLoading(false);
      }
    } catch (error) {
      toastError(`Error: ${error.message || "Unknown error"}`);
      console.error("Error:", error?.message);
    }
  };

  return (
    <div className="select-template">
      <div className="div-2">
      <PreEventDetails />
        
        <div className="frame-19">
          <div className="frame-20">
            <div className="section-title">
              <div className="frame-21">
                <div className="page-title">
                  <div className="text-wrapper-3">Select Template</div>
                </div>
                <div className="div-3">
                  <img
                    className="material-symbols"
                    alt="Material symbols"
                    src={homeImage}
                  />
                  <img
                    className="keyboard-arrow-right"
                    alt="Keyboard arrow right"
                    src={rightarrow}
                  />
                  <div className="text-wrapper-4">Events</div>
                  <img
                    className="keyboard-arrow-right"
                    alt="Keyboard arrow right"
                    src={rightarrow}
                  />
                  <div className="text-wrapper-5">Select Template</div>
                </div>
              </div>
            </div>
            <div className="frame-22">
              <div className="frame-23">
              <StyledEngineProvider injectFirst>
                <Stepper  gradReelType={gradReelType} handleGradReelTypeChange={handleGradReelTypeChange}/>
              </StyledEngineProvider>
              </div>
              <FrameSelectTemplate onGradReelTypeChange={handleGradReelTypeChange} selectedType={gradReelType}/>
                
            </div>
          </div>
          <div className="frame-36">
            <Link to="/EventOverview" className="button-text-2">
              <div className="primary-BTN-2">
                Back
              </div>
            </Link>
            <div className={`primary-BTN-3 ${isButtonSelected ? "" : "disabled"}`} onClick={handleSaveAndContinue} >
              <button className="button-text-3">
                Save & Continue
              </button>
</div>
          </div>
          {loading && (
            <div className="loader-container">
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px' }}>
              <CircularProgress />
            </Box>
          </div>
          )}
        </div>
        
      </div>
      
    </div>
  );
};


const mapStateToProps = (state) => {
  return {
    eventSuccess: state.preEvent.eventSuccess,
    loading: state.preEvent.loading,
    eventlist: state.preEvent.eventlist,    
    eventDetails: state.preEvent.eventDetails,
    gradreelTypeData: state.preEvent.gradreelType,
    eventState: state.preEvent.eventState,
    seasonList: state.preEvent.seasonList?.result
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadevent: (eventData) => dispatch(FetchGradreelType(eventData)),
    createEventMaster: (eventData) => dispatch(createEventMaster(eventData)),
    postTemplateData: (eventData) => dispatch(postTemplateData(eventData)),
    setEventSuccess: (flag) => dispatch(setEventSuccess(flag)),
  };
};

const ConnectedSelectTemplate = connect(mapStateToProps, mapDispatchToProps)(SelectTemplate);

export { ConnectedSelectTemplate as SelectTemplate };