import PropTypes from "prop-types";
import React from "react";

import "./style.css";

import { StandardBtn } from "../StandardBtn";
import { StandardCustom } from "../StandardCustom";

export const FrameSelectTemplate = ({ selectedType, onGradReelTypeChange, selectedSeason=null }) => {
  
  return (
    <div className="select-gradreel-type">
      <div className="frame-select-gradreel">
        <div className="frame-select-gradreel2">
          <div className="frame-select-gradreelheader">
            <div className="text-wrapper-8">Select GradReel Type</div>
          </div>
          <div className="frame-select-gradreelbuttons">
            <div className="frame-select-gradreelbuttons2">
              <StandardBtn property1="default" text="Standard GradReel" onGradReelTypeChange={() => onGradReelTypeChange('Standard')}   selected={selectedType === 'Standard'}/>
              <StandardBtn property1="default" text="Custom GradReel" onGradReelTypeChange={() => onGradReelTypeChange('Custom')}   selected={selectedType === 'Custom'}/>
            </div>
          </div>
        </div>
        <StandardCustom className="standard-custom-gradreel" frameWrapperVisible={false} property1="default" selectedSeason= {selectedSeason}/>
      </div>
    </div>
  );
};

FrameSelectTemplate.propTypes = {
  onGradReelTypeChange: PropTypes.func.isRequired,
};
