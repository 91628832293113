/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

// import React from "react";
// import { FiRrEdit1 } from "../../icons/FiRrEdit1";
// import "./style.css";

// export const PropertyDefault = ({ className, icon = <FiRrEdit1 className="fi-rr-edit" /> }) => {
//   return (
//     <div className={`property-default ${className}`}>
//       <div className="congratulations-wrapper">
//         <div className="congratulations">CONGRATULATIONS</div>
//       </div>
//       <div className="fi-rr-edit-wrapper">{icon}</div>
//     </div>
//   );
// };
import React, { useState } from "react";
import { FiRrEdit1 } from "../../icons/FiRrEdit1";
import "./style.css";

export const PropertyDefault = ({ className, icon = <FiRrEdit1 className="fi-rr-edit" />,onclick,id,headvalue, onChange, value  }) => {

  const [inputWidth, setInputWidth] = useState((value.length + 1) * 25);
  
  const handleInputChange = (e) => {
    let newText = e.target.value;
    setInputWidth((newText.length + 1) * 25);
    if (onChange) {
      onChange(newText); // Pass the updated text content to the parent component
    }
  };

  return (
    <div className={`property-default ${className}`}>
      <div className="congratulations-wrapper">
      <input className="congratulations" style={{ fontFamily: 'Source Sans Pro', color: headvalue !='' ? headvalue : '#6e6e6e',  width: inputWidth + 'px', maxWidth: '100%'}} maxLength={18} value={value} onChange={(e)=>handleInputChange(e)}/>
         {/* <div className="congratulations" style={{color: headvalue !='' ? headvalue : '#6e6e6e'}} onKeyDown={handleKeyDown} contentEditable onInput={handleInputChange}>{value || 'CONGRATULATIONS'}</div>  */}
         <div className="fi-rr-edit-wrapper" id={id} onClick={onclick}>{icon}</div>
      </div>
      
    </div>
  );
};