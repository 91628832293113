import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { connect } from "react-redux";

import { Box, CircularProgress } from "@mui/material";
import { StyledEngineProvider } from '@mui/material/styles';
import "./style.css";

import { SeasonSettings } from "../SeasonSettings";
import Tabs from '../Tabs';

import homeImage from '../../../icons/ImageIcons/material-symbols_home-rounded.svg';
import rightarrow from '../../../icons/ImageIcons/keyboard-arrow-right.svg';

import { RadioButtons4 } from "../../../icons/RadioButtons4";
import { RadioButtons5 } from "../../../icons/RadioButtons5";

import DropDownSeasonSettings from "../../../components/DropDownSeasonSettings/DropDownSeasonSettings";
import { AudioClipSection } from "../../../components/AudioClipSection";


import { getClientData, toastError } from "../../../constants/Constants";
import { createSeasonSettingsClientMaster, fetchClientMaster, fetchMediaDetailsForSettings, getSelectedSeason, postMediaDetailsForSettings, setEventSuccess } from "../../../store/Actions/SeasonSettingsAction";

const MediaDetailsSettings = ({ loading, fetchClientMaster, fetchMediaDetailsForSettings, clientMasterData, uploadMediaDetails, mediaDetailsForSettings, selectedSeason, getSelectedSeason, eventSuccess, setEventSuccess, createSeasonSettingsClientMaster }) => {

  const navigate = useNavigate();
  const clientData = getClientData();

  const [videoSrc, setVideoSrc] = useState("");
  const [mediaUrl, setMediaUrl] = useState(null);
  const [showStreamingDetails, setShowStreamingDetails] = useState(false);
  const [isButtonSelected, setIsButtonSelected] = useState(false);
  const [seasonId, setSeasonId] = useState(0);
  const [seasonName, setSeasonName] = useState('');
  const [seasonStartPeriod, setSeasonStartPeriod] = useState('');
  const [seasonEndPeriod, setSeasonEndPeriod] = useState('');
  const [mediaDetails, setMediaDetails] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [chooseMusicClipFromLib, setChooseMusicClipFromLib] = useState(false);
  const [streamingUrl, setStreamingUrl] = useState('');
  const [streamingProvider, setStreamingProvider] = useState('Mediasite');
  const [timezone, setTimeZone] = useState(true);
  const [selectedMusicClipId, setSelectedMusicClipId] = useState("");
  const [selectedMusicClipName, setSelectedMusicClipName] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [isUploading, setIsUploading] = useState(loading);
  
  useEffect(() => {
    const fetchData = async () => {
      if (seasonName) {
        await fetchClientMaster({ clientId: clientData.clientID, seasonName: seasonName });
        await fetchMediaDetailsForSettings({ clientId: clientData.clientID, seasonName: seasonName });
      }
    };
  
    fetchData();
  }, [seasonName, selectedSeason]);

  useEffect(() => {
    if (eventSuccess && !loading) {
      setEventSuccess(false);
      const path = isButtonSelected ? "/CreateIntroClipSettings" : "#";
      navigate(path);
    }
  }, [eventSuccess, isButtonSelected, loading]);

  useEffect(() => {
    if (clientMasterData?.result?.isDataAvailable) {
      console.log(clientMasterData);
      setMediaDetails(clientMasterData)
      // if(clientMasterData?.result?.data?.template){
      //   setIsButtonSelected(true)
      // }
    }
  }, [clientMasterData])

  useEffect(() => {
    if(mediaDetailsForSettings?.result?.mediaData?.chooseMusicClipFromLib !== undefined){
      setChooseMusicClipFromLib(mediaDetailsForSettings?.result?.mediaData?.chooseMusicClipFromLib)
      setSelectedMusicClipId(mediaDetailsForSettings?.result?.mediaData?.selectedMusicCLipId)
    } 
    // else{
      // setChooseMusicClipFromLib(false);
      // setSelectedMusicClipId(null);
    // }

  }, [mediaDetailsForSettings?.result?.mediaData])

  useEffect(() => {
    if (mediaDetailsForSettings?.result?.mediaData?.uploadedMusicCLipPathSaSUri && !mediaDetailsForSettings?.result?.mediaData?.chooseMusicClipFromLib) {
      if(!mediaDetailsForSettings?.result?.mediaData?.selectedMusicCLipId){
      setMediaUrl(mediaDetailsForSettings?.result?.mediaData?.uploadedMusicCLipPathSaSUri)
      setIsChecked(true);
      }
      setIsButtonSelected(true);
    } else {
      setMediaUrl(null)
    }
  }, [mediaDetailsForSettings?.result?.mediaData?.uploadedMusicCLipPathSaSUri])

  useEffect(() => {
    setShowStreamingDetails(mediaDetailsForSettings?.result?.mediaData?.isStreamingEvent)
    setStreamingUrl(mediaDetailsForSettings?.result?.mediaData?.streamingUrl)
    setStreamingProvider(mediaDetailsForSettings?.result?.mediaData?.streamingProvider || 'Mediasite')
    setTimeZone(mediaDetailsForSettings?.result?.mediaData?.streamingTimeZone);
  }, [mediaDetailsForSettings?.result?.mediaData?.isStreamingEvent, mediaDetailsForSettings?.result?.mediaData?.streamingProvider, mediaDetailsForSettings?.result?.mediaData?.streamingUrl, mediaDetailsForSettings?.result?.mediaData?.streamingTimeZone])

  useEffect(() => {
    setIsButtonSelected(isValidDetails(isChecked));
  }, [isChecked, selectedFile, mediaUrl, streamingUrl, showStreamingDetails, selectedMusicClipId, chooseMusicClipFromLib])

  // Check if the values related to choose and upload music clip are available
  const isValidDetails = (isChecked) => {
   if(chooseMusicClipFromLib){
    return ((showStreamingDetails && streamingUrl) ||  !showStreamingDetails) && selectedMusicClipId;
   }else{
      return ((showStreamingDetails && streamingUrl) ||  !showStreamingDetails) && (mediaUrl   || selectedFile) && isChecked;
   }
    
  }

  // Checks if event is streamed online
  const handleCheckboxChange = (isChecked) => {
    setShowStreamingDetails(isChecked);
    setStreamingUrl('');
  };

  // Set the selected season name
  const handleSeasonName = (seasonName, seasonId) => {
    setSeasonName(seasonName);
    getSelectedSeason(seasonId);
    setSeasonId(seasonId);
  }

  // Set the selected season dates
  const handleSeasonDates = (startDate, endDate) => {
    setSeasonStartPeriod(startDate);
    setSeasonEndPeriod(endDate);
  }

  // Set the selected file by user
  const handleFileChangeInMediaDetails = (file) => {
    setSelectedFile(file);
    
  };

  // Changes the music clip option to upload or select
  const handleMusicClipOptionChange = (isChooseMusicClip) => {
    setChooseMusicClipFromLib(isChooseMusicClip);
    if(!isChooseMusicClip){
    setSelectedMusicClipId("");
    setSelectedMusicClipName('');
    } else {
      setSelectedMusicClipId(mediaDetailsForSettings?.result?.mediaData?.selectedMusicCLipId);
    }
  };

  // Sets the selected clip ID and name
  const handleSelectMusicClip = (clipId, clipName) => {
    setSelectedMusicClipId(clipId);
    setSelectedMusicClipName(clipName);
  };

  // Add the selected provider
  const handleStreamingProviderChange = (event) => {
    setStreamingProvider(event.target.value);
  };

  // Add the timezone
  const handleTimeZone = (event) => {
    setTimeZone(event.target.value);
  };

  // Add the streaming url
  const handleStreamingUrlChange = (event) => {
    setStreamingUrl(event.target.value);
  };

  const handleFileChange = (file) => {
    try {
      // Transform file into blob URL
      setVideoSrc(URL.createObjectURL(file));
    } catch (error) {
      console.error(error);
    }
  };

  // Uploads the media related data and calls the function to send data through API
  const handleSaveMediaDetails = async () => {
    if (!isButtonSelected) {
      return;
    }
    if (!seasonName) {
      toastError('Please select Season from dropdown.');
      return;
    }
    if(showStreamingDetails){
      if (!streamingUrl) {
        toastError('Please select the streaming details.');
        return;
    }
    }

    const uploadedMusicCLipPath = mediaDetailsForSettings?.result?.mediaData?.uploadedMusicCLipPath;

    if (chooseMusicClipFromLib) {
      if (!selectedMusicClipId) {
        toastError('Please select at least one media file from library.');
        return;
      }
    } else if((!selectedFile && !uploadedMusicCLipPath)){
      toastError('Please select at least one media file');
      return;
    }

    // setLoading(true);
    if(!clientMasterData?.result?.data?.template){
      await createSeasonSettingsClientMaster({
        itemType: "SeasonSettingsMaster",
        itemTypeDesc: "SeasonSettingsMaster",
        clientId: clientData.clientID,
        clientName: clientData.clientName,
        seasonId: seasonId,
        seasonName: seasonName,
        seasonStartDate: seasonStartPeriod,
        seasonEndDate: seasonEndPeriod,
        template: 'Standard',
        templateDesc: 'Standard',
      });
    }
    const mediaDetails = {
      id: '',
      clientId: clientData.clientID,
      clientName: clientData.clientName,
      seasonName: seasonName,
      seasonStartPeriod: seasonStartPeriod,
      seasonEndPeriod: seasonEndPeriod,
      isStreamingEvent: showStreamingDetails,
      streamingProvider: streamingProvider,
      streamingTimeZone: timezone,
      streamingUrl: streamingUrl,
      chooseMusicClipFromLib: chooseMusicClipFromLib,
      selectedMusicCLipId: chooseMusicClipFromLib ? selectedMusicClipId : '',
      selectedMusicClipPath: selectedMusicClipName,
      uploadedMusicCLipPath: !chooseMusicClipFromLib ? ( selectedFile  ? '' : uploadedMusicCLipPath) : '',
      updatedBy: clientData.userEmail
    };

    if (selectedFile) {
      await uploadMediaDetails(selectedFile, mediaDetails);
    } else {
      if(chooseMusicClipFromLib){
        setIsUploading(true);
        try {
          const file = await fetch(selectedMusicClipName);
          const fileBlob = await file.blob();
    
          let musicClipName;
          if (selectedMusicClipName.startsWith("/")) {
            musicClipName = selectedMusicClipName.substring(1);
        }

          await uploadMediaDetails(fileBlob, mediaDetails);
          setIsUploading(false);
        } catch (error) {
          console.error('Error:', error);
          setIsUploading(false);
        }
      } else if(!selectedFile && uploadedMusicCLipPath ) {
        await uploadMediaDetails(null, mediaDetails);
      }
    }
    // setLoading(false);
  }
  return (
    <div className="media-details-settings">
      <SeasonSettings />

      <div className="frame-29">
        <div className="frame-30">
          <div className="section-title">
            <div className="frame-31">
              <div className="page-title">
                <div className="text-wrapper-7">Season Settings</div>
              </div>
              <div className="div-2">
                <img
                  className="material-symbols"
                  alt="Material symbols"
                  src={homeImage}
                  />
                <img
                  className="keyboard-arrow-right"
                  alt="Keyboard arrow right"
                  src={rightarrow}
                  />
                <div className="text-wrapper-8">Settings</div>
                <img
                  className="keyboard-arrow-right"
                  alt="Keyboard arrow right"
                  src={rightarrow}
                  />
                <div className="text-wrapper-9">Media Details Settings</div>
              </div>
            </div>
          </div>
          <div className="label">
            <div className="event-ID">Select Season Name</div>
          </div>
          <StyledEngineProvider injectFirst>
            <DropDownSeasonSettings onSeasonsName={handleSeasonName} selectedSeason={selectedSeason} onSeasonDates={handleSeasonDates}/>
          </StyledEngineProvider>
          <StyledEngineProvider injectFirst>
            <Tabs />
          </StyledEngineProvider>
          <div className="frame-32">
            <div className="frame-33">
              {mediaUrl && !chooseMusicClipFromLib ? <><div className="frame-24">
                <div className="frame-34">
                  <div className="text-wrapper-10">Background Music Details</div>
                </div>
              </div><audio controls>
                  <source src={mediaUrl} type="audio/ogg" />
                </audio><div className="delete-button button-text-wrapper"><button className="button" type="button" onClick={() => { setMediaUrl(null); setIsButtonSelected(false); setIsChecked(false); }}>Delete</button></div></> :

                <div className="frame-34">
                  <AudioClipSection
                    className="design-component-instance-node-2"
                    frameWrapperCheckboxPropertyFiRrClassName="audio-clip-section-instance"
                    override={<RadioButtons5 className="icon-instance-node" />}
                    property1="default"
                    radioBtnMusicClipRadioOptionIcon={<RadioButtons4 className="icon-instance-node" />}
                    onFileChange={handleFileChangeInMediaDetails}
                    onMusicClipOptionChange={handleMusicClipOptionChange}
                    onSelectMusicClip={handleSelectMusicClip}
                    selectedMusicId = {selectedMusicClipId}
                  />
                { chooseMusicClipFromLib === false && <span className="checkboxtext" >
                    <input type="checkbox" className="frame-instance" onChange={(e) => setIsChecked(e.target.checked)} />
                    <span className="checkbox-label">We confirm ownership of all usage and distribution rights for the uploaded music clip.</span>
                  </span>}
                </div>}
              <div className="frame-37">
                <div className="frame-38">
                  <div className="frame-35">
                    <div className="text-wrapper-10">Streaming Details</div>
                  </div>
                  <span className="checkboxtext2">
                    <input type="checkbox" className="frame-instance2" checked={showStreamingDetails} onChange={(e) => handleCheckboxChange(e.target.checked)} />
                    <span className="checkbox-label2">Please check the option if event is streamed online</span>
                  </span>
                </div>
                <div className="frame-44" style={{ display: showStreamingDetails ? "inline-flex" : "none" }}>
                  <div className="event-ID-wrapper">
                    <div className={`event-ID group-4`}>Select Streaming Providers</div>
                    <select id="streamingprovider" className="element" value={streamingProvider} onChange={handleStreamingProviderChange}>
                      <option value="Mediasite">Mediasite</option>
                      <option value="Kaltura">Kaltura</option>
                      <option value="Vimeo">Vimeo</option>
                      <option value="IBM">IBM</option>
                    </select>
                  </div>
                  <div className="event-ID-wrapper">
                    <div className={`event-ID group-4`}>Enter Ceremony Streaming Url</div>
                    <input id="streamingurl" type="text" className="element" placeholder="https://" value={streamingUrl}
                      onChange={handleStreamingUrlChange} />
                  </div>




                </div>
                <div className="event-ID-wrapper" style={{ display: showStreamingDetails ? "inline-flex" : "none" }}>
                  <div className={`event-ID group-4`}>Time Zone</div>
                  <select id="streamingprovider" className="element" value={timezone} onChange={handleTimeZone}>
                    <option value="true" defaultValue={timezone}>True</option>
                    <option value="false">False</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="frame-36">
        <button className="primary-BTN-2">
                            <Link className="button-text-2" to="/SelectTemplateSettings" >Back</Link>
                        </button>
          <div className={`primary-BTN-3 ${isButtonSelected ? "" : "disabled"}`}>
            <Link

              className="button-text-3"
              onClick={handleSaveMediaDetails}
            >
              Save
            </Link>
          </div>
        </div>
        {(isUploading || loading) && (
          <div className="loader-container">
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px' }}>
              <CircularProgress />
            </Box>
          </div>
        )}
      </div>
    </div>
  );
};


const mapStateToProps = (state) => {
  return {
    loading: state.seasonSettings.loading,
    eventSuccess: state.seasonSettings.eventSuccess,
    clientMasterData: state.seasonSettings.clientMasterData,
    selectedSeason: state.seasonSettings.selectedSeason,
    mediaDetailsForSettings: state.seasonSettings.mediaDetailsForSettings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchClientMaster: (eventData) => dispatch(fetchClientMaster(eventData)),
    getSelectedSeason: (eventData) => dispatch(getSelectedSeason(eventData)),
    createSeasonSettingsClientMaster: (clientData) =>
      dispatch(createSeasonSettingsClientMaster(clientData)),
    fetchMediaDetailsForSettings: (eventData) => dispatch(fetchMediaDetailsForSettings(eventData)),
    uploadMediaDetails: (file, infoData) => dispatch(postMediaDetailsForSettings(file, infoData)),
    setEventSuccess: (flag) => dispatch(setEventSuccess(flag)),
  };
};

const ConnectedMediaDetailsSettings = connect(mapStateToProps, mapDispatchToProps)(MediaDetailsSettings);

export { ConnectedMediaDetailsSettings as MediaDetailsSettings };
