import axios from "axios"
import { toast } from "react-toastify"
import { toastSuccess, toastError } from "../../constants/Constants";
import { COMPLETE_STEP, CREATE_EVENT_FAILURE, CREATE_EVENT_START, CREATE_EVENT_SUCCESS, FAIL_REQUEST, GENERATE_VIDEO_FOR_EXIT, GENERATE_VIDEO_FOR_INTRO, GET_CUSTOM_REVIEW_EVENT_LIST, GET_EVENT_LIST, GET_EVENT_STATE, GET_EXIT_VIDEO_DATA, GET_GRADREEL_TYPE, GET_INTRO_VIDEO_DATA, GET_MEDIA_DETAILS, GET_STUDENT_CLIP_DATA, GET_STUDENT_DETAILS, GET_STUDENT_VIDEO_FOR_APPROVAL, GET_STUDENT_VIDEO_SUMMARY, INITIALIZE_VALUES_OF_PREEVENT, MAKE_REQUEST, SET_EVENT_DETAILS, SET_EVENT_SUCCESS, SET_SEARCH_QUERY_FOR_PRE } from "../ActionTypes/PreEventActionTypes";

export const makeRequest = () => {
  return {
    type: MAKE_REQUEST,
  };
};
export const failRequest = (err) => {
  return {
    type: FAIL_REQUEST,
    payload: err,
  };
};

export const createEventStart = () => ({
  type: CREATE_EVENT_START,
});

export const createEventSuccess = () => ({
  type: CREATE_EVENT_SUCCESS,
});

export const createEventFailure = (error) => ({
  type: CREATE_EVENT_FAILURE,
  payload: error,
});

export const setEventSuccess = (flag) => {
  return {
    type: SET_EVENT_SUCCESS,
    payload: flag,
  };
};


export const getEventList = (data) => {
  return {
    type: GET_EVENT_LIST,
    payload: data,
  };
};

export const getGradreelType = (data) => {
    return {
      type: GET_GRADREEL_TYPE,
      payload: data
    }
  }

  export const completeStep = (step, status ) => ({
    type: COMPLETE_STEP,
    payload: { step, status },
  });

  export const setEventDetails = (details) => ({
    type: SET_EVENT_DETAILS,
    payload: details,
  });


  export const setSearchQueryForPreevent = (searchQuery) => {
    return {
      type: SET_SEARCH_QUERY_FOR_PRE,
      payload: searchQuery,
    };
  };

  export const getEventState = (eventState) => {
    return {
      type: GET_EVENT_STATE,
      payload: eventState,
    };
  };

  export const getCustomerDetails = (data) => ({
    type: GET_STUDENT_DETAILS,
    payload: data,
  });

  export const getMediaDetails = (data) => ({
    type: GET_MEDIA_DETAILS,
    payload: data,
  });

export const getIntroData = (data) => {
    return {
      type: GET_INTRO_VIDEO_DATA,
      payload: data
    }
  }

  export const getCustomerClip = (data) => ({
    type: GET_STUDENT_CLIP_DATA,
    payload: data,
  });

  export const getExitData = (data) => {
    return {
      type: GET_EXIT_VIDEO_DATA,
      payload: data
    }
  }

  export const getIntroVideoGen = (data) => {
    return {
      type: GENERATE_VIDEO_FOR_INTRO,
      payload: data
    }
  }

  export const getExitVideoGen = (data) => {
    return {
      type: GENERATE_VIDEO_FOR_EXIT,
      payload: data
    }
  }

  export const initializeValues = () => {
    return {
      type: INITIALIZE_VALUES_OF_PREEVENT,
    }
  }

  export const getCustomReviewEvents = (data) => {
    return {
      type: GET_CUSTOM_REVIEW_EVENT_LIST,
      payload: data
    }
  }

  export const getStudentVideo = (data) => {
    return {
      type: GET_STUDENT_VIDEO_FOR_APPROVAL,
      payload: data
    }
  }

  export const getStudentSummary = (data) => {
    return {
      type: GET_STUDENT_VIDEO_SUMMARY,
      payload: data
    }
  }


// Gets list of pre events
export const FetchEventList = (clientId, contactId) => {
  return async (dispatch) => {
    dispatch(makeRequest());
    // toast.info("Loading...");
    return await axios
      .get(`${window.APP.apiUrl}api/initialize/GetEventListByClientId`, {
        headers: {
          "X-API-Key": window.APP.apiKey,
        },
        params: {
          ClientId: clientId,
          contactid: contactId,
        },
      })
      .then((res) => {
        const eventlist = res.data;
        dispatch(getEventList(eventlist));
        // toast.dismiss();
      })
      .catch((err) => {
        dispatch(failRequest(err.message));
        // toast.dismiss();
      });
  };
};

// Applys season settings data for selected events
export const applySeasonSettings = (clientData) => {
  return async (dispatch) => {
    dispatch(createEventStart());

    try {
      const payload = new FormData();
      payload.append('seasonSettingsEvents', JSON.stringify(clientData));
      const result = await axios.post(`${window.APP.apiUrl}api/initialize/ApplySeasonSettingsToEvents`, payload, {
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
          "X-API-Key": window.APP.apiKey,
        }
      });

      if (result.data.result.isSuccess) {
        toastSuccess("Season settings applied to selected events successfully!");
        dispatch(createEventSuccess());
      } else {
        toastError(result.data.result.errorMessages[0]);
        dispatch(createEventFailure(result.error));
      }
    } catch (error) {
      toastError(`Error: ${error.message || "Unknown error"}`);
      dispatch(createEventFailure(error.message));
    }
  };
};

// Gets the event master data
  export const FetchGradreelType = (eventData) => {
    return async (dispatch) => {
        dispatch(makeRequest());
        // Return the axios promise directly
        return await axios.get(`${window.APP.apiUrl}api/initialize/GetEventMaster`, {
            headers: {
                "X-API-Key": window.APP.apiKey,
            },
            params: {
                ClientId: eventData.clientId,
                EventId: eventData.eventId,
            }
        }).then(res => {
            const gradresslType = res.data;
            dispatch(getGradreelType(gradresslType));
            return gradresslType; // You can choose to return data if needed
        }).catch(err => {
            dispatch(failRequest(err.message));
            throw err; // Re-throw the error to propagate it to the caller
        });
    };
  };

  // Creates the master data for events
  export const createEventMaster = (eventData) => {
    return async (dispatch) => {
      dispatch(createEventStart());
  
      try {
        const result = await fetch(`${window.APP.apiUrl}api/initialize/CreateEventMaster`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            "X-API-Key": window.APP.apiKey,
            // Add any other headers as needed
          },
          body: JSON.stringify(eventData),
        });
  
        if (result.ok) {
          toastSuccess("Event created successfully!");
          dispatch(createEventSuccess());
        } else {
          toastError(`Error: ${result.data.error || "Unknown error"}`);
          dispatch(createEventFailure(result.error));
        }
      } catch (error) {
        toastError(`Error: ${error.message || "Unknown error"}`);
        dispatch(createEventFailure(error.message));
      }
    };
  };
  
  // Update the selection of template
  export const postTemplateData = (eventData) => {
    return async (dispatch) => {
      dispatch(makeRequest());
  
      try {
        const result = await axios.post(`${window.APP.apiUrl}api/initialize/PostTemplateData`, {}, {
          headers: {
            //   'Content-Type': 'application/json',
            "X-API-Key": window.APP.apiKey,
          },
          params: {
            ClientId: eventData.clientId,
            EventId: eventData.eventId,
            TemplateType: eventData.templateType,
            userEmail: eventData.userEmail
          }
        });
  
        if (result.data.isSuccess) {
          toastSuccess("Event updated successfully!");
          dispatch(createEventSuccess());
        } else {
          toastError(`Error: ${result.data.error || "Unknown error"}`);
          dispatch(createEventFailure(result.data.error));
        }
      } catch (error) {
        toastError(`Error: ${error.response.data.title || "Unknown error"}`);
        dispatch(createEventFailure(error.response.data.title));
      }
    };
  };
  
// Gets the student data for selected event
  export const FetchCustomerDetails = (eventData) => {
    return async (dispatch) => {
      dispatch(makeRequest());
      await axios.get(`${window.APP.apiUrl}api/initialize/GetCustomerDetails`, {
        headers: {
          "X-API-Key": window.APP.apiKey,
        },
        params: {
          ClientId: eventData.clientId,
          EventId: eventData.eventId,
        }
      }).then(res => {
        const studentDetails = res.data;
        dispatch(getCustomerDetails(studentDetails));
      }).catch(err => {
        dispatch(failRequest(err.message))
      })
  
    }
  }

  // Uploads the student data for selected event
  export const uploadStudentInformation = (files, studentDetails) => {
    return async (dispatch) => {
      dispatch(makeRequest());
  
      try {
        const studentDetailsJSON = JSON.stringify({
          EventId: studentDetails.EventId,
          EventName: studentDetails.EventName,
          ClientId: studentDetails.ClientId,
          ClientName: studentDetails.ClientName,
        });
  
        const payload = new FormData();
        payload.append('files', files);
        payload.append('customerDetails', studentDetailsJSON);
  
        const result = await axios.post(`${window.APP.apiUrl}api/initialize/PostCustomerDetails`, payload, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'X-API-Key': window.APP.apiKey,
          },
        });
  
        if (result.data.isSuccess) {
          toastSuccess("Student Information Uploaded successfully!");
          dispatch(createEventSuccess());
        } else {
          toastError('Invalid file: Headers are not valid or invalid data');
          dispatch(createEventFailure(result.data.errorMessages[0]));
        }
      } catch (error) {
        toastError(`Error: ${error.message || "Unknown error"}`);
        dispatch(createEventFailure(error.message));
      }
    };
  };

  // Gets the media details for selected event
export const FetchMediaDetails = (eventData) => {
    return async (dispatch) => {
      dispatch(makeRequest());
      //setTimeout(() => {
      await axios.get(`${window.APP.apiUrl}api/initialize/GetMediaDetails`, {
        headers: {
          "X-API-Key": window.APP.apiKey,
        },
        params: {
          ClientId: eventData.clientId,
          EventId: eventData.eventId,
        }
      }).then(res => {
        const mediaDetails = res.data;
        dispatch(getMediaDetails(mediaDetails));
      }).catch(err => {
        dispatch(failRequest(err.message))
      })
      // }, 2000);
  
    }
  }

  // Uploads the media data for selected event
  export const postMediaDetails = (files, mediaDetails) => {
    return async (dispatch) => {
      toast.info('Uploading...');
      dispatch(makeRequest());
  
      try {
        const payload = new FormData();
        if(files){
          if(files instanceof File){
            payload.append('files', files);
          } else {  
            if (mediaDetails.SelectedMusicCLipId === "1") {
              payload.append('files', files, 'UAB Intro Music 3.mp3');
          } else if (mediaDetails.SelectedMusicCLipId === "2") {
              payload.append('files', files, 'Pomp and Circumstance Graduation Walking March (1).mp3');
          }
          }
          payload.append('isdataAvailable', false);      
        } else {
          payload.append('isdataAvailable', true); 
        }
        payload.append('mediaDetails', JSON.stringify(mediaDetails));
  
  
        const result = await axios.post(`${window.APP.apiUrl}api/initialize/PostMediaDetails`, payload, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'X-API-Key': window.APP.apiKey,
          },
        });
  
        if (result.data.isSuccess) {
          toast.dismiss();
          toastSuccess("Media Information Uploaded successfully!");
          dispatch(createEventSuccess());
        } else {
          toast.dismiss();
          toastError(`Error: ${result.data.error || "Unknown error"}`);
          dispatch(createEventFailure(result.data.error));
        }
      } catch (error) {
        toast.dismiss();
        toastError(`Error: ${error.message || "Unknown error"}`);
        dispatch(createEventFailure(error.message));
      }
    };
  };

  // Gets the intro video data for selected event
export const getIntroVideoData = (eventData) => {
    return (dispatch) => {
      dispatch(makeRequest());
      // toast.info('Loading...');
      axios.get(`${window.APP.apiUrl}api/initialize/GetIntroVideoData`, {
        headers: {
          "X-API-Key": window.APP.apiKey,
        },
        params: {
          ClientId: eventData.ClientId,
          EventId: eventData.EventId,
        }
      }).then(res => {
        const introData = res.data;
        dispatch(getIntroData(introData));
        // toast.dismiss();
      }).catch(err => {
        dispatch(failRequest(err.message))
        // toast.dismiss();
      })
  
    }
  };

  // Uploads the intro video or data for creation of video for selected event
  export const postIntroVideo = (files, introData) => {
    return async (dispatch) => {
      toast.info('Uploading...');
      dispatch(makeRequest());
   
      try {
        const payload = new FormData();
        if (Array.isArray(files)){
          if(files.length > 1){
            files.forEach((file) => {
              payload.append('files', file);
            });  
          } else {
            payload.append('files', files[0]);
          }
        } else {
          payload.append('files', files);
        }
       
        payload.append('introData', JSON.stringify(introData));
        const result = await axios.post(`${window.APP.apiUrl}api/initialize/PostIntroVideo`, payload, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'X-API-Key': window.APP.apiKey,
          },
        });
   
        if (result.data.isSuccess) {
          toast.dismiss();
          toastSuccess("Details submitted successfully!");
          dispatch(createEventSuccess());
        } else {
          toast.dismiss();
          toastError(`Error: ${result.data.error || "Unknown error"}`);
          dispatch(createEventFailure(result.data.error));
        }
      } catch (error) {
        toast.dismiss();
        toastError(`Error: ${error.message || "Unknown error"}`);
        dispatch(createEventFailure(error.message));
      }
    };
  };

// Gets the student video data for selected event
  export const FetchCustomerClipConfig = (eventData) => {
    return (dispatch) => {
      dispatch(makeRequest());
      axios.get(`${window.APP.apiUrl}api/initialize/GetCustomerClipConfig`, {
        headers: {
          "X-API-Key": window.APP.apiKey,
        },
        params: {
          ClientId: eventData.clientId,
          EventId: eventData.eventId,
        }
      }).then(res => {
        const studentDetails = res.data;
        dispatch(getCustomerClip(studentDetails));
      }).catch(err => {
        dispatch(failRequest(err.message))
      })
  
    }
  }

  // Uploads the data for creation of student video for selected event
  export const postCustomerClipConfig = (files, customData) => {
    return async (dispatch) => {
      toast.info('Uploading...');
      dispatch(makeRequest());
   
      try {
        const payload = new FormData();
        if (Array.isArray(files)){
          if(files.length > 1){
            files.forEach((file) => {
              payload.append('files', file);
            });  
          } else {
            payload.append('files', files[0]);
          }
        } else if (Object.keys(files)?.length >= 1) {
              if (files.logo) {
                payload.append('files', files.logo);
              } 
      
              if (files.video) {
                payload.append('files', files.video);
              }
        } else {
          payload.append('files', files);
        }
       
        payload.append('customerSection', JSON.stringify(customData));
        const result = await axios.post(`${window.APP.apiUrl}api/initialize/SaveCustomerClipConfig`, payload, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'X-API-Key': window.APP.apiKey,
          },
        });
   
        if (result.data.isSuccess) {
          toast.dismiss();
          toastSuccess("Details submitted successfully!");
          dispatch(createEventSuccess());
        } else {
          toast.dismiss();
          toastError(`Error: ${result.data.error || "Unknown error"}`);
          dispatch(createEventFailure(result.data.error));
        }
      } catch (error) {
        toast.dismiss();
        toastError(`Error: ${error.message || "Unknown error"}`);
        dispatch(createEventFailure(error.message));
      }
    };
  };

  // Gets the exit video data for selected event
  export const getExitVideoData = (eventData) => {
    return (dispatch) => {
      dispatch(makeRequest());
      // toast.info('Loading...');
      axios.get(`${window.APP.apiUrl}api/initialize/GetExitVideoData`, {
        headers: {
          "X-API-Key": window.APP.apiKey,
        },
        params: {
          ClientId: eventData.ClientId,
          EventId: eventData.EventId,
        }
      }).then(res => {
        const exitData = res.data;
        dispatch(getExitData(exitData));
        // toast.dismiss();
      }).catch(err => {
        dispatch(failRequest(err.message))
        // toast.dismiss();
      })
  
    }
  };

  // Uploads the data for creation of exit video for selected event
  export const postExitVideo = (files, exitData) => {
    return async (dispatch) => {
      toast.info('Uploading...');
      dispatch(makeRequest());
   
      try {
        const payload = new FormData();
        if (Array.isArray(files)){
          if(files.length > 1){
            files.forEach((file) => {
              payload.append('files', file);
            });  
          } else {
            payload.append('files', files[0]);
          }
        } else {
          payload.append('files', files);
        }
       
        payload.append('exitData', JSON.stringify(exitData));
        const result = await axios.post(`${window.APP.apiUrl}api/initialize/PostExitVideo`, payload, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'X-API-Key': window.APP.apiKey,
          },
        });
   
        if (result.data.isSuccess) {
          toast.dismiss();
          toastSuccess("Details submitted successfully!");
          dispatch(createEventSuccess());
        } else {
          toast.dismiss();
          toastError(`Error: ${result.data.error || "Unknown error"}`);
          dispatch(createEventFailure(result.data.error));
        }
      } catch (error) {
        toast.dismiss();
        toastError(`Error: ${error.message || "Unknown error"}`);
        dispatch(createEventFailure(error.message));
      }
    };
  };

  // Generates the intro and exit video based on given data
  export const generateVideo = (videoData, params) => {
    return async (dispatch) => {
      // if(params.preview){
      //   toast.info(`Generating video for ${params.videotype}, please wait...`);
      // }
      dispatch(makeRequest());
  
      try {
        const payload = new FormData();
        payload.append("videodata", `{\"clientid\":${videoData.clientId}, \"eventid\":${videoData.eventId}, \"new_key\":\"no data\"}`); 
        await axios.post(`${window.APP.videoGenApi}videogen?videotype=${params.videotype}&preview=${params.preview}&isseasonalsetting=${params.isseasonalsetting}`, payload, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }).then(res => {
          const videoData = res.data;
          if (params.videotype === 'intro') {
            dispatch(getIntroVideoGen(videoData));
          } else if (params.videotype === 'exit') {
            dispatch(getExitVideoGen(videoData));
          }
          if (videoData && videoData['VideoGen response data'] !== undefined && videoData['VideoGen response data'] !== null && videoData['VideoGen response data'] !== ""){
            if(params.preview){
            toastSuccess(`${params.videotype} video generated successfully`);
          }
          } else {
            toastError(`Error occured while generating ${params.videotype} video`);
          }
          return videoData;
        }).catch(err => {
          dispatch(failRequest(err.message))
        })
      } catch (error) {
        dispatch(failRequest(error.message));
      }
    };
  };

  // Publishes the intro and exit video 
  export const publishClip = (eventData) => {
    return async (dispatch) => {
      dispatch(makeRequest());
  
      try {
        const result = await axios.post(`${window.APP.apiUrl}api/initialize/PublishClipByEventId`,{
          clientId: eventData.clientId,
          eventId: eventData.eventId,
          clipType: eventData.clipType,
          userEmail: eventData.userEmail,
        }, {
          headers: {
            'Content-Type': 'application/json',
            "X-API-Key": window.APP.apiKey,
          }
        });
  
        if (result.data.isSuccess) {
          // dispatch(createEventSuccess());
        } else {
          dispatch(createEventFailure(result.data.error));
        }
      } catch (error) {
        dispatch(createEventFailure(error.message));
      }
    };
  };

  // SUbmits pre event data
  export const submitPreEvent = (eventData) => {
    return async (dispatch) => {
      dispatch(makeRequest());
  
      try {
        const result = await axios.post(`${window.APP.apiUrl}api/initialize/SubmitPreEvent`, {}, {
          headers: {
            "X-API-Key": window.APP.apiKey,
          },
          params: {
            ClientId: eventData.clientId,
            EventId: eventData.eventId,
            userEmail: eventData.userEmail
          }
        });
  
        if (result.data.isSuccess) {
          dispatch(createEventSuccess());
          toastSuccess(`Event details submitted successfully`);
        } else {
          dispatch(createEventFailure(result.data.error));
          toastError(result.data.error);
        }
      } catch (error) {
        dispatch(createEventFailure(error.response.data.title));
        toastError(error.response.data.title);
      }
    };
  };
  

  //Custom Video Review actions

  // Gets list of customer review events
  export const FetchCustomerReviewEvents = (clientId) => {
    return (dispatch) => {
      dispatch(makeRequest());
      toast.info("Loading...");
      axios
        .get(`${window.APP.apiUrl}api/initialize/GetCustomVideoReviewEvents`, {
          headers: {
            "X-API-Key": window.APP.apiKey,
          },
          params: {
            clientId: clientId,
          },
        })
        .then((res) => {
          const eventlist = res.data;
          dispatch(getCustomReviewEvents(eventlist));
          toast.dismiss();
        })
        .catch((err) => {
          dispatch(failRequest(err.message));
          toast.dismiss();
        });
    };
  };

// Gets the list of student video data
  export const getStudentVideoForApproval = (clientId, eventId) => {
    return (dispatch) => {
      dispatch(makeRequest());
      axios.get(`${window.APP.apiUrl}api/initialize/GetStudentVideoForApproval`, {
        headers: {
          "X-API-Key": window.APP.apiKey,
        },
        params: {
          EventId: eventId,
          ClientId: clientId,
        }
      }).then(res => {
        const data = res.data;
        dispatch(getStudentVideo(data));
      }).catch(err => {
        dispatch(failRequest(err.message))
      })
  
    }
  }

  // Gets summary of student video
  export const getStudentVideoSummary = (clientId, eventId) => {
    return (dispatch) => {
      dispatch(makeRequest());
      axios.get(`${window.APP.apiUrl}api/initialize/GetStudentVideoSummary`, {
        headers: {
          "X-API-Key": window.APP.apiKey,
        },
        params: {
          EventId: eventId,
          ClientId: clientId,
        }
      }).then(res => {
        const data = res.data;
        dispatch(getStudentSummary(data));
      }).catch(err => {
        dispatch(failRequest(err.message))
      })
  
    }
  };

  // Save student status as approved or rejected for selected ids
  export const saveStudentVideoStatus = (studentVideoStatus) => {
    return async (dispatch) => {
      dispatch(makeRequest());
  
      try {
        const studentVideoStatusJSON = JSON.stringify(studentVideoStatus);
  
        const payload = new FormData();
        payload.append('saveStudentVideoStatus', studentVideoStatusJSON);
  
        const result = await axios.post(`${window.APP.apiUrl}api/initialize/SaveStudentVideoStatus`, payload, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'X-API-Key': window.APP.apiKey,
          },
        });
  
        if (result.data.result.isSuccess) {
          // toast.success("Student Information Uploaded successfully!");
          dispatch(createEventSuccess());
        } else {
          toastError(`Error: ${result.data.result.errorMessage || "Unknown error"}`);
          dispatch(createEventFailure(result.data.result.errorMessage));
        }
      } catch (error) {
        toastError(`Error: ${error.message || "Unknown error"}`);
        dispatch(createEventFailure(error.message));
      }
    };
  };