import React from "react";
import { Header } from "../../components/Header";
import { LeftNav } from "../../components/LeftNav";

export const PreEventDetails = () => {
  return (
    <div>
      <Header className="header-instance" />
      <LeftNav
        className="left-nav-instance"
        frameClassName="left-nav-3"
        homeHasGroup={false}
        homeText="65%"
        homeText1="85%"
        overlapGroupClassName="left-nav-2"
        tab='preEvent' // Indicate that this is pre-event
      />
    </div>
  );
};
