/*
We're constantly improving the code you see.
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/
 
import React from "react"; 

export const FiRrEdit8 = ({ className }) => {
  return (
    <svg
      className={`fi-rr-edit-8 ${className}`}
      fill="none"
      height="17"
      viewBox="0 0 16 17"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_422_101556)">
        <path
          className="path"
          d="M12.4375 1.21815L4.30951 9.34615C3.99907 9.65492 3.75296 10.0222 3.58543 10.4267C3.4179 10.8312 3.33227 11.265 3.33351 11.7028V12.5982C3.33351 12.775 3.40375 12.9445 3.52877 13.0696C3.6538 13.1946 3.82336 13.2648 4.00018 13.2648H4.89551C5.33335 13.2661 5.76709 13.1804 6.17161 13.0129C6.57614 12.8454 6.94341 12.5993 7.25218 12.2888L15.3802 4.16082C15.7698 3.77027 15.9886 3.24114 15.9886 2.68949C15.9886 2.13784 15.7698 1.60871 15.3802 1.21815C14.984 0.839414 14.457 0.628052 13.9088 0.628052C13.3607 0.628052 12.8337 0.839414 12.4375 1.21815ZM14.4375 3.21815L6.30951 11.3462C5.93359 11.7198 5.42551 11.9301 4.89551 11.9315H4.66684V11.7028C4.66823 11.1728 4.87855 10.6647 5.25218 10.2888L13.3802 2.16082C13.5226 2.02479 13.7119 1.94888 13.9088 1.94888C14.1058 1.94888 14.2951 2.02479 14.4375 2.16082C14.5775 2.30116 14.6561 2.49128 14.6561 2.68949C14.6561 2.88769 14.5775 3.07781 14.4375 3.21815Z"
          fill="#5C5C5C"
        />
        <path
          className="path"
          d="M15.3333 6.58402C15.1565 6.58402 14.987 6.65426 14.8619 6.77929C14.7369 6.90431 14.6667 7.07388 14.6667 7.25069V10.598H12C11.4696 10.598 10.9609 10.8087 10.5858 11.1838C10.2107 11.5589 10 12.0676 10 12.598V15.2647H3.33333C2.8029 15.2647 2.29419 15.054 1.91912 14.6789C1.54405 14.3038 1.33333 13.7951 1.33333 13.2647V3.93136C1.33333 3.40092 1.54405 2.89222 1.91912 2.51714C2.29419 2.14207 2.8029 1.93136 3.33333 1.93136H9.36133C9.53815 1.93136 9.70771 1.86112 9.83274 1.73609C9.95776 1.61107 10.028 1.4415 10.028 1.26469C10.028 1.08788 9.95776 0.918309 9.83274 0.793285C9.70771 0.66826 9.53815 0.598022 9.36133 0.598022H3.33333C2.4496 0.599081 1.60237 0.95061 0.97748 1.5755C0.352588 2.20039 0.00105857 3.04763 0 3.93136L0 13.2647C0.00105857 14.1484 0.352588 14.9957 0.97748 15.6205C1.60237 16.2454 2.4496 16.597 3.33333 16.598H10.8953C11.3333 16.5993 11.7671 16.5137 12.1718 16.3461C12.5764 16.1786 12.9438 15.9325 13.2527 15.622L15.0233 13.85C15.3338 13.5413 15.58 13.174 15.7477 12.7695C15.9153 12.365 16.0011 11.9312 16 11.4934V7.25069C16 7.07388 15.9298 6.90431 15.8047 6.77929C15.6797 6.65426 15.5101 6.58402 15.3333 6.58402ZM12.31 14.6794C12.042 14.9467 11.7031 15.1318 11.3333 15.2127V12.598C11.3333 12.4212 11.4036 12.2516 11.5286 12.1266C11.6536 12.0016 11.8232 11.9314 12 11.9314H14.6167C14.5342 12.3004 14.3493 12.6386 14.0833 12.9074L12.31 14.6794Z"
          fill="#5C5C5C"
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_422_101556">
          <rect className="rect" fill="white" height="16" transform="translate(0 0.598022)" width="16" />
        </clipPath>
      </defs>
    </svg>
  );
};
