/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Frame } from "../Frame";
import { NotificationWarning } from "../NotificationWarning";
import "./style.css";
import bellImage from '../../icons/ImageIcons/icon-filled-bell-1@2x.png';

import menuIcon from '../../icons/ImageIcons/menuIcon.svg';
import { clearStorage, getClientData } from "../../constants/Constants";
import { useSelector } from "react-redux";
import { Property1Variant4 } from "../../icons/Property1Variant4";
import { PowerButton } from "../../icons/PowerButton/PowerButton";

export const Header = ({ className }) => {
  const clientData = getClientData();
  const userLoginDetails = useSelector(state => state.shared.loginUserDetails)
  const [userData, setUserData] = useState(userLoginDetails?.result || clientData);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleSuccessButtonClick = () => {
    closeModal();
    clearStorage();
    window.location.href = `${ window.APP.loginUrl}Login/Logout`
  }

  const handleLogout = () => {
    openModal(); // Open the confirmation dialog
  };

  useEffect(() => {
    if (userLoginDetails?.isSuccess) {
      setUserData(userLoginDetails.result);
    }
  }, [userLoginDetails?.isSuccess]);

  return (
    <>
    <div className={`header ${className}`}>
      <div className="div">
        <div className="frame-2">
          <Frame className="frame-1" />
          <img className="menu" alt="Menu" src={menuIcon} />
          <div class="text-wrapper-3">{userData?.clientName}</div>
        </div>
        
      </div>
      <div className="frame-5">
      <div className="schedule">
          <div className="overlap">
            <img className="element-bell" alt="Element bell" src={bellImage} />
            <NotificationWarning className="notification-warning-instance" />
          </div>
        </div>
      <div className="frame-6">
        <div className="frame-wrapper">
            <div className="text-wrapper">{userData?.userName}</div>
            <div className="text-wrapper-2">{userData?.userRole}</div>
        </div>
        </div>
        {userData && <div style={{cursor: 'pointer'}} onClick={handleLogout}>
              <PowerButton />
            </div>
}
      </div>
    </div>
    {isModalOpen && (
      <div className="video-modal" onClick={closeModal}>
        <div className="video-player-container">
          {/* <button className="close-button" onClick={closeModal}>&times;</button> */}
          <div className="frame-model">
            <div className="div-model">
              <Property1Variant4 className="failed-loader" color="#EA690C" />
              <p className="once-published">
                <span className="text-wrapper">
                  Are you sure you want to logout?
                  <br />
                </span>
              </p>
            </div>
            <div className="div-2-model">
            <div className="no-BTN">
                <div className="button-text-logout" onClick={closeModal}>No</div>
              </div>
              <div className="yes-BTN" onClick={handleSuccessButtonClick}>
                <div className="button-text-logout">Yes</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )}
    </>
  );
};

Header.propTypes = {
  ellipse: PropTypes.string,
};
