/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const FiRrCheck1 = ({ className }) => {
  return (
    <svg
      className={`fi-rr-check-1 ${className}`}
      fill="none"
      height="34"
      viewBox="0 0 35 34"
      width="35"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_2029_26084)">
        <path
          className="path"
          d="M31.9471 6.40224L12.6005 25.7474C12.4704 25.878 12.3158 25.9816 12.1456 26.0523C11.9754 26.1231 11.7929 26.1594 11.6086 26.1594C11.4242 26.1594 11.2417 26.1231 11.0715 26.0523C10.9013 25.9816 10.7467 25.878 10.6167 25.7474L3.13506 18.2588C3.00499 18.1283 2.85041 18.0246 2.68019 17.9539C2.50998 17.8832 2.32748 17.8468 2.14316 17.8468C1.95885 17.8468 1.77635 17.8832 1.60613 17.9539C1.43592 18.0246 1.28134 18.1283 1.15126 18.2588C1.02068 18.3889 0.917058 18.5435 0.846355 18.7137C0.775653 18.8839 0.739258 19.0664 0.739258 19.2507C0.739258 19.4351 0.775653 19.6176 0.846355 19.7878C0.917058 19.958 1.02068 20.1126 1.15126 20.2426L8.63566 27.7256C9.42519 28.5137 10.4951 28.9563 11.6107 28.9563C12.7262 28.9563 13.7961 28.5137 14.5857 27.7256L33.9309 8.38464C34.0612 8.25459 34.1647 8.1001 34.2353 7.93001C34.3058 7.75993 34.3422 7.57759 34.3422 7.39344C34.3422 7.20929 34.3058 7.02695 34.2353 6.85687C34.1647 6.68678 34.0612 6.53229 33.9309 6.40224C33.8008 6.27165 33.6462 6.16803 33.476 6.09733C33.3058 6.02663 33.1233 5.99023 32.939 5.99023C32.7547 5.99023 32.5721 6.02663 32.4019 6.09733C32.2317 6.16803 32.0771 6.27165 31.9471 6.40224Z"
          fill="#1BA421"
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_2029_26084">
          <rect className="rect" fill="white" height="33.6" transform="translate(0.700195 0.199219)" width="33.6" />
        </clipPath>
      </defs>
    </svg>
  );
};
