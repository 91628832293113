/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const FiRrEdit14 = ({ className }) => {
  return (
    <svg
      className={`fi-rr-edit-14 ${className}`}
      fill="none"
      height="17"
      viewBox="0 0 17 17"
      width="17"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_424_3327)">
        <path
          className="path"
          d="M12.9375 1.21827L4.80951 9.34627C4.49907 9.65504 4.25296 10.0223 4.08543 10.4268C3.9179 10.8314 3.83227 11.2651 3.83351 11.7029V12.5983C3.83351 12.7751 3.90375 12.9447 4.02877 13.0697C4.1538 13.1947 4.32336 13.2649 4.50018 13.2649H5.39551C5.83335 13.2662 6.26709 13.1806 6.67161 13.013C7.07614 12.8455 7.44341 12.5994 7.75218 12.2889L15.8802 4.16094C16.2698 3.77039 16.4886 3.24126 16.4886 2.68961C16.4886 2.13796 16.2698 1.60883 15.8802 1.21827C15.484 0.839537 14.957 0.628174 14.4088 0.628174C13.8607 0.628174 13.3337 0.839537 12.9375 1.21827ZM14.9375 3.21827L6.80951 11.3463C6.43359 11.7199 5.92551 11.9302 5.39551 11.9316H5.16684V11.7029C5.16823 11.1729 5.37855 10.6649 5.75218 10.2889L13.8802 2.16094C14.0226 2.02491 14.2119 1.949 14.4088 1.949C14.6058 1.949 14.7951 2.02491 14.9375 2.16094C15.0775 2.30128 15.1561 2.4914 15.1561 2.68961C15.1561 2.88781 15.0775 3.07793 14.9375 3.21827Z"
          fill="#5C5C5C"
        />
        <path
          className="path"
          d="M15.8333 6.58415C15.6565 6.58415 15.487 6.65438 15.3619 6.77941C15.2369 6.90443 15.1667 7.074 15.1667 7.25081V10.5981H12.5C11.9696 10.5981 11.4609 10.8089 11.0858 11.1839C10.7107 11.559 10.5 12.0677 10.5 12.5981V15.2648H3.83333C3.3029 15.2648 2.79419 15.0541 2.41912 14.679C2.04405 14.304 1.83333 13.7952 1.83333 13.2648V3.93148C1.83333 3.40105 2.04405 2.89234 2.41912 2.51726C2.79419 2.14219 3.3029 1.93148 3.83333 1.93148H9.86133C10.0381 1.93148 10.2077 1.86124 10.3327 1.73622C10.4578 1.61119 10.528 1.44162 10.528 1.26481C10.528 1.088 10.4578 0.918431 10.3327 0.793407C10.2077 0.668382 10.0381 0.598145 9.86133 0.598145H3.83333C2.9496 0.599203 2.10237 0.950733 1.47748 1.57562C0.852588 2.20052 0.501059 3.04775 0.5 3.93148L0.5 13.2648C0.501059 14.1485 0.852588 14.9958 1.47748 15.6207C2.10237 16.2456 2.9496 16.5971 3.83333 16.5981H11.3953C11.8333 16.5994 12.2671 16.5138 12.6718 16.3463C13.0764 16.1787 13.4438 15.9326 13.7527 15.6221L15.5233 13.8501C15.8338 13.5414 16.08 13.1741 16.2477 12.7696C16.4153 12.3651 16.5011 11.9314 16.5 11.4935V7.25081C16.5 7.074 16.4298 6.90443 16.3047 6.77941C16.1797 6.65438 16.0101 6.58415 15.8333 6.58415ZM12.81 14.6795C12.542 14.9468 12.2031 15.1319 11.8333 15.2128V12.5981C11.8333 12.4213 11.9036 12.2518 12.0286 12.1267C12.1536 12.0017 12.3232 11.9315 12.5 11.9315H15.1167C15.0342 12.3005 14.8493 12.6388 14.5833 12.9075L12.81 14.6795Z"
          fill="#5C5C5C"
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_424_3327">
          <rect className="rect" fill="white" height="16" transform="translate(0.5 0.598145)" width="16" />
        </clipPath>
      </defs>
    </svg>
  );
};