import React, { useState, useEffect } from "react";

import { StyledEngineProvider } from '@mui/material/styles';
import { Box, CircularProgress } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CachedIcon from '@mui/icons-material/Cached';
import "./style.css";

import { PostEventDetails } from "../PostEventDetails";
import { DataGridVideoProcessing } from "../DatagridVideoProcessing";

import alert from "./brightness-alert.png"

import homeImage from '../../../icons/ImageIcons/material-symbols_home-rounded.svg';
import rightarrow from '../../../icons/ImageIcons/keyboard-arrow-right.svg';
import searchicon from '../../../icons/ImageIcons/searchicon.png';

import ellipseicon1 from "../../../icons/ImageIcons/ellipse1.svg"
import { FiRrCheck2 } from "../../../icons/FiRrCheck2";
import { getVideoProcessingStatusForEvent } from "../../../store/Actions/PostEventAction";
import { connect } from "react-redux";
import { getClientData } from "../../../constants/Constants";
import { useNavigate } from "react-router-dom";

const PostEventVideoProcessing = ({ loading, postEventDetails, getVideoProcessingStatusForEvent, videoProcessingStatus }) => {

  const navigate = useNavigate();
  const [selectedRowCount, setSelectedRowCount] = useState([]);
  const [rows, setRows] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  const clientData = getClientData();

  React.useEffect(() => {
    if (!postEventDetails) {
      navigate('/PostEventDetailsDashboard')
    } else {
      getVideoProcessingStatusForEvent(clientData.clientID, postEventDetails?.eventId);
    }
  }, [])

  // Filters rows based on user input
  const filterRows = (query) => {
    return rows.filter(row =>
      row.customerId.toLowerCase().includes(query.toLowerCase()) ||
      row.customerName.toLowerCase().includes(query.toLowerCase())
    );
  };

  // Event handler for search input change
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    const sortedRows = (Array.isArray(videoProcessingStatus?.result?.customerClipDetails?.customerDetails) ? videoProcessingStatus?.result?.customerClipDetails?.customerDetails : []).map(row => ({
      id: row.customerId,
      customerId: row.customerId,
      customerName: row.customerName,
      encodingStatus: row.encodingStatus,
      expectedPath: row.expectedPath,
      isClipAvailable: row.isClipAvailable,
      isError: row.isError,
    })).sort((a, b) => a.customerName.localeCompare(b.customerName)); // Sort by customerName alphabetically
    setRows(sortedRows);
  }, [videoProcessingStatus])


  // Function to calculate the number of videos in process
  const getVideosInProcess = () => {
    if (videoProcessingStatus?.result?.customerClipDetails) {
      const { total, completed, failed } = videoProcessingStatus.result.customerClipDetails;
      const videosInProcess = total - completed - failed;
      return videosInProcess >= 0 ? videosInProcess : 0;
    }
    return 0;
  };

  // Function to handle refresh button click
  const handleRefresh = () => {
    getVideoProcessingStatusForEvent(clientData.clientID, postEventDetails?.eventId);
  };


  return (

    <div className="video-processing-dashboard">
      <div className="div-2">
        <PostEventDetails />
        <div className="frame-19">
          <div className="frame-20">
            <div className="section-title">
              <div className="frame-21">
                <div className="page-title">
                  <div className="pagetitle">Post Event Details</div>
                </div>
                <div className="breadcrum">
                  <img
                    className="material-symbols"
                    alt="Material symbols"
                    src={homeImage}
                  />
                  <img
                    className="keyboard-arrow-right"
                    alt="Keyboard arrow right"
                    src={rightarrow}
                  />
                  <div className="text-wrapper-4">Post Event Details</div>
                  <img
                    className="keyboard-arrow-right"
                    alt="Keyboard arrow right"
                    src={rightarrow}
                  />
                  <div className="text-wrapper-5">Videos Processing Dashboard</div>

                </div>


                <div className="video-review-stats">
                  <div className="video-review-stats__item">
                    <span className="video-review-stats__label">Total Videos:</span>&nbsp;
                    <span className="video-review-stats__value">{videoProcessingStatus?.result?.customerClipDetails?.total}</span>
                  </div>
                  {/* <div className="video-review-stats__item">
                    <span className="video-review-stats__label">Videos Pending:</span>&nbsp;
                    <span className="video-review-pending">200</span>
                  </div> */}
                  <div className="video-review-stats__item">
                    <span className="video-review-stats__label">Videos in Process:</span>&nbsp;
                    <span className="video-review-queue">{getVideosInProcess()}</span>
                  </div>
                  <div className="video-review-stats__item">
                    <span className="video-review-stats__label">Videos Failed:</span>&nbsp;
                    <span className="video-review-rejected">{videoProcessingStatus?.result?.customerClipDetails?.failed}</span>
                  </div>
                  <div className="video-review-stats__item">
                    <span className="video-review-stats__label">Videos Succeeded:</span>&nbsp;
                    <span className="video-review-approved">{videoProcessingStatus?.result?.customerClipDetails?.completed}</span>
                  </div>
                </div>

              </div>
            </div>
            <div className="event-card">
              <div className="event-details">
                <p><strong>Event Details: </strong> {postEventDetails?.eventId} -  {postEventDetails?.eventName}</p>
                {/* <p><strong>Event Name:</strong> {postEventDetails?.eventName}</p> */}
              </div>
            </div>
            <div className="frame-data">

              <div className="frame-datasearch">

                <div className="frame-datasearch2">
                  <div className="search">
                    <p className="label-and">
                      <input className="input" placeholder="Search" type="text" value={searchQuery}
                        onChange={handleSearchChange} />
                    </p>
                    <img
                      className="generic"
                      alt="Generic"
                      src={searchicon}
                    />
                  </div>

                </div>
                <button className="refresh-button" onClick={handleRefresh}>
                  <CachedIcon className="refresh-icon" />
                </button>
              </div>

              <div className="frame-datagrid">
                <StyledEngineProvider injectFirst>
                  <DataGridVideoProcessing rows={filterRows(searchQuery)} setSelectedRowCount={setSelectedRowCount} selectedRowCount={selectedRowCount} />
                </StyledEngineProvider>
              </div>
              {loading && (
                <div className="loader-container">
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px' }}>
                    <CircularProgress />
                  </Box>
                </div>
              )}
            </div>

          </div>

        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.postEvent.loading,
    postEventDetails: state.postEvent.postEventDetails,
    videoProcessingStatus: state.postEvent.videoProcessingStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getVideoProcessingStatusForEvent: (clientId, eventId) => dispatch(getVideoProcessingStatusForEvent(clientId, eventId)),
  };
};

const ConnectedPostEventVideoProcessing = connect(mapStateToProps, mapDispatchToProps)(PostEventVideoProcessing);

export { ConnectedPostEventVideoProcessing as PostEventVideoProcessing };