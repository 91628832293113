export const MAKE_REQUEST='MAKE_REQUEST'
export const FAIL_REQUEST='FAIL_REQUEST'

export const CREATE_EVENT_START = 'CREATE_EVENT_START';
export const CREATE_EVENT_SUCCESS = 'CREATE_EVENT_SUCCESS';
export const CREATE_EVENT_FAILURE = 'CREATE_EVENT_FAILURE';

export const GET_LOGIN_USER = 'GET_LOGIN_USER'

export const GET_USER_ACCEPTANCE = 'GET_USER_ACCEPTANCE'
export const POST_USER_ACCEPTANCE = 'POST_USER_ACCEPTANCE'

export const GET_SEASON_LIST = 'GET_SEASON_LIST'