/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const FiRrCheck2 = ({ color = "#374957", className }) => {
  return (
    <svg
      className={`fi-rr-check-2 ${className}`}
      fill="none"
      height="56"
      viewBox="0 0 56 56"
      width="56"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_180_6927)">
        <path
          className="path"
          d="M52.0772 10.339L19.8329 42.581C19.6161 42.7987 19.3585 42.9714 19.0748 43.0892C18.7911 43.207 18.4869 43.2677 18.1797 43.2677C17.8725 43.2677 17.5684 43.207 17.2847 43.0892C17.001 42.9714 16.7433 42.7987 16.5266 42.581L4.05722 30.1C3.84043 29.8824 3.5828 29.7097 3.2991 29.5918C3.01541 29.474 2.71124 29.4133 2.40405 29.4133C2.09686 29.4133 1.79269 29.474 1.509 29.5918C1.2253 29.7097 0.967673 29.8824 0.750884 30.1C0.533238 30.3168 0.360542 30.5744 0.242705 30.8581C0.124868 31.1418 0.064209 31.446 0.064209 31.7532C0.064209 32.0604 0.124868 32.3645 0.242705 32.6482C0.360542 32.9319 0.533238 33.1896 0.750884 33.4064L13.2249 45.878C14.5408 47.1915 16.324 47.9291 18.1832 47.9291C20.0424 47.9291 21.8257 47.1915 23.1416 45.878L55.3836 13.643C55.6008 13.4263 55.7732 13.1688 55.8909 12.8853C56.0085 12.6018 56.0691 12.2979 56.0691 11.991C56.0691 11.6841 56.0085 11.3802 55.8909 11.0967C55.7732 10.8133 55.6008 10.5558 55.3836 10.339C55.1668 10.1214 54.9091 9.94868 54.6254 9.83084C54.3417 9.713 54.0376 9.65234 53.7304 9.65234C53.4232 9.65234 53.119 9.713 52.8353 9.83084C52.5516 9.94868 52.294 10.1214 52.0772 10.339Z"
          fill={color}
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_180_6927">
          <rect className="rect" fill="white" height="56" width="56" />
        </clipPath>
      </defs>
    </svg>
  );
};

FiRrCheck2.propTypes = {
  color: PropTypes.string,
};
