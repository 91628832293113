import React, { useState, useRef, useCallback, useEffect } from 'react';
import Cropper from 'react-easy-crop';
import { Typography, Slider } from "@mui/material";
import "./cropper.css"; // Import your CSS file
import "./style.css"; // Import your CSS file

const CropperComponent = ({ src, ratio = 1, onCropCompleted, onUpload, onFileCropping, onClose }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1);
  const [completedCrop, setCompletedCrop] = useState(null);

  const cropperRef = useRef(null);
  const cropContainerRef = useRef(null);
  const previewCanvasRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
       if (cropContainerRef.current && !cropContainerRef.current.contains(event.target)) {
         onClose();
       }
    };
   
    document.addEventListener('mousedown', handleClickOutside);
   
    return () => {
       document.removeEventListener('mousedown', handleClickOutside);
    };
   }, [onClose]);
   
  // Sets the cropping size of an image
  const onCropChange = useCallback((crop) => {
    setCrop(crop);
  }, []);

  // Sets the area/ pixels of the cropped image
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCompletedCrop(croppedAreaPixels);
    onCropCompleted(croppedAreaPixels);
  }, []);

  // Sets the zoom level
  const onZoomChange = useCallback((zoom) => {
    setZoom(zoom);
  }, []);

  // Generates the selected cropped area as image file
  const generateImageFile = useCallback(() => {
    if (!completedCrop || !src) {
      return;
    }
  
    const image = new Image();
    image.src = src.url;
  
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
  
    const canvas = previewCanvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext('2d');
      const pixelRatio = window.devicePixelRatio;
  
      canvas.width = completedCrop.width * pixelRatio;
      canvas.height = completedCrop.height * pixelRatio;
  
      ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
      ctx.imageSmoothingQuality = 'high';
  
      // Apply rotation
      ctx.translate(canvas.width / 2, canvas.height / 2);
      ctx.rotate((rotation * Math.PI) / 180);
      ctx.drawImage(
        image,
        completedCrop.x * scaleX,
        completedCrop.y * scaleY,
        completedCrop.width * scaleX,
        completedCrop.height * scaleY,
        -canvas.width / 2,
        -canvas.height / 2,
        completedCrop.width,
        completedCrop.height
      );
  
      // Convert canvas to data URL
      const dataURL = canvas.toDataURL(`image/${src.url.substring(src.url.indexOf('/') + 1, src.url.indexOf(';'))}`, 0.95);
      onFileCropping({ url: dataURL, uploadedData: false });
  
      canvas.toBlob((blob) => {
        const file = new File([blob], src.name, { type: `image/${src.url.substring(src.url.indexOf('/') + 1, src.url.indexOf(';'))}` });
        onUpload(file);
      }, `image/${src.url.substring(src.url.indexOf('/') + 1, src.url.indexOf(';'))}`, 1);
    }
  }, [completedCrop, src, onUpload, rotation, onFileCropping]);
  

  return (
    <div className="cropper" ref={cropContainerRef}>
      <Cropper
        minZoom={0.7}
        restrictPosition={false}
        ref={cropperRef}
        image={src.url}
        crop={crop}
        zoom={zoom}
        rotation={rotation}
        aspect={ratio}
        onCropChange={onCropChange}
        onCropComplete={onCropComplete}
        onZoomChange={onZoomChange}
        onRotationChange={setRotation}
        style={{
          containerStyle: {
            width: 500,
            height: 500,
            position: "relative",
            backgroundColor:'#00000061'
            //background: `rgba(0,0,0,.5) url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA3NCSVQICAjb4U/gAAAABlBMVEXMzMz////TjRV2AAAACXBIWXMAAArrAAAK6wGCiw1aAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABFJREFUCJlj+M/AgBVhF/0PAH6/D/HkDxOGAAAAAElFTkSuQmCC")`
          },
        }}
      />
       <canvas ref={previewCanvasRef} style={{ display: 'none' }} />
       <div className="controls">
        <div className="sliderContainer">
          <Typography
            variant="overline"
            className="sliderLabel"
          >
            Zoom
          </Typography>
          <Slider
            value={zoom}
            min={0.7}
            max={3}
            step={0.1}
            aria-labelledby="Zoom"
            className="slider"
            onChange={(e, zoom) => setZoom(zoom)}
          />
        </div>
        <div className="sliderContainer">
          <Typography
            variant="overline"
            className="sliderLabel"
          >
            Rotation
          </Typography>
          <Slider
            value={rotation}
            min={0}
            max={360}
            step={1}
            aria-labelledby="Rotation"
            className="slider"
            onChange={(e, rotation) => setRotation(rotation)}
          />
        </div>
      </div>
      
	   <div style={{marginTop: '1rem', alignItems: 'center', justifyContent: 'center', display: 'flex'}}>
        <button
          onClick={onClose}
          className="btn-cancel"
        >Cancel</button>
        <button
          onClick={generateImageFile}
          className="btn-save"
        >Save</button>
      </div>
      
    </div>
  );
};

export default CropperComponent;