export const MAKE_REQUEST='MAKE_REQUEST'
export const FAIL_REQUEST='FAIL_REQUEST'

export const CREATE_EVENT_START = 'CREATE_EVENT_START';
export const CREATE_EVENT_SUCCESS = 'CREATE_EVENT_SUCCESS';
export const CREATE_EVENT_FAILURE = 'CREATE_EVENT_FAILURE';

export const SET_EVENT_SUCCESS = 'SET_EVENT_SUCCESS'

export const GET_SELECTED_SEASON = 'GET_SELECTED_SEASON'

export const GET_SEASON_SETTINGS_CLIENT_MASTER = 'GET_SEASON_SETTINGS_CLIENT_MASTER'
export const GET_SEASON_SETTINGS_MEDIA_DETAILS = 'GET_SEASON_SETTINGS_MEDIA_DETAILS'

export const GET_SEASON_SETTINGS_INTRO_VIDEO = 'GET_SEASON_SETTINGS_INTRO_VIDEO'
export const GET_SEASON_SETTINGS_EXIT_VIDEO = 'GET_SEASON_SETTINGS_EXIT_VIDEO'

export const GET_SEASON_SETTINGS_STUDENT_VIDEO = 'GET_SEASON_SETTINGS_STUDENT_VIDEO'

export const INITIALIZE_VALUES_OF_SEASON_SETTINGS = 'INITIALIZE_VALUES_OF_SEASON_SETTINGS';