import React, { useState, useEffect } from "react";
import "./style_pendingVideo.css";
import { StyledEngineProvider } from '@mui/material/styles';
import { CustomVideoReview } from "../CustomVideoReview";
import DropDown from "../../../components/DropDown/DropDown";
import { Box, CircularProgress } from "@mui/material";
import { ToastContainer } from 'react-toastify';
import { DataGridPending }  from "../DatagridPending";
import homeImage from '../../../icons/ImageIcons/material-symbols_home-rounded.svg';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import alert from "./brightness-alert.png"
import { FiRrCheck2 } from "../../../icons/FiRrCheck2";

import rightarrow from '../../../icons/ImageIcons/keyboard-arrow-right.svg';
import searchicon from '../../../icons/ImageIcons/searchicon.png';

import ellipseicon1 from "../../../icons/ImageIcons/ellipse1.svg"
import { connect } from "react-redux";
import { getStudentVideoSummary, getStudentVideoForApproval, saveStudentVideoStatus } from "../../../store/Actions/PreEventAction";
import { useNavigate } from "react-router-dom";

const PendingVideoDashboard = ({loading, eventSuccess, eventDetails, studentVideoSummary, studentVideoForApproval, getStudentVideoSummary, getStudentVideoForApproval, saveStudentVideoStatus}) => {

  const [selectedRowCount, setSelectedRowCount] = useState([]);
  const [isAcceptModalOpen, setAcceptModalOpen] = useState(false);
  const [isSecondModalOpen, setSecondModalOpen] = useState(false);

  const [isRejectModalOpen, setRejectModalOpen] = useState(false);
  const [isThirdModalOpen, setThirdModalOpen] = useState(false);
  const [reason, setReason] = useState('');
  const [rows, setRows] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isAccepting, setIsAccepting] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!eventDetails) {
          navigate('/CustomVideoReviewDashboard');
          return;
        }
        // Dispatch all three API calls simultaneously
        await Promise.all([
          getStudentVideoSummary(eventDetails?.clientId, eventDetails?.eventId),
          getStudentVideoForApproval(eventDetails?.clientId, eventDetails?.eventId),
        ]);
      } catch (error) {
        console.log('Error fetching data:', error);
        // Handle error if necessary
      }
    };
  
    fetchData();
  }, []);

  useEffect(() => {
    setRows((Array.isArray(studentVideoForApproval?.result?.result) ? studentVideoForApproval?.result?.result : []).map(row => ({
      id: row.participantsIdentifier,
      eventId: row.eventId,
      clientId: row.clientId,
      participantIdentifier: row.participantIdentifier,
      eventName: row.eventName,
      clientName: row.clientName,
      participantName: row.participantName,
      eventDateTime: row.eventDateTime,
      videoUrl: row.videoUrl
    })));
  }, [studentVideoForApproval])

  useEffect(() => {
    if (eventSuccess) {
       if (isAccepting) {
         setSecondModalOpen(true);
       } else {
         setThirdModalOpen(true);
       }
    }
   }, [eventSuccess, isAccepting]);

   // Filters the rows based on input value
  const filterRows = (query) => {
    return rows.filter(row =>
      row.eventName.toLowerCase().includes(query.toLowerCase()) ||
      row.clientName.toLowerCase().includes(query.toLowerCase()) ||
      row.participantName.toLowerCase().includes(query.toLowerCase())
    );
  };

  // Set the input value added by user into search query
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  //Opens a modal when user clicks on accept button
  const handleAcceptButtonClick = () => {
    setAcceptModalOpen(true);
  };

  // Submit the student video staus as approved
  const handleAcceptModalYesClick = () => {
    setAcceptModalOpen(false);
    setIsAccepting(true);
    // Filter rows to find participantsIdentifier based on eventIDs in selectedRowCount
    const data = selectedRowCount.map(id => {
      const row = rows.find(row => row.id === id);
      return {
        eventID: row.eventId,
        participantsIdentifier: row.id,
        status: 'Approved',
        reason: reason
      };
    });
    saveStudentVideoStatus(data);
  };

  //Opens a modal when user clicks on reject button
  const handleRejectButtonClick = () => {
    // Perform any logic you need before opening the modal
    setRejectModalOpen(true);
  };

  // Submit the student video staus as rejected
  const handleRejectModalYesClick = () => {
    setRejectModalOpen(false);
    setIsAccepting(false);
    const data = selectedRowCount.map(id => {
      const row = rows.find(row => row.id === id);
      return {
        eventID: row.eventId,
        participantsIdentifier: row.id,
        status: 'Rejected',
        reason: reason
      };
    });
    saveStudentVideoStatus(data);
  };

  // Opens a successful rejection modal
  const handleThirdModalYesClick = () => {
    // Handle logic for the third modal
    setThirdModalOpen(false);
  };
  
  useEffect(() => {
    if (isSecondModalOpen) {
      const timer = setTimeout(() => {
        handleSecondModalYesClick();
      }, 5000000);

      // Clear the timer if the component unmounts or modal closes before 10 seconds
      return () => clearTimeout(timer);
    }
  }, [isSecondModalOpen]);

  useEffect(() => {
    if (isThirdModalOpen) {
      const timer = setTimeout(() => {
        handleThirdModalYesClick();
      }, 5000);

      // Clear the timer if the component unmounts or modal closes before 10 seconds
      return () => clearTimeout(timer);
    }
  }, [isThirdModalOpen]);

  // Opens a successful accepted modal
  const handleSecondModalYesClick = () => {
    // Handle logic for the second modal
    setSecondModalOpen(false);

  };
  return (

    <div className="pending-video-dashboard">
      <div className="div-2">
        <CustomVideoReview />
        <div className="frame-19">
          <div className="frame-20">
            <div className="section-title">
              <div className="frame-21">
                <div className="page-title">
                  <div className="pagetitle">Custom Video Review</div>
                </div>
                <div className="breadcrum">
                  <img
                    className="material-symbols"
                    alt="Material symbols"
                    src={homeImage}
                  />
                  <img
                    className="keyboard-arrow-right"
                    alt="Keyboard arrow right"
                    src={rightarrow}
                  />
                  <div className="text-wrapper-4">Custom Video Review</div>
                  <img
                    className="keyboard-arrow-right"
                    alt="Keyboard arrow right"
                    src={rightarrow}
                  />
                  <div className="text-wrapper-5">Pending Videos</div>

                </div>

                <div className="video-review-stats">
                  <div className="video-review-stats__item">
                    <span className="video-review-stats__label">Total Participants:</span><br />
                    <span className="video-review-stats__value">{studentVideoSummary?.result?.result[0]?.totalParticipants || 0}</span>
                  </div>
                  <div className="video-review-stats__item">
                    <span className="video-review-stats__label">Total Videos Uploaded:</span><br />
                    <span className="video-review-stats__value">{studentVideoSummary?.result?.result[0]?.totalVideosUploaded || 0}</span>
                  </div>
                  <div className="video-review-stats__item">
                    <span className="video-review-stats__label">Approved:</span><br />
                    <span className="video-review-approved">{studentVideoSummary?.result?.result[0]?.totalVideosApproved || 0}</span>
                  </div>
                  <div className="video-review-stats__item">
                    <span className="video-review-stats__label">Pending:</span><br />
                    <span className="video-review-pending">{studentVideoSummary?.result?.result[0]?.totalVideosPending || 0}</span>
                  </div>
                  <div className="video-review-stats__item">
                    <span className="video-review-stats__label">Rejected:</span><br />
                    <span className="video-review-rejected">{studentVideoSummary?.result?.result[0]?.totalVideosRejected || 0}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="frame-data">

              <div className="frame-datasearch">

                <div className="frame-datasearch2">
                  <div className="search">
                    <p className="label-and">
                      <input className="input" placeholder="Search" type="text" value={searchQuery}
                        onChange={handleSearchChange} />
                    </p>
                    <img
                    className="generic"
                    alt="Generic"
                    src={searchicon}
                  />
                  </div>
                  
                </div>
                {selectedRowCount.length > 1 && (
                  <div className="accept-reject-button">
                    <button className="accept-button" onClick={handleAcceptButtonClick}>Accept</button>
                    <button className="reject-button" onClick={handleRejectButtonClick} >Reject</button>
                  </div>
                )}

                <Dialog open={isAcceptModalOpen} onClose={() => setAcceptModalOpen(false)}
                  className="dialogConfig"
                // sx={{ width: '50%', height: '40%', marginLeft: 'auto', marginRight: 'auto', marginTop: '15vh', padding: '30px 50px' }} 
                // sx={{ padding: '30px 50px', margin: '50px !important' }}
                >
                  {/* <div style={{padding: '5px 15px'}}> */}
                  <img className="brightness-alert" alt="Brightness alert" src={alert} /><br></br>
                  <DialogTitle style={{ textAlign: "center", padding: '50px 35px 15px 35px' }}>Student Clip Approval</DialogTitle>
                  <DialogContent style={{
                    padding: '0px 35px 20px 35px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center'
                  }}>
                    {/* Add content for the modal */}
                    <p className="modal-label">Your approved student clips will be queued for processing the Gradreels</p>
                    <br></br>
                    <p className="modal-label">Do you want to continue?</p>

                  </DialogContent>
                  <DialogActions style={{ textAlign: "center", padding: '0px 35px 35px 35px' }}>
                    <button onClick={() => setAcceptModalOpen(false)} className="button-modal">No</button>
                    <button onClick={handleAcceptModalYesClick} className="button-modal">Yes</button>
                  </DialogActions>
                  {/* </div> */}
                </Dialog>

                <Dialog open={isSecondModalOpen} onClose={() => setSecondModalOpen(false)} className="dialogConfig2"
                // sx={{ width: '400px', height: '400px', marginLeft: 'auto', marginRight: 'auto', marginTop: '15vh', padding: '20px' }}
                >
                  {/* -------------------------------------------------------------------------------------------------- */}
                  <div className="frame-wrapper">
                    <div className="frame">
                      <div className="sucess-loader">
                        <div className="overlap-group1">
                          <img
                            className="ellipse1"
                            alt="Ellipse"
                            src={ellipseicon1}
                          />
                          <FiRrCheck2 color="#1ba421" className="fi-rr-check" />
                        </div>

                      </div>
                      {/* <div className="text-wrapper">Successful</div> */}
                    </div>
                  </div>
                  {/* -------------------------------------------------------------------------------------------------- */}

                  <DialogTitle
                    style={{
                      textAlign: "center",
                      padding: '2px 35px 15px 35px'
                    }}>Success</DialogTitle>
                  <DialogContent style={{
                    // padding: '0px 35px 20px 35px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center'
                  }}>
                    {/* Content for the second modal */}
                    <p className="modal-label">Your approved student clips has been successfully queued for processing the Gradreels </p>

                  </DialogContent>

                </Dialog>

                <Dialog open={isRejectModalOpen} onClose={() => setRejectModalOpen(false)}
                  className="rejectDialogConfig"
                // sx={{ width: '400px', height: '500px', marginLeft: 'auto', marginRight: 'auto', marginTop: '15vh', padding: '20px' }}
                >
                  <img className="brightness-alert" alt="Brightness alert" src={alert} /><br></br>
                  <DialogTitle style={{ textAlign: "center", padding: '60px 35px 15px 35px' }}>Student Clip Approval</DialogTitle>
                  <DialogContent style={{
                    padding: '0px 35px 20px 35px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center'
                  }}>
                    {/* Add content for the modal */}
                    <p className="modal-label-reject">Reason for Rejection:</p>
                    <textarea style={{ width: '100%', height: 80,marginTop:'10px' }} onChange={(e) => setReason(e.target.value)}></textarea><br></br><br></br>
                    <p className="modal-label">Your selected student clips will be rejected and the student will be notified</p>
                    <br></br><p className="modal-label">Do you want to continue?</p>
                  </DialogContent>
                  <DialogActions style={{ textAlign: "center", padding: '0px 35px 35px 35px' }}>
                    <button onClick={() => setRejectModalOpen(false)} className="button-modal rejectno">No</button>
                    <button onClick={handleRejectModalYesClick} className="button-modal rejectyes">Yes</button>
                  </DialogActions>
                </Dialog>

                <Dialog open={isThirdModalOpen} onClose={() => setThirdModalOpen(false)} className="dialogConfig2"
                // sx={{ width: '400px', height: '400px', marginLeft: 'auto', marginRight: 'auto', marginTop: '15vh', padding: '20px', }}
                >
                  <div className="frame-wrapper">
                    <div className="frame">
                      <div className="sucess-loader">
                        <div className="overlap-group1">
                          <img
                            className="ellipse1"
                            alt="Ellipse"
                            src={ellipseicon1}
                          />
                          <FiRrCheck2 color="#1ba421" className="fi-rr-check" />
                        </div>

                      </div>
                      {/* <div className="text-wrapper">Successful</div> */}
                    </div>
                  </div>
                  <DialogTitle style={{ textAlign: "center", padding: '2px 35px 15px 35px' }}>Success</DialogTitle>
                  <DialogContent style={{
                    padding: '0px 35px 20px 35px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center'
                  }}>
                    {/* Content for the third modal */}
                    <p className="modal-label">Your selected student clips has been successfully rejected from the Gradreels. </p>
                  </DialogContent>
                </Dialog>
              </div>

              <div className="frame-datagrid">
                <StyledEngineProvider injectFirst>
                  <DataGridPending rows={filterRows(searchQuery)} setSelectedRowCount={setSelectedRowCount} selectedRowCount={selectedRowCount} />
                </StyledEngineProvider>
              </div>

              {loading && (
            <div className="loader-container">
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px' }}>
              <CircularProgress />
            </Box>
        </div>
          )}

            </div>

          </div>

        </div>
      </div>
      <ToastContainer autoClose={1000} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    eventDetails: state.preEvent.eventDetails,
    loading: state.preEvent.loading,
    studentVideoSummary: state.preEvent.studentVideoSummary,
    studentVideoForApproval: state.preEvent.studentVideoForApproval,
    eventSuccess: state.preEvent.eventSuccess,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getStudentVideoSummary: (clientId, eventId) => dispatch(getStudentVideoSummary(clientId, eventId)),
    getStudentVideoForApproval: (clientId, eventId) => dispatch(getStudentVideoForApproval(clientId, eventId)),
    saveStudentVideoStatus: (data) => dispatch(saveStudentVideoStatus(data)),
  };
};

const ConnectedPendingVideoDashboard = connect(mapStateToProps, mapDispatchToProps)(PendingVideoDashboard);

export { ConnectedPendingVideoDashboard as PendingVideoDashboard };