import { CREATE_EVENT_FAILURE, CREATE_EVENT_START, CREATE_EVENT_SUCCESS, FAIL_REQUEST, GET_LOGIN_USER, GET_SEASON_LIST, GET_USER_ACCEPTANCE, MAKE_REQUEST, SET_EVENT_SUCCESS } from "../ActionTypes/SharedActionTypes";

// Initial values for shared reducer
const initialState = {
    loading: true,
    errmessage: '',
    loginUserDetails: null,
    getUserAcceptanceDetails: null,
    seasonList: null,
}

// Stores the fetched or modified values in store
const SharedReducer = (state = initialState, action) => {
  switch (action.type) {
    case MAKE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FAIL_REQUEST:
      return {
        ...state,
        loading: false,
        errmessage: action.payload,
      };
    case CREATE_EVENT_START:
      return {
        ...state,
        loading: true,
      };
    case CREATE_EVENT_SUCCESS:
      return {
        ...state,
        loading: false,
        eventSuccess: true,
      };

    case CREATE_EVENT_FAILURE:
      return {
        ...state,
        loading: false,
        errmessage: action.payload,
        eventSuccess: false,
      };
    case GET_LOGIN_USER:
      return {
        ...state,
        loading: false,
        errmessage: "",
        loginUserDetails: action.payload,
      };
    case GET_USER_ACCEPTANCE:
      return {
        ...state,
        loading: false,
        errmessage: "",
        getUserAcceptanceDetails: action.payload,
      };
    case GET_SEASON_LIST:
      return {
        ...state,
        loading: false,
        errmessage: "",
        seasonList: action.payload,
      };
    default:
      return state;
  }
};

export default SharedReducer;
