import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { Callout, ColorPicker, FontWeights, getTheme, mergeStyleSets } from '@fluentui/react';
import { Box, CircularProgress } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { StyledEngineProvider } from '@mui/material/styles';
import "./style.css";

import { SeasonSettings } from "../SeasonSettings";
import Tabs from '../Tabs';

import { FiRrCloudUpload13 } from "../../../icons/FiRrCloudUpload13/FiRrCloudUpload13";
import { FiRrCommentInfo } from "../../../icons/FiRrCommentInfo/FiRrCommentInfo";
import { FiRrEdit12 } from "../../../icons/FiRrEdit12/FiRrEdit12";
import { FiRrEdit14 } from "../../../icons/FiRrEdit14/FiRrEdit14";
import { FiRrEdit8 } from "../../../icons/FiRrEdit8/FiRrEdit8";
import { FiRrPicture1 } from "../../../icons/FiRrPicture1/FiRrPicture1";
import { TemplateEmptyVuesaxLinearProperty332 } from "../../../icons/TemplateEmptyVuesaxLinearProperty332/TemplateEmptyVuesaxLinearProperty332";

import rightarrow from '../../../icons/ImageIcons/keyboard-arrow-right.svg';
import homeImage from '../../../icons/ImageIcons/material-symbols_home-rounded.svg';
import uploadIcon from '../../../icons/ImageIcons/upload-icon.png';
import uploadMoreIcon from '../../../icons/ImageIcons/upload-more-icon.svg';

import { Checkbox } from "../../../components/Checkbox";
import DropDownSeasonSettings from "../../../components/DropDownSeasonSettings/DropDownSeasonSettings";
import { PropertyDefault } from "../../../components/PropertyDefault/PropertyDefault";

import { useBoolean } from '@fluentui/react-hooks';

import ImageModal from "../../../components/ImageCropper/ImageModal";
import { getClientData, toastError } from "../../../constants/Constants";
import { createSeasonSettingsClientMaster, fetchClientMaster, getIntroVideoForSettings, getSelectedSeason, postIntroVideoForSettings, setEventSuccess } from "../../../store/Actions/SeasonSettingsAction";

const fixedWidthForClass =20
const fixedWidthForYear = 25

const CreateIntroClipFrameSeasonSettings = ({ loading, getIntroVideoData, postIntroVideo, clientMasterData, introVideoData, selectedSeason, getSelectedSeason, fetchClientMaster, setEventSuccess, eventSuccess, createSeasonSettingsClientMaster }) => {
    const navigate = useNavigate();
    const clientData = getClientData();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const createVideo = searchParams.get('createVideo');

    const [videoSrc, setVideoSrc] = useState("");
    const [studentFileUrl, setFile] = useState();
    const [openUploadModal, setOpenUploadModal] = useState(false);
    const [openLogoUploadModal, setOpenLogoUploadModal] = useState(false);
    const [isLogoUploaded, setIsLogoUploaded] = useState(false);

    const [sheetBackcolor, setsheetBackcolor] = React.useState('#f5f5f5');
    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
    const [isCalloutVisible_Images, { toggle: toggleIsCalloutVisible_Images }] = useBoolean(false);
    const [isCalloutVisible_Heading, { toggle: toggleIsCalloutVisible_Heading }] = useBoolean(false);
    const [isCalloutVisible_Subheading, { toggle: toggleIsCalloutVisible_Subheading }] = useBoolean(false);
    const [isCalloutVisible_Year, { toggle: toggleIsCalloutVisible_Year }] = useBoolean(false);
    const [textchangeClass, setTextChangeClass] = React.useState('CLASS OF');
    const [textchangeHeading, setTextChangeHeading] = React.useState('CONGRATULATIONS');
    const [textchangeYear, setTextChangeYear] = React.useState('2024');
    const [isbuttontoggle, setToggle] = useBoolean(false);
    const [imageBordercolor, setimageBordercolor] = React.useState('#6e6e6e');
    const [headingColor, setheadingColor] = React.useState('#6e6e6e');
    
    const [subheadingColor, setsubheadingColor] = React.useState('#6e6e6e');
    const [yearColor, setyearColor] = React.useState('#6e6e6e');
    const [isModalOpen_Images, { setTrue: showModal_Images, setFalse: hideModal_Images }] = useBoolean(false);
    const [isModalOpen_Images_logo, { setTrue: showModal_Images_logo, setFalse: hideModal_Images_logo }] = useBoolean(false);
    const [preview_Images, setPreview_Images] = useState([]);
    const [preview_Images_logo, setPreview_Images_logo] = useState("");
    const [latestFile, setLatestFile] = useState('');
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [isFileSelectorDisabled, setIsFileSelectorDisabled] = useState(false);
    const [uploadedImageFiles, setUploadedImageFiles] = useState([]);
    const [seasonId, setSeasonId] = useState(0);
    const [seasonName, setSeasonName] = useState('');
    const [seasonStartPeriod, setSeasonStartPeriod] = useState('');
    const [seasonEndPeriod, setSeasonEndPeriod] = useState('');
    // const [loading, setLoading] = useState(false);

    const [inputWidthForYear, setInputWidthForYear] = useState((textchangeYear.length + 2 )*fixedWidthForYear);
    const [inputWidthForClass, setInputWidthForClass] = useState((textchangeClass.length + 1 )*fixedWidthForClass);

    const [selectedImageForCropping, setSelectedImageForCropping] = useState(null);
    const [isCroppingForLogo, setIsCroppingForLogo] = useState(false);
    const [isCroppingForImages, setIsCroppingForImages] = useState(false);
    const [completedCrop, setCompletedCrop] = useState(null);

      useEffect(() => {
        const fetchData = async () => {
          if (seasonName) {
            await fetchClientMaster({ clientId: clientData.clientID, seasonName: seasonName});
            await getIntroVideoData({ ClientId: clientData.clientID, seasonName: seasonName});
          }
        };
      
        fetchData();
      }, [seasonName, selectedSeason]);

      useEffect(() => {
        if (eventSuccess && !loading) {
          console.log("Event created successfully!");
          setEventSuccess(false);
          navigate("/StudentClipSettings");
        }
      }, [eventSuccess, loading]);


    useEffect(() => {
        if (!createVideo) {
            if (introVideoData?.result?.data?.createVideo === false || introVideoData?.result?.isDataAvailable === false) {
                navigate('/CreateIntroClipSettings');
            }
        }
            setsheetBackcolor(introVideoData?.result?.data?.createIntroVideo?.frameBGColor || '#f5f5f5');
            setimageBordercolor(introVideoData?.result?.data?.createIntroVideo?.thinFrameColor || '#6e6e6e');
            if (introVideoData?.result?.data?.createIntroVideo?.textAsset) {
                setheadingColor(introVideoData?.result?.data?.createIntroVideo?.textAsset[0]?.textColor)
                setsubheadingColor(introVideoData?.result?.data?.createIntroVideo?.textAsset[1]?.textColor)
                setyearColor(introVideoData?.result?.data?.createIntroVideo?.textAsset[2]?.textColor)
                setTextChangeHeading(introVideoData?.result?.data?.createIntroVideo?.textAsset?.[0]?.textData || 'CONGRATULATIONS');
                setTextChangeClass(introVideoData?.result?.data?.createIntroVideo?.textAsset?.[1]?.textData || 'CLASS OF');
                setTextChangeYear(introVideoData?.result?.data?.createIntroVideo?.textAsset?.[2]?.textData || '2024');
                setInputWidthForYear(((introVideoData?.result?.data?.createIntroVideo?.textAsset?.[2]?.textData || '2024').length + 1) * fixedWidthForYear);
                setInputWidthForClass(((introVideoData?.result?.data?.createIntroVideo?.textAsset?.[1]?.textData || 'CLASS OF').length + 1) * fixedWidthForClass);
            } else {
                setheadingColor('#6e6e6e')
                setsubheadingColor('#6e6e6e')
                setyearColor('#6e6e6e')
                setTextChangeHeading('CONGRATULATIONS');
                setTextChangeClass('CLASS OF');
                setTextChangeYear('2024');
                
            }

            setPreview_Images_logo({ url: introVideoData?.result?.data?.createIntroVideo?.logoSasUri, uploadedData: true } || {});
            if (introVideoData?.result?.data?.createIntroVideo?.thinFramePersonImageAssets) {
                const filteredImages = introVideoData?.result?.data?.createIntroVideo?.thinFramePersonImageAssets.filter(imageData => imageData.orderId !== 0);
                const allEmpty = filteredImages.every(imageData => (
                !imageData.imageName &&
                !imageData.imageSasUri
            ));

            if (allEmpty) {
                setPreview_Images(null);
                setLatestFile(null)
                setUploadedImageFiles(null)
            } else {
                const previewImages = filteredImages.map(imageData => ({
                    name: imageData.imageName,
                    result: imageData.imageSasUri,
                    uploadedData: true
                }));
                setPreview_Images(previewImages);
                setLatestFile(previewImages[0]?.result || null);
                const previewImagesWithAllData = filteredImages.map(imageData => ({
                  orderId: imageData.orderId,
                  imageName: imageData.imageName,
                  imagePath: imageData.imagePath,
                  imageType: imageData.imageType,
              }));
                setUploadedImageFiles(previewImagesWithAllData)
            }} else {
                setPreview_Images(null);
                setLatestFile(null)
                setUploadedImageFiles(null);
            }
    }, [introVideoData])

    // Changes selected colors based on user inputs
    const onChangeBackgroundColor = React.useCallback((ev, colorObj) =>
        setsheetBackcolor(colorObj.str), []);

    // Changes selected colors based on user inputs
    const onChangeBackgroundColor_Images = React.useCallback((ev, colorObj) =>
        setimageBordercolor(colorObj.str), []);

    // Changes selected colors based on user inputs
    const onChangeBackgroundColor_Heading = React.useCallback((ev, colorObj) =>
        setheadingColor(colorObj.str), []);

    // Changes selected colors based on user inputs
    const onChangeBackgroundColor_Subheading = React.useCallback((ev, colorObj) =>
        setsubheadingColor(colorObj.str), []);

    // Changes selected colors based on user inputs
    const onChangeBackgroundColor_Year = React.useCallback((ev, colorObj) =>
        setyearColor(colorObj.str), []);

    // Addds the texts based on user inputs
    const onChangeTexts = (e, text) => {
        if (text === 'Class') {
            setTextChangeClass(e.target.value);
            setInputWidthForClass((e.target.value.length + 1) * fixedWidthForClass);
            
        }
        if (text === 'Year') {
            setTextChangeYear(e.target.value);
            setInputWidthForYear((e.target.value.length + 2) * fixedWidthForYear);
        }
    }

    // Adds the user selected image file to the UI
    const handleOnChange = (e) => {
        if (e.target.files.length > 0) {
            const file = e.target.files[0];
            const reader = new FileReader();

            const allowedTypes = ['image/png', 'image/jpeg'];
            if (!allowedTypes.includes(file.type)) {
                toastError('Invalid file type. Please select an image file with one of the following extensions: .png, .jpeg');
                return;
            } else {
                reader.onload = function () {
                    const image = new Image();
                    image.onload = () => {
                        const width = image.width;
                        const height = image.height;
                        if (width >= 1000 && height >= 1000) {
                            setSelectedImageForCropping({ name: file.name, url: reader.result });
                            setIsCroppingForImages(true);
                            setOpenUploadModal(false);

                        } else {
                            toastError('File is not in given dimensions');
                        }

                    };
                    image.src = reader.result;
                };

                reader.readAsDataURL(file);
                e.target.value = null;
            }
        }
    };

    // Adds the user selected logo/image file to the UI
    const handleOnChange_logo = (e) => {
        if (e.target.files.length > 0) {
            const file = e.target.files[0];
            const reader = new FileReader();

            const allowedTypes = ['image/png'];
            if (!allowedTypes.includes(file.type)) {
                toastError('Invalid file type. Please select a .png file.');
            } else {
                reader.onload = function () {
                    const image = new Image();
                    image.onload = () => {
                        const width = image.width;
                        const height = image.height;
                        if (width >= 500 && height >= 100) {
                            setSelectedImageForCropping({name: file.name, url: reader.result});
                            setIsLogoUploaded(true);
                            setOpenLogoUploadModal(true);
                        } else {
                            toastError('File is not in given dimensions');
                        }
                    };
                    image.src = reader.result;
                };
                reader.readAsDataURL(file);
            }
        }
    };
      
    // Opens modal to upload logo
    const preview_logo_click=()=>{
        setIsCroppingForLogo(true);
        setIsLogoUploaded(false);
        setOpenLogoUploadModal(false)
    }

    // Closes the upload logo modal
    const handleCloseModal = () => {
        setOpenLogoUploadModal(false);
        setIsLogoUploaded(false); // Reset logoUploaded state when modal is closed
    };

    // Opens file explorer to select the desired file
    const openUpload = () => {
        if (document.getElementById('image_upload') != undefined) {
            document.getElementById('image_upload').click();
        }
    }

    // Opens file explorer to select the desired file
    const openUpload_outer = () => {
        if (document.getElementById('image_upload_outer') != undefined) {
            document.getElementById('image_upload_outer').click();
        }
    }

    // Opens file explorer to select the desired file
    const openUpload_logo = () => {
        if (document.getElementById('image_upload_logo') != undefined) {
            document.getElementById('image_upload_logo').click();
        }
    }

    const handleDrop = (e) => {
        const allowedTypes = new Set([e.target.accept]);
        if (!allowedTypes.has(e.dataTransfer.files[0].type)) {
            // stop event prepagation
            e.preventDefault();
        }
    };

    // Set the selected season name
    const handleSeasonName = (seasonName, seasonId) => {
        setSeasonName(seasonName);
        getSelectedSeason(seasonId);
        setSeasonId(seasonId);
    };

    // Handles redirection between upload and create video
    const handleSeasonChange = () => {
        searchParams.forEach((value, key) => {
            searchParams.delete(key);
        });

        // Reassign the modified searchParams object to location.search
        location.search = searchParams.toString();
    }

    // Set the selected season dates
    const handleSeasonDates = (startDate, endDate) => {
        setSeasonStartPeriod(startDate);
        setSeasonEndPeriod(endDate);
      }

    // Uploads the data for the creation of intro video
    const handleSave = async () => {
        if (!seasonName) {
            toastError('Please select Season from dropdown.');
            return;
        }
        if ((!preview_Images_logo?.url && !selectedImageForCropping) || (!uploadedImageFiles?.length && !preview_Images?.length)) {
            toastError('Please select at least one file for images and logo');
            return;
        }

        if(textchangeHeading === '' || textchangeClass === '' || textchangeYear === ''){
            toastError('One of the text field is empty.');
            return;
        }
        // setLoading(true)
        const thinFramePersonImageAssets = [];
        let logoDetails;

        if (preview_Images_logo?.uploadedData) {
            logoDetails = {
                logoName: introVideoData?.result?.data?.createIntroVideo?.logoName,
                logoPath: introVideoData?.result?.data?.createIntroVideo?.logoPath,
            };
        } else {
            logoDetails = {
                logoName: preview_Images_logo.name,
            };
        }

        const previewImagesToAdd = preview_Images.filter(image => !image.uploadedData);
        let lengthOfUploadedImages = 0
        let remainingSlots = 3
        if (uploadedImageFiles) {

            lengthOfUploadedImages = uploadedImageFiles?.length;
            remainingSlots = 3 - lengthOfUploadedImages;

            if (uploadedImageFiles?.length > 0) {
                uploadedImageFiles.forEach(image => {
                    const jsonObject = {
                        orderId: image.orderId,
                        imageName: image.imageName,
                        imagePath: image.imagePath,
                        imageType: image.imageType,
                    };
                    thinFramePersonImageAssets.push(jsonObject);
                });
            }
        }

        previewImagesToAdd.slice(0, remainingSlots).forEach((image, index) => {
            const imageType = image.result.substring(image.result.indexOf('/') + 1, image.result.indexOf(';'));
            const jsonObject = {
                orderId: lengthOfUploadedImages + index + 1,
                imageName: image.name,
                imagePath: "",
                imageType: imageType,
            };
            thinFramePersonImageAssets.push(jsonObject);
        });

        if(!clientMasterData?.result?.data?.template){
            await createSeasonSettingsClientMaster({
              itemType: "SeasonSettingsMaster",
              itemTypeDesc: "SeasonSettingsMaster",
              clientId: clientData.clientID,
              clientName: clientData.clientName,
              seasonId: seasonId,
              seasonName: seasonName,
              seasonStartDate: seasonStartPeriod,
              seasonEndDate: seasonEndPeriod,
              template: 'Standard',
              templateDesc: 'Standard',
            });
          }

        await postIntroVideo(selectedFiles, {
            clientId: clientData.clientID,
            clientName: clientData.clientName,
            seasonName: seasonName,
            seasonStartPeriod: seasonStartPeriod,
            seasonEndPeriod: seasonEndPeriod,
            createVideo: true,
            createIntroVideo: {
                frameBGColor: sheetBackcolor,
                thinFrameColor: imageBordercolor,
                textAsset: [
                    { textId: "1", textData: textchangeHeading, textFontStyle: "Bold", textColor: headingColor },
                    { textId: "2", textData: textchangeClass, textFontStyle: "BoldItalic", textColor: subheadingColor },
                    { textId: "3", textData: textchangeYear, textFontStyle: "BoldLayers", textColor: yearColor },
                ],
                thinFramePersonImageAssets: thinFramePersonImageAssets,
                ...logoDetails,
                GeneratedVideoPath: ""
            },
            updatedOn: new Date(),
            updatedBy: clientData.userEmail
        });
        // setLoading(false)
    };

    // Deletes the images when user clicks on cross icon
    const handleDelete = (index) => {
        // Create a copy of the preview_Images array
        const updatedPreviewImages = [...preview_Images];
        
        // Remove the image at the specified index
        updatedPreviewImages.splice(index, 1);
    
        // Update the state with the modified array
        setPreview_Images(updatedPreviewImages);
    
        // Check if the deleted image was the latestFile
        if (index === 0 && updatedPreviewImages.length === 0) {
            // If the deleted image was the latestFile and there are no more images, reset latestFile to null
              setLatestFile(null);
          }
          else if (index === 0 && updatedPreviewImages.length === 1){
            setLatestFile(updatedPreviewImages[0].result);
          }
          else if (updatedPreviewImages.length > 0) {
            // If the deleted image was the latestFile, set the main preview to the next image
              setLatestFile(updatedPreviewImages[index - 1].result);
          }
    
        // Update uploadedImageFiles if necessary
        if (uploadedImageFiles) {
            const uploadedImages = [...uploadedImageFiles];
            uploadedImages.splice(index, 1);
            setUploadedImageFiles(uploadedImages);
        }
    
        // Reset isFileSelectorDisabled if necessary
        setIsFileSelectorDisabled(updatedPreviewImages.length === 3);
    };
    
    
    // Opens modal to upload images
    const handleFrameOnClick = () => {
        setOpenUploadModal(true);
    }

    // Opens modal to upload logo
    const handleLogoFrameOnClick = () => {
        console.log(preview_Images_logo)
        setOpenLogoUploadModal(true);
    }
    const handleFileChange = (file) => {
        try {
            // Transform file into blob URL
            setVideoSrc(URL.createObjectURL(file));
        } catch (error) {
            console.error(error);
        }
    };

    // const handleImageClick = (index) => {
    //   setLatestFile(preview_Images[index].result);
    // }

    // Closes the cropper modal
    const onCloseCropper = () => {
        setIsCroppingForLogo(false);
        setIsCroppingForImages(false); 
      }

    return (
        <div className="ind-introclip-season">
            <div className="div-3">
                <SeasonSettings />
                <div className="frame-12">
                    <div className="frame-13">
                        <div className="section-title">
                            <div className="frame-14">
                                <div className="page-title">
                                    <div className="text-wrapper-4">Season Settings</div>
                                </div>
                                <div className="breadcrum">
                                    <img
                                        className="material-symbols"
                                        alt="Material symbols"
                                        src={homeImage} />
                                    <img
                                        className="keyboard-arrow-right"
                                        alt="Keyboard arrow right"
                                        src={rightarrow}/>
                                    <div className="text-wrapper-5">Settings</div>
                                    <img
                                        className="keyboard-arrow-right"
                                        alt="Keyboard arrow right"
                                        src={rightarrow}/>
                                    <div className="text-wrapper-6">Create Intro Clip Frame Settings</div>
                                </div>
                            </div>
                        </div>
                        <div className="label">
                            <div className="event-ID">Select Season Name</div>
                        </div>
                        <StyledEngineProvider injectFirst>
                            <DropDownSeasonSettings handleSeasonChange={handleSeasonChange} onSeasonsName={handleSeasonName} selectedSeason={selectedSeason} onSeasonDates={handleSeasonDates}/>
                        </StyledEngineProvider>
                        <StyledEngineProvider injectFirst>
                            <Tabs />
                        </StyledEngineProvider>
                        <div className="frame-15">


                            <div className="frame-16">

                              
                                        <div className="frame-18">
                                        <div className="frame-19">
                                            <div className="frame-20">
                                            <div className="frame-21">
                                                <div className="text-wrapper-7">Create Intro Clip</div>
                                            </div>
                                            <div className="frame-22">
                                                <div className="frame-23">
                                                <div className="component-wrapper">
                                                    <TemplateEmptyVuesaxLinearProperty332 className="template-empty" />
                                                    {/* <Component className="component-1" /> */}
                                                </div>
                                                <p className="video-tutorial-how">
                                                    <span className="span">Video Tutorial: </span>
                                                    <span className="text-wrapper-8">How to Generate an Intro Clip?</span>
                                                </p>
                                                </div>
                                            </div>
                                            </div>
                                            <FiRrCommentInfo className="icon-instance-node" color="#7E7E7E" />
                                        </div>
                                            <div className="group-2" style={{ backgroundColor: sheetBackcolor != '' ? sheetBackcolor : '#f5f5f5' }}>
                                                        <div className="group-3" style={{ borderColor: imageBordercolor != '' ? imageBordercolor : '#6e6e6e' }}>
                                                            {latestFile != null ?
                                                                <img style={{ width: '100%', height: '100%' }} src={latestFile} alt="Upload preview" />
                                                                :
                                                                <div className="frame-24" onClick={handleFrameOnClick}>
                                                                    <FiRrPicture1 className="icon-instance-node-upload" />
                                                                    <div className="text-wrapper-9">Upload Image(s)</div>
                                                                </div>
                                                            }

                                                        <div className="fi-rr-edit-12-wrapper"
                                                            id="callout-button-1" onClick={toggleIsCalloutVisible_Images}>
                                                            <FiRrEdit12 className="icon-instance-node-2" />
                                                        </div>
                                                        </div>
                                                        
                                                    

                                                <div className="frame-25">
                                                    <PropertyDefault
                                                        className="design-component-instance-node-2"
                                                        icon={<FiRrEdit8 className="icon-instance-node-2" />}
                                                        onclick={toggleIsCalloutVisible_Heading}
                                                        id="callout-button-2"
                                                        headvalue={headingColor}
                                                        onChange={(newValue) => {
                                                            setTextChangeHeading(newValue);

                                                        }}
                                                        value={textchangeHeading}
                                                    />
                                                    <div className="frame-27">
                                                        <input className="text-wrapper-10" style={{ fontFamily:'Lato', color: subheadingColor != '' ? subheadingColor : '#6e6e6e', width: inputWidthForClass + 'px', maxWidth: '100%' }} maxLength={18} onChange={(e)=> onChangeTexts(e,'Class')} value={textchangeClass}/>

                                                        <div className="fi-rr-edit-14-wrapper" id="callout-button-3" onClick={toggleIsCalloutVisible_Subheading}>
                                                            <FiRrEdit14 className="icon-instance-node-2" />
                                                        </div>
                                                    </div>
                                                    <div className="frame-27">
                                                         <input className="text-wrapper-11" style={{ fontFamily:'SeasideResortNF', color: yearColor != '' ? yearColor : '#6e6e6e', width: inputWidthForYear + 'px', maxWidth: '100%' }} maxLength={18} onChange={(e)=>onChangeTexts(e,'Year')} value={textchangeYear}/>
                                                        
                                                        <div className="fi-rr-edit-15-wrapper" id="callout-button-4" onClick={toggleIsCalloutVisible_Year}>
                                                            <FiRrEdit14 className="icon-instance-node-2" />
                                                        </div>
                                                    </div>
                                                
                                                <div className="overlap-2">
                                                    <div className="rectangle-2" />

                                                    <div className="frame-28" style={{ width: preview_Images_logo?.url != null ? '98%': '', left: preview_Images_logo?.url != null ? '3px' : '75px' }} onClick={handleLogoFrameOnClick}>
                                                        {preview_Images_logo?.url != null ?
                                                            <img style={{ width: '100%', height: '56px' }} src={preview_Images_logo?.url} />
                                                            :
                                                            <>
                                                                <div className="group-4">
                                                                    <div className="overlap-group-3">
                                                                        <div className="text-wrapper-12">Logo</div>
                                                                    </div>
                                                                </div>
                                                                <div className="text-wrapper-13">Your logo goes here</div>
                                                            </>
                                                        }

                                                    </div>
                                                    <div className="fi-rr-cloud-upload-wrapper" onClick={handleLogoFrameOnClick}>
                                                        <FiRrCloudUpload13 className="icon-instance-node-2" color="#5C5C5C" />
                                                    </div>
                                                </div>
                                                </div>
                                                <div className="fi-rr-edit-11-wrapper" id="callout-button" onClick={toggleIsCalloutVisible}>
                                                <FiRrEdit14 className="icon-instance-node-2" />
                                            </div>
                                            </div>
                                            <input id="image_upload_outer" onChange={(e) => handleOnChange(e)} style={{ display: 'none' }} type="file" accept="image/png, image/jpeg" disabled={isFileSelectorDisabled} />
                                    
                                            {preview_Images?.length > 0 ?
                                                <div style={{ display: 'flex', flexDirection: 'row',width:'960px',marginLeft:'20px'}}>
                                                    {preview_Images?.map((itm, index) => {
                                                        return (
                                                            <p style={{ marginRight: '30px' }}>
                                                                <img className="plusIcon_ImagePreview" src={itm.result} alt="Upload preview" />
                                                                <button className="delete-button" onClick={() => handleDelete(index)}>X</button>
                                                            </p>
                                                        )
                                                    })}
                                                    {preview_Images?.length == 3 ? '' :
                                                        <div onClick={openUpload_outer} className="plusIcon_Image">
                                                            <img src={uploadMoreIcon} alt="Upload preview" />
                                                        </div>
                                                    }
                                                </div>
                                            : ''}
                                        </div>
                                    </div>
                                                    
                        </div>
                        
                    </div>
                    <div className="primary-BTN-wrapper">
                    <button className="primary-BTN-2">
                            <Link className="button-text-2" to="/MediaDetailsSettings">Back</Link>
                        </button>
                        <button className="primary-BTN">
                            <Link className="button-text" onClick={handleSave}>Save</Link>
                        </button>
                    </div>

                    <ImageModal
                        modalIsOpen={isCroppingForLogo}
                        onClose={onCloseCropper}
                        image={selectedImageForCropping}
                        ratio={5}
                        onCropCompleted={(area) => {
                            setCompletedCrop(area);
                        }}
                        onUpload={(file) => {
                            setSelectedFiles(prevSelectedFiles => [...prevSelectedFiles, file]);
                        }}
                        onFileCropping={(file) => {
                            if (completedCrop.width >= 500 && completedCrop.height >= 100) {
                                setIsCroppingForLogo(false);
                                setPreview_Images_logo({ name: selectedImageForCropping.name, url: file.url, uploadedData: false })
                            } else {
                                toastError('Cropped image minimum dimensions must be 500x100');
                            }
                        }}
                    />

                    <ImageModal
                        modalIsOpen={isCroppingForImages}
                        onClose={onCloseCropper}
                        image={selectedImageForCropping}
                        ratio={1000 / 1000}
                        onCropCompleted={(area) => {
                            setCompletedCrop(area);
                        }}
                        onUpload={(file) => {
                            setSelectedFiles(prevSelectedFiles => [...prevSelectedFiles, file]);
                        }}
                        onFileCropping={(file) => {
                            if (completedCrop.width >= 1000 && completedCrop.height >= 1000) {
                                const newArray = [{ name: selectedImageForCropping.name, result: file.url, uploadedData: false }];
                                setPreview_Images(prev => prev ? [...prev, ...newArray] : newArray);
                                setLatestFile(file.url); // Set latestFile only if it hasn't been set yet
                                setIsCroppingForImages(false);
                            } else {
                                toastError('Cropped image minimum dimensions must be 1000x1000');
                            }
                        }}
                    />

                    {isCalloutVisible && (
                        <Callout
                            className={styles1.callout}
                            ariaLabelledBy={'callout-label'}
                            ariaDescribedBy={'callout-description'}
                            role="dialog"
                            gapSpace={0}
                            target={`#callout-button`}
                            onDismiss={toggleIsCalloutVisible}
                            setInitialFocus
                        >
                            <ColorPicker
                                color={sheetBackcolor}
                                onChange={onChangeBackgroundColor}
                                alphaType='none'
                                showPreview={false}
                                styles={colorPickerStyles}
                                strings={{
                                    hueAriaLabel: 'Hue',
                                }}
                            />
                        </Callout>
                    )}

                    {isCalloutVisible_Images && (
                        <Callout
                            className={styles1.callout}
                            ariaLabelledBy={'callout-label'}
                            ariaDescribedBy={'callout-description'}
                            role="dialog"
                            gapSpace={0}
                            target={`#callout-button-1`}
                            onDismiss={toggleIsCalloutVisible_Images}
                            setInitialFocus
                        >
                            <ColorPicker
                                color={imageBordercolor}
                                onChange={onChangeBackgroundColor_Images}
                                alphaType='none'
                                showPreview={false}
                                styles={colorPickerStyles}
                                strings={{
                                    hueAriaLabel: 'Hue',
                                }}
                            />
                        </Callout>
                    )}

                    {isCalloutVisible_Heading && (
                        <Callout
                            className={styles1.callout}
                            ariaLabelledBy={'callout-label'}
                            ariaDescribedBy={'callout-description'}
                            role="dialog"
                            gapSpace={0}
                            target={`#callout-button-2`}
                            onDismiss={toggleIsCalloutVisible_Heading}
                            setInitialFocus
                        >
                            <ColorPicker
                                color={headingColor}
                                onChange={onChangeBackgroundColor_Heading}
                                alphaType='none'
                                showPreview={false}
                                styles={colorPickerStyles}
                                strings={{
                                    hueAriaLabel: 'Hue',
                                }}
                            />
                        </Callout>
                    )}

                    {isCalloutVisible_Subheading && (
                        <Callout
                            className={styles1.callout}
                            ariaLabelledBy={'callout-label'}
                            ariaDescribedBy={'callout-description'}
                            role="dialog"
                            gapSpace={0}
                            target={`#callout-button-3`}
                            onDismiss={toggleIsCalloutVisible_Subheading}
                            setInitialFocus
                        >
                            <ColorPicker
                                color={subheadingColor}
                                onChange={onChangeBackgroundColor_Subheading}
                                alphaType='none'
                                showPreview={false}
                                styles={colorPickerStyles}
                                strings={{
                                    hueAriaLabel: 'Hue',
                                }}
                            />
                        </Callout>
                    )}

                    {isCalloutVisible_Year && (
                        <Callout
                            className={styles1.callout}
                            ariaLabelledBy={'callout-label'}
                            ariaDescribedBy={'callout-description'}
                            role="dialog"
                            gapSpace={0}
                            target={`#callout-button-4`}
                            onDismiss={toggleIsCalloutVisible_Year}
                            setInitialFocus
                        >
                            <ColorPicker
                                color={yearColor}
                                onChange={onChangeBackgroundColor_Year}
                                alphaType='none'
                                showPreview={false}
                                styles={colorPickerStyles}
                                strings={{
                                    hueAriaLabel: 'Hue',
                                }}
                            />
                        </Callout>
                    )}

                    <Dialog open={openUploadModal} onClose={() => setOpenUploadModal(false)}
                        className="dialogConfig1"

                    >

                        <DialogContent style={{
                            padding: '0px 35px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center'
                        }}>


                            <div className="uploadContainer">
                                <div className="div-2">
                                    <button className="CTA">
                                        <div className="label">UPLOAD PHOTO</div>
                                    </button>
                                    <div className="heading-upload">
                                        <div className="text-wrapper">Upload Photos</div>
                                    </div>
                                    <div className="upload-file-area"  onClick={openUpload}>
                                        <div className="frame-2">
                                            <img
                                                className="upload-icon"
                                                alt="Upload icon"
                                                src={uploadIcon}/>
                                            <div className="frame-3">
                                                <div className="drag-drop-files">
                                                    <p className="drag-drop-files-or">
                                                        <span className="span">Drag & drop files or </span>
                                                        <span className="text-wrapper-2">Browse</span>
                                                    </p>
                                                    <input id="image_upload" onChange={(evt) => { handleOnChange(evt) }}
                                                        type="file" style={{ display: 'none' }} accept="image/png, image/jpeg" />
                                                </div>
                                                <div className={`frame frame-1027`}>
                                                    <div className="div" style={{ display: 'flex',gap:'2px' }}>
                                                    <Checkbox className="checkbox-instance" property1="fi-sr-info" />
                                                    <p className="i-authorise">Kindly submit images in either .jpeg or .png format. <br />Ensure that the images have a minimum <br />dimension of 1000x1000 pixels.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </DialogContent>


                    </Dialog>


                    <Dialog open={openLogoUploadModal} onClose={handleCloseModal}
                        className="dialogConfig1"

                    >

                        <DialogContent style={{
                            padding: '0px 35px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center'
                        }}>


                            <div className="uploadContainer">
                                <div className="div-2">
                                <button className="CTA" onClick={preview_logo_click}
                                    disabled={!isLogoUploaded} // Disable the button if logoUploaded is false
                                    style={{ opacity: isLogoUploaded ? 1 : 0.5,
                                    cursor:isLogoUploaded ? 'pointer' : 'not-allowed' }}>
                                    <div className="label">UPLOAD LOGO</div>
                                    </button>
                                    <div className="heading-upload">
                                        <div className="text-wrapper">Upload Logo</div>
                                    </div>
                                    <input id="image_upload_logo" onChange={(evt) => {handleOnChange_logo(evt)}}
                                    type="file" style={{ display: 'none' }} accept="image/png" />
                                                
                                    <div className="upload-file-area" onClick={openUpload_logo}>
                                        <div className="frame-2">
                                            <img
                                                className="upload-icon"
                                                alt="Upload icon"
                                                src={uploadIcon}
                                            />
                                            <div className="frame-3">
                                                <div className="drag-drop-files">
                                                    <p className="drag-drop-files-or">
                                                        <span className="span">Drag & drop files or </span>
                                                        <span className="text-wrapper-2">Browse</span>
                                                    </p>
                                                </div>
                                                <div className={`frame frame-1027`}>
                                                <div className="div" style={{ display: 'flex',gap:'2px' }}>
                                                    <Checkbox className="checkbox-instance" property1="fi-sr-info" />
                                                    <p className="i-authorise">Kindly upload logos in .png format ensuring they have a<br /> transparent background. Ensure there is minimal<br /> blank space surrounding the logo, and that its dimensions<br /> are at least 500x100 pixels</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </DialogContent>


                    </Dialog>
                    
                    {loading && (
                        <div className="loader-container">
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px' }}>
                                <CircularProgress />
                            </Box>
                        </div>
                    )}
                </div>
            </div>
            
        </div>

    );
};
const colorPickerStyles = {
    panel: { padding: 12 },
    root: {
        maxWidth: 352,
        //minWidth: 352,
    },
    colorRectangle: { height: 268 },
};

const styles1 = mergeStyleSets({
    button: {
        width: 130,
    },
    callout: {
        width: 320,
        maxWidth: '90%',
        padding: '20px 24px',
    },
    title: {
        marginBottom: 12,
        fontWeight: FontWeights.semilight,
    },
    link: {
        display: 'block',
        marginTop: 20,
    },
});

// Images Popup
const cancelIcon = { iconName: 'Cancel' };

const theme = getTheme();
const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        borderRadius: '20px',
        width: '25%',

        padding: '26px'
    },
    header: [
        theme.fonts.xLargePlus,
        {
            flex: '1 1 auto',
            borderTop: `4px solid ${theme.palette.themePrimary}`,
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    heading: {
        color: theme.palette.neutralPrimary,
        fontWeight: FontWeights.semibold,
        fontSize: 'inherit',
        margin: '0',
    },
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
    },
});
const stackProps = {
    horizontal: true,
    tokens: { childrenGap: 40 },
    styles: { root: { marginBottom: 20 } },
};
const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};

const mapStateToProps = (state) => {
    return {
        loading: state.seasonSettings.loading,
        eventSuccess: state.seasonSettings.eventSuccess,
        clientMasterData: state.seasonSettings.clientMasterData,
        selectedSeason: state.seasonSettings.selectedSeason,
        introVideoData: state.seasonSettings.introVideoDataForSettings,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchClientMaster: (eventData) => dispatch(fetchClientMaster(eventData)),
        getIntroVideoData: (eventData) => dispatch(getIntroVideoForSettings(eventData)),
        createSeasonSettingsClientMaster: (clientData) =>
        dispatch(createSeasonSettingsClientMaster(clientData)),
        getSelectedSeason: (eventData) => dispatch(getSelectedSeason(eventData)),
        postIntroVideo: (files, introData) => dispatch(postIntroVideoForSettings(files, introData)),
        setEventSuccess: (flag) => dispatch(setEventSuccess(flag)),
    };
};

const ConnectedCreateIntroClipFrameSeasonSettings = connect(mapStateToProps, mapDispatchToProps)(CreateIntroClipFrameSeasonSettings);

export { ConnectedCreateIntroClipFrameSeasonSettings as CreateIntroClipFrameSeasonSettings };
