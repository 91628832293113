import axios from "axios";
import { toast } from "react-toastify";
import { toastSuccess, toastError } from "../../constants/Constants";
import { CREATE_EVENT_FAILURE, CREATE_EVENT_START, CREATE_EVENT_SUCCESS, FAIL_REQUEST, GET_SEASON_SETTINGS_CLIENT_MASTER, GET_SEASON_SETTINGS_EXIT_VIDEO, GET_SEASON_SETTINGS_INTRO_VIDEO, GET_SEASON_SETTINGS_MEDIA_DETAILS, GET_SEASON_SETTINGS_STUDENT_VIDEO, GET_SELECTED_SEASON, INITIALIZE_VALUES_OF_SEASON_SETTINGS, MAKE_REQUEST, SET_EVENT_SUCCESS } from "../ActionTypes/SeasonSettingsActionTypes";

export const makeRequest = () => {
  return {
    type: MAKE_REQUEST,
  };
};
export const failRequest = (err) => {
  return {
    type: FAIL_REQUEST,
    payload: err,
  };
};

export const createEventStart = () => ({
  type: CREATE_EVENT_START,
});

export const createEventSuccess = () => ({
  type: CREATE_EVENT_SUCCESS,
});

export const createEventFailure = (error) => ({
  type: CREATE_EVENT_FAILURE,
  payload: error,
});

export const setEventSuccess = (flag) => {
  return {
    type: SET_EVENT_SUCCESS,
    payload: flag,
  };
};


export const getSelectedSeason = (seasonName) => {
  return {
    type: GET_SELECTED_SEASON,
    payload: seasonName,
  };
};

export const getClientMasterForSeasonSettings = (data) => {
    return {
      type: GET_SEASON_SETTINGS_CLIENT_MASTER,
      payload: data
    }
  }

  export const getMediaDetailsForSeasonSettings = (data) => {
    return {
      type: GET_SEASON_SETTINGS_MEDIA_DETAILS,
      payload: data
    }
  }

  export const getIntroDataForSS = (data) => {
    return {
      type: GET_SEASON_SETTINGS_INTRO_VIDEO,
      payload: data
    }
  }

  export const getCustomDataForSS = (data) => {
    return {
      type: GET_SEASON_SETTINGS_STUDENT_VIDEO,
      payload: data
    }
  }

  
export const getExitDataForSS = (data) => {
    return {
      type: GET_SEASON_SETTINGS_EXIT_VIDEO,
      payload: data
    }
  }

  export const initializeValues = () => {
    return {
      type: INITIALIZE_VALUES_OF_SEASON_SETTINGS,
    }
  }

  // Gets the event master data
export const fetchClientMaster = (eventData) => {
    return async (dispatch) => {
      dispatch(makeRequest());
      //setTimeout(() => {
     await axios.get(`${window.APP.apiUrl}api/initialize/GetSeasonSettingsClientMaster`, {
        headers: {
          "X-API-Key": window.APP.apiKey,
        },
        params: {
          ClientId: eventData.clientId,
          seasonName: eventData.seasonName,
        }
      }).then(res => {
        const clientMasterData = res.data;
        dispatch(getClientMasterForSeasonSettings(clientMasterData));
      }).catch(err => {
        dispatch(failRequest(err.message))
      })
      // }, 2000);
  
    }
  }

  // Creates the master data for events
  export const createSeasonSettingsClientMaster = (clientData) => {
    return async (dispatch) => {
      dispatch(createEventStart());
  
      try {
        const payload = new FormData();
        payload.append('seasonSettingsMaster', JSON.stringify(clientData));
        const result = await axios.post(`${window.APP.apiUrl}api/initialize/CreateSeasonSettingsClientMaster`, payload, {
          method: 'POST',
          headers: {
            'Content-Type': 'multipart/form-data',
            "X-API-Key": window.APP.apiKey,
          }
        });
  
        if (result.data.isSuccess) {
          toastSuccess("Event created successfully!");
          dispatch(createEventSuccess());
        } else {
          toastError(`Error: ${result.data.error || "Unknown error"}`);
          dispatch(createEventFailure(result.error));
        }
      } catch (error) {
        toastError(`Error: ${error.message || "Unknown error"}`);
        dispatch(createEventFailure(error.message));
      }
    };
  };
  
  // Update the selection of template
  export const postSeasonSettingsTemplateData = (eventData) => {
    return async (dispatch) => {
      dispatch(makeRequest());
  
      try {
        const result = await axios.post(`${window.APP.apiUrl}api/initialize/PostSeasonSettingsTemplateData`, {}, {
          headers: {
            //   'Content-Type': 'application/json',
            "X-API-Key": window.APP.apiKey,
          },
          params: {
            ClientId: eventData.ClientId,
            SeasonId: eventData.SeasonId,
            TemplateType: eventData.TemplateType,
            userEmail: eventData.userEmail
          }
        });
  
        if (result.data.isSuccess) {
          toastSuccess("Event updated successfully!");
          dispatch(createEventSuccess());
        } else {
          toastError(`Error: ${result.data.error || "Unknown error"}`);
          dispatch(createEventFailure(result.data.error));
        }
      } catch (error) {
        toastError(`Error: ${error.message || "Unknown error"}`);
        dispatch(createEventFailure(error.message));
      }
    };
  };

  // Gets the media details for selected event
  export const fetchMediaDetailsForSettings = (eventData) => {
    return async (dispatch) => {
      dispatch(makeRequest());
      //setTimeout(() => {
      await axios.get(`${window.APP.apiUrl}api/initialize/GetSeasonSettingsMediaDetails`, {
        headers: {
          "X-API-Key": window.APP.apiKey,
        },
        params: {
          ClientId: eventData.clientId,
          seasonName: eventData.seasonName,
        }
      }).then(res => {
        const mediaDetails = res.data;
        dispatch(getMediaDetailsForSeasonSettings(mediaDetails));
      }).catch(err => {
        dispatch(failRequest(err.message))
      })
      // }, 2000);
  
    }
  }

  // Uploads the media data for selected event
  export const postMediaDetailsForSettings = (files, mediaDetails) => {
    return async (dispatch) => {
      toast.info('Uploading...');
      dispatch(makeRequest());
  
      try {
        const payload = new FormData();
        if(files){
          if(files instanceof Blob){
            payload.append('file', files, 'UAB Intro Music 3.mp3');
          } else {
          payload.append('file', files);
          }
          payload.append('isdataAvailable', false);      
        } else {
          payload.append('isdataAvailable', true); 
        }  
        payload.append('mediaDetails', JSON.stringify(mediaDetails));
        const result = await axios.post(`${window.APP.apiUrl}api/initialize/PostSeasonSettingsMediaDetails`, payload, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'X-API-Key': window.APP.apiKey,
          },
        });
  
        if (result.data.isSuccess) {
          toast.dismiss();
          toastSuccess("Media Information Uploaded successfully!");
          dispatch(createEventSuccess());
        } else {
          toast.dismiss();
          toastError(`Error: ${result.data.error || "Unknown error"}`);
          dispatch(createEventFailure(result.data.error));
        }
      } catch (error) {
        toast.dismiss();
        toastError(`Error: ${error.message || "Unknown error"}`);
        dispatch(createEventFailure(error.message));
      } finally {
        toast.dismiss(); // Dismiss the uploading toast here
      }
    };
  };

  // Gets the intro video data for selected event
  export const getIntroVideoForSettings = (eventData) => {
    return async (dispatch) => {
      dispatch(makeRequest());
      // toast.info('Loading...');
     await axios.get(`${window.APP.apiUrl}api/initialize/GetSeasonSettingsIntroVideoData`, {
        headers: {
          "X-API-Key": window.APP.apiKey,
        },
        params: {
          ClientId: eventData.ClientId,
          seasonName: eventData.seasonName,
        }
      }).then(res => {
        const introData = res.data;
        dispatch(getIntroDataForSS(introData));
        // toast.dismiss();
      }).catch(err => {
        dispatch(failRequest(err.message))
        // toast.dismiss();
      })
  
    }
  };

  // Uploads the intro video or data for creation of video for selected event
  export const postIntroVideoForSettings = (files, introData) => {
    return async (dispatch) => {
      toast.info('Uploading...');
      dispatch(makeRequest());
   
      try {
        const payload = new FormData();
        if (Array.isArray(files)){
          if(files.length > 1){
            files.forEach((file) => {
              payload.append('files', file);
            });  
          } else {
            payload.append('files', files[0]);
          }
        } else {
          payload.append('files', files);
        }
       
        payload.append('introData', JSON.stringify(introData));
        const result = await axios.post(`${window.APP.apiUrl}api/initialize/PostSeasonSettingsIntroClip`, payload, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'X-API-Key': window.APP.apiKey,
          },
        });
   
        if (result.data.isSuccess) {
          toast.dismiss();
          toastSuccess("Details submitted successfully!");
          dispatch(createEventSuccess());
        } else {
          toast.dismiss();
          toastError(`Error: ${result.data.error || "Unknown error"}`);
          dispatch(createEventFailure(result.data.error));
        }
      } catch (error) {
        toast.dismiss();
        toastError(`Error: ${error.message || "Unknown error"}`);
        dispatch(createEventFailure(error.message));
      }
    };
  };
  
// Gets the student video data for selected event
  export const getCustomerVideoForSettings = (eventData) => {
    return async (dispatch) => {
      dispatch(makeRequest());
      // toast.info('Loading...');
     await axios.get(`${window.APP.apiUrl}api/initialize/GetSeasonSettingsCustomerVideoData`, {
        headers: {
          "X-API-Key": window.APP.apiKey,
        },
        params: {
          ClientId: eventData.ClientId,
          seasonName: eventData.seasonName,
        }
      }).then(res => {
        const customData = res.data;
        dispatch(getCustomDataForSS(customData));
        // toast.dismiss();
      }).catch(err => {
        dispatch(failRequest(err.message))
        // toast.dismiss();
      })
  
    }
  };

  // Uploads the data for creation of student video for selected event
  export const postCustomerVideoForSettings = (files, customData) => {
    return async (dispatch) => {
      toast.info('Uploading...');
      dispatch(makeRequest());
   
      try {
        const payload = new FormData();
        if (Array.isArray(files)){
          if(files.length > 1){
            files.forEach((file) => {
              payload.append('files', file);
            });  
          } else {
            payload.append('files', files[0]);
          }
        } else if (Object.keys(files)?.length >= 1) {
              if (files.logo) {
                payload.append('files', files.logo);
              } 
      
              if (files.video) {
                payload.append('files', files.video);
              }
        } else {
          payload.append('files', files);
        }
       
        payload.append('customerClip', JSON.stringify(customData));
        const result = await axios.post(`${window.APP.apiUrl}api/initialize/PostSeasonSettingsCustomerClip`, payload, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'X-API-Key': window.APP.apiKey,
          },
        });
   
        if (result.data.isSuccess) {
          toast.dismiss();
          toastSuccess("Details submitted successfully!");
          dispatch(createEventSuccess());
        } else {
          toast.dismiss();
          toastError(`Error: ${result.data.error || "Unknown error"}`);
          dispatch(createEventFailure(result.data.error));
        }
      } catch (error) {
        toast.dismiss();
        toastError(`Error: ${error.message || "Unknown error"}`);
        dispatch(createEventFailure(error.message));
      }
    };
  };
  
// Gets the exit video data for selected event
  export const getExitVideoForSettings = (eventData) => {
    return async (dispatch) => {
      dispatch(makeRequest());
      // toast.info('Loading...');
      await axios.get(`${window.APP.apiUrl}api/initialize/GetSeasonSettingsExitVideoData`, {
        headers: {
          "X-API-Key": window.APP.apiKey,
        },
        params: {
          ClientId: eventData.ClientId,
          seasonName: eventData.seasonName,
        }
      }).then(res => {
        const exitData = res.data;
        dispatch(getExitDataForSS(exitData));
        // toast.dismiss();
      }).catch(err => {
        dispatch(failRequest(err.message))
        // toast.dismiss();
      })
  
    }
  };

  // Uploads the data for creation of exit video for selected event
  export const postExitVideoForSettings = (files, exitData) => {
    return async (dispatch) => {
      dispatch(makeRequest());
   
      try {
        const payload = new FormData();
        if (Array.isArray(files)){
          if(files.length > 1){
            files.forEach((file) => {
              payload.append('files', file);
            });  
          } else {
            payload.append('files', files[0]);
          }
        } else {
          payload.append('files', files);
        }
       
        payload.append('exitClipDetails', JSON.stringify(exitData));
        const result = await axios.post(`${window.APP.apiUrl}api/initialize/PostSeasonSettingsExitClip`, payload, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'X-API-Key': window.APP.apiKey,
          },
        });
   
        if (result.data.isSuccess) {
          toastSuccess("Details submitted successfully!");
          dispatch(createEventSuccess());
        } else {
          toastError(`Error: ${result.data.error || "Unknown error"}`);
          dispatch(createEventFailure(result.data.error));
        }
      } catch (error) {
        toastError(`Error: ${error.message || "Unknown error"}`);
        dispatch(createEventFailure(error.message));
      }
    };
  };