import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CircularProgress, Typography, Box, Button } from '@mui/material';
import "./style.css";
import CancelIcon from '@mui/icons-material/Cancel';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { CancelledFileName, cancelUpload } from "../../store/Actions/PostEventAction";
import { Property1Variant4 } from "../../icons/Property1Variant4";

const UploadProgressModal = () => {
  const dispatch = useDispatch();
  const uploads = useSelector((state) => state.postEvent.uploads);
  const queue = useSelector((state) => state.postEvent.uploadQueue);
  const fileName = useSelector((state) => state.postEvent.fileNameForCeremony);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [minimized, setMinimized] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const handleMinimize = () => setMinimized(true);
  const handleMaximize = () => setMinimized(false);

  const handleConfirmCancel = () => {
    dispatch(cancelUpload(fileName));
  };

  const handleCancelButtonClick = (fileName) => {
    setIsModalOpen(true);
    dispatch(CancelledFileName(fileName));
  };

  return (
    <div id="FileUpload" className={minimized ? 'minimized' : ''}>
      {!minimized && (uploads.length > 0 || queue.length > 0) && (
        <div className="wrapper">
          <div className="upload-header">
            <Typography variant="h6" gutterBottom>
              Uploading...
              <div className="header-buttons">
                <Button style={{ color: 'white', padding: 0 }} onClick={handleMinimize}><ExpandMoreIcon /></Button>
              </div>
            </Typography>
          </div>
          {uploads.map((upload) => (
            <div key={upload.fileName} className="upload">
              <CircularProgressWithLabel value={upload.progress} />
              {upload.eventId}-
              <p title={upload.fileName}>	&nbsp;{upload.fileName}</p>
              <Button onClick={() => handleCancelButtonClick(upload.fileName)}><CancelIcon style={{ color: "#d32f2f", padding: '0px' }} /></Button>
            </div>
          ))}
          {queue.map((upload, index) => (
            <div key={upload.fileName + index} className="upload queued">
              <Typography variant="caption" component="div">
                Queued: {upload.fileName}
              </Typography>
            </div>
          ))}
        </div>
      )}
      {minimized && (
        <div className="minimized-wrapper">
          <Typography variant="body2" gutterBottom style={{ color: 'white' }}>
            Uploading...
          </Typography>
          <Typography variant="caption" component="div" style={{ color: 'white' }}>
            {uploads.length > 0 && `${Math.round(uploads[0].progress)}%`}
          </Typography>
          <Button style={{ color: 'white', padding: '0px', minWidth: '25px' }} onClick={handleMaximize}><ExpandLessIcon /></Button>
        </div>
      )}
      {isModalOpen && (
        <div className="video-modal" onClick={closeModal}>
          <div className="video-player-container">
            <div className="frame-model">
              <div className="div-model">
                <Property1Variant4 className="failed-loader" color="#EA690C" />
                <p className="once-published">
                  <span className="text-wrapper">
                    Are you sure you want to cancel the upload?
                    <br />
                  </span>
                </p>
              </div>
              <div className="div-2-model">
                <div className="no-BTN">
                  <div className="button-text-logout" onClick={closeModal}>No</div>
                </div>
                <div className="yes-BTN" onClick={() => handleConfirmCancel()}>
                  <div className="button-text-logout">Yes</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex', width: '65px', padding: '10px' }}>
      <CircularProgress variant="determinate" {...props} size='50px' />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary" style={{ fontSize: '15px' }}>
          {Math.round(props.value)}%
        </Typography>
      </Box>
    </Box>
  );
}

export default UploadProgressModal;
