import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { connect, useDispatch } from "react-redux";

import { StyledEngineProvider } from '@mui/material/styles';
import { Box, CircularProgress } from "@mui/material";
import "./style.css";
import { toastError } from '../../../constants/Constants';
import { PreEventDetails } from "../PreEventDetails";
import Stepper from '../Stepper';

import homeImage from '../../../icons/ImageIcons/material-symbols_home-rounded.svg';
import rightarrow from '../../../icons/ImageIcons/keyboard-arrow-right.svg';
import isolationmodeicon from "../../../icons/ImageIcons/isolation-mode.svg"

import { RadioButtons4 } from "../../../icons/RadioButtons4";
import { RadioButtons5 } from "../../../icons/RadioButtons5";

import { AudioClipSection } from "../../../components/AudioClipSection";
import { PropertyDefaultWrapper } from "../../../components/PropertyDefaultWrapper";

import { completeStep, FetchCustomerDetails, FetchGradreelType, FetchMediaDetails, postMediaDetails, setEventSuccess } from "../../../store/Actions/PreEventAction";


const MediaDetails = ({loading, visible = true, loadevent, eventSuccess, eventDetails, mediaDetails, studentDetails, uploadMediaDetails, getCustomerDetails, getMediaDetails, setEventSuccess, eventState, eventMasterData }) => {
  const [videoSrc, setVideoSrc] = useState("");
  const [showStreamingDetails, setShowStreamingDetails] = useState(false);
  const [isButtonSelected, setIsButtonSelected] = useState(false);
  const [uploadButtonText, setUploadButtonText] = useState("Upload");
  const [selectedFile, setSelectedFile] = useState(null);
  const [chooseMusicClipFromLib, setChooseMusicClipFromLib] = useState(false);
  const [streamingProvider, setStreamingProvider] = useState('Mediasite');
  const [streamingUrl, setStreamingUrl] = useState('');
  const [mediaUrl, setMediaUrl] = useState(null);
  const [studentInfoUrl, setStudentInfoUrl] = useState(null);
  const [isUploading, setIsUploading] = useState(loading);
  const [selectedMusicClipId, setSelectedMusicClipId] = useState("");
  const [selectedMusicClipName, setSelectedMusicClipName] = useState("");
  const [isLoadEventCompleted, setIsLoadEventCompleted] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

useEffect(() => {
  const fetchData = async () => {
    try {
      if (!eventDetails) {
        navigate('/PreEventDetailsDashboard');
        return;
      }
      const eventData = { eventId: eventDetails?.eventId, clientId: eventDetails?.clientId };
      await loadevent(eventData);
      await getCustomerDetails(eventData);
      await getMediaDetails(eventData);
      setIsLoadEventCompleted(true); // Update local loading state once all API calls are complete
    } catch (error) {
      console.error('Error fetching data:', error);
      // Handle error if necessary
    }
  };

  fetchData();
}, []);


  useEffect(() => {
    if (!isLoadEventCompleted) return;
    if(!eventMasterData?.result?.isDataAvailable){
      navigate('/SelectTemplate')
    }
  }, [eventMasterData, isLoadEventCompleted])

  useEffect(() => {
    if (eventSuccess) {
      console.log("Event created successfully!");
      setEventSuccess(false)
      const path = isButtonSelected ? "/CreateIntroClip" : "#";
      navigate(path);
      dispatch(completeStep(2, true));
    }
  }, [eventSuccess, isButtonSelected]);

  useEffect(() => {
    if(mediaDetails?.result?.mediaData?.chooseMusicClipFromLib){
      setChooseMusicClipFromLib(true)
      setSelectedMusicClipId(mediaDetails?.result?.mediaData?.selectedMusicCLipId)
    } else{
      setChooseMusicClipFromLib(false);
      setSelectedMusicClipId(null);
    }

  }, [mediaDetails?.result?.mediaData])


  useEffect(() => {
    if (studentDetails?.result?.studentData?.studentExcelFilePathSaSUri && mediaDetails?.result?.mediaData?.uploadedMusicCLipPathSaSUri) {
      setIsButtonSelected(true);
      setIsChecked(true);
    } else {
      setIsButtonSelected(false);
    }

    setStudentInfoUrl(studentDetails?.result?.studentData?.studentExcelFilePathSaSUri)
    setMediaUrl(mediaDetails?.result?.mediaData?.uploadedMusicCLipPathSaSUri)
  }, [studentDetails?.result?.studentData?.studentExcelFilePathSaSUri, mediaDetails?.result?.mediaData?.uploadedMusicCLipPathSaSUri])

  useEffect(() => {
    setShowStreamingDetails(mediaDetails?.result?.mediaData?.isStreamingEvent)
    setStreamingUrl(mediaDetails?.result?.mediaData?.streamingUrl)
    setStreamingProvider(mediaDetails?.result?.mediaData?.streamingProvider || 'Mediasite')
  }, [mediaDetails?.result?.mediaData?.isStreamingEvent, mediaDetails?.result?.mediaData?.streamingProvider, mediaDetails?.result?.mediaData?.streamingUrl])


  useEffect(() => {
    setIsButtonSelected(isValidDetails(isChecked));
  }, [isChecked, selectedFile, mediaUrl, streamingUrl, showStreamingDetails, selectedMusicClipId, chooseMusicClipFromLib, studentInfoUrl])

  const isValidDetails = (isChecked) => {
   if(chooseMusicClipFromLib){
    return ((showStreamingDetails && streamingUrl) || !showStreamingDetails) && selectedMusicClipId && studentInfoUrl;
   }else{
      return ((showStreamingDetails && streamingUrl) || !showStreamingDetails) && ((mediaUrl || selectedFile) && studentInfoUrl) && isChecked;
   }
    
  }

  // Checks if event is streamed online
  const handleCheckboxChange = (isChecked) => {
    setShowStreamingDetails(isChecked);
    if(!isChecked){
      setStreamingUrl('')
    }
  };

  // Set the selected file by user
  const handleFileChangeInMediaDetails = (file) => {
    setSelectedFile(file);
  };

  // Changes the music clip option to upload or select
  const handleMusicClipOptionChange = (isChooseMusicClip) => {
    setChooseMusicClipFromLib(isChooseMusicClip);
    setSelectedFile(null);
    if(!isChooseMusicClip){
    setSelectedMusicClipId("");
    setSelectedMusicClipName('');
    } else {
      setSelectedMusicClipId(mediaDetails?.result?.mediaData?.selectedMusicCLipId);
    }
  };

  // Sets the selected clip ID and name
  const handleSelectMusicClip = (clipId, clipName) => {
    setSelectedMusicClipId(clipId);
    setSelectedMusicClipName(clipName);
  };
  
  // Add the selected provider
  const handleStreamingProviderChange = (event) => {
    setStreamingProvider(event.target.value);
  };

  // Add the streaming url
  const handleStreamingUrlChange = (event) => {
    setStreamingUrl(event.target.value);
  };

  // Calls an API to get the updates data for student info
  const handleStudentInfo = () => {
    // setIsButtonSelected(true)
    setStudentInfoUrl('true')
    const eventData = { eventId: eventDetails?.eventId, clientId: eventDetails?.clientId }
    getCustomerDetails(eventData)
  }


  const handleFileChange = (file) => {
    try {
      // Transform file into blob URL
      setVideoSrc(URL.createObjectURL(file));
    } catch (error) {
      console.error(error);
    }
  };

  // Downloads the sample file of student
  const downloadSampleFile = (e) => {
    // e.preventDefault();
    const fileUrl = '/SampleStudentFile.xlsx';
    const link = document.createElement('a');
    link.href = fileUrl;
    link.setAttribute('download', 'SampleStudentFile.xlsx'); // Filename for the downloaded file
    // Append the link to the body
    document.body.appendChild(link);
    // Trigger the click event to start the download
    link.click();
    // Clean up: remove the link from the DOM
    document.body.removeChild(link);

  }

  // Uploads the media related data and calls the function to send data through API
  const handleSaveAndContinue = async () => {
    if (!isButtonSelected) {
      return;
    }

    if(eventState){
      navigate("/CreateIntroClip")
    } else if(!studentInfoUrl) {
      toastError('Please add student details.');
      return;
    } else {

    if(showStreamingDetails){
      if (!streamingUrl) {
        toastError('Please select the streaming details.');
        return;
    }
    }

     const mediaData = {
        id: '',
        itemType: 'MediaDetails',
        EventId: eventDetails.eventId,
        EventName: eventDetails.eventName,
        ClientId: eventDetails.clientId,
        ClientName: eventDetails.clientName,
        ChooseMusicClipFromLib: chooseMusicClipFromLib,
        SelectedMusicCLipId: selectedMusicClipId || '',
        // UploadedMusicCLipPath: '',
        IsStreamingEvent: showStreamingDetails,
        streamingProvider: streamingProvider,
        streamingUrl: streamingUrl,
        UpdatedOn: new Date(),
        UpdatedBy: eventDetails?.adminEmail
    };
    // setLoading(true);
      if (selectedFile) {
        await uploadMediaDetails(selectedFile, mediaData);
      } else {
        if (chooseMusicClipFromLib) {
          if (Number(mediaDetails?.result?.mediaData?.selectedMusicCLipId) === Number(selectedMusicClipId)) {
            await uploadMediaDetails(null, mediaData);
          } else if (selectedMusicClipName === '') {
            toastError('Please choose music clip.')
          }
          else {
            try {
              setIsUploading(true);
              const file = await fetch(selectedMusicClipName);
              const fileBlob = await file.blob();

              const formData = new FormData();
              let musicClipName;
              if (selectedMusicClipName.startsWith("/")) {
                musicClipName = selectedMusicClipName.substring(1);
              }
              formData.append('file', fileBlob, musicClipName);

              await uploadMediaDetails(fileBlob, mediaData);
              setIsUploading(false);
            } catch (error) {
              console.error('Error:', error);
            }
          }
        } else if (mediaUrl) {
          await uploadMediaDetails(null, mediaData);
        } else {
          toastError('Please select media file.')
        }
      }
    // setLoading(false);

    if(studentInfoUrl && mediaUrl){
      const path = isButtonSelected ? "/CreateIntroClip" : "#";
      navigate(path);
    }
  }
  };

  
  return (
    <div className="media-details">
      <PreEventDetails />

      <div className="frame-29">
        <div className="frame-30">
          <div className="section-title">
            <div className="frame-31">
              <div className="page-title">
                <div className="text-wrapper-7">Media Details</div>
              </div>
              <div className="div-2">
                <img
                  className="material-symbols"
                  alt="Material symbols"
                  src={homeImage}
                  />
                <img
                  className="keyboard-arrow-right"
                  alt="Keyboard arrow right"
                  src={rightarrow}
                  />
                <div className="text-wrapper-8">Events</div>
                <img
                  className="keyboard-arrow-right"
                  alt="Keyboard arrow right"
                  src={rightarrow}
                  />
                <div className="text-wrapper-9">Media Details</div>
              </div>
            </div>
          </div>
          <div className="frame-32">
            <StyledEngineProvider injectFirst>
              <Stepper gradReelType={eventMasterData?.result?.eventData?.template}/>
            </StyledEngineProvider>

            <div className="frame-33">
              <div className="frame-34">
                <div className="frame-35">
                  <div className="text-wrapper-10">Students Info</div>
                </div>
                <div className="frame-36">
                  <div className="div-2">
                    {studentInfoUrl ? <><a href={studentInfoUrl} target="_blank" rel="noopener noreferrer" style={{fontFamily: 'Poppins'}}> Download File
                      {/* {studentInfoUrl} */}
                    </a>
                    <div className="delete-button button-text-wrapper" style={{ pointerEvents: eventState ? 'none' : 'auto', opacity: eventState ? 0.5 : 1 }}>
                      <button className="button" type="button" onClick={() => { setStudentInfoUrl(null); setIsButtonSelected(false); }}>Delete</button>
                      </div></> :<>
                    <PropertyDefaultWrapper
                      className="group-3"
                      divClassName="group-4"
                      frameWrapperText="Please choose .XLSX file to upload"
                      frameWrapperVisible={false}
                      property1="default"
                      text="Upload Student information"
                      text1={uploadButtonText}
                      handleStudentInfo = {handleStudentInfo}
                      visible={false}
                    />
                    <div onClick={downloadSampleFile}>
                    <img
                    className="isolation-mode"
                    alt="Download sample file"
                    title="Download sample file"
                    src={isolationmodeicon}
                  />
                  </div>
                  </>
                  }

                  </div>
                </div>
              </div>
              <br />
              {mediaUrl && !chooseMusicClipFromLib ? <><div className="frame-24">
                <div className="frame-34" >
                  <div className="text-wrapper-10">Background Music Details</div>
                </div>
              </div><audio controls>
                  <source src={mediaUrl} type="audio/ogg" />
                </audio>
                <br /><div className="delete-button button-text-wrapper" style={{ pointerEvents: eventState ? 'none' : 'auto', opacity: eventState ? 0.5 : 1 }}><button className="button" type="button" onClick={() => { setMediaUrl(null); setIsButtonSelected(false); setIsChecked(false); }}>Delete</button></div></> :
                <div className="frame-34" style={{marginTop:studentInfoUrl ? '0px':'50px'}}>
                  <AudioClipSection
                    className="design-component-instance-node-2"
                    frameWrapperCheckboxPropertyFiRrClassName="audio-clip-section-instance"
                    override={<RadioButtons5 className="icon-instance-node" />}
                    property1="default"
                    radioBtnMusicClipRadioOptionIcon={<RadioButtons4 className="icon-instance-node" />}
                    onFileChange={handleFileChangeInMediaDetails}
                    onMusicClipOptionChange={handleMusicClipOptionChange}
                    onSelectMusicClip={handleSelectMusicClip}
                    selectedMusicId = {selectedMusicClipId}
                  />
                 { !chooseMusicClipFromLib && <span className="checkboxtext" >
                    <input type="checkbox" className="frame-instance" onChange={(e) => setIsChecked(e.target.checked)} />
                    <span className="checkbox-label">We confirm ownership of all usage and distribution rights for the uploaded music clip.</span>
                  </span>}
                </div>}
              <div className="frame-37" style={{ pointerEvents: eventState ? 'none' : 'auto', opacity: eventState ? 0.5 : 1 }}>
                <div className="frame-38">
                  <div className="frame-35">
                    <div className="text-wrapper-10">Streaming Details</div>
                  </div>
                  <span className="checkboxtext2">
                    <input type="checkbox" className="frame-instance2" checked={showStreamingDetails} onChange={(e) => handleCheckboxChange(e.target.checked)} />
                    <span className="checkbox-label2">Please check the option if event is streamed online</span>
                  </span>
                </div>
                <div className="frame-44" style={{ display: showStreamingDetails ? "inline-flex" : "none" }}>
                  <div className="event-ID-wrapper">
                    <div className={`event-ID group-4`}>Select Streaming Providers</div>
                    <select id="streamingprovider" className="element" value={streamingProvider} onChange={handleStreamingProviderChange}>
                      <option value="Mediasite">Mediasite</option>
                      <option value="Kaltura">Kaltura</option>
                      <option value="Vimeo">Vimeo</option>
                      <option value="IBM">IBM</option>
                    </select>
                  </div>
                  <div className="event-ID-wrapper">
                    <div className={`event-ID group-4`}>Enter Ceremony Streaming Url</div>
                    <input id="streamingurl" type="text" className="element" placeholder="https://" value={streamingUrl}
            onChange={handleStreamingUrlChange}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="frame-36">
         <Link to="/SelectTemplate" className="button-text-2">
            <div className="primary-BTN-2">
              Back
            </div>
          </Link>
<button onClick={handleSaveAndContinue} className="button-text-3">
            <div className={`primary-BTN-3 ${isButtonSelected ? "" : "disabled"}`}>          
                Save & Continue          
            </div>
          </button>
        </div>
              {(isUploading || loading) && (
                  <div className="loader-container">
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px' }}>
                          <CircularProgress />
                      </Box>
                  </div>
              )}
      </div>
    </div>
  );
};


const mapStateToProps = (state) => {
  return {
    loading: state.preEvent.loading,
    eventSuccess: state.preEvent.eventSuccess,
    eventDetails: state.preEvent.eventDetails,
    mediaDetails: state.preEvent.mediaDetails,
    studentDetails: state.preEvent.studentDetails,
    eventState: state.preEvent.eventState,
    eventMasterData: state.preEvent.gradreelType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadevent: (eventData) => dispatch(FetchGradreelType(eventData)),
    uploadMediaDetails: (file, infoData) => dispatch(postMediaDetails(file, infoData)),
    getCustomerDetails: (eventData) => dispatch(FetchCustomerDetails(eventData)),
    getMediaDetails: (eventData) => dispatch(FetchMediaDetails(eventData)),    
    setEventSuccess: (flag) => dispatch(setEventSuccess(flag)),
  };
};

const ConnectedMediaDetails = connect(mapStateToProps, mapDispatchToProps)(MediaDetails);

export { ConnectedMediaDetails as MediaDetails };
