export const MAKE_REQUEST_FOR_POST = 'MAKE_REQUEST_FOR_POST'
export const FAIL_REQUEST_FOR_POST = 'FAIL_REQUEST_FOR_POST'

export const CREATE_EVENT_START_FOR_POST = 'CREATE_EVENT_START_FOR_POST';
export const CREATE_EVENT_SUCCESS_FOR_POST = 'CREATE_EVENT_SUCCESS_FOR_POST';
export const CREATE_EVENT_FAILURE_FOR_POST = 'CREATE_EVENT_FAILURE_FOR_POST';

export const SET_EVENT_SUCCESS_FOR_POST = 'SET_EVENT_SUCCESS_FOR_POST'

export const INITIALIZE_VALUES_OF_POSTEVENT = 'INITIALIZE_VALUES_OF_POSTEVENT'

export const GET_POST_EVENT_LIST = 'GET_POST_EVENT_LIST'
export const SET_POST_EVENT_DETAILS = 'SET_POST_EVENT_DETAILS';

export const GET_POST_EVENT_DATA = 'GET_POST_EVENT_DATA'
export const GET_CEREMONY_VIDEO_UPLOAD_URI = 'GET_CEREMONY_VIDEO_UPLOAD_URI'
export const GET_HANDSHAKE_DETAILS = 'GET_HANDSHAKE_DETAILS'
export const GET_FIRST_CANDIDATE_ANNOUNCEMENT_TIME = 'GET_FIRST_CANDIDATE_ANNOUNCEMENT_TIME'

export const SET_SEARCH_QUERY_FOR_POST = 'SET_SEARCH_QUERY_FOR_POST'

export const GET_VIDEO_PROCESSING_STATUS = 'GET_VIDEO_PROCESSING_STATUS'
export const GET_URL_FOR_CLIP = 'GET_URL_FOR_CLIP'

export const UPLOAD_PROGRESS = 'UPLOAD_PROGRESS';
export const UPLOAD_FAILED = 'UPLOAD_FAILED';
export const UPLOAD_SUCCESS = 'UPLOAD_SUCCESS';
export const CANCEL_UPLOAD = 'CANCEL_UPLOAD';
export const START_UPLOAD = 'START_UPLOAD';
export const CANCEL_FILE = 'CANCEL_FILE';
export const ADD_TO_QUEUE = 'ADD_TO_QUEUE';
export const PROCESS_QUEUE = 'PROCESS_QUEUE';
export const START_UPLOAD_FROM_QUEUE = 'START_UPLOAD_FROM_QUEUE';