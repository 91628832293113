/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { FiRrCheck2 } from "../../icons/FiRrCheck2";
import "./style.css";
import EllipseLoader from './Ellipse436.svg';

export const PropertyGroup = ({
  className,
  overlapGroupClassName,
  ellipseClassName,
  icon = <FiRrCheck2 className="fi-rr-check" color="#1BA421" />,
}) => {
  return (
    <div className={`property-group ${className}`}>
      <div className={`overlap-group ${overlapGroupClassName}`}>
        <img className={`ellipse ${ellipseClassName}`} alt="Ellipse" src={EllipseLoader} />
        {icon}
      </div>
    </div>
  );
};

PropertyGroup.propTypes = {
  ellipse: PropTypes.string,
};
