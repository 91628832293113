import React from "react";
import { Header } from "../../components/Header";
import { LeftNav } from "../../components/LeftNav";
export const CustomVideoReview = () => {
  return (
    
          <div>
          <Header className="header-instance" />
        
        <LeftNav
          className="left-nav-instance"
          frameClassName="left-nav-3"
          homeclassName="home-3"
          homeinactiveclassName="home-instance"
          homeHasGroup={false}
          homeText="65%"
          homeText1="85%"
          overlapGroupClassName="left-nav-2"
          tab='customVideoReview' // Indicate that this is pre-event
        />
    </div>
  );
};
