import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import { Player } from "video-react";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { DataGrid } from '@mui/x-data-grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import SmartDisplayIcon from '@mui/icons-material/SmartDisplay';
import Chip from '@mui/material/Chip';
import { useNavigate, createSearchParams } from "react-router-dom";
import "./style_datagridpending.css";
import { ReasonForRejection } from '../../components/ReasonForRejection/ReasonForRejection';
import Button from '@mui/material/Button';
import video_poster from './video_poster.png'
import alert from "./PendingVideosDashboard/brightness-alert.png"
import { connect } from 'react-redux';
import { saveStudentVideoStatus, setEventSuccess } from '../../store/Actions/PreEventAction';
import ellipseicon1 from "../../icons/ImageIcons/ellipse1.svg";
import { FiRrCheck2 } from "../../icons/FiRrCheck2";
import { toastSuccess } from "../../constants/Constants";

const DataGridPending = ({ rows, setSelectedRowCount, selectedRowCount, saveStudentVideoStatus, eventSuccess, setEventSuccess }) => {
  const navigate = useNavigate();
  const [showReasonForRejection, setShowReasonForRejection] = useState(false);
  const [selectedRowForRejection, setSelectedRowForRejection] = useState(null);
  const [selectedRow, setSelectedRow] = useState('');
  const [doubleClick, setDoubleClick] = useState(false);
  const [isAcceptModalOpen, setAcceptModalOpen] = useState(false);
  const [reason, setReason] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [videoSrc, setVideoSrc] = useState("");

  const [isThirdModalOpen, setThirdModalOpen] = useState(false);
  const [isSecondModalOpen, setSecondModalOpen] = useState(false);
  const [isAccepting, setIsAccepting] = useState(null);

  React.useEffect(() => {
    if (eventSuccess) {
      setEventSuccess(false);
      toastSuccess('Student info is updated.')
    }

  }, [eventSuccess])

  useEffect(() => {
    if (isSecondModalOpen) {
      const timer = setTimeout(() => {
        handleSecondModalYesClick();
      }, 5000000);

      // Clear the timer if the component unmounts or modal closes before 10 seconds
      return () => clearTimeout(timer);
    }
  }, [isSecondModalOpen]);

  useEffect(() => {
    if (isThirdModalOpen) {
      const timer = setTimeout(() => {
        handleThirdModalYesClick();
      }, 5000);

      // Clear the timer if the component unmounts or modal closes before 10 seconds
      return () => clearTimeout(timer);
    }
  }, [isThirdModalOpen]);

  useEffect(() => {
    if (eventSuccess) {
       if (isAccepting) {
         setSecondModalOpen(true);
       } else {
         setThirdModalOpen(true);
       }
    }
   }, [eventSuccess, isAccepting]);

   // Opens a successful rejection modal
   const handleThirdModalYesClick = () => {
    // Handle logic for the third modal
    setThirdModalOpen(false);
  };

  // Opens a successful accepted modal
  const handleSecondModalYesClick = () => {
    // Handle logic for the second modal
    setSecondModalOpen(false);

  };

  // Opens modal for video preview
  const openModal = (videoUrl) => {
    setIsModalOpen(true);
    setVideoSrc(videoUrl);
  };

  // Closes model for video preview
  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Function to stop event propagation
  const stopPropagation = (event) => {
    event.stopPropagation();
  };

  // Selection of rows
  const handleOnCellClick = (params, event) => {

    const currentRowId = params.row.id;
    // Assuming the checkbox field is named 'checkboxSelection'
    // Adjust this condition based on your actual checkbox field name
    if (params.field !== '__check__') {
      event.stopPropagation(); // Prevent the row from being selected
    } else {
      // Handle multiple selection via checkboxes
      if (params.row.isSelected) {
        // If the row is already selected, remove it from selectedRowCount
        setSelectedRowCount(selectedRowCount.filter(id => id !== currentRowId));
      } else {
        // If the row is not selected, add it to selectedRowCount
        setSelectedRowCount([...selectedRowCount, currentRowId]);
      }
    }
  };

  const theme = createTheme();

  // Override MUI theme styles for DataGrid
  const customTheme = createTheme({
    components: {
      MuiDataGrid: {
        styleOverrides: {
          root: {
            border: 'none',
          },
          row: {
            '&:nth-of-type(even)': {
              backgroundColor: '#F8F8F8', // Alternate row color
            },
            '&:nth-of-type(odd)': {
              backgroundColor: '#FFFFFF', // Starting row color
            },
            fontWeight: 'normal', // Change font weight to normal
          },
          header: {
            backgroundColor: '#E4E4E4', // Header background color
            borderBottom: `1px solid ${theme.palette.divider}`, // Bottom border for header
          },
          cell: {
            borderBottom: 'none', // No bottom border for cells
          },
        },
      },
    },
  });

  // Renders action column of accept and reject on UI
  const renderActionsColumn = (params) => {
    return (
      <div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: '79px' }}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: '24px' }}>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => handleAccept(params.row.id)} style={{ cursor: 'pointer' }}>
              <g clipPath="url(#clip0_4153_49632)">
                <path d="M12.6667 0H3.33334C1.49331 0.00221875 0.00221875 1.49331 0 3.33334V12.6667C0.00221875 14.5067 1.49331 15.9978 3.33334 16H12.6667C14.5067 15.9978 15.9978 14.5067 16 12.6667V3.33334C15.9978 1.49331 14.5067 0.00221875 12.6667 0ZM13.3333 5.386L7.164 11.5553C6.64341 12.0762 5.79919 12.0763 5.27838 11.5557C5.27825 11.5556 5.27812 11.5555 5.278 11.5553L2.66666 8.94469C2.40578 8.68381 2.40578 8.26088 2.66666 8.00003C2.92753 7.73919 3.35047 7.73916 3.61131 8.00003L6.22197 10.6107L12.392 4.44134C12.6528 4.18141 13.075 4.18216 13.335 4.443C13.5949 4.70387 13.5942 5.12606 13.3333 5.386Z" fill="#1BA421" />
              </g>
              <defs>
                <clipPath id="clip0_4153_49632">
                  <rect width="16" height="16" rx="8" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <span style={{ fontSize: '9px', fontWeight: '500', color: '#1BA421', fontFamily: 'Poppins', lineHeight: '14px', marginTop: '4px', cursor: 'pointer' }} onClick={() => handleAccept(params.row.id)}>Accept</span>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => handleReject(params.row.id)} style={{ cursor: 'pointer' }}>
              <g clipPath="url(#clip0_4153_49637)">
                <path d="M8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C15.9952 3.58369 12.4163 0.00478125 8 0ZM10.6667 9.72466C10.9376 9.974 10.9551 10.3958 10.7057 10.6667C10.4564 10.9376 10.0346 10.9551 9.76372 10.7057C9.75016 10.6933 9.73716 10.6802 9.72466 10.6667L8 8.94266L6.276 10.6667C6.01116 10.9224 5.58913 10.9151 5.33334 10.6503C5.08384 10.3919 5.08384 9.98234 5.33334 9.724L7.05734 8L5.33334 6.276C5.07756 6.01116 5.08491 5.58913 5.34975 5.33334C5.60809 5.08384 6.01766 5.08384 6.276 5.33334L8 7.05734L9.72466 5.33334C9.974 5.06244 10.3958 5.04494 10.6667 5.29428C10.9376 5.54363 10.9551 5.96537 10.7057 6.23628C10.6933 6.24984 10.6802 6.26284 10.6667 6.27534L8.94266 8L10.6667 9.72466Z" fill="#EA0C0C" />
              </g>
              <defs>
                <clipPath id="clip0_4153_49637">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <span style={{ fontSize: '9px', fontWeight: '500', color: '#EA0C0C', fontFamily: 'Poppins', lineHeight: '14px', marginTop: '4px', cursor: 'pointer' }} onClick={() => handleReject(params.row.id)}>Reject</span>
          </div>
        </div>

        {/* {showReasonForRejection && <ReasonForRejection />} */}
        {selectedRowForRejection === params.row.id && showReasonForRejection && <ReasonForRejection onClose={() => setShowReasonForRejection(false)} onReason={(data) => setReason(data)} confirmReject={confirmRejectEvent}/>}
    
      </div>


    );
  };

  // Renders a preview column on UI
  const renderVideoPreviewColumn = (params) => {
    return (
      <div className="table-cells">
        <div className="thumb-wrapper" onClick={() => openModal(params.row.videoUrl)}>
          <div className="thumb">
            <div className="overlap-group-wrapper">
              <div className="overlap-group">
                <img src={video_poster} alt="Video Thumbnail" className="thumb-image" style={{ width: '100%' }} />
                {/* <SmartDisplayIcon /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  //Opens a modal when user clicks on accept button
  const handleAccept = (id) => {
    // Handle accept logic
    console.log(`Accepted row with id ${id}`);
    setSelectedRow(id);
    setAcceptModalOpen(true);
  };

  // Submit the student video staus as approved
  const handleAcceptModalYesClick = () => {
    setAcceptModalOpen(false);
    setIsAccepting(true);
    const selectedRowForAcceptance = rows.find(row => row.id === selectedRow);
    const data = [{
      eventID: selectedRowForAcceptance.eventId,
      participantsIdentifier: selectedRowForAcceptance.id,
      status: "Approved",
      reason: "" // Empty reason for approval
    }];
    saveStudentVideoStatus(data);
  };

  // Submit the student video staus as rejected
  const confirmRejectEvent = () => {
    setShowReasonForRejection(false);
    setIsAccepting(false);
    const selectedRowForRejection = rows.find(row => row.id === selectedRow);
    const data = [{
      eventID: selectedRowForRejection.eventId,
      participantsIdentifier: selectedRowForRejection.id,
      status: "Rejected",
      reason: reason // Empty reason for approval
    }];
    saveStudentVideoStatus(data);
  };

  // Handles the rejection and shows the tab to write reason of rejection
  const handleReject = (id) => {
    console.log(`Rejected row with id ${id}`);
    setSelectedRow(id);

    if (doubleClick && selectedRowForRejection === id) {
      // If double-clicked on the same row, close the ReasonForRejection component
      setShowReasonForRejection(false);
      setDoubleClick(false);
    } else {
      // If a single click or double-click on a different row, open the ReasonForRejection component
      setSelectedRowForRejection(id);
      setShowReasonForRejection(true);

      // Set doubleClick to true and reset after a short delay
      setDoubleClick(true);
      //setTimeout(() => setDoubleClick(false), 300); // Adjust the delay as needed
    }
  };
  const columns = [
    {
      field: "videoUrl",
      headerName: "Video",
      flex: 1,
      renderCell: renderVideoPreviewColumn,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'eventId',
      headerName: 'Event ID',
    },
    {
      field: 'eventName',
      headerName: 'Event Name',
      // minWidth: 200,
      flex: 1
    },

    {
      field: 'clientName',
      headerName: 'School / University',
      // minWidth: 200,
      flex: 1
    },
    {
      field: 'participantName',
      headerName: 'Customer Name',
      // minWidth: 200,
      flex: 1
    },
    {
      field: 'eventDateTime',
      headerName: 'Event Date & Time',
      type: 'dateTime',
      valueGetter: ({ value }) => value && new Date(value),
      width: 250,
    },
    {
      field: 'Actions',
      headerName: 'Actions',
      width: 200,
      renderCell: renderActionsColumn,
      hide: true,
      disableColumnMenu: true, // Disable column menu for this column
      sortable: false,
    },
  ];

  return (
    <ThemeProvider theme={customTheme}>
      <Box sx={{ height: '100%', width: '100%' }}>
        <DataGrid
          onCellClick={(params, event) => handleOnCellClick(params, event)}
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setSelectedRowCount(newRowSelectionModel);
          }}
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10, 20]}
          checkboxSelection
          sx={{
            '& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell': {
              fontWeight: 400, fontSize: '14px', fontFamily: 'Poppins', color: '#717171'
            }, '& .MuiDataGrid-columnHeader': {
              backgroundColor: '#F8F8F8', fontSize: '16px', fontFamily: 'Poppins', color: '#171B1E'
            }, '& .MuiDataGrid-columnHeaderTitle': {
              fontWeight: '600'
            }, '& .MuiDataGrid-columnHeadersInner': {
              width: '100%', backgroundColor: 'rgb(248, 248, 248)'

            },
          }}
        />
      </Box>
      <Dialog open={isAcceptModalOpen} onClose={() => setAcceptModalOpen(false)}
        className="dialogConfig"
      // sx={{ width: '50%', height: '40%', marginLeft: 'auto', marginRight: 'auto', marginTop: '15vh', padding: '30px 50px' }} 
      // sx={{ padding: '30px 50px', margin: '50px !important' }}
      >
        {/* <div style={{padding: '5px 15px'}}> */}
        <img className="brightness-alert" alt="Brightness alert" src={alert} /><br></br>
        <DialogTitle style={{ textAlign: "center", padding: '50px 35px 15px 35px' }}>Student Clip Approval</DialogTitle>
        <DialogContent style={{
          padding: '0px 35px 20px 35px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        }}>
          {/* Add content for the modal */}
          <p className="modal-label">Your approved student clips will be queued for processing the Gradreels</p>
          <br></br>
          <p className="modal-label">Do you want to continue?</p>

        </DialogContent>
        <DialogActions style={{ textAlign: "center", padding: '0px 35px 35px 35px' }}>
          <button onClick={() => setAcceptModalOpen(false)} className="button-modal">No</button>
          <button onClick={handleAcceptModalYesClick} className="button-modal">Yes</button>
        </DialogActions>
        {/* </div> */}
      </Dialog>
      <Dialog open={isSecondModalOpen} onClose={() => setSecondModalOpen(false)} className="dialogConfig2"
      // sx={{ width: '400px', height: '400px', marginLeft: 'auto', marginRight: 'auto', marginTop: '15vh', padding: '20px' }}
      >
        {/* -------------------------------------------------------------------------------------------------- */}
        <div className="frame-wrapper">
          <div className="frame">
            <div className="sucess-loader">
              <div className="overlap-group1">
                <img
                  className="ellipse1"
                  alt="Ellipse"
                  src={ellipseicon1}
                />
                <FiRrCheck2 color="#1ba421" className="fi-rr-check" />
              </div>

            </div>
            {/* <div className="text-wrapper">Successful</div> */}
          </div>
        </div>
        {/* -------------------------------------------------------------------------------------------------- */}

        <DialogTitle
          style={{
            textAlign: "center",
            padding: '2px 35px 15px 35px'
          }}>Success</DialogTitle>
        <DialogContent style={{
          // padding: '0px 35px 20px 35px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        }}>
          {/* Content for the second modal */}
          <p className="modal-label">Your approved student clips has been successfully queued for processing the Gradreels </p>

        </DialogContent>

      </Dialog>
      <Dialog open={isThirdModalOpen} onClose={() => setThirdModalOpen(false)} className="dialogConfig2"
      // sx={{ width: '400px', height: '400px', marginLeft: 'auto', marginRight: 'auto', marginTop: '15vh', padding: '20px', }}
      >
        <div className="frame-wrapper">
          <div className="frame">
            <div className="sucess-loader">
              <div className="overlap-group1">
                <img
                  className="ellipse1"
                  alt="Ellipse"
                  src={ellipseicon1}
                />
                <FiRrCheck2 color="#1ba421" className="fi-rr-check" />
              </div>

            </div>
            {/* <div className="text-wrapper">Successful</div> */}
          </div>
        </div>
        <DialogTitle style={{ textAlign: "center", padding: '2px 35px 15px 35px' }}>Success</DialogTitle>
        <DialogContent style={{
          padding: '0px 35px 20px 35px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        }}>
          {/* Content for the third modal */}
          <p className="modal-label">Your selected student clips has been successfully rejected from the Gradreels. </p>
        </DialogContent>
      </Dialog>
      {isModalOpen && (
        <div className="video-modal" onClick={closeModal}>
          <div className="video-player-container" onClick={stopPropagation}>
            <button className="close-button" onClick={closeModal}>
              &times;
            </button>
            <div className="video-player">
              <Player src={videoSrc} />
            </div>
          </div>
        </div>
      )}
      
    </ThemeProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    eventDetails: state.preEvent.eventDetails,
    loading: state.preEvent.loading,
    eventSuccess: state.preEvent.eventSuccess,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveStudentVideoStatus: (data) => dispatch(saveStudentVideoStatus(data)),
    setEventSuccess: (flag) => dispatch(setEventSuccess(flag)),
  };
};

const ConnectedDataGridPending = connect(mapStateToProps, mapDispatchToProps)(DataGridPending);

export { ConnectedDataGridPending as DataGridPending };